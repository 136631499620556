import { clientDataService } from '../clientDataService'
import { NotificationInterface } from 'types/NotificationInterface'
import { NotificationSettingView } from 'types/NotificationSettingView'
import { UpdateNotificationSettingRequest } from 'types/requests/UpdateNotificationSettingRequest'

export const getNotifications = (): Promise<NotificationInterface[]> =>
  clientDataService<NotificationInterface[]>({
    url: '/notifications',
  })

export const markAllNotificationsRead = (): Promise<void> =>
  clientDataService<void>({
    url: `/notifications/mark-as-read`,
    method: 'PUT',
  })

export const markNotificationRead = (notificationId: string): Promise<void> =>
  clientDataService<void>({
    url: `/notifications/${notificationId}/mark-as-read`,
    method: 'PUT',
  })

export const deleteNotification = (notificationId: string): Promise<void> =>
  clientDataService<void>({
    url: `/notifications/${notificationId}`,
    method: 'DELETE',
  })

export const getNotificationSettings = (): Promise<NotificationSettingView[]> =>
  clientDataService<NotificationSettingView[]>({
    url: '/notifications/settings',
  })

  export const updateNotificationSettings = (data: UpdateNotificationSettingRequest): Promise<void> =>
  clientDataService<void>({
    url: '/notifications/settings/switch-mailing',
    method: 'PUT',
    data
  })
