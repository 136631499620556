import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { styled, alpha, InputBase } from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import { translations } from 'locales/i18n'

const Search = styled('div')(({theme}) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.background.paper, 0.9),
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.paper, 1),
  },
  marginLeft: 0,
  width: '100%',
  height: '33px',
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    width: 'auto',
  },
  [theme.breakpoints.up('md')]: {
    height: '46px',
  },
}))

const SearchIconWrapper = styled('div')(({theme}) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const StyledInputBase = styled(InputBase)(({theme}) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    fontSize: '14px',
    lineHeight: '17px',
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '200px',
    },
  },
}))

type SearchbarProps = {
  setSearchQuery: (value: string) => void
}

const Searchbar: FC<SearchbarProps> = ({setSearchQuery}) => {
  const {t} = useTranslation()

  const [currentSearchQuery, setCurrentSearchQuery] = React.useState('')

  const handleSearchChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const {value} = e.target
    setCurrentSearchQuery(value)
    setSearchQuery(value)
  }

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon/>
      </SearchIconWrapper>
      <StyledInputBase
        value={currentSearchQuery}
        onChange={handleSearchChange}
        placeholder={t(translations.common.search)}
        inputProps={{'aria-label': t(translations.common.search)}}
      />
    </Search>
  )
}

export default Searchbar
