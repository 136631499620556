import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import 'swiper/swiper.min.css'

import { translations } from 'locales/i18n'
import SwipeableViews from '../shared/swipeable-views/SwipeableViews'
import PreviousYearsOfficialTestingCard from 'components/quizzes/previous-years/PreviousYearsOfficialTestingCard'
import { Specialty } from 'types/Specialty'
import { OfficialNationalSimulationCategory } from 'types/OfficialNationalSimulationCategory'

type OfficialNationalProps = {
  officialNationalQuizzes?: OfficialNationalSimulationCategory[]
}

const backgroundColors = [
    '/images/quizes/official-national-1.svg',
    '/images/quizes/official-national-2.svg',
    '/images/quizes/official-national-3.svg',
  ]

const OfficialNational: FC<OfficialNationalProps> = ({officialNationalQuizzes}) => {
  const {t} = useTranslation()

  const getTitle = (specialty) => {
    switch (specialty) {
      case Specialty.HEALTHCARE:
        return t(translations.quizzes.healthcareTests)
      case Specialty.VETERINARY:
        return t(translations.quizzes.veterinaryTests)
      default:
        return t(translations.quizzes.medicalTests)
    }
  }

  return (
    <Box sx={{pt: {xs: 4, md: 7}, mb: 5}}>
      <Box sx={{px: {xs: '20px', sm: 6.5}}}>
        <Typography sx={{
          mt: 3,
          mb: 2,
          fontSize: {xs: '18px', md: '30px'},
          lineHeight: {xs: '23px', md: '55px'},
          fontWeight: '800',
        }}>
          {t(translations.home.previousYearsOfficialTestings)}
        </Typography>
      </Box>
      {officialNationalQuizzes && <Box sx={{mb: {xs: '30px'}, pl: {xs: '20px', sm: 6.5}}}>
        <SwipeableViews
          spaceBetween={20}
          slidesPerView="auto"
          hideIndicator={true}
          lastItemSx={{pr: {xs: '20px', sm: 6.5}}}
          views={officialNationalQuizzes}
          slideRenderer={(index) => {
            const quiz = officialNationalQuizzes[index]
            return (
              <Box
                sx={{
                  width: {xs: '317px', sm: '400px', lg: '480px'},
                }}
              >
                 <PreviousYearsOfficialTestingCard
                  speciality={quiz.specialty}
                  title={getTitle(quiz.specialty)}
                  fromYear={quiz.fromYear}
                  toYear={quiz.toYear}
                  backgroundImg={backgroundColors[index % backgroundColors.length]}
                />
              </Box>
            )
          }}
        />
      </Box>}
    </Box>
  )
}

export default OfficialNational
