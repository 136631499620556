import React from 'react'
import { Box, Typography } from '@mui/material'

import { translations } from 'locales/i18n'
import { useTranslation } from 'react-i18next'



const ClassesHead = () => 
  {const {t} = useTranslation()

  return (
  <Box p={0} sx={
    { position: 'relative',
      backgroundImage: 'url(/images/community/Bitmap1.png)',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: {xs: 'auto', lg: 'top left'},
      backgroundSize: {xs: 'cover', lg: '105%'},
      height: {xs: '120px', md: '252px'},
    }
  }>
    <Box
      sx={{
        position: 'absolute',
        left: {xs: '20px', md: '55px'},
        bottom: {xs: '80px', md: '110px'},
      }}>
      <img src='images/icons/combined_shape.svg' alt='402 error' style={{width: 'inherit'}}/>
    </Box>
    <Typography sx={{
      position: 'absolute',
      left: {xs: '20px', md:'55px'},
      bottom: '62px',
      margin: 0,
      fontSize: {xs: '18', md: '36px'},
      fontWeight: 800,
      lineHeight: '1.21'
    }}>
      {t(translations.community.welcomeToCommunities)}
    </Typography>
    <Typography sx={{
      position: 'absolute',
      left: {xs: '20px', md:'55px'},
      bottom: '30px',
      margin: 0,
      fontSize: {xs: '18', md: '20px'},
      fontWeight: 400,
      lineHeight: '1.21'
    }}>
    {t(translations.community.waysToFindCommunities)}
    </Typography>
  </Box>
)
  }
export default ClassesHead
