import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import CourseCard from './CourseCard'
import SearchInput from 'components/shared/search-input/SearchInput'
import { CourseSearchView } from 'types/CourseSearchView'
import { EnrolledCourseState } from 'types/EnrolledCourseState'
import { combine } from 'utils/constants'
import { translations } from 'locales/i18n'
import SwitchElement from '../shared/switch-element/SwitchElement'

type CoursesListProps = {
  courses: CourseSearchView[]
  showOnlyUnopenedToggle: boolean
  title?: string
}

const CoursesList: FC<CoursesListProps> = ({
                                             courses,
                                             showOnlyUnopenedToggle,
                                             title,
                                           }) => {
  const {t} = useTranslation()

  const [unopenedOnly, setUnopenedOnly] = React.useState(false)
  const [searchQuery, setSearchQuery] = React.useState('')

  const toggleUnopenedOnly = () => setUnopenedOnly(!unopenedOnly)

  const filterByUnopenedOnly = (courses) => unopenedOnly
    ? courses.filter(({courseState}) => courseState === EnrolledCourseState.UNOPENED)
    : courses

  const filterBySearchQuery = (courses) => !isEmpty(searchQuery)
    ? courses.filter(({courseName}) => courseName.match(new RegExp(searchQuery, 'i')))
    : courses

  const search = combine(filterBySearchQuery, filterByUnopenedOnly)

  const coursesToShow = search(courses)

  return (
    <>
      <Grid container sx={{alignItems: {xs: 'center'}, flexWrap: 'wrap'}}>
        <Grid item md={12} xs={6} sx={{order: {xs: 1, md: 1}}}>
          <Typography sx={{
            fontWeight: '400',
            fontSize: {xs: '18px', md: '34px'},
            mt: {xs: '10px', md: '20px'},
            mb: {xs: '10px', md: '20px'},
          }}>
            {title || t(translations.courses.title)}
          </Typography>
        </Grid>
        <Grid item md={6} xs={12}
              sx={{order: {xs: 3, md: 2}, mt: {xs: '10px', md: '20px'}, mb: {xs: '30px', md: '20px'}}}>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onClear={() => setSearchQuery('')}
          />
        </Grid>
        {showOnlyUnopenedToggle && (
          <Grid item md={6} xs={6}
                sx={{
                  order: {xs: 2, md: 3},
                  mt: {xs: '10px', md: '20px'},
                  mb: {xs: '10px', md: '20px'},
                }}>
            <SwitchElement onChange={toggleUnopenedOnly} text={t(translations.courses.onlyUnopened)}/>
          </Grid>)}
      </Grid>

      <Grid container direction={"column"}>
        <Grid container spacing="20px">
          {coursesToShow.map((course) => {
            return (
              <Grid item key={course.courseId} xs={12} md={6} lg={4}>
                <CourseCard
                  course={course}
                />
              </Grid>
            )
          })}
        </Grid>
      </Grid>
    </>
  )
}

export default CoursesList
