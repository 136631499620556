import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress} from '@mui/material'

import PreviousYearsOfficialTestings from '../quizzes/previous-years/PreviousYearsOfficialTestings'
import { translations } from 'locales/i18n'
import Heading from './Heading'
import { Specialty } from 'types/Specialty'
import { OfficialNationalSimulationCategory } from 'types/OfficialNationalSimulationCategory'

type PreviousYearsProps = {
  specialtyToFilter?: Specialty
  officialNationalQuizzes?: OfficialNationalSimulationCategory[]
}

const PreviousYears: FC<PreviousYearsProps> = ({specialtyToFilter, officialNationalQuizzes}) => {
  const {t} = useTranslation()

  return (
    <Box sx={{mb:{xs: '30px', md: '40px'}}}>
      <Heading content={t(translations.quizzes.previousYearsOfficialTestings)}/>
      <Box>
        {officialNationalQuizzes &&
        <PreviousYearsOfficialTestings categories={officialNationalQuizzes} specialtyToFilter={specialtyToFilter}/>}
        {officialNationalQuizzes === undefined && <CircularProgress/>}
      </Box>
    </Box>
  )
}

export default PreviousYears
