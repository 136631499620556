export enum WsMessageType {
  POLL_VOTE_ADDED = 'POLL_VOTE_ADDED',
  POST_ADDED = 'POST_ADDED',
  POST_DELETED = 'POST_DELETED',
  POST_UPDATED = 'POST_UPDATED',
  POST_PINNED = 'POST_PINNED',
  POST_UNPINNED = 'POST_UNPINNED',
  POST_COMMENT_ADDED = 'POST_COMMENT_ADDED',
  POST_COMMENT_DELETED = 'POST_COMMENT_DELETED',
  POST_COMMENT_UPDATED = 'POST_COMMENT_UPDATED',

  USER_MENTIONED_IN_COMMENT = 'USER_MENTIONED_IN_COMMENT',
  COMMENT_ADDED_TO_YOUR_POST = 'COMMENT_ADDED_TO_YOUR_POST',
  MONTHLY_SIMULATION_UPDATED = 'MONTHLY_SIMULATION_UPDATED',
}
