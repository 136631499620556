import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { Box, Button, Collapse } from '@mui/material'
import { v4 } from 'uuid'

import { translations } from 'locales/i18n'
import FormInput from 'components/ui/form/form-input/form-input'
import PostCommentFormSchema from './PostCommentValidatorSchema'
import PostCommentCardHeader from '../PostCommentCardHeader'
import { PostCommentInterface } from 'types/PostCommentInterface'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import CommunityUserAvatar from 'components/community/CommunityUserAvatar'
import WithUserMention from 'hocs/WithUserMention'

type PostCommentFormProps = {
  classId: string
  currentUser: StudentProfileInterface
  postId: string
  comment: PostCommentInterface
  isCommentFormSubmitting: boolean
  onTypingActive: (status: boolean) => void
  onSubmit: (data: PostCommentRequest) => Promise<PostCommentResponse>
  onCancel?: () => void
  inputBackground: string
}

const commentTextFieldName = 'commentText'

const PostCommentForm: FC<PostCommentFormProps> = ({
                                                     classId,
                                                     currentUser,
                                                     postId,
                                                     comment,
                                                     isCommentFormSubmitting,
                                                     onTypingActive,
                                                     onSubmit,
                                                     onCancel,
                                                     inputBackground,
                                                   }) => {
  const {t} = useTranslation()

  const {profile} = currentUser
  const {firstName, lastName, profileImageUri} = profile
  const {id, parentCommentId, text, createdAt} = comment
  const commentId = id || v4()
  const commentTextFieldId = `${commentTextFieldName}_${commentId}`

  const inputRef = useRef<HTMLTextAreaElement>(null)

  const [formOpen, setFormOpen] = useState(!!createdAt)

  const handleTextPaste = (
    e: React.ClipboardEvent<HTMLDivElement>,
    setFieldValue: FormikHelpers<PostCommentRequest>['setFieldValue'],
  ) => {
    const pastedText = e.clipboardData.getData('Text')
    const target = e.target as HTMLTextAreaElement
    if (/\/classes\/.+\/posts\/.+/gi.test(pastedText)) {
      e.preventDefault()
      const cursorPosition = target.selectionStart
      let textBeforeCursorPosition = target.value.substring(0, cursorPosition)
      let textAfterCursorPosition = target.value.substring(cursorPosition, target.value.length)
      const link = `<a href="${pastedText}" target="_blank">post</a>`
      target.value = `${textBeforeCursorPosition}${link}${textAfterCursorPosition}`
      setFieldValue(commentTextFieldName, target.value)
    }
    setFormOpen(true)
  }

  const handleSubmit = (data: PostCommentRequest) => onSubmit(data).then(_ => handleCancel())

  const handleCancel = (resetForm?: () => void) => {
    onCancel && onCancel()
    resetForm && resetForm()
    const input = inputRef.current
    if (input) {
      input.value = text
    }
    setFormOpen(false)
    onTypingActive(false)
  }

  return (
    <Formik
      validateOnChange
      initialValues={{
        classId,
        commentText: text,
        postId,
        parentCommentId,
        commentId,
      }}
      validationSchema={PostCommentFormSchema}
      onSubmit={handleSubmit}
    >
      {({handleSubmit, resetForm, values, setFieldValue}) => (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: createdAt ? 'column' : 'row',
            }}
          >
            {createdAt ? (
              <PostCommentCardHeader
                currentUser={currentUser}
                comment={comment}
              />
            ) : (
              <CommunityUserAvatar
                profileImageUri={profileImageUri}
                firstName={firstName}
                lastName={lastName}
                sx={{width: {xs: "35px", sm: "48px"}, height: {xs: "35px", sm: "48px"}}}
              />
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                flexGrow: 1,
                mt: createdAt ? '12px' : 0,
                ml: createdAt ? {xs: '46px', sm: '58px'} : '10px',
                width: createdAt ? 'auto' : 'unset',
              }}
            >
              <WithUserMention
                classId={classId}
                inputId={commentTextFieldId}
                inputName={commentTextFieldName}
                inputValue={values[commentTextFieldName]}
                setFieldValue={setFieldValue}
              >
                <FormInput
                  name={commentTextFieldName}
                  id={commentTextFieldId}
                  type="text"
                  multiline={true}
                  margin="none"
                  inputRef={inputRef}
                  placeholder={t(translations.community.feed.comment)}
                  onChange={() => {
                    setFormOpen(true)
                    onTypingActive(true)
                  }}
                  onPaste={(e) => handleTextPaste(e, setFieldValue)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      padding: {xs: '11px 17px', sm: '12px 19px'},
                      background: inputBackground,
                      borderRadius: '3px',
                    },
                    '& .MuiOutlinedInput-input': {
                      padding: 0,
                      color: '#FFFFFF',
                      background: inputBackground,
                      fontSize: {xs: '12px', sm: '16px'},
                      lineHeight: {xs: '22px', sm: '28px'},
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      border: 'none',
                    },
                  }}
                />
              </WithUserMention>

              <Collapse in={formOpen}>
                <Box
                  sx={{
                    display: 'flex',
                    mt: '10px',
                  }}
                >
                  <Button
                    variant="contained"
                    type="submit"
                    disabled={isCommentFormSubmitting}
                    sx={{
                      fontSize: {xs: '14px', sm: '16px'},
                      lineHeight: {xs: '17px', sm: '19px'},
                      width: {xs: 'auto', sm: '105px'},
                      textTransform: 'none',
                    }}
                  >
                    {t(translations.common.save)}
                  </Button>
                  <Button
                    sx={{
                      fontSize: {xs: '14px', sm: '16px'},
                      lineHeight: {xs: '17px', sm: '19px'},
                      display: 'flex',
                      ml: '10px',
                      backgroundColor: '#2D2E2F',
                      textTransform: 'none',
                    }}
                    variant="contained"
                    type="button"
                    onClick={() => handleCancel(resetForm)}
                  >
                    {t(translations.common.cancel)}
                  </Button>
                </Box>
              </Collapse>
            </Box>
          </Box>
        </form>
      )}
    </Formik>
  )
}
export default PostCommentForm
