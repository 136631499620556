import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, Card, useMediaQuery } from '@mui/material'
import { Skeleton } from '@material-ui/lab'
import dayjs from 'dayjs'

import textLessonBg from 'images/lesson-text-bg.svg'
import { FULL_DATE_FORMAT } from 'utils/constants'
import config from 'utils/config'
import theme from 'theme'

type BlogCardProps = {
  isLoading?: boolean
  id: string
  title: string
  introText: string
  date: number
  imageUri: string
}

const animation = 'pulse'
const variant = 'rect'

const BlogCard: FC<BlogCardProps> = ({
                                       isLoading = false,
                                       id,
                                       title,
                                       introText,
                                       date,
                                       imageUri,
                                     }) => {
  const isMd = useMediaQuery(theme.breakpoints.up('md'))

  return (
    <Box className="u-w-100 u-h-100">
      <Card sx={{ borderRadius: '3px' }}>
          <Box className="u-position-relative u-cursor-pointer">
            <Box sx={{
              background: theme => theme.palette.background.paper,
              width: '100%',
              height: { xs: '150px', md: '180px' },
              position: 'relative',
            }}>
              {isLoading
                ? <Skeleton variant={variant} animation={animation} height={isMd ? 180 : 150}/>
                :
                <Link to={`/blog/${id}`} style={{ height: 'inherit' }}>
                  <img
                  src={imageUri ? `${config.cdnBaseApiURI}${imageUri}` : textLessonBg}
                  className="u-w-100"
                  alt={title}
                  style={{ height: 'inherit', objectFit: 'cover', aspectRatio: '4 / 2' }}
                />
                </Link>
              }
            </Box>
          </Box>
        <Box
          sx={{ py: { xs: '8px', md: '12px' }, px: { xs: '10px', md: '20px' } }}
        >
          {
            isLoading
              ? <Skeleton variant={variant} animation={animation} height={isMd ? 28 : 17}/>
              : <Typography
                className="u-text-overflow-ellipsis"
                sx={{
                  fontSize: { xs: '14px', md: '20px' },
                  lineHeight: { xs: '17px', md: '28px' },
                  fontWeight: 'bold',
                }}
              >
                {title}
              </Typography>
          }
          <Box sx={{ mt: { xs: '8px', md: '10px' } }}>
            {
              isLoading
                ? <Skeleton variant={variant} animation={animation} height={isMd ? 72 : 57}/>
                : <Typography
                  className="u-text-overflow-ellipsis-lines-3"
                  sx={{
                    fontSize: { xs: '12px', md: '16px' },
                    lineHeight: { xs: '19px', md: '24px' },
                    opacity: '80%',
                    height: { xs: '57px', md: '72px' },
                  }}
                >
                  {introText}
                </Typography>
            }
          </Box>
          <Box sx={{ mt: { xs: '8px', md: '17px' } }}>
            {
              isLoading
                ?
                <Skeleton variant={variant} animation={animation} height={isMd ? 24 : 19} width={isMd ? '60%' : '50%'}/>
                : <Typography
                  sx={{
                    fontSize: { xs: '12px', md: '16px' },
                    lineHeight: { xs: '19px', md: '24px' },
                  }}
                >
                  {dayjs(date).format(FULL_DATE_FORMAT)}
                </Typography>
            }
          </Box>
        </Box>
      </Card>
    </Box>
  )
}

export default BlogCard
