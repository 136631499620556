import { Box } from "@material-ui/core"
import DashboardLayout from "components/layouts/dashboard/DashboardLayout"
import StaticPracticeQuizzes from "components/quizzes-page/StaticPracticeQuizzes"
import { Back } from "components/shared/back"
import LoadingIndicator from "components/shared/loading-indicator"
import useStaticPracticeSimulations from "hooks/useStaticPracticeSimulations"
import { translations } from "locales/i18n"
import { isEmpty } from "lodash"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, useHistory } from "react-router-dom"

export function StaticPracticeQuizzesPage(
  
  props: RouteComponentProps<{ id?: string }>,
) {
  const history = useHistory()
  const {t} = useTranslation()

  const idParam = props.match.params.id

  if (isEmpty(idParam)) {
    history.push('/quizzes')
  }

  const StaticPracticeSimulationsQuery = useStaticPracticeSimulations(idParam)

  return (
    <DashboardLayout>
      <Back text={t(translations.navigation.quizzesPage)} url={'/quizzes'}/>
      <Box sx={{
        mx: {xs: '54px', sm: 6.5},
        mb: {xs: '54px', sm: 6.5},
        mt: {xs: '40px', md: '70px'},
      }}>
        {StaticPracticeSimulationsQuery.isSuccess &&
        <StaticPracticeQuizzes
          groupItems={StaticPracticeSimulationsQuery.data}
          groupId={idParam}
        />}
        {StaticPracticeSimulationsQuery.isLoading && <LoadingIndicator/>}
      </Box>
    </DashboardLayout>
  )
}
  
  export default StaticPracticeQuizzesPage
