import { deleteQuizAttemptQuestionFlag } from 'services/quizAttemtService'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useDeleteQuizAttemptQuestionFlag = () => useMutationWithSnackbar(
    (data: QuizAttemptQuestionTagRequest) => deleteQuizAttemptQuestionFlag(data),
    undefined,
    undefined,
    {},
  )

export default useDeleteQuizAttemptQuestionFlag
