import { useQueryClient } from 'react-query'

import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { deleteNotification } from 'services/community/notificationService'
import ReactQueryKeys from '../../types/ReactQueryKeys'


const useDeleteNotification = () => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (notificationId: string) => deleteNotification(notificationId),
    undefined,
    undefined,
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.NOTIFICATIONS)
      },
    },
  )
}

export default useDeleteNotification
