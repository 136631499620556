import React, { FC, RefObject } from "react";
import { Box, InputLabel, Typography } from "@mui/material";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { isEmpty } from "lodash";
import config from "utils/config";
import { useTranslation } from "react-i18next";
import { translations } from "locales/i18n";

type photoProps = { title: string; uri: string };

type StreamPhotoMaskProps = {
  href: RefObject<HTMLLabelElement>;
  photo: photoProps;
  initialImage: photoProps;
};

const StreamPhotoMask: FC<StreamPhotoMaskProps> = ({
  href,
  photo,
  initialImage,
  children,
}) => {
  const {t} = useTranslation()
  const getBackgroundImage = ({ title, uri }) => {
    if (!isEmpty(uri)) {
      switch (title) {
        case "photo":
          return uri;
        case "initialImage":
          return `${config.cdnBaseApiURI}${uri}`;
      }
    } else {
      return `/images/community/templatePhoto.png`;
    }
  };

  return (
    <Box
      p={0}
      sx={{
        position: "relative",
        height: { xs: "300px", sm: "150px" },
        width: { xs: "100vw", sm: "257px" },
        cursor: "pointer",
        borderRadius: "3px",
      }}
    >
      <InputLabel
        ref={href}
        htmlFor="photo"
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, 0%)",
          display: "flex",
          alignItems: "center",
          margin: 0,
          padding: "7px 12px  9px",
          fontSize: "14px",
          fontWeight: 400,
          lineHeight: "16.94px",
          backgroundColor: "rgba(255, 255, 255, 0.3)",
          borderRadius: { xs: "none", sm: "3px" },
        }}
      >
        <PhotoCameraIcon sx={{ width: "20px", height: "20px", mr: "10px" }} />
        Photo
      </InputLabel>
      <Box
        sx={{
          borderRadius: "3px",
          width: { xs: "100vw", sm: "257px" },
          height: { xs: "300px", sm: "169px" },
        }}
      >
        <img
          src={
            !isEmpty(photo.uri)
              ? getBackgroundImage(photo)
              : getBackgroundImage(initialImage)
          }
          alt="streamPhoto"
          width="100%"
          height="100%"
        />
      </Box>
      {children}
      <Typography
        sx={{
          textTransform: "capitalize",
          fontSize: "12px",
          lineHeight: 1.21,
          color: "#606060",
          pt: "18px",
        }}
      >
        {t(translations.streams.uploadCoverDescription)}
      </Typography>
    </Box>
  );
};

export default StreamPhotoMask;
