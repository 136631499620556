import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { deletePostComment } from 'services/community/postCommentService'
import { translations } from 'locales/i18n'

const useDeletePostComment = () => useMutationWithSnackbar(
  (data: DeletePostCommentRequest) => deletePostComment(data),
  undefined,
  translations.messages.postCommentDeleted,
)

export default useDeletePostComment
