import dayjs, { Dayjs } from 'dayjs'

import { TIME_FORMAT } from 'utils/constants'

type DateType = Dayjs | number

export const getDurationMilli = (from: DateType, to?: DateType) => dayjs.duration(dayjs(to).diff(from)).asMilliseconds()

export const getDurationMilliFormatted = (dur: number, format: string = TIME_FORMAT) => dayjs.utc(dur).format(format)

export const getDurationFormatted = (from: DateType, to?: DateType) => {
  const dur = getDurationMilli(from, to)
  return dayjs.utc(dur).format(TIME_FORMAT)
}

export const secondsToFullTimeFormat = (seconds: number) =>
  new Date(seconds * 1000).toISOString().substr(11, 8)

