import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { getStaticPracticeSimulations } from 'services/quizService'

const useStaticPracticeSimulations = (
  groupId?: string,
  options?: UseQueryOptions<StaticPracticeSimulation, AxiosError>,
): UseQueryResult<StaticPracticeSimulation> => {
  return useQueryWithSnackbar<StaticPracticeSimulation>(
    [ReactQueryKeys.STATIC_PRACTICE_GROUP, groupId],
    () => getStaticPracticeSimulations(groupId),
    {
      enabled: !!groupId,
      ...options,
    },
  )
}

export default useStaticPracticeSimulations
