import axios, { AxiosError, AxiosRequestConfig } from 'axios'

import config from 'utils/config'
import { HttpStatus } from 'utils/constants'

export const instance = axios.create({
  baseURL: config.baseApiURI,
  withCredentials: true,
})

instance.interceptors.response.use(
  (response) => response.data.payload,
  async (error: AxiosError) => {
    if (!error.response) {
      return Promise.reject(error)
    }

    const {status} = error.response

    switch(status) {
      case HttpStatus.UNAUTHORIZED:
        if (!/.*(\/login|\/forgot-password|\/password-reset)/.test(window.location.href)) {
          window.location.href = '/login'
        }
        break
      case HttpStatus.PAYMENT_REQUIRED:
        window.location.replace('/402')
    }
    return Promise.reject(error)
  }
)

export const clientDataService = <T = any>(config: AxiosRequestConfig): Promise<T> => {
  return instance.request(config)
}
