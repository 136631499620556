import { UseMutationResult } from 'react-query/types/react/types'
import { useHistory } from 'react-router-dom'
import { AxiosError } from 'axios'

import { completeQuizAttemptQuestion } from 'services/quizAttemtService'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'

const useCompleteQuizAttempt = (): UseMutationResult<void, AxiosError, string> => {
  const history = useHistory()

  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  return useMutationWithSnackbar(
    (quizAttemptId: string) => completeQuizAttemptQuestion(quizAttemptId),
    'completeQuizAttempt',
    undefined,
    {
      async onSuccess(_, quizAttemptId) {
        enqueueSnackbar(
          t(translations.messages.quizCompleted),
          { variant: 'success' },
        )

        await history.push(`/quiz-attempts/${quizAttemptId}/results`)
      },
      async onError(error, quizAttemptId) {

        if(error.response?.data?.payload?.code === "QUIZ_ALREADY_COMPLETED")
        {
          await history.push(`/quiz-attempts/${quizAttemptId}/results`)
        }

      }
    },
  )
}

export default useCompleteQuizAttempt

