import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import dayjs from 'dayjs'

import { Back } from 'components/shared/back'
import SwipeableViews from 'components/shared/swipeable-views/SwipeableViews'
import BlogCard from 'components/shared/cards/BlogCard'
import { translations } from 'locales/i18n'
import config from 'utils/config'
import theme from 'theme'
import { FULL_DATE_FORMAT } from 'utils/constants'

type BlogProps = {
  blog: BlogInterface
}

const Blog: FC<BlogProps> = ({ blog }) => {
  const { t } = useTranslation()
  const { previewImageUri, title, text, publishedAt, relatedBlogs } = blog

  return (
    <Box>
      <Back text={t(translations.navigation.blogsPage)} url="/blog" sx={{ mx: { xs: '20px', md: 6.5 } }}/>
      <img
        src={`${config.cdnBaseApiURI}${previewImageUri}`}
        alt={title}
        style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}
      />
      <Box
        sx={{
          position: 'relative',
        }}>
        <Box
          sx={{
            background: {
              xs: theme.palette.common.videoGradient.replace('{start}', '24.56').replace('{finish}', '71.5'),
              md: theme.palette.common.videoGradient.replace('{start}', '0').replace('{finish}', '56.25'),
            },
            top: { xs: '-200px', sm: '-255px', lg: '-365px' },
            width: '100%',
            height: { xs: '205px', sm: '260px', lg: '370px' },
            position: 'absolute',
            opacity: '60%',
            display: 'flex',
            alignItems: 'flex-end',
            pb: { xs: '20px', sm: '30px' },
          }}
        >
          <Box
            sx={{
              mx: { xs: '20px', md: 6.5, lg: '150px', xl: '270px' },
            }}
          >
            <Typography
              sx={{
                mt: { xs: '70px', sm: '77px' },
                fontSize: { xs: '24px', sm: '30px', md: '46px', lg: '56px' },
                lineHeight: { xs: '28px', sm: '36px', md: '53px', lg: '68px' },
                fontWeight: 'bold',
                maxWidth: { xs: '250px', sm: '310px', md: '590px' },
              }}
            >
              {title}
            </Typography>
            <Typography
              sx={{
                mt: { xs: '15px', md: '18px' },
                fontSize: { xs: '12px', md: '16px' },
                lineHeight: { xs: '15px', md: '19px' },
              }}
            >
              {dayjs(publishedAt).format(FULL_DATE_FORMAT)}
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          mx: { xs: '20px', md: 6.5, lg: '150px', xl: '270px' },
          mb: 3,
          mt: { md: 4 },
        }}
        className="LessonTextContent"
        dangerouslySetInnerHTML={{ __html: text }}
      />

      <Box
        sx={{
          ml: { xs: '20px', md: 6.5 },
          mb: { xs: '30px' },
        }}
      >
        <SwipeableViews
          spaceBetween={20}
          slidesPerView="auto"
          lastItemSx={{ mr: { xs: '20px', md: 6.5 } }}
          views={relatedBlogs}
          slideRenderer={(index) => {
            const {
              id,
              title,
              previewText,
              publishedAt,
              previewImageUri,
            } = relatedBlogs[index]
            return (
              <Box sx={{ maxWidth: { xs: '228px', md: '293px' } }}>
                <BlogCard
                  id={id}
                  title={title}
                  introText={previewText}
                  date={publishedAt}
                  imageUri={previewImageUri}
                />
              </Box>
            )
          }}
        />
      </Box>
    </Box>
  )
}

export default Blog
