import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Box, Typography } from "@mui/material";

import QuestionsCountWithPlayIcon from "../QuestionsCountWithPlayIcon";
import { Specialty } from "types/Specialty";
import { QuizType } from "types/QuizType";
import { useTranslation } from "react-i18next";
import { translations } from "locales/i18n";

type PreviousYearsOfficialTestingCardProps = {
  speciality: Specialty;
  title: string;
  fromYear: number;
  toYear: number;
  backgroundImg: string;
};

const PreviousYearsOfficialTestingCard: FC<
  PreviousYearsOfficialTestingCardProps
> = ({ speciality, title, fromYear, toYear, backgroundImg }) => {
  const { t } = useTranslation()
  
  return (
    <Link to={`/quizzes/official/${speciality.toLowerCase()}`}>
      <Box
        sx={{
          backgroundColor: (theme) => theme.palette.background.paper,
          backgroundImage: `url(${backgroundImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          minHeight: { xs: "140px", sm: "200px", md: "233px" },
          maxWidth: "500px",
          padding: { xs: "12px", sm: "21px 25px" },
          borderRadius: "3px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          cursor: "pointer",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: { xs: "18px", sm: "24px" },
              lineHeight: { xs: "23px", sm: "29px" },
            }}
            className="u-weight-bold"
          >
            {title}
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "18px", sm: "24px" },
              lineHeight: { xs: "23px", sm: "29px" },
            }}
            className="u-weight-bold"
          >{`${fromYear} - ${toYear}`}</Typography>
        </Box>

        <QuestionsCountWithPlayIcon
          quizType={QuizType.OFFICIAL_NATIONAL_SIMULATION}
          textButton={t(translations.quizzes.open)}
          backgroundButton={"white"}
          colorText={"black"}
        />
      </Box>
    </Link>
  );
};
export default PreviousYearsOfficialTestingCard;
