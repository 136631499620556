import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import dayjs from 'dayjs'

import { QuizAttemptReviewInterface } from 'types/QuizAttemptReviewInterface'
import QuizQuestionReviewCard from './quiz-question-card/QuizQuestionReviewCard'
import getQuizTypeTitle from '../shared/getQuizTypeTitle'
import { translations } from 'locales/i18n'
import { FULL_DATE_FORMAT } from 'utils/constants'
import examMode from 'images/exam-mode.svg'
import QuizAttemptReviewNotAllowed from './QuizAttemptReviewNotAllowed'
import { QuizAttemptStatisticsInterface } from 'types/QuizAttemptStatisticsInterface'
import TitilePracticeQuiz from 'components/quiz-attempt/components/PracticeQuizTitle'

 type QuizAttemptReviewProps = {
   quizAttemptReview: QuizAttemptReviewInterface,
   onSendReport: (report: ReportQuizAttemt) => void
   statistics: QuizAttemptStatisticsInterface
   groupName?: string
 }

const QuizAttemptReview: FC<QuizAttemptReviewProps> = ({quizAttemptReview,statistics,groupName, onSendReport}) => {
  const {t} = useTranslation()
  const {quizAttemptId}: { quizAttemptId: string } = useParams()
  
  const {
    completedAt,
    quizType,
    questions,
    canReviewAnswer,
  } = quizAttemptReview

  return (
    <>
      {!canReviewAnswer && <QuizAttemptReviewNotAllowed quizAttemptId={quizAttemptId}/>}
      {canReviewAnswer && (
        <Box className="u-mt-88 u-mb-30">
          <Grid container justifyContent="center" sx={{minWidth: {md: '827px'}}}>
            <Grid container item justifyContent="center">
              <Typography sx={{fontSize: {xs: '12px', md: '14px'}, lineHeight: '22px', mb: {xs: '4px', md: '6px'}}}
                          className="u-color-pale-sky">
                {dayjs(completedAt).format(FULL_DATE_FORMAT)}
              </Typography>
            </Grid>
            <Grid container item justifyContent="center">
              <Typography sx={{
                fontSize: {xs: '18px', md: '34px'},
                lineHeight: {xs: '23px', md: '41px'},
                mb: {xs: '7px', md: '19px'},
              }}>
                {getQuizTypeTitle(quizType,'',groupName)}
              </Typography>
            </Grid>
            <Grid container item display="flex" justifyContent="center" alignItems="center"
                  sx={{mb: {xs: '33px', md: '49px'}}}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                mr: {xs: '7px', md: '10px'},
                height: {xs: '16px', md: '21px'},
                width: {xs: '13px', md: '17px'},
              }}>
                <img
                  src={examMode}
                  className="u-w-100 u-h-100"
                  alt=""
                  role="presentation"
                />
              </Box>
              <Typography component={'span'} className="u-color-pale-sky"
                          sx={{fontSize: {xs: '12px', md: '18px'}, lineHeight: {xs: '14px', md: '22px'}}}>
                {t(translations.quizAttemptReview.reviewQuestions)}
              </Typography>
            </Grid>    
            <Grid container item justifyContent="center" alignItems="center">
              <TitilePracticeQuiz
                statistics={statistics}
                width='600px'
              />
            </Grid>
            <Box sx={{mt: '49px'}}>
              {questions && questions.map(({text, answerExplanation, tagged, options, number, id}) => (
                <Box key={number}>
                  <Box sx={{mb: "20px"}}>
                    <QuizQuestionReviewCard
                      questionId={id}
                      questionText={text}
                      options={options}
                      questionNumber={number}
                      answerExplanation={answerExplanation}
                      tagged={tagged}
                      onSendReport={onSendReport}
                    />
                  </Box>
                </Box>
              ))
              }
            </Box>
          </Grid>
        </Box>
      )}
    </>
  )
}

export default QuizAttemptReview
