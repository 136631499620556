import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {Box, Grid, ListItem} from "@mui/material";
import dayjs from "dayjs";

import {MEDIUM_DATE_FORMAT} from "utils/constants";
import {translations} from "locales/i18n";

type RankingRecordsProps = {
    rankingItems: RankingRecordsInterface;
};

const RankingRecords: FC<RankingRecordsProps> = ({rankingItems}) => {
  const { t } = useTranslation();

    const studentsRankingJSX = rankingItems.rankingRecords.map(
        ({firstName, lastName, points, postedOn, userId, isCurrentUser}, i) => {

        return (
          <ListItem
            key={i}
            className={`u-pl-0 u-pr-0 u-pt-16 u-pb-16 ${
              isCurrentUser && "u-weight-bold"
            }`}
            divider
            sx={{
              fontSize: { xs: "12px", sm: "14px" },
              lineHeight: { xs: "14px", sm: "17px" },
              background: isCurrentUser ? "#4780D8" : "unset"}}
          >
            <Grid
              container
              sx={{
                display: "flex",
                alignItems: "center",
                px: { xs: "20px", sm: "12px" },
              }}
            >
              <Grid
                item
                xs={1}
              >
                {i + 1}
              </Grid>
              <Grid
                item
                xs={5}
                sx={{ pl: "10px" }}
              >
                {`${firstName} ${lastName}`}
              </Grid>
              <Grid item xs={3} display="flex" justifyContent="flex-end">
                {points / 100.0}
              </Grid>
              <Grid
                item
                xs={3}
                display="flex"
                justifyContent="flex-end"
                className="u-color-submarine"
              >
                {dayjs(postedOn).format(MEDIUM_DATE_FORMAT)}
              </Grid>
            </Grid>
          </ListItem>
        );
      }
    );

  return (
      <Box>
        <Grid
          container
          sx={{
            pt: 3,
            letterSpacing: "0.73px",
            fontSize: { xs: "12px", sm: "14px" },
            lineHeight: { xs: "11px", sm: "13px" },
            px: { xs: "20px", sm: "12px" },
            mb: 2,
          }}
          className="u-color-hit-grey"
        >
          <Grid item xs={1}>
            {t(translations.monthlyRanking.position)}
          </Grid>
          <Grid item xs={5} sx={{ pl: "10px" }}>
            {t(translations.monthlyRanking.name)}
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="flex-end" sx={{lineHeight: '20px'}}>
            {t(translations.monthlyRanking.points)}
            <br/>
            MAX ( {rankingItems.maxPoints / 100} )
          </Grid>
          <Grid item xs={3} display="flex" justifyContent="flex-end">
            {t(translations.monthlyRanking.postedOn)}
          </Grid>
        </Grid>
          {studentsRankingJSX}
      </Box>
  );
};

export default RankingRecords;
