
import { clientDataService } from '../clientDataService'


export const getClassParticipants = (classId: string, searchQuery: string): Promise<ClassParticipants> =>
  clientDataService<ClassParticipants>({
    url: `/classes/${classId}/participants?mentionString=${searchQuery}`,
  })

export const getClasses = (): Promise<ClassesValueInterface[]> =>
  clientDataService<ClassesValueInterface[]>({
    url: '/classes',
  })
