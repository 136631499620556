import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import 'swiper/swiper.min.css'

import { translations } from 'locales/i18n'
import SwipeableViews from '../shared/swipeable-views/SwipeableViews'
import PackSimulationCard from './PackSimulationCard'
import { PackSimulationsInterface } from 'types/PackSimulationsInterface'
import { quizCardBackgroundColors } from 'components/quizzes/common/quizCardBackgroundColors'
import { isEmpty } from 'lodash'

type PackSimulationsProps = {
    packSimulations?: PackSimulationsInterface[]
}

const PackSimulations: FC<PackSimulationsProps> = ({packSimulations}) => {
  const {t} = useTranslation()

  return (
    <>
    { !isEmpty(packSimulations) && packSimulations && <Box sx={{pt: {xs: 4, md: 7}, mb: 5}}>
      <Box sx={{px: {xs: '20px', sm: 6.5}}}>
        <Typography sx={{
          mt: 3,
          mb: 2,
          fontSize: {xs: '18px', md: '30px'},
          lineHeight: {xs: '23px', md: '55px'},
          fontWeight: '800',
        }}>
          {t(translations.quizzes.simulations)}
        </Typography>
      </Box>
      <Box sx={{mb: {xs: '30px'}, pl: {xs: '20px', sm: 6.5}}}>
        <SwipeableViews
          spaceBetween={20}
          slidesPerView="auto"
          hideIndicator={true}
          lastItemSx={{pr: {xs: '20px', sm: 6.5}}}
          views={packSimulations}
          slideRenderer={(index) => {
            const quiz = packSimulations[index]
            return (
              <Box>
              <PackSimulationCard packSimulations={quiz} backgroundColors={quizCardBackgroundColors[index % quizCardBackgroundColors.length]}/>
              </Box>
            )
          }}
        />
      </Box>
    </Box>}
    </>
  )
}

export default PackSimulations
