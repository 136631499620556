import { UseQueryOptions, UseQueryResult } from 'react-query'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getLesson } from 'services/lessonService'
import { LessonView } from 'types/LessonView'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { AxiosError } from 'axios'

const useLesson = (courseId: string, lessonId: string, options?: UseQueryOptions<LessonView, AxiosError>): UseQueryResult<LessonView, AxiosError> =>
  useQueryWithSnackbar<LessonView>(
    [ReactQueryKeys.LESSON, courseId, lessonId],
    () => getLesson(courseId, lessonId),
    {
      enabled: !!courseId && !!lessonId,
      ...options,
    },
    'getLesson',
  )

export default useLesson
