import { debounce } from 'lodash'
import { FormikHelpers } from 'formik/dist/types'

import { DEBOUNCE_TYPING_MILLI } from 'utils/constants'

type SetDebouncedFieldValueParams = {
  setFieldValue: FormikHelpers<unknown>['setFieldValue']
  name: string
  value: unknown
}

const setFieldValueDebounced =
  debounce(({name, value, setFieldValue}: SetDebouncedFieldValueParams): void => {
    setFieldValue(name, value)
  }, DEBOUNCE_TYPING_MILLI)

export default setFieldValueDebounced
