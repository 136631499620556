import React, { FC, ReactElement } from 'react'
import { Card, CardContent, Grid } from '@mui/material'

type JawProps = {
  header?: ReactElement;
}

const Jaw: FC<JawProps> = ({header, children}) => {
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      className="u-pt-110 u-pb-70"
      sx={{height: '100%', overflowY: 'auto'}}
    >
      <Grid item sx={{
        width: {sm: 600},
        padding: {xs: '0px 20px', md: '0px'}
        }}>
        {header && <Grid className="u-mb-40">{header}</Grid>}
        <Card sx={{border: '1px solid #363B3F'}}>
          <CardContent className="u-p-0">
            {children}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

export default Jaw
