import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Field, Form, Formik } from 'formik'
import { useHistory } from 'react-router-dom'
import { Box, Button, FormControl, Grid, IconButton, ListItem, MenuItem, Select } from '@mui/material'

import { translations } from 'locales/i18n'
import theme from 'theme'

const CustomizedSelectForFormik = ({ children, form, field }) => {
  const { name, value } = field
  const { setFieldValue } = form

  return (
    <Select
      variant="standard"
      name={name}
      value={value}
      onChange={e => setFieldValue(name, e.target.value)}
      sx={{
        '&::before, &::after': { display: 'none' },
        fontSize: { xs: '10px', lg: '16px' },
        lineHeight: { xs: '12px', lg: '22px' },
      }}
    >
      {children}
    </Select>
  )
}

type StatisticsPerSubjectProps = {
  subjectStatistics: SubjectStatistics[]
}

const StatisticsPerSubjectProMed: FC<StatisticsPerSubjectProps> = ({ subjectStatistics }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const colorfulnessSimulation = (percent) => {
    if (percent <= 33) return '#DA6060'
    else if (percent > 33 && percent <= 66) return '#EFCF6D'
    else if (percent > 66) return '#2FB577'
  }

  const showWorstTopic = subjectStatistics.find((item) => item.worstTopics.length > 0)

  return (
    <Grid
      container
      sx={{
        borderRadius: { xs: 0, sm: '4px' },
        pt: { xs: '15px', lg: '32px' },
        pb: { xs: '30px', lg: '45px' },
        px: { xs: '20px', lg: '35px' },
        background: theme => theme.palette.background.paper,
      }}
    >
      <Grid container item>
        <Grid
          item
          sx={{
            fontSize: { xs: '14px', sm: '20px' },
            lineHeight: { xs: '17px', sm: '24px' },
            fontWeight: 'bold',
          }}
        >
          {t(translations.statistics.correctAnswersPerSubject)}
        </Grid>
        <Grid
          container
          item
          className="u-mt-25 u-mb-5 u-color-hit-grey"
          sx={{
            fontSize: { xs: '7px', sm: '11px' },
            lineHeight: { xs: '8px', sm: '13px' },
          }}
          alignItems="flex-end"
        >
          <Grid item xs={showWorstTopic ? 2 : 3}>
            <Grid sx={{ maxWidth: '100px' }}>
              {t(translations.statistics.subject)}
            </Grid>
          </Grid>
          <Grid item xs={showWorstTopic ? 2 : 3} display="flex"
                justifyContent="flex-start">{t(translations.statistics.lastSimulation)}</Grid>
          <Grid item xs={showWorstTopic ? 3 : 4}>{t(translations.statistics.bestTopic)}</Grid>
          <Grid item xs={showWorstTopic ? 5 : 2} display="flex"
                justifyContent={showWorstTopic ? 'flex-start' : 'flex-end'}>{t(translations.statistics.worstTopic)}</Grid>
        </Grid>
        {subjectStatistics && subjectStatistics.map(({
                                                       subject,
                                                       percents,
                                                       bestTopic,
                                                       worstTopics,
                                                       lastSimulationPercents,
                                                     }, index) => {
          return (
            <ListItem
              divider={index !== subjectStatistics.length - 1}
              key={subject.id}
              sx={{
                padding: '16px 0px',
                fontSize: { xs: '10px', sm: '16px' },
              }}
            >
              <Grid container alignItems="center">
                <Grid item xs={3} display="flex"
                      sx={{
                        flexDirection: { xs: 'column', md: 'row' },
                        alignItems: { md: 'center' },
                      }}>
                  <Box
                    sx={{
                      fontSize: { xs: '10px', lg: '14px' },
                      lineHeight: { xs: '17px', lg: '22px' },
                      pr: '5px',
                    }}
                  >
                    {subject.name}
                  </Box>
                  <Box
                    sx={{
                      fontSize: { xs: '12px', lg: '18px' },
                      lineHeight: { xs: '14px', lg: '22px' },
                      fontWeight: '500',
                    }}
                    className="u-color-dusty-grey"
                  >
                    {`${percents}%`}
                  </Box>
                </Grid>
                <Grid item xs={showWorstTopic ? 1 : 3}
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-start',
                        color: `${colorfulnessSimulation(lastSimulationPercents)} !important`,
                        fontSize: { xs: '12px', md: '13px', lg: '18px' },
                        lineHeight: 1.21,
                      }}>
                  {lastSimulationPercents}%
                </Grid>
                <Grid item xs={showWorstTopic ? 3 : 4}
                      sx={{
                        fontSize: { xs: '10px', lg: '16px' },
                        lineHeight: { xs: '12px', lg: '22px' },
                        pr: { xs: '10px', sm: 0 },
                        textOverflow: 'ellipsis',
                        whiteSpace: 'pre-wrap',
                        overflow: 'hidden',
                      }}
                >
                  {bestTopic?.name}
                </Grid>
                <Grid item xs={showWorstTopic ? 5 : 2}
                      display="flex"
                      justifyContent={showWorstTopic ? 'space-between' : 'flex-end'}
                      alignItems="center"
                >
                  {worstTopics.length > 0 ? (
                    <Formik
                      initialValues={{ selectId: worstTopics[0].id }}
                      onSubmit={({ selectId }) => history.push(`/quizzes/subjects/${subject.id}/topics/${selectId}/new`)}
                    >
                      <Form style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <Grid item xs={9} md={8} sx={{ pr: '20px', display: 'flex', alignItems: 'center' }}>
                          <FormControl sx={{ width: '100%' }}>
                            <Field name="selectId" component={CustomizedSelectForFormik}>
                              {worstTopics.map(({ id, name }) => (
                                <MenuItem value={id} key={id} sx={{
                                  fontSize: {
                                    xs: '10px',
                                    lg: '14px',
                                    lineHeight: '40px',
                                  },
                                }}>{name}</MenuItem>
                              ))}
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={3} md={4}
                              sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                          <IconButton color="primary" component="button" type="submit"
                                      sx={{ display: { md: 'none' } }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg" className="u-h-100 u-w-100">
                              <circle cx="12" cy="12" r="12" fill={theme.palette.common.accentLight}/>
                              <path
                                d="M10.4837 8.09005C10.0421 7.84209 9.48596 8.0041 9.24146 8.4519C9.16644 8.58931 9.12708 8.74381 9.12708 8.90088V15.2922C9.12708 15.8041 9.53623 16.219 10.041 16.219C10.1959 16.219 10.3482 16.1791 10.4838 16.103L16.1735 12.9069C16.615 12.6589 16.7747 12.0948 16.5301 11.647C16.447 11.4949 16.3234 11.3695 16.1734 11.2853L10.4837 8.09005Z"
                                fill="white"/>
                            </svg>

                          </IconButton>
                          <Button
                            type="submit"
                            sx={{
                              display: { xs: 'none', md: 'block' },
                              height: '26px',
                              width: '120px',
                              lineHeight: '16.94px',
                              fontSize: { md: '10px', lg: '14px' },
                              padding: 0,
                              textTransform: 'none',
                            }}
                            variant="contained"
                          >
                            {t(translations.subjectPractiseInitPage.startQuiz)}
                          </Button>
                        </Grid>
                      </Form>
                    </Formik>
                  ) : (
                    <Box sx={{ lineHeight: '16.94px', fontSize: { md: '10px', lg: '14px' } }}>
                      {t(translations.common.noDataYet)}
                    </Box>
                  )}
                </Grid>
              </Grid>
            </ListItem>
          )
        })}
      </Grid>
    </Grid>
  )
}

export default StatisticsPerSubjectProMed
