import React from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'

import p from '../../pages'
import {PrivateRoute} from 'utils/PrivateRoute'
import {isWeTest} from 'theme/constants'

const Routes = () => {

  return (
    <Switch>
      <Route path="/login" component={p.Login}/>
      <Route exact path="/forgot-password" component={p.ForgotPasswordPage}/>
      <Route exact path="/password-reset" component={p.ResetPasswordPage}/>

      <Switch>
        <PrivateRoute exact path="/" component={p.Home}/>
        <PrivateRoute exact path="/profile" component={p.Profile}/>
        <PrivateRoute exact path="/profile/login-information" component={p.ProfileLoginInformationPage}/>
        <PrivateRoute exact path="/profile/notification-preferences" component={p.ProfileNotificationPreferencesPage}/>
        <PrivateRoute exact path="/ranking" component={p.RankingPage}/>
        <PrivateRoute exact path="/quizzes/coming-soon" component={p.ComingSoonPage}/>
        <PrivateRoute exact path="/courses" component={p.Courses}/>
        <PrivateRoute exact path="/courses/in-progress" component={p.CoursesInProgress}/>
        <PrivateRoute exact path="/courses/:courseId" component={p.Course}/>
        <PrivateRoute exact path="/courses/:courseId/lessons/:lessonId" component={p.Lesson}/>
        <PrivateRoute exact path="/courses/:courseId/lessons/:lessonId/editor" component={p.LessonEditor}/>
        <PrivateRoute exact path="/statistics" component={p.StatisticsPage}/>
        <PrivateRoute exact path="/quizzes" component={isWeTest() ? p.QuizzesWeTestPage : p.QuizzesProMedPage}/>
        <PrivateRoute exact path="/quizzes/pack-simulations/:packSimulationId" component={p.PackSimulationsPage}/>
        <PrivateRoute exact path="/quizzes/pack-simulations/:packSimulationId/quizzes/:quizId/new" component={p.QuizStartPackSimulationPage}/>
        <PrivateRoute exact path="/quizzes/subjects/:subjectId/new" component={p.PracticeQuizCreate}/>
        <PrivateRoute exact path="/quizzes/subjects/:subjectId/topics/:topicId/new"
                      component={p.PracticeTopicQuizCreate}/>
        <PrivateRoute exact path="/quizzes/:quizType/new" component={p.QuizStartSimulationPage}/>
        <PrivateRoute exact path="/quizzes/official/:quizId/new" component={p.QuizStartOfficialNationalSimulationPage}/>
        <PrivateRoute exact path="/quizzes/practice/:groupId/:quizId/new" component={p.QuizStartStaticPracticePage}/>
        <PrivateRoute exact path="/quizzes/official/:speciality" component={p.PreviousYearQuizzesPage}/>
        <PrivateRoute exact path="/quizzes/practice/:id" component={p.StaticPracticeQuizzesPage}/>
        <PrivateRoute exact path="/quiz-attempts/:quizAttemptId" component={p.QuizAttemptPage}/>
        <PrivateRoute exact path="/quiz-attempts/:quizAttemptId/complete" component={p.QuizCompletionConfirmationPage}/>
        <PrivateRoute exact path="/quiz-attempts/:quizAttemptId/results" component={p.QuizAttemptResultsPage}/>
        <PrivateRoute exact path="/quiz-attempts/:quizAttemptId/review" component={p.QuizAttemptReviewPage}/>
        <PrivateRoute exact path="/notifications" component={p.NotificationsPage}/>
        {!isWeTest() && <PrivateRoute exact path="/classes" component={p.CommunityPage}/>}
        {!isWeTest() && <PrivateRoute exact path="/classes/:classId" component={p.CommunityClassPage}/>}
        {!isWeTest() && <PrivateRoute exact path="/classes/:classId/posts/:postId" component={p.CommunityClassPage}/>}
        {!isWeTest() && <PrivateRoute exact path="/classes/:classId/posts/:postId/comments/:commentId"
                                      component={p.CommunityClassPage}/>}
        {!isWeTest() && <PrivateRoute exact path="/classes/:classId/streams" component={p.StreamListPage}/>}
        {!isWeTest() && <PrivateRoute exact path="/classes/:classId/streams/:streamId" component={p.StreamShowPage}/>}
        {!isWeTest() && <PrivateRoute exact path="/classes/:classId/streams/:streamId/edit" component={p.StreamCreatePage}/>}
        {isWeTest() && <PrivateRoute exact path="/blog" component={p.BlogListPage}/>}
        {isWeTest() && <PrivateRoute exact path="/blog/:blogId" component={p.BlogShowPage}/>}
        <PrivateRoute exact path="/404" component={p.NotFound}/>
        <PrivateRoute exact path="/402" component={p.AccessDeniedPage}/>
        <Redirect path="*" to="/404"/>
      </Switch>
    </Switch>
  )
}

export default Routes
