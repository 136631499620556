import { UseQueryResult } from 'react-query'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getCourse } from 'services/courseService'
import { CourseView } from 'types/CourseView'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useCourse = (courseId): UseQueryResult<CourseView> => useQueryWithSnackbar(
  [ReactQueryKeys.COURSE, courseId],
  () => getCourse(courseId),
  {
    enabled: !!courseId,
  },
  'getCourse',
)

export default useCourse
