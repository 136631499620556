import React from 'react'
import { Link } from 'react-router-dom'
import {
  Button,
  styled,
} from '@mui/material'

import { LOGO_URL } from 'theme/constants'

const BasicHomeButton = styled(Button)(({ theme }) => ({
  background: theme.palette.background.default,
  height: '50px',
  maxHeight: '50px',
  width: '65px',
  borderRadius: 0,
}))

const HomeButton = () => {
  return (
    <BasicHomeButton>
      <Link to="/">
        <img src={LOGO_URL} alt="Logo"/>
      </Link>
    </BasicHomeButton>
  )
}

export default HomeButton
