import React, { FC } from 'react'
import { Box, Button } from '@mui/material'

import nextIcon from 'images/next-icon.svg'
import prevIcon from 'images/prev-icon.svg'

const style = {
  minWidth: 'unset',
  width: { xs: '37px', md: '62px' },
  height: { xs: '37px', md: '62px' },
  borderRadius: 0,
  ':hover': {
    background: theme => theme.palette.background.paperLighter,
    filter: 'brightness(0.6) !important',
  },
} as const

type CarouselButtonsProps = {
  onPreviousClick?: () => void
  onNextClick?: () => void
}

const CarouselButtons: FC<CarouselButtonsProps> = ({ onPreviousClick, onNextClick }) => {
  return (
    <Box display="flex" flexDirection="row">
      <Button
        sx={{
          ...style,
          background: theme => theme.palette.background.paperLighter,
        }}
        onClick={onPreviousClick}
      >
        <img
          src={prevIcon}
          alt="Show previous item"
        />
      </Button>
      <Button
        className="u-pt-10 u-pb-10"
        sx={{
          ...style,
          background: theme => theme.palette.background.paper,
        }}
        onClick={onNextClick}
      >
        <img
          src={nextIcon}
          alt="Show next item"
        />
      </Button>
    </Box>
  )
}

export default CarouselButtons
