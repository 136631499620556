import React from 'react'
import {
  Avatar,
  Button,
  CSSObject,
  Drawer,
  DrawerProps, List,
  ListItem,
  ListItemIcon,
  ListItemText as MuiListItemText,
  styled,
  Theme,
  Typography,
  Box,
} from '@mui/material'
import { useLocation, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import HomeButton from './HomeButton'
import config from 'utils/config'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { COURSES_IMAGE, HOME_IMAGE, isWeTest, PROFILE_IMAGE, QUIZZES_IMAGE } from 'theme/constants'
import { ImageSize } from 'types/ImageSize';

export const NAVBAR_WIDTH = 160
export const NAVBAR_WIDTH_MOBILE = 183
export const NAVBAR_WIDTH_COLLAPSED = 65

const openedMixin = (theme: Theme): CSSObject => ({
  border: 0,
  overflow: 'hidden',
  [theme.breakpoints.down('sm')]: {
    width: NAVBAR_WIDTH_MOBILE,
  },
  [theme.breakpoints.up('sm')]: {
    width: NAVBAR_WIDTH,
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
})

const closedMixin = (theme: Theme): CSSObject => ({
  overflow: 'hidden',
  border: 0,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  [theme.breakpoints.down('sm')]: {
    width: 0,
  },
  [theme.breakpoints.up('sm')]: {
    width: NAVBAR_WIDTH_COLLAPSED,
  },
})

interface NavBarDrawerProps extends DrawerProps {
  navCollapsed: boolean;
}

const NavBarDrawer = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'navCollapsed' })<NavBarDrawerProps>(() => (
  ({ theme, navCollapsed }) => ({
    width: NAVBAR_WIDTH,
    overflowX: 'hidden',
    '& .MuiDrawer-paper': {
      width: NAVBAR_WIDTH,
    },
    '& .MuiDrawer-docked.MuiDrawer-paper': {
      top: 0,
      [theme.breakpoints.down('sm')]: {
        top: '50px',
      },
    },
    '& .MuiDrawer-paper.MuiDrawer-paperAnchorLeft': {
      top: 0,
      [theme.breakpoints.down('sm')]: {
        top: '50px',
      },
    },
    ...(!navCollapsed && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(navCollapsed && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  })))


const ListItemText = styled(MuiListItemText)(() => ({
  '& .MuiListItemText-primary': {
    textTransform: 'none',
    fontSize: theme => theme.typography.fontSize,
  },
}))

type NavBarProps = {
  navCollapsed: boolean
  toggleNav: () => void
  currentUser?: StudentProfileInterface
}

const home = {
  text: 'dashboard.menu.home',
  icon: HOME_IMAGE,
  link: '/',
}
const profile = {
  text: 'dashboard.menu.profile',
  icon: PROFILE_IMAGE,
  link: '/profile',
}
const classes = {
  text: 'dashboard.menu.classes',
  icon: '/images/icons/navbar/classes.svg',
  link: '/classes',
}
const courses = {
  text: 'dashboard.menu.courses',
  icon: COURSES_IMAGE,
  link: '/courses',
}
const quizzes = {
  text: 'dashboard.menu.quizzes',
  icon: QUIZZES_IMAGE,
  link: '/quizzes',
}
const statistics = {
  text: 'dashboard.menu.statistics',
  icon: '/images/icons/navbar/statistics.svg',
  link: '/statistics',
}
const blogs = {
  text: 'dashboard.menu.blogs',
  icon: '/images/icons/navbar/blogs.svg',
  link: '/blog',
}

const proMedNavs = [
  home,
  profile,
  classes,
  courses,
  quizzes,
  statistics,
]
const weTestNavs = [
  home,
  profile,
  courses,
  quizzes,
  statistics,
  blogs,
]

const navs = isWeTest() ? weTestNavs : proMedNavs

function NavBar({ navCollapsed, toggleNav, currentUser }: NavBarProps) {
  const { t } = useTranslation()
  const location = useLocation()

  const profile = currentUser?.profile
  const firstName = profile?.firstName || ''
  const lastName = profile?.lastName || ''
  const profileImageUri = profile?.profileImageUri || ''

  const isSelected = (link: string) => {
    if (link === '/classes' || link === '/courses') {
      return location.pathname.includes(link)
    } else if (link === '/quizzes') {
      return location.pathname.includes('/quiz')
    }
    return link === location.pathname
  }

  return (
    <NavBarDrawer
      variant="permanent"
      anchor="left"
      navCollapsed={navCollapsed}
      sx={{
        position: { xs: 'absolute', sm: 'unset' },
        '& .MuiDrawer-docked.MuiDrawer-paper': { top: { xs: '50px', md: 0 } },
      }}
    >
      <Box sx={{ display: { xs: 'flex', sm: 'none' }, padding: '20px 10px', mt: '10px', flexDirection: 'column' }}>
        <Avatar alt={`${firstName} ${lastName}`}
                src={isEmpty(profile?.profileImageUri)
                  ? `https://eu.ui-avatars.com/api/?name=${firstName}+${lastName}&background=4780D8&color=fff`
                  : `${config.cdnBaseApiURI}${profileImageUri}${ImageSize.PREVIEW}`}
                sx={{ margin: 'auto', width: '70px', height: '70px', mb: '10px' }}/>
        <Typography sx={{
          textAlign: 'center',
          fontSize: '12px',
          lineHeight: '14px',
          color: '#D3DBE2',
          opacity: '0.8',
        }}>{`${firstName} ${lastName}`}</Typography>
      </Box>

      <List component="nav" sx={{ pt: 0 }}>
        <Box sx={{ display: { xs: 'none', sm: 'flex' } }}>
          <HomeButton/>
        </Box>
        <ListItem sx={{ pl: 0, height: '50px', display: { xs: 'none', sm: 'flex' }, justifyContent: 'flex-start' }}
                  component={Button} onClick={toggleNav}>
          <ListItemIcon sx={{ minWidth: '65px', justifyContent: 'center' }}>
            <img src="/images/icons/navbar/menu.svg" alt={t('dashboard.menu.menu')}/>
          </ListItemIcon>
        </ListItem>
        {
          navs.map(({ text, icon, link }) => (
            <Link to={link} key={link}>
              <ListItem
                className="Navigation"
                sx={{
                  pl: 0,
                  height: '50px',
                  '&.Mui-selected.MuiButtonBase-root': {
                    backgroundColor: theme => theme.palette.common.navigationSelected,
                  },
                }}
                component={Button}
                selected={isSelected(link)}
              >
                <ListItemIcon sx={{ minWidth: '65px', justifyContent: 'center' }}>
                  <img src={icon} alt={t(text)} className="Navigation-icon"/>
                </ListItemIcon>
                <ListItemText primary={t(text)} className="Navigation-text"/>
              </ListItem>
            </Link>
          ))
        }
      </List>
    </NavBarDrawer>
  )
}

export default NavBar
