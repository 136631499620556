import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import PracticeQuizCreate from 'components/quizzes/subject-practise/PracticeQuizCreate'
import { Back } from 'components/shared/back'
import useSubjectTopics from 'hooks/useSubjectTopics'
import useStartQuizPractice from 'hooks/useStartQuizPractice'
import useSubject from 'hooks/useSubject'

const PracticeQuizCreatePage = (props: RouteComponentProps<{ subjectId?: string }>) => {
  const subjectId: string = props.match.params.subjectId || ''

  const {
    data: subject,
  } = useSubject(subjectId)
  const {t} = useTranslation()
  const subjectTopicsQuery = useSubjectTopics(subject && subject.id)
  const topics = subjectTopicsQuery.data


  const startQuizPracticeMutation = useStartQuizPractice()
  const handleSubmit = (data: PracticeQuizCreateRequest) => startQuizPracticeMutation.mutate(data)

  return (
    <DashboardLayout>
      <Back
        text={t(translations.navigation.quizzesPage)}
        url={'/quizzes'}
        sx={{ mx: {xs: '20px', lg: 6.5} }}
      />
      {subject && topics && <PracticeQuizCreate subject={subject} topics={topics} handleSubmit={handleSubmit}/>}
    </DashboardLayout>
  )
}

export default PracticeQuizCreatePage
