import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { updatePostComment } from 'services/community/postCommentService'
import { translations } from 'locales/i18n'

const useUpdatePostComment = () => useMutationWithSnackbar(
  (data: PostCommentRequest) => updatePostComment(data),
  'updatePostComment',
  translations.messages.postCommentUpdated,
)

export default useUpdatePostComment
