import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import Course from 'components/course/Course'
import useCourse from 'hooks/useCourse'
import LoadingIndicator from 'components/shared/loading-indicator'
import useUpdateCourseVideoWatchedSeconds from 'hooks/useUpdateCourseVideoWatchedSeconds'

const CoursePage = (
  props: RouteComponentProps<{ courseId?: string }>,
) => {
  const history = useHistory()
  const courseId: string = props.match.params.courseId || ''

  const courseQuery = useCourse(courseId)
  const course = courseQuery.data

  if (isEmpty(courseId)) {
    history.push('/')
  }

  const updateCourseVideoWatchedSecondsMutation = useUpdateCourseVideoWatchedSeconds()
  const handleUpdateCourseVideoWatchedSeconds = (currentVideoTime: number) =>
    updateCourseVideoWatchedSecondsMutation.mutate({
      courseId,
      seconds: currentVideoTime,
    } as LessonVideoWatchedSecondsUpdateRequest)

  return (
    <DashboardLayout>
      {course && <Course course={course} frequentTopicLessons={[]}
                         onUpdateCourseVideoWatchedSeconds={handleUpdateCourseVideoWatchedSeconds}/>}
      {courseQuery.isLoading && <LoadingIndicator/>}
    </DashboardLayout>
  )
}

export default CoursePage
