import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import {
  styled,
  Box,
  ListItem,
  Menu,
  MenuItem,
  ListItemAvatar,
  ListItemText,
  List,
} from "@mui/material"

import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { VOTED_USERS_COUNT_TO_SHOW } from 'utils/constants'
import threeDots from 'images/three-horizontal-dots.svg'
import CommunityUserAvatar from '../../../CommunityUserAvatar'
import { translations } from 'locales/i18n'

const StyledVoiceList = styled(List)(({theme}) => ({
  display: 'flex',
  flexDirection: 'row-reverse',
  padding: 0,

  '& .MuiListItem-root': {
    alignItems: 'center',
    margin: 0,
    padding: 0,
    marginLeft: '-10px',
    border: `3px solid ${theme.palette.background.default}`,
    borderRadius: '50%',
    overflow: 'hidden',
  },

}))

const ITEM_HEIGHT = 80

type PollVotesListProps = {
  currentUser: StudentProfileInterface
  votedUsers?: PollVoteAuthor[]
  maxVotesCount: number
  pinned: boolean
}

const PollVotesList: FC<PollVotesListProps> = ({
                                                 currentUser,
                                                 votedUsers = [],
                                                 maxVotesCount,
                                                 pinned,
                                               }) => {
  const {t} = useTranslation()

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleRestUsersOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget)
  const handleRestUsersClose = () => setAnchorEl(null)

  const renderUserAvatar = (firstName: string, lastName: string, profileImageUri: string) => (
    <CommunityUserAvatar
      profileImageUri={profileImageUri}
      firstName={firstName}
      lastName={lastName}
      sx={{width: '100%', height: '100%'}}
    />
  )

  const votesCountToDefineWidth = maxVotesCount > VOTED_USERS_COUNT_TO_SHOW ? VOTED_USERS_COUNT_TO_SHOW : maxVotesCount

  const getWidth = (avatarWidth: number, menuWidth: number) =>
    votesCountToDefineWidth > 1
      ? `${avatarWidth * votesCountToDefineWidth - 10 + (maxVotesCount > VOTED_USERS_COUNT_TO_SHOW ? menuWidth : 0)}px`
      : `${avatarWidth}px`

  const renderVotesCount = (color: string, display) => (
    votedUsers?.length > VOTED_USERS_COUNT_TO_SHOW && (
      <Box
        sx={{
          mt: {md: '3px'},
          ml: '3px',
          color: color,
          fontSize: {xs: '10px', md: '12px'},
          lineHeight: {xs: '12px', md: '15px'},
          display: display,
        }}
      >
        {`${t(translations.community.postCard.votes)}: ${votedUsers?.length}`}
      </Box>
    )
  )

  return maxVotesCount > 0 ? (
    <Box sx={{
      display: 'flex',
      ml: {xs: '25px', md: '30px'},
      justifyContent: {xs: 'space-between', md: 'flex-end'},
      alignItems: 'center',
      minWidth: {xs: getWidth(23, 10), md: getWidth(30, 26)},
    }}
    >
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
      >
        {renderVotesCount(pinned ? '#202021' : 'black', {xs: 'none', md: 'block'})}
        <StyledVoiceList>
          {votedUsers.filter((option, index) => index < VOTED_USERS_COUNT_TO_SHOW).map(({
                                                                                          id,
                                                                                          firstName,
                                                                                          lastName,
                                                                                          profileImageUri,
                                                                                        }) => (
            <ListItem key={id}
              sx={{
                width: { xs: "23px", md: "30px" },
                height: { xs: "23px", md: "30px" },
                border: `3px solid ${pinned ? '#1f2021' : 'black'} !important`
              }}>
              {renderUserAvatar(firstName, lastName, profileImageUri)}
            </ListItem>
          ))}
        </StyledVoiceList>
        {renderVotesCount('#66696C', 'block')}
      </Box>
      {votedUsers.length > VOTED_USERS_COUNT_TO_SHOW && (
        <Box>
          <Box
            onClick={handleRestUsersOpen}
            sx={{
              display: 'flex',
              alignItems: 'center',
              pl: {xs: '7px', md: '20px'},
              cursor: 'pointer',
              height: '20px',
            }}
          >
            <img src={threeDots} alt="Rest users"/>
          </Box>
          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleRestUsersClose}
            PaperProps={{
              elevation: 0,
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 'auto',
              },
            }}
            transformOrigin={{horizontal: 'right', vertical: 'top'}}
            anchorOrigin={{horizontal: 'right', vertical: 'top'}}
          >
            {votedUsers.map(({id, firstName, lastName, profileImageUri}) => (
              <MenuItem key={id} selected={currentUser.id === id} onClick={handleRestUsersClose}>
                <ListItem alignItems="center">
                  <ListItemAvatar sx={{
                    minWidth: '25px',
                    width: { xs: '25px', md: '30px' },
                    height: { xs: '25px', md: '30px' },
                    mr: '14px'
                  }}>
                    {renderUserAvatar(firstName, lastName, profileImageUri)}
                  </ListItemAvatar>
                  <ListItemText primary={`${firstName} ${lastName}`}/>
                </ListItem>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}
    </Box>
  ) : <></>
}

export default PollVotesList
