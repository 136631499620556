import React from 'react'
import { RouteComponentProps } from 'react-router'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { isEmpty, isArray } from 'lodash'

import PasswordLayout from 'components/layouts/password/PasswordLayout'
import ResetPassword from 'components/password/reset-password/ResetPassword'
import useResetPassword from 'hooks/useResetPassword'

function ResetPasswordPage(props: RouteComponentProps) {
  const history = useHistory()
  const queryParams = queryString.parse(props.location.search)
  const userId = queryParams.userId || ''
  const code = queryParams.code || ''

  if (isEmpty(userId) || isEmpty(code)) {
    history.push('/login')
  }

  const getFirstValue = (value: string | string[]) => isArray(value) ? value[0] : value

  const resetPasswordMutation = useResetPassword()

  const handleSubmit = (data: ResetPasswordRequest) => resetPasswordMutation.mutate(data)

  return (
    <PasswordLayout>
      <ResetPassword
        userId={getFirstValue(userId)}
        code={getFirstValue(code)}
        onSubmit={handleSubmit}
        isLoading={resetPasswordMutation.isLoading}
      />
    </PasswordLayout>
  )
}

export default ResetPasswordPage
