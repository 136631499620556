import React, { FC } from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import { Box, Divider } from '@mui/material'

import QuestionNumber from './QuestionNumber'
import Question from './Question'
import AnswerOption from './AnswerOption'
import { QUESTION_ANCHOR_PREFIX } from 'utils/constants'


type QuizQuestionCardProps = {
  question: QuestionInterface
  quizAttemptId: string
  questionNumber: number
  questionIndex: number
  onToggleFlag: (selectedQuestion: number) => void
  onSubmitAnswer: (data: QuizAttemptAnswerCreateRequest) => void
}

const QuizQuestionCard: FC<QuizQuestionCardProps> = ({
                                                       quizAttemptId,
                                                       question,
                                                       questionIndex,
                                                       questionNumber,
                                                       onToggleFlag,
                                                       onSubmitAnswer,
                                                     }) => {
  const { text, options} = question
                                                      
  return (
    <ScrollableAnchor id={`${QUESTION_ANCHOR_PREFIX}${questionNumber}`}>
      <Box
        sx={{
          width: {xs: '100%', lg: "713px"},
          background: theme => theme.palette.background.paper,
          borderRadius: "3px",
        }}
        className="u-text-noselect"
      >
        <Box sx={{py: {xs: "16px", md: "32px"}, px: {xs: "20px", md: "46px"}}}>
          <QuestionNumber number={questionNumber} questionIndex={questionIndex} onToggleFlag={onToggleFlag}/>
          <Question questionText={text}/>
        </Box>
        <Divider/>
        {
          options.map((option, i) => (
            <AnswerOption
              index={i}
              key={`${option.number} + ${i}`}
              selected={option.studentAnswer}
              text={option.text}
              onSelect={() => onSubmitAnswer({quizAttemptId, questionNumber, answer: option.number})}
              onDelete={() => onSubmitAnswer({quizAttemptId, questionNumber, answer: null})}
            />
          ))
        }
      </Box>
    </ScrollableAnchor>
  )}

export default QuizQuestionCard
