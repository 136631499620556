import { clientDataService } from './clientDataService'

export const getSubjects = (): Promise<Subject[]> =>
  clientDataService<Subject[]>({
    url: '/subjects',
  })

export const getSubject = (subjectId?: string): Promise<Subject> =>
  clientDataService<Subject>({
    url: `/subjects/${subjectId}`,
  })
