import { boolean, object, string, mixed } from 'yup'
import i18n from 'i18next'
import { translations } from 'locales/i18n'
import config from 'utils/config'
import { IMAGE_SUPPORTED_FORMATS } from 'utils/constants'

const UpdateProfileFormSchema = object().shape({
  firstName: string().required(),
  lastName: string().required(),
  nickname: string().required(),
  deleteProfileImage: boolean(),
  profileImage: mixed()
    .test(
      'size',
      i18n.t(translations.profilePage.changeImage),
      value => !value || value && value.size <= config.image.maxFileSizeMb * 1024 * 1024,
    ).test(
      'type',
      i18n.t(translations.profilePage.changeImage),
      value => !value || value && IMAGE_SUPPORTED_FORMATS.includes(value.type),
    ),
})

export default UpdateProfileFormSchema
