import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, List, ListItem, ListItemText, ListItemAvatar } from '@mui/material'
import { isEmpty } from 'lodash'
import { CommunityTitle } from './ui/StyledComponent'
import Searchbar from './posts/SearchBar'
import { translations } from 'locales/i18n'
import CommunityUserAvatar from './CommunityUserAvatar'
import PopularPollsList from '../community/popular/PopularPollsList'
import { PostInterface } from 'types/PostInterface'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import TopicMenu from './TopicMenu'

type TopicNavigationProps = {
  classId: string
  teachers?: TeacherInterface[]
  currentUser?: StudentProfileInterface
  recentPosts: PostInterface[]
  onFullscreenPost: (post: PostInterface) => void
  onEditPopularPoll: (post: PostInterface) => void
  onDeletePopularPoll: (post: PostInterface) => void
  onReportPopularPoll: (post: PostInterface) => void
  setSearchQuery: (value: string) => void
}

const TopicNavigation: FC<TopicNavigationProps> = ({
                                                     classId,
                                                     teachers,
                                                     currentUser,
                                                     recentPosts,
                                                     setSearchQuery,
                                                     onFullscreenPost,
                                                     onEditPopularPoll,
                                                     onReportPopularPoll,
                                                     onDeletePopularPoll,
                                                   }) => {
  const {t} = useTranslation()
  const isClassMember = currentUser?.classes.includes(classId) || currentUser?.mainRole === 'ADMIN'
  return (
    <Box
      sx={{
        maxWidth: {md: '235px', lg: 'unset'},
      }}
    >
      <Searchbar setSearchQuery={setSearchQuery}/>
      <Box>
        <TopicMenu classId={classId} isClassMember={isClassMember}/>
        <Box sx={{display: {xs: 'none', md: 'block'}}}>
          {teachers && teachers.length > 0 && (
            <List component="ul">
              <CommunityTitle sx={{
                pt: '50px',
                pb: '20px',
              }}
              >
                {t(translations.community.feed.teachers)}
              </CommunityTitle>
              {teachers.map(({id, firstName, lastName, profileImageUri}, index) => (
                  <ListItem
                    key={id}
                    sx={{pl: 0, height: '50px'}}
                  >
                    <ListItemAvatar>
                      <CommunityUserAvatar
                        profileImageUri={profileImageUri}
                        firstName={firstName}
                        lastName={lastName}
                        sx={{width: {xs: "25px", md: "30px"}, height: {xs: "25px", md: "30px"}}}
                      />
                    </ListItemAvatar>
                    <ListItemText primary={`${firstName} ${lastName}`} className="Navigation-text"/>
                  </ListItem>
                ),
              )}
            </List>
          )}
        </Box>
        {!isEmpty(recentPosts) && currentUser &&
        <Box sx={{display: {xs: 'none', md: 'block', xl: 'none'}, pt: {md: '45px'}}}>
          <PopularPollsList
            currentUser={currentUser}
            recentPosts={recentPosts}
            onFullscreenPost={onFullscreenPost}
            onEditPopularPoll={onEditPopularPoll}
            onReportPopularPoll={onReportPopularPoll}
            onDeletePopularPoll={onDeletePopularPoll}
            showMenu={isClassMember}
          />
        </Box>}
      </Box>
    </Box>
  )
}

export default TopicNavigation
