import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'

import QuizAttemptResults from 'components/quiz-attempt-results'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import useQuizAttemptResults from 'hooks/useQuizAttemptResults'
import { Back } from 'components/shared/back'
import LoadingIndicator from 'components/shared/loading-indicator'
import useQuizAttemptStatistics from 'hooks/useQuizAttemptStatistics'
import useStartRandomSimulation from 'hooks/useStartRandomSimulation'
import useStartMonthlySimulation from 'hooks/useStartMonthlySimulation'
import useStartQuizPractice from 'hooks/useStartQuizPractice'
import useStartOfficialNationalSimulation from 'hooks/useStartOfficialNationalSimulation'
import useStartLessonQuiz from 'hooks/useStartLessonQuiz'
import { QuizType } from 'types/QuizType'
import useStartStaticPractice from 'hooks/useStartStaticPractice'

export function QuizAttemptResultsPage(
  props: RouteComponentProps<{ quizAttemptId?: string }>,
) {
  const {t} = useTranslation()
  const history = useHistory()
  const quizAttemptId: string = props.match.params.quizAttemptId || ''

  if (isEmpty(quizAttemptId)) {
    history.push('/')
  }

  const quizAttemptResultsQuery = useQuizAttemptResults(quizAttemptId)
  const quizAttemptStatisticsQuery = useQuizAttemptStatistics(quizAttemptId)
  const results = quizAttemptResultsQuery.data
  const statistics = quizAttemptStatisticsQuery.data
  const quizType = statistics?.quizType
  const quizAttemptDetails = statistics?.quizAttemptDetails

  const startQuizPracticeMutation = useStartQuizPractice()
  const startRandomSimulationMutation = useStartRandomSimulation()
  const startMonthlySimulationMutation = useStartMonthlySimulation()
  const startOfficialNationalSimulationMutation = useStartOfficialNationalSimulation()
  const startStaticPracticeSimulationMutation = useStartStaticPractice()
  const startLessonQuizMutation = useStartLessonQuiz()
  const groupName = `${quizAttemptDetails?.groupName}: ${quizAttemptDetails?.quizName}`

  const handleRestartTest = () => {
    switch (quizType) {
      case QuizType.MONTHLY_SIMULATION:
      case QuizType.AREA_MONTHLY_PRACTICE:
        return startMonthlySimulationMutation.mutate()
      case QuizType.RANDOM_SIMULATION:
        return startRandomSimulationMutation.mutate()
      case QuizType.STATIC_PRACTICE:
        return startStaticPracticeSimulationMutation.mutate(quizAttemptDetails?.quizId)
      case QuizType.OFFICIAL_NATIONAL_SIMULATION:
        return startOfficialNationalSimulationMutation.mutate(quizAttemptDetails?.quizId)
      case QuizType.LESSON:
        return startLessonQuizMutation.mutate({
            courseId: quizAttemptDetails?.courseId,
            lessonId: quizAttemptDetails?.lessonId,
          } as LessonQuizCreateRequest,
        )
      case QuizType.PRACTICE:
      case QuizType.AREA_PRACTICE:
        return startQuizPracticeMutation.mutate({
            subjectId: quizAttemptDetails?.subject?.id,
            difficulty: quizAttemptDetails?.difficulty,
            topicId: quizAttemptDetails?.topic?.id,
          } as PracticeQuizCreateRequest,
        )
      default:
        break
    }
  }

  const getLinkBack = () => {
    if (quizType === 'LESSON') {
      return {
        text: t(translations.navigation.lessonPage),
        url: `/courses/${quizAttemptDetails?.courseId}/lessons/${quizAttemptDetails?.lessonId}`
      }
    }
      return {
        text: t(translations.navigation.quizzesPage),
        url: '/quizzes'
      }
  }
  const dataButtonBack = getLinkBack()

  return (
    <DashboardLayout>
      {!quizAttemptResultsQuery.isLoading &&
        <Back
          text={dataButtonBack.text}
          url={dataButtonBack.url}
          sx={{display: {xs: 'none', lg: 'initial'}, position: 'fixed'}}
        />
      }
      {results && statistics &&
        <QuizAttemptResults results={results} groupName={groupName} statistics={statistics} handleRestartTest={handleRestartTest}/>
      }
      {quizAttemptResultsQuery.isLoading && <LoadingIndicator/>}
    </DashboardLayout>
  )
}

export default QuizAttemptResultsPage
