import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import { PostInterface } from 'types/PostInterface'
import { translations } from 'locales/i18n'
import PostCardHeaderData from './PostCardHeaderData'
import addToFavsIcon from 'images/add-to-favs.svg'
import copyLinkIcon from 'images/copy-link.svg'
import removeFromFavsIcon from 'images/remove-from-favs.svg'
import pencilIcon from 'images/pencil.svg'
import deleteIcon from 'images/delete.svg'
import pinPostIcon from 'images/pin-post.svg'
import unpinPostIcon from 'images/unpin-post.svg'
import { UserProfileRole } from 'types/UserProfileRole'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import CommunityMenu from 'components/community/CommunityMenu'

type PostCardHeaderProps = {
  classId: string
  post: PostInterface
  pinned: boolean
  onStartEdit: () => void
  onPostDelete: () => void
  onPostPin: () => void
  onPostUnpin: () => void
  onCopiedLink: () => void
  onAddPostToFavourites: () => void
  onRemovePostFromFavourites: () => void
  onOpenPostReport: () => void
  currentUser: StudentProfileInterface
  classTeachers: TeacherInterface[]
  showMenu: boolean
}

const PostCardHeader: FC<PostCardHeaderProps> = ({
                                                   classId,
                                                   currentUser,
                                                   post,
                                                   pinned,
                                                   onStartEdit,
                                                   onPostDelete,
                                                   onPostPin,
                                                   onPostUnpin,
                                                   onCopiedLink,
                                                   onAddPostToFavourites,
                                                   onRemovePostFromFavourites,
                                                   onOpenPostReport,
                                                   classTeachers,
                                                   showMenu,
                                                 }) => {
  const {t} = useTranslation()
  const {postAuthor, favorite, postId} = post
  const {profile, mainRole, id: currentUserId} = currentUser
  const isAdmin = mainRole === UserProfileRole.ADMIN
  const isClassTeacher = !!classTeachers.find(({id}) => id === currentUserId)
  const canPin = isAdmin || isClassTeacher
  const canEdit = isAdmin || isClassTeacher || profile.nickname === postAuthor.nickname

  const menuItems = [
    !favorite && ({
      icon: addToFavsIcon,
      title: t(translations.community.postCard.addToFavs),
      onClick: onAddPostToFavourites,
    } as MenuItem),
    ({
      icon: copyLinkIcon,
      title: (
        <CopyToClipboard
          text={`${window.location.origin}/classes/${classId}/posts/${postId}`}
          onCopy={() => onCopiedLink()}
        >
          <Box>{t(translations.community.postCard.copyLink)}</Box>
        </CopyToClipboard>
      ),
    } as MenuItem),
    ({
      icon: '/images/icons/community/post-report-icon.svg',
      title: t(translations.community.postCard.reportPost),
      onClick: onOpenPostReport,
    } as MenuItem),
    favorite && {
      icon: removeFromFavsIcon,
      title: t(translations.community.postCard.removeFromFavs),
      onClick: onRemovePostFromFavourites,
    } as MenuItem,
    canPin && !pinned && {
      icon: pinPostIcon,
      title: t(translations.community.postCard.pinPost),
      onClick: onPostPin,
    } as MenuItem,
    canPin && pinned && {
      icon: unpinPostIcon,
      title: t(translations.community.postCard.unpinPost),
      onClick: onPostUnpin,
    } as MenuItem,
    canEdit && {
      icon: pencilIcon,
      title: t(translations.community.postCard.editPost),
      onClick: onStartEdit,
    } as MenuItem,
    canEdit && {
      icon: deleteIcon,
      title: t(translations.community.postCard.deletePost),
      onClick: onPostDelete,
    } as MenuItem,
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      {post && <PostCardHeaderData post={post} menuShown={showMenu}/>}

      {showMenu && menuItems.length > 0 && (
        <CommunityMenu menuItems={menuItems} keyPrefix={postId}/>
      )}
    </Box>
  )
}
export default PostCardHeader
