import React, { FC } from 'react'
import { Box, List, ListItem, ListItemText } from "@mui/material"
import ContentWithMath from 'components/quiz-attempt-review/quiz-question-card/ContentWithMath'

const indexToLetter = (index: number) => String.fromCharCode(index + 65)

type AnswerProps = {
  selected: boolean
  onSelect: () => void
  onDelete: () => void
  text: string
  index: number
}

const AnswerOption: FC<AnswerProps> = ({selected, onSelect, onDelete, text, index}) => (
    <List className="u-p-0">
      <ListItem
        button
        divider={index !== 4}
        sx={{
          minHeight: "79px",
          backgroundColor: selected ? 'rgba(255, 255, 255, 0.1)' : 'inherit',
          p: {xs: "18px 21px", md: "27px 26px 26px 46px"},
        }}
        onClick={selected ? onDelete : onSelect}
      >
        <ListItemText
          disableTypography={true}
          primary={
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Box sx={{
                display: "flex",
                minWidth: {xs: "16px", md: "25px"},
                maxWidth: {xs: "16px", md: "25px"},
                minHeight: {xs: "16px", md: "25px"},
                maxHeight: {xs: "16px", md: "25px"},
                mr: {xs: "16px", md: "25px"},
              }}>
                <img
                  src={selected ? "/images/icons/correctAnswer.svg" : "/images/icons/Oval9.svg"}
                  alt="oval9"
                  className="u-w-100 u-h-100"
                />
              </Box>
              <Box sx={{
                minHeight: {xs: "13px", md: "20px"},
                minWidth: {xs: "13px", md: "20px"},
                border: {xs: "1px solid", md: "1.7px solid"},
                borderRadius: {xs: "2px", md: "4px"},
                mr: {xs: "14px", md: "25px"},
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
              >
                <Box sx={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: {xs: "7px", md: "11px"},
                  lineHeight: {xs: 1, md: 'inherit'},
                }}>
                  {indexToLetter(index)}
                </Box>
              </Box>
              <Box>
                <ContentWithMath
                  content={text}
                  contentSx={{
                    fontSize: {xs: '12px', md: '18px'},
                    lineHeight: {xs: '19px', md: '28px'},
                  }}
                />
              </Box>
            </Box>
          }/>
      </ListItem>
    </List>
  )

export default AnswerOption
