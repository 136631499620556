import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getQuizAttemptStatistics } from 'services/quizAttemtService'
import { QuizAttemptStatisticsInterface } from 'types/QuizAttemptStatisticsInterface'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useQuizAttemptStatistics = (
  quizAttemptId: string,
  options?: UseQueryOptions<QuizAttemptStatisticsInterface, AxiosError>,
): UseQueryResult<QuizAttemptStatisticsInterface> =>
  useQueryWithSnackbar<QuizAttemptStatisticsInterface>(
    [ReactQueryKeys.QUIZ_ATTEMPT_STATISTICS, quizAttemptId],
    () => getQuizAttemptStatistics(quizAttemptId),
    {
      enabled: !!quizAttemptId,
      ...options,
    })


export default useQuizAttemptStatistics
