export enum QuizType {
  MONTHLY_SIMULATION = 'MONTHLY_SIMULATION',
  AREA_MONTHLY_PRACTICE = 'AREA_MONTHLY_PRACTICE',
  RANDOM_SIMULATION = 'RANDOM_SIMULATION',
  OFFICIAL_NATIONAL_SIMULATION = 'OFFICIAL_NATIONAL_SIMULATION',
  LESSON = 'LESSON',
  PRACTICE = 'PRACTICE',
  AREA_PRACTICE = 'AREA_PRACTICE',
  STATIC_PRACTICE = 'STATIC_PRACTICE',
  SIMULATION ='SIMULATION',
  PACK_SIMULATION = 'PACK_SIMULATION'
}
