import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getNotificationSettings } from 'services/community/notificationService'
import { NotificationSettingView } from 'types/NotificationSettingView'

const useNotificationSetting = (options?: UseQueryOptions<NotificationSettingView[], AxiosError>): UseQueryResult<NotificationSettingView[]> => {
  return useQueryWithSnackbar<NotificationSettingView[]>(
    [ReactQueryKeys.NOTIFICATION_SETTING],
    () => getNotificationSettings(),
    options,
  )
}

export default useNotificationSetting
