import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { updateMyProfile } from 'services/userService'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { translations } from 'locales/i18n'

const useUpdateProfile = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (data: ProfileUpdateRequest) => updateMyProfile(data),
    'updateMyProfile',
    undefined,
    {
      async onSuccess() {
        enqueueSnackbar(
          t(translations.messages.profileUpdated),
          { variant: 'success' },
        )
        await queryClient.invalidateQueries(ReactQueryKeys.ME)
      },
    },
  )
}

export default useUpdateProfile
