import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import {
  Box,
  Button,
  Typography,
  useTheme,
} from '@mui/material'
import DifficultyLevelCards from './DifficultyLevelCards'
import { useTranslation } from 'react-i18next'
import { translations } from '../../../locales/i18n'

type SelectDifficultyLevelProps = {
  selectedDifficulty?: number,
  setDifficulty: (id: number) => void
  isValid: boolean
}


const SelectDifficultyLevel: FC<SelectDifficultyLevelProps> = ({
                                                                 selectedDifficulty,
                                                                 setDifficulty,
                                                                 isValid,
                                                               }) => {
  const {t} = useTranslation()
  const theme = useTheme()

  return (
    <Box sx={{display: 'flex', flexDirection: 'column', minWidth: {lg: '600px'}}}>
      <Typography
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: {xs: '18px', lg: '34px'},
          lineHeight: {xs: '23px', lg: '41px'},
        }}
      >
        {t(translations.subjectPractiseInitPage.selectDifficultyLevel)}
      </Typography>
      <Box sx={{display: 'flex', mt: 2, justifyContent: 'center'}}>
        <img src="/images/icons/quizList.svg" alt="quizList"/>
        <Typography
          sx={{
            ml: 1,
            color: theme => theme.palette.grey['600'],
            fontSize: {xs: '12px', lg: '18px'},
            lineHeight: {xs: '14px', lg: '22px'},
          }}
        >
          Quiz
        </Typography>
      </Box>
      <Box sx={{
        background: theme.palette.background.paper,
        border: '1px solid #363B3F',
        borderRadius: '3px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        mt: 3,
        width: {xs: '100%', lg: '600px'},
        height: {xs: 'auto', lg: '676px'},
      }}>
        <Box sx={{display: 'flex', mt: 3}}>
          <img src="/images/icons/checkMark.svg" alt="checkMark" className="u-pr-10"/>
          <Typography
            sx={{
              ml: 1,
              color: theme => theme.palette.grey['600'],
              fontSize: {xs: '12px', lg: '16px'},
              lineHeight: {xs: '14px', lg: '19px'},
            }}
          >
            10
          </Typography>
        </Box>
        <Box
          className="u-mt-25 u-weight-bold u-text-center"
          sx={{
            fontSize: {xs: '14px', lg: '24px'},
            lineHeight: {xs: '22px', lg: '34px'},
          }}
        >
          {t(translations.subjectPractiseInitPage.youHave3DifficultyLevels)}
        </Box>
        <Box
          className="u-mb-40 u-weight-bold u-text-center"
          sx={{
            fontSize: {xs: '14px', lg: '24px'},
            lineHeight: {xs: '22px', lg: '34px'},
          }}
        >
          {t(translations.subjectPractiseInitPage.chooseRightOne)}
        </Box>
        <Box sx={{ml: {xs: '20px', lg: '50px'}, mr: {xs: '20px', lg: '50px'}}}>
          <DifficultyLevelCards selectedDifficulty={selectedDifficulty} setDifficulty={setDifficulty}/>
          <Box sx={{display: 'flex', justifyContent: 'space-between', width: '100%', mt: 5, mb: 5}}>
            <Link to="/quizzes" className="u-w-48">
            <Button
              variant={'contained'}
              className="Button Button--secondary"
              sx={{textTransform: 'none', width: '100%' }}
            >
              <Box sx={{
                display: 'flex',
                width: {xs: '100%', lg: '80%'},
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}>
                <img src="/images/icons/arrowLeft.svg" alt="arrowLeft"/>
                <Typography sx={{fontSize: {xs: '12px', lg: '16px'}}}>
                  {t(translations.subjectPractiseInitPage.anotherTest)}
                </Typography>
              </Box>
            </Button>
            </Link>
            <Button
              variant={'contained'}
              sx={{textTransform: 'none', width: '48%'}}
              className="Button Button--primary"
              disabled={!isValid}
              type="submit"
            >
              <Box sx={{
                display: 'flex',
                width: {xs: '100%', lg: '80%'},
                justifyContent: 'space-evenly',
                alignItems: 'center',
              }}>
                <Typography sx={{fontSize: {xs: '12px', lg: '16px'}}}>
                  {t(translations.subjectPractiseInitPage.startQuiz)}
                </Typography>
                <img src="/images/icons/arrowRight.svg" alt="arrowRight"/>
              </Box>
            </Button>
          </Box>
        </Box>

      </Box>
    </Box>
  )
}

export default SelectDifficultyLevel
