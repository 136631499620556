import React, { CSSProperties, FC } from 'react'
import cn from 'classnames'
import { CircularProgress } from '@material-ui/core'

import styles from './loading-button-mobile.module.scss'
import { Button, ButtonProps } from '@mui/material'

interface LoadingButtonProps extends ButtonProps {
  loading?: boolean
  className?: string
  withoutUppercase?: boolean
  underlined?: boolean
}

const LoadingButton: FC<LoadingButtonProps> = ({
 children,
 underlined = false,
 className,
 withoutUppercase,
 loading,
 ...rest
}) => {
  const style: CSSProperties = {}

  if (withoutUppercase) {
    style.textTransform = 'none'
  }

  return (
    <Button
      style={style}
      className={cn(className, styles.loadingButton)}
      {...rest}>
      <span className={cn(underlined && styles.underlined)}>{children}</span>
      {loading && <CircularProgress className={styles.progress} size={24} />}
    </Button>
  )
}

export default LoadingButton
