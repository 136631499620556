import React, { FC } from 'react'
import { Box, Grid } from '@mui/material'
import { PostInterface } from 'types/PostInterface'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import PopularPollCardHeader from './PopularPollCardHeader'

type PopularPollCardProps = {
  currentUser: StudentProfileInterface
  post: PostInterface
  onFullscreenPost: (post: PostInterface) => void
  onEditPopularPoll: (post: PostInterface) => void
  onDeletePopularPoll: (post: PostInterface) => void
  onReportPopularPoll: (post: PostInterface) => void
  showMenu: boolean
}

const PopularPollCard: FC<PopularPollCardProps> = ({
                                                     currentUser,
                                                     post,
                                                     onFullscreenPost,
                                                     onEditPopularPoll,
                                                     onDeletePopularPoll,
                                                     onReportPopularPoll,
                                                     showMenu
                                                   }) => {

  const {postText} = post
  const cleanText = postText.replace(/<\/?[^>]+(>|$)/g, "").replace(/&nbsp;/g, ' ')

  return (
    <Grid
      sx={{
        display: {xs: 'none', sm: 'flex'},
        flexDirection: 'column',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <PopularPollCardHeader
        post={post}
        currentUser={currentUser}
        onStartEdit={() => onEditPopularPoll(post)}
        onDelete={() => onDeletePopularPoll(post)}
        onOpenPostReport={() => onReportPopularPoll(post)}
        showMenu={showMenu}
      />
      <Box
        sx={{
          backgroundColor: 'inherit',
          border: 'none',
          cursor: 'pointer',
          ml: {xs: '44px', lg: '64px'},
          fontSize: {xs: '14px', lg: '16px'},
          lineHeight: {xs: '16px', lg: '19px'},
          maxHeight: '50px',
          my: '16px',
          overflow: 'hidden !important',
          textAlign: 'left',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
        }}
        dangerouslySetInnerHTML={{__html: cleanText}}
        onClick={() => onFullscreenPost(post)}
      />
    </Grid>
  )
}

export default PopularPollCard
