import { translations } from 'locales/i18n'
import { reportComment } from 'services/community/postCommentService'
import useMutationWithSnackbar from 'hooks/useMutationWithSnackbar'

const useCommentReport = () => useMutationWithSnackbar(
  (data: ReportCommentRequest) => reportComment(data),
  undefined,
  translations.lessonPage.reports.sendReport,
)

export default useCommentReport
