import React, { FC } from 'react'
import { Card, CardContent, Container, Grid } from '@mui/material'
import styles from './LoginLayout.module.scss'

const LoginLayout: FC = ({ children }) => {
  return (
    <div className={styles.container}>
      {/* TODO add background image */}
      <Container maxWidth="lg">
        <Grid container justifyContent="center" alignItems="center">
          <Grid item xs={12} className={styles.cardWrapper}>
            <Card
              sx={{
                background: theme => theme.palette.background.paper,
                border: '1px solid #363B3F',
                boxSizing: 'border-box',
                borderRadius: '3px',
              }}
            >
              <CardContent className={styles.cardContent}>
                {children}
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default LoginLayout
