import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getSubjectTopics } from 'services/topicService'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useSubjectTopics = (
  subjectId?: string,
  options?: UseQueryOptions<Topic[], AxiosError>,
): UseQueryResult<Topic[]> => {
  return useQueryWithSnackbar<Topic[]>(
    [ReactQueryKeys.SUBJECT_TOPICS, subjectId],
    () => getSubjectTopics(subjectId),
    {
      enabled: !!subjectId,
      ...options,
    },
  )
}

export default useSubjectTopics
