import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getSubject } from 'services/subjectService'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useSubject = (
  subjectId?: string,
  options?: UseQueryOptions<Subject, AxiosError>,
): UseQueryResult<Subject> => {
  return useQueryWithSnackbar<Subject>(
    [ReactQueryKeys.SUBJECT, subjectId],
    () => getSubject(subjectId),
    {
      enabled: !!subjectId,
      ...options,
    },
  )
}

export default useSubject
