import { clientDataService } from './clientDataService'
import { OfficialNationalSimulationCategory } from 'types/OfficialNationalSimulationCategory'
import { OfficialNationalSimulationCategoryItem } from 'types/OfficialNationalSimulationCategoryItem'
import { Specialty } from 'types/Specialty'
import { QuizInterface } from 'types/QuizInterface'
import { UUID } from 'domain/types'
import { PackSimulationQuizzesInterface } from 'types/PackSimulationQuizzesInterface'

export const getQuizzes = (): Promise<QuizInterface> => {
  return clientDataService<QuizInterface>({
    url: '/v3/quizzes'
  })
}
export const getPackSimulationQuizzes = (packSimulationId: UUID): Promise<PackSimulationQuizzesInterface> => {
  return clientDataService<PackSimulationQuizzesInterface>({
    url: `/v1/pack-simulations/${packSimulationId}`
  })
}

export const getOfficialNationalSimulationCategories = (): Promise<OfficialNationalSimulationCategory[]> => {
  return clientDataService<OfficialNationalSimulationCategory[]>({
    url: '/v2/quizzes/official-national-simulations/summary',
  })
}

export const getOfficialNationalSimulationCategoryItems = (specialty?: Specialty): Promise<OfficialNationalSimulationCategoryItem[]> => {
  return clientDataService<OfficialNationalSimulationCategoryItem[]>({
    url: `/v2/quizzes/official-national-simulations?specialty=${specialty}`,
  })
}

export const startQuizPractice = (data: PracticeQuizCreateRequest): Promise<QuizAttemptStartResultInterface> => {
  return clientDataService<QuizAttemptStartResultInterface>({
    url: '/v3/quizzes/practice',
    method: 'POST',
    data: data,
  })
}

export const startRandomSimulation = (): Promise<QuizAttemptStartResultInterface> => {
  return clientDataService<QuizAttemptStartResultInterface>({
    url: '/v3/quizzes/random-simulations/start',
    method: 'POST',
  })
}

export const startMonthlySimulation = (): Promise<QuizAttemptStartResultInterface> => {
  return clientDataService<QuizAttemptStartResultInterface>({
    url: '/v3/quizzes/monthly-simulations/start',
    method: 'POST',
  })
}

export const getInfo = ( type: string, quizId?: UUID): Promise<QuizStartInfoResponse> => {
  return clientDataService<QuizStartInfoResponse>({
    url: '/v3/quizzes/info',
    params: {
      type,
      quizId
    }
  })
}

export const startOfficialNationalSimulation = (quizId?: string): Promise<QuizAttemptStartResultInterface> => {
  return clientDataService<QuizAttemptStartResultInterface>({
    url: `/v3/quizzes/official-national-simulations/${quizId}/start`,
    method: 'POST',
  })
}

export const startLessonQuiz = (courseId: string, lessonId: string): Promise<QuizAttemptStartResultInterface> => {
  return clientDataService<QuizAttemptStartResultInterface>({
    url: `/v2/quizzes/courses/${courseId}/lessons/${lessonId}/start`,
    method: 'POST',
  })
}

export const startStaticPracticeSimulationsQuiz = (quizId?: string): Promise<QuizAttemptStartResultInterface> => {
  return clientDataService<QuizAttemptStartResultInterface>({
    url: `/quizzes/static-practice-simulations/${quizId}/start`,
    method: 'POST'
  })
}

export const startPackSimulationQuiz = (data: QuizStartPackSimulationQuiz): Promise<QuizAttemptStartResultInterface> => {
  return clientDataService<QuizAttemptStartResultInterface>({
    url: `/v1/pack-simulations/${data.packSimulationId}/quizzes/${data.quizId}/start`,
    method: 'POST'
  })
}

export const getStaticPracticeSimulationGroupInfo = (quizId?: string): Promise<StaticPracticeSimulationGroupInfoInterface> => {
  return clientDataService<StaticPracticeSimulationGroupInfoInterface>({
    url: `/quizzes/static-practice-simulations/${quizId}/info`,
  })
}

export const getStaticPracticeSimulations = (groupId?: string): Promise<StaticPracticeSimulation> => {
  return clientDataService<StaticPracticeSimulation>({
    url: `/quizzes/static-practice-simulations?groupId=${groupId}`
  })
}
