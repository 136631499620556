import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import { Back } from 'components/shared/back'
import PackSimulations from 'components/quizzes-page/PackSimulations'
import { quizCardBackgroundColors } from 'components/quizzes/common/quizCardBackgroundColors'
import { RouteComponentProps } from 'react-router-dom'
import usePackSimulationQuizzes from 'hooks/usePackSimulationQuizzes'

export function PackSimulationsPage( props: RouteComponentProps<{ packSimulationId: string }>) {
  const {t} = useTranslation()
  const packSimulationId = props.match.params.packSimulationId || ''
  const simulations = usePackSimulationQuizzes(packSimulationId).data

  return (
    <DashboardLayout>
      <Back text={t(translations.navigation.quizzesPage)} url={'/quizzes'}/>
      <Box sx={{
        mx: {xs: '20px', sm: 6.5},
        mb: {xs: '20px', sm: 6.5},
        mt: {xs: '40px', md: '70px'},
      }}>
       
        {simulations && <PackSimulations
         simulations={simulations}
         backgroundColor={quizCardBackgroundColors}
         packSimulationId={packSimulationId}
        />}
      </Box>
    </DashboardLayout>
  )
}

export default PackSimulationsPage
