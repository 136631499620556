import React, { FC } from 'react'
import { isEmpty } from 'lodash'
import { Box, InputAdornment } from '@mui/material'

import CommunityUserAvatar from '../CommunityUserAvatar'
import FormSelect from 'components/ui/form/form-select/FormSelect'

type StreamSpeakersProps = {
  teachers: TeacherInterface[]
  currentSpeaker?: ClassStreamSpeakerData
}

const StreamSpeakers: FC<StreamSpeakersProps> = ({ teachers, currentSpeaker }) => {

  const getOptionLabel = ({ firstName, lastName }) => `${firstName} ${lastName}`

  const renderSpeaker = ({firstName, lastName, profileImageUri, id}, selectedId, props) => (
    <Box
      key={id}
      sx={{
        padding: {xs: '10px 0', md: '10px 20px'},
        background: {md: id === selectedId ? '#3b3c3d !important' : ''},
        '&:hover': {
          cursor: 'pointer',
          background: '#262727',
        },
      }}
      {...props}
    >
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <CommunityUserAvatar
          profileImageUri={profileImageUri}
          firstName={firstName}
          lastName={lastName}
          sx={{width: '30px', height: '30px'}}
        />
        <Box
          sx={{
            display: 'flex',
            fontSize: {xs: '14px', md: '12px'},
            lineHeight: {xs: '16px', md: '14px'},
            pl: '13px',
          }}
        >
          <Box sx={{fontWeight: 500}}>{`${firstName} ${lastName}`}</Box>
        </Box>
      </Box>
    </Box>
  )

  const renderIcon = (data: TeacherInterface)  => (
                    !isEmpty(data) && (<InputAdornment position="end">
                      <CommunityUserAvatar
                        profileImageUri={data.profileImageUri}
                        firstName={data.firstName}
                        lastName={data.lastName}
                        sx={{width: '30px', height: '30px', mr: '20px'}}
                      />
                    </InputAdornment>)
  )

  return (
    <FormSelect
      name="speakerId"
      options={teachers}
      selectedOption={currentSpeaker}
      renderOption={renderSpeaker}
      label={{ text: getOptionLabel, icon: renderIcon }}
      sx={{maxWidth: {xs: '100%', sm: '376px'}, minWidth: '326px'}}
    />
  );
}

export default StreamSpeakers
