import React, { FC, ReactElement } from 'react'
import cn from 'classnames'

type CircularChartProps = {
  className?: string
  height: string
  percentage: { value: number, maxValue: number }
  text?: ReactElement
}

const CircularChart: FC<CircularChartProps> = ({
                                                 className,
                                                 height,
                                                 percentage,
                                                 text,
                                               }) => {
  return (
    <svg viewBox="0 0 36 36" className={cn('CircularChart', className)} height={height}>
      <path className="Circle-bg"
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      <path className="Circle"
            strokeDasharray={`${Math.trunc(percentage.value / 100) <= 0 ? 0 : percentage.value / percentage.maxValue * 100}, 100`}
            d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
      />
      {text}
    </svg>
  )
}

export default CircularChart
