import React, { FC } from 'react'
import { Box } from '@mui/material'

import config from 'utils/config'

type PostFormImageListProps = {
  fileUri: string
  playIcon: string
  borderRadius?: boolean
  videoHeight?: string
}

const PostVideoPreview: FC<PostFormImageListProps> = ({
                                                        fileUri,
                                                        playIcon,
                                                        borderRadius,
                                                        videoHeight= '100%',
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: borderRadius ? '3px' : 'none',
        position: 'relative',
      }}
    >
      <img
        src={playIcon}
        alt="Preview video"
        style={{
          zIndex: 1,
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
      <video width="100%" height={videoHeight} style={{objectFit: 'cover'}}>
        <source src={`${config.cdnBaseApiURI}/${fileUri}`} type="video/mp4" key={fileUri}/>
      </video>
    </Box>
  )
}

export default PostVideoPreview
