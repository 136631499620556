import React, { FC } from 'react'
import { Skeleton } from '@material-ui/lab'

type FormEditorSkeletonProps = {
  height: number | string
}

const FormEditorSkeleton: FC<FormEditorSkeletonProps> = ({height}) => {
  return (
    <div>
      <Skeleton variant="rect" height={39} style={{marginBottom: '1px'}} />
      <Skeleton variant="rect" height={height} />
    </div>
  )
}

export default FormEditorSkeleton
