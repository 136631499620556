import React, { ChangeEvent, FC } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'

import { PostType } from 'types/PostType'
import { PostInterface } from 'types/PostInterface'
import PostForm from '../post-form/PostForm'
import { PostFile } from 'types/PostFile'
import { PostRequest } from 'types/requests/PostRequest'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import theme from 'theme'

const buttonStyles = {
  minWidth: 'unset',
  width: '50%',
  height: '50px',
  borderRadius: '4px',
  ':hover': {filter: 'brightness(0.8)'},
  backgroundColor: theme.palette.background.paper,
  boxShadow: '0px 1px rgba(0, 0, 0, 0.4)',
  cursor: 'pointer',
} as const

type CreatePostFormProps = {
  classId: string
  currentUser: StudentProfileInterface
  post: PostInterface
  postType: PostType
  setPostType: (value: PostType) => void
  postText: string
  setPostText: (value: string) => void
  uploadingFiles: File[]
  currentFiles: PostFile[]
  showAddOption: boolean
  pollVariants: PollOption[]
  handleAddFiles: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => Promise<void>,
  handleRemoveFile: (fileId: string) => void
  handleAddOption: () => void
  handleRemoveOption: (fileId: string) => void
  handleUpdateOptionText: (variantId: string, newText: string) => void
  handleUpdateOptionPosition: (oldIndex: number, newIndex: number) => void
  handleSubmit: (data: PostRequest) => void
  handleCancel: () => void
}

const CreatePostForm: FC<CreatePostFormProps> = ({
                                                   classId,
                                                   currentUser,
                                                   post,
                                                   postType,
                                                   setPostType,
                                                   postText,
                                                   setPostText,
                                                   uploadingFiles,
                                                   currentFiles,
                                                   showAddOption,
                                                   pollVariants,
                                                   handleAddFiles,
                                                   handleRemoveFile,
                                                   handleAddOption,
                                                   handleRemoveOption,
                                                   handleUpdateOptionText,
                                                   handleUpdateOptionPosition,
                                                   handleSubmit,
                                                   handleCancel,
}) => {
  const { t } = useTranslation()

  return (
    <Box sx={{width: '100%', height: 'auto'}}>
      <Box>
        <Box display="flex" flexDirection="row">
          <Button
            className="u-pt-10 u-pb-10"
            sx={{
              ...buttonStyles,
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderBottom: postType === PostType.SIMPLE_POST ? "3px solid #E25F60 !important" : '1px solid rgba(255, 255, 255, .1)',
              opacity: postType === PostType.SIMPLE_POST ? '100%' : '40%',
              borderRight: '1px solid rgba(255, 255, 255, .1)',
            }}
            onClick={() => setPostType(PostType.SIMPLE_POST)}
          >
            <img
              src='/images/icons/community/post.svg'
              alt="Show post"
            />
            <Typography className="u-ml-10">Post</Typography>
          </Button>
          <Button
            className="u-pt-10 u-pb-10"
            sx={{
              ...buttonStyles,
              borderTopLeftRadius: 0,
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
              borderBottom: postType === PostType.POLL_POST ? "3px solid #E25F60 !important" : '1px solid rgba(255, 255, 255, .1)',
              opacity: postType === PostType.POLL_POST ? '100%' : '40%',
            }}
            onClick={() => setPostType(PostType.POLL_POST)}
          >
            <img
              src='/images/icons/community/pull.svg'
              alt="Show poll"
            />
            <Typography className="u-ml-10">{t(translations.community.feed.poll)}</Typography>
          </Button>
        </Box>
        <Box sx={{
          p: {xs: '0 20px', sm: '15px 20px'},
        }}>
          {post && (
            <PostForm
              classId={classId}
              currentUser={currentUser}
              post={post}
              postType={postType}
              postText={postText}
              setPostText={setPostText}
              uploadingFiles={uploadingFiles}
              currentFiles={currentFiles}
              showAddOption={showAddOption}
              pollVariants={pollVariants}
              handleAddFiles={handleAddFiles}
              handleRemoveFile={handleRemoveFile}
              handleAddOption={handleAddOption}
              handleRemoveOption={handleRemoveOption}
              handleUpdateOptionText={handleUpdateOptionText}
              handleUpdateOptionPosition={handleUpdateOptionPosition}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}
export default CreatePostForm
