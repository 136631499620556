import { object, string, ref } from 'yup'
import i18n from 'i18next'
import { translations } from 'locales/i18n'

const ResetPasswordFormSchema = object().shape({
  password: string()
    .min(8, i18n.t(translations.validatorSchema.resetPassword.password.min))
    .max(64, i18n.t(translations.validatorSchema.resetPassword.password.max))
    .matches(/^.*[a-zA-Z].*[0-9].*$/, i18n.t(translations.validatorSchema.resetPassword.password.matches))
    .required(),
  passwordConfirm: string()
    .oneOf([ref('password', ), null], i18n.t(translations.validatorSchema.resetPassword.passwordConfirm.oneOf))
    .required(),
})

export default ResetPasswordFormSchema
