import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Typography, Button, Box } from "@mui/material"

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import { translations } from 'locales/i18n'

const NotFound = () => {
  const {t} = useTranslation()
  return (
    <DashboardLayout>
      <Box sx={{height: 'calc(100vh - 60px)', padding: '60px'}}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: "center",
          textAlign: 'center',
          height: '100%',
          width: {xs: '100%', sm: '60%'},
          margin: 'auto',
        }}>
          <Typography className="u-weight-bold" sx={{
            fontSize: {xs: '30px', md: '44px'},
            lineHeight: {xs: '34px', md: '53px'},
            mb: 6,
            width: {md: '80%'},
          }}>
            {t(translations.notFoundPage.cantFindThePage)}
          </Typography>
          <img src="images/404.svg" alt="404 error" style={{width: 'inherit'}}/>
          <Link to="/" style={{textDecoration: 'none'}}>
            <Button
              color="primary"
              className="u-text-transform-none u-color-white u-bg-havelock-blue"
              sx={{
                marginTop: 6,
                fontSize: {xs: '12px', sm: '16px'},
                lineHeight: {xs: '16px', sm: '19px'},
                padding: '10px 13px',
                minWidth: '146px',
              }}
            >
              {t(translations.common.goHome)}
            </Button>
          </Link>
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default NotFound
