import { useHistory } from 'react-router-dom'

import { startLessonQuiz } from 'services/quizService'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useStartLessonQuiz = () => {
  const history = useHistory()

  return useMutationWithSnackbar(
    ({ courseId, lessonId }: LessonQuizCreateRequest) => startLessonQuiz(courseId, lessonId),
    'startLessonQuiz',
    undefined,
    {
      async onSuccess(quizAttempt) {
        history.push(`/quiz-attempts/${quizAttempt.quizAttemptId}`)
      },
    },
  )
}

export default useStartLessonQuiz
