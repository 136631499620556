import { clientDataService } from './clientDataService'
import { LessonsSearchView } from 'types/LessonsSearchView'
import { LessonView } from 'types/LessonView'

export const searchLessons = (searchQuery: string): Promise<LessonsSearchView> =>
  clientDataService<LessonsSearchView>({
    url: `/lessons/search?tag=${searchQuery}&size=6`,
  })


export const getLesson = (courseId: string, lessonId: string): Promise<LessonView> =>
  clientDataService<LessonView>({
    url: `/courses/${courseId}/lessons/${lessonId}`,
  })

export const updateLessonVideoWatchedTime = (data: LessonVideoWatchedSecondsUpdateRequest): Promise<void> =>
  clientDataService<void>({
    url: `/courses/${data.courseId}/lessons/${data.lessonId}/video-viewed-seconds`,
    method: 'POST',
    data,
  })

export const updateLessonNoteText = (data: LessonNoteTextUpdateRequest): Promise<void> =>
  clientDataService<void>({
    url: `/courses/${data.courseId}/lessons/${data.lessonId}/notes`,
    method: 'POST',
    data,
  })

export const resetLessonNoteText = (data: LessonNoteTextResetRequest): Promise<void> =>
  clientDataService<void>({
    url: `/courses/${data.courseId}/lessons/${data.lessonId}/notes/reset`,
    method: 'PUT',
    data,
  })

export const completeLesson = (data: CourseLessonRequest): Promise<void> =>
  clientDataService<void>({
    url: `/courses/${data.courseId}/lessons/${data.lessonId}/complete`,
    method: 'PUT',
    data: {},
  })

export const reportLesson = (data: ReportLessonRequest): Promise<void> =>
  clientDataService<void>({
    url: `/courses/${data.courseId}/lessons/${data.lessonId}/report`,
    method: 'POST',
    data,
  })

