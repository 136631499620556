import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Avatar, CardHeader, Menu, MenuItem, Box, Typography } from '@mui/material'
import { isEmpty } from 'lodash'

import useLogOut from 'hooks/useLogOut'
import { translations } from 'locales/i18n'
import arrowDown from 'images/arrow-down.svg'
import arrowUp from 'images/arrow-up.svg'
import config from 'utils/config'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { ImageSize } from 'types/ImageSize';

type AccountMenuProps = {
  currentUser: StudentProfileInterface
}

const AccountMenu: FC<AccountMenuProps> = ({currentUser}) => {
  const {t} = useTranslation()

  const [menuOpen, setMenuOpen] = useState(false)
  const avatarRef = useRef(null)

  const profile = currentUser.profile
  const firstName = profile.firstName || ''
  const lastName = profile.lastName || ''
  const profileImageUri = profile.profileImageUri || ''

  const logOutMutation = useLogOut()
  const handleLogOut = () => logOutMutation.mutate()

  const toggleMenuOpen = () => setMenuOpen(!menuOpen)

  return (
    <>
      <CardHeader
        avatar={
          <Avatar
            alt={`${firstName} ${lastName}`}
            src={isEmpty(profile.profileImageUri)
              ? `https://eu.ui-avatars.com/api/?name=${firstName}+${lastName}&background=4780D8&color=fff`
              : `${config.cdnBaseApiURI}${profileImageUri}${ImageSize.PREVIEW}`
            }
            sx={{width: 30, height: 30}}/>
        }
        title={<Box component="span" sx={{display: 'flex', flexDirection: 'row'}}>
          <Typography component="span" sx={{fontSize: '14px', lineHeight: '17px', display: {xs: 'none', sm: 'block'}}}>{`${firstName} ${lastName}`}</Typography>
          <img
            src={menuOpen ? arrowUp : arrowDown}
            className="u-pl-10"
            alt=""
            role="presentation"
          /></Box>
        }
        sx={{padding: 0, '& .MuiCardHeader-avatar':  {mr: {xs: '5px', sm: '16px'}}}}
        ref={avatarRef}
        onClick={toggleMenuOpen}
        className="u-cursor-pointer"
      />
      <Menu
        anchorEl={avatarRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onClick={() => setMenuOpen(false)}
        PaperProps={{elevation: 0}}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        <Link to="/profile">
        <MenuItem>
          {t(translations.accountMenu.profile)}
        </MenuItem>
        </Link>
        <MenuItem onClick={handleLogOut}>
          {t(translations.accountMenu.logOut)}
        </MenuItem>
      </Menu>
    </>
  )
}

export default AccountMenu
