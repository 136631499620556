import { useHistory } from 'react-router-dom'

import { startMonthlySimulation } from 'services/quizService'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useStartMonthlySimulation = () => {
  const history = useHistory()

  return useMutationWithSnackbar(
    () => startMonthlySimulation(),
    'startMonthlySimulation',
    undefined,
    {
      async onSuccess(quizAttempt) {
        history.push(`/quiz-attempts/${quizAttempt.quizAttemptId}`)
      },
    },
  )
}

export default useStartMonthlySimulation
