import { inputLabelColor } from 'theme/constants'

export const textFieldStyles = {
  '& .MuiInputBase-root::before': {
    borderBottom: '1px solid #646464',
  },
  '& .MuiInput-root': {
    fontSize: { xs: '16px', md: '19px' },
    lineHeight: '29px',
    '&.MuiInput-input': {
      borderBottom: '1px solid #646464',
    },
    '&:before': {
      borderBottom: '1px solid #646464',
    },
    '&.Mui-disabled': {
      '&:before': {
        borderBottom: '1px solid #646464',
      },
    },
    'input': {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': theme => `0 0 0 50px ${theme.palette.background.paper} inset`,
        '-webkit-text-fill-color': theme => theme.palette.text.primary,
        'caret-color': theme => theme.palette.text.primary,

        '&:focus': {
          '-webkit-box-shadow': '0 0 0 50px $background-secondary inset',
          '-webkit-text-fill-color': theme => theme.palette.text.primary,
          'caret-color': theme => theme.palette.text.primary,
        },
      },
    },
  },
  '& .MuiInputLabel-root': {
    fontSize: { xs: '10px', md: '11px' },
    lineHeight: { xs: '11px', md: '13px' },
    '&.Mui-focused': {
      color: inputLabelColor,
      borderColor: inputLabelColor,
    },
  },
} as const
