import React, { FC } from 'react'
import { head } from 'lodash'

import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { Box } from '@mui/material'

type AreaLabelProps = {
  currentUser: StudentProfileInterface
}

const AreaLabel: FC<AreaLabelProps> = ({ currentUser }) => {
  return (
    <Box sx={{ position: 'relative', display: 'flex', justifyContent: 'flex-end' }}>
      <Box
        sx={{
          background: theme => theme.palette.common.navigationSelected,
          mx: { xs: '20px', sm: '52px' },
          mt: { xs: '20px', sm: '33px' },
          p: '8px 20px',
          width: { xs: 'calc(100% - 40px)', sm: 'fit-content' },
          borderRadius: '4px',
          textAlign: 'center',
          fontWeight: 'bold',
          fontSize: {xs: '18px', lg: '22px'},
          lineHeight: {xs: '24px', lg: '34px'},
        }}
      >
        {head(currentUser.areas)?.name}
      </Box>
    </Box>
  )
}

export default AreaLabel
