import React, { FC } from 'react'
import { Box, Collapse, Typography } from '@mui/material'
import { Dictionary } from 'lodash'

import { QuizType } from 'types/QuizType'
import { QuizAttemptQuestion } from 'types/QuizAttemptQuestion'
import dropdownOpenedIcon from 'images/dropdown-opened.svg'
import dropdownClosedIcon from 'images/dropdown-closed.svg'
import QuestionNavigation from '../quizzes/question-navigation'
import FinishQuiz from '../quizzes/FinishQuiz'
import QuizAttemptSections from './QuizAttemptSections'
import { simpleQuizTypes } from './constants'

type QuizAttemptHeaderMobileProps = {
  dropdownMenuOpen: boolean
  toggleDropdownMenuOpen: () => void
  title: string
  quizType: QuizType
  isDividedBySections: boolean
  actualSection: string
  sections: QuizAttemptSections[]
  currentQuestion: QuizAttemptQuestion
  questions: QuizAttemptQuestion[]
  answeredQuestions: QuizAttemptQuestion[]
  questionsGroupedBySubject: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  questionsGroupedBySectionId: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  answeredQuestionsGroupedBySubject: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  answeredQuestionsGroupedBySection: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  getQuestionNavigation: (number: number) => string
  remainingTime: number
  showRemainingTime: boolean
  buttonText: string
  timerStyle: string
  onFinishQuiz: (e: React.FormEvent<HTMLButtonElement>) => void
  isFlagged: (number: number) => boolean
}

const QuizAttemptHeaderMobile: FC<QuizAttemptHeaderMobileProps> = ({
                                                                     dropdownMenuOpen,
                                                                     toggleDropdownMenuOpen,
                                                                     title,
                                                                     isDividedBySections,
                                                                     quizType,
                                                                     sections,
                                                                     currentQuestion,
                                                                     actualSection,
                                                                     questions,
                                                                     answeredQuestions,
                                                                     questionsGroupedBySubject,
                                                                     answeredQuestionsGroupedBySection,
                                                                     answeredQuestionsGroupedBySubject,
                                                                     questionsGroupedBySectionId,
                                                                     buttonText,
                                                                     getQuestionNavigation,
                                                                     remainingTime,
                                                                     showRemainingTime,
                                                                     onFinishQuiz,
                                                                     isFlagged,
                                                                     timerStyle
                                                                   }) => {
  const subjectEntries = Object.entries(questionsGroupedBySubject)

  return (
    <Box sx={{
      background: theme => theme.palette.background.default,
    }}>
      <Box sx={{
        pt: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
      }}>
        <Box>
          <Typography
            sx={{
              fontSize: "10px",
              letterSpacing: "2px",
              lineHeight: "12px",
              fontWeight: '600',
              color: "#E25F60",
            }}
          >
            QUIZ
          </Typography>
          <span className="u-mt-5 u-fs-18 u-lh-22 u-weight-bold">{title}</span>
        </Box>
        {!simpleQuizTypes.includes(quizType) && subjectEntries.length > 1 && (
          <Box
            sx={{
              width: '32px',
              height: '32px',
              borderRadius: '4px',
              background: theme => theme.palette.background.paper,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
            }}
            onClick={toggleDropdownMenuOpen}
          >
            <img src={dropdownMenuOpen ? dropdownOpenedIcon : dropdownClosedIcon} alt="Collapse"/>
          </Box>
        )}
      </Box>
      <Collapse in={dropdownMenuOpen}>
        <Box sx={{width: '100%', height: 'auto'}}>

          <QuizAttemptSections
              quizQuestions={questions}
              activeSectionId={actualSection}
              sections={sections}
              quizType={quizType}
              isDividedBySections={isDividedBySections}
              questionsGroupedBySubject={questionsGroupedBySubject}
              currentQuestion={currentQuestion}
              answeredQuestionsGroupedBySection={answeredQuestionsGroupedBySection}
              answeredQuestionsGroupedBySubject={answeredQuestionsGroupedBySubject}
            /> 

        </Box>
      </Collapse>
      <Box
        sx={{
          mt: {xs: '25px', lg: 9},
          pr: '32px',
          overflowX: 'auto',
          width: {xs: 'calc(100vw - 40px)', sm: 'calc(100vw - 105px)'},
        }}
      >
        <QuestionNavigation
          actualSection={actualSection}
          questionsGroupedBySectionId={questionsGroupedBySectionId}
          questions={questions}
          isDividedBySections={isDividedBySections}
          isFlagged={isFlagged}
          getQuestionNavigation={getQuestionNavigation}
        />
      </Box>
      <FinishQuiz
        questionsAmount={questions.length}
        answeredQuestionsAmount={answeredQuestions.length}
        onFinishQuiz={onFinishQuiz}
        remainingTime={remainingTime}
        showRemainingTime={showRemainingTime}
        buttonText={buttonText}
        timerStyle={timerStyle}
        quizType={quizType}
      />
    </Box>
  )
}

export default QuizAttemptHeaderMobile
