import React, { FC, useState } from 'react'
import { Box, Grid, IconButton, useMediaQuery, Dialog } from '@mui/material'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'
import { PostInterface } from 'types/PostInterface'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import PostCard from './PostCard'
import PostComments from './comments/PostComments'
import { PostFileType } from 'types/PostFileType'
import resizeEnterIcon from 'images/resize-enter.svg'
import resizeLeaveIcon from 'images/resize-leave.svg'
import closeIcon from 'images/close-fullscreen.svg'
import Carousel from 'components/shared/carousel/Carousel'
import config from 'utils/config'
import VideoPlayer from 'components/video-player/VideoPlayer'
import theme from 'theme'
import PostCardSingleImage from './PostCardSingleImage'
import Overlay from 'components/shared/modal/Overlay'
import backIcon from 'images/back.svg'
import { ImageSize } from 'types/ImageSize'


type PostCardFullProps = {
  classId: string
  currentUser: StudentProfileInterface
  classTeachers: TeacherInterface[]
  post: PostInterface
  isCommentFormSubmitting: boolean
  fullscreenPostOpen: boolean
  onExitFullScreen: () => void
  onStartEdit: () => void
  onPostDelete: () => void
  onPostPin: () => void
  onPostUnpin: () => void
  onCopiedLink: () => void
  onAddPostToFavourites: () => void
  onRemovePostFromFavourites: () => void
  onOpenPostReport: () => void
  onTypingActive: (status: boolean) => void
  onOpenCommentReport?: (data: PostCommentReport) => void
  onCommentCreate: (data: PostCommentRequest) => Promise<PostCommentResponse>
  onCommentUpdate: (data: PostCommentRequest) => Promise<PostCommentResponse>
  onCommentDelete: (data: DeletePostCommentRequest) => void
  onCreateVotePollOption: (data: VotePollRequest) => void
  onUpdateVotePollOption: (data: VotePollRequest) => void
}

const PostCardFullScreen: FC<PostCardFullProps> = ({
                                                     classId,
                                                     currentUser,
                                                     classTeachers,
                                                     post,
                                                     isCommentFormSubmitting,
                                                     fullscreenPostOpen,
                                                     onExitFullScreen,
                                                     onStartEdit,
                                                     onPostDelete,
                                                     onPostPin,
                                                     onPostUnpin,
                                                     onCopiedLink,
                                                     onAddPostToFavourites,
                                                     onRemovePostFromFavourites,
                                                     onOpenPostReport,
                                                     onTypingActive,
                                                     onOpenCommentReport,
                                                     onCommentCreate,
                                                     onCommentUpdate,
                                                     onCommentDelete,
                                                     onCreateVotePollOption,
                                                     onUpdateVotePollOption,
                                                   }) => {
  const isLaptop = useMediaQuery(theme.breakpoints.up('lg'))
  const {t} = useTranslation()
  const [postOpen, setPostOpen] = useState(true)
  const [mobileCarouselOpen, setMobileCarouselOpen] = useState(false)
  const [mobileCurrentCarouselIndex, setMobileCurrentCarouselIndex] = useState(0)

  const togglePostOpen = () => setPostOpen(!postOpen)
  const handleMobileCarouselOpen = (imageIndex: number) => {
    setMobileCurrentCarouselIndex(imageIndex)
    setMobileCarouselOpen(true)
  }

  const types = [PostFileType.IMAGE, PostFileType.VIDEO]
  const imagesAndVideo = !isEmpty(post.files) ? post.files.filter(({fileType}) => types.includes(fileType)) : []
  const {pinned} = post

  const renderCarousel = () => (
    <Carousel
      initialSlideIndex={mobileCurrentCarouselIndex}
      views={imagesAndVideo}
      slideRenderer={(index) => {
        const {fileUri, fileType, originalFileName, addedAt} = imagesAndVideo[index]
        return (
          <Box>
            {fileType === PostFileType.IMAGE ? (
              <img className="u-w-100" alt={originalFileName} src={`${config.cdnBaseApiURI}/${fileUri}?t=${addedAt}${ImageSize.ORIGINAL}`}/>
            ) : <VideoPlayer videoFileUri={`${config.cdnBaseApiURI}/${fileUri}`}/>
            }
          </Box>
        )
      }}
    />
  )

  return (
    <>
      {post &&
        <Dialog
          fullScreen={imagesAndVideo.length > 0}
          open={fullscreenPostOpen}
          onClose={onExitFullScreen}
        >
          {isLaptop && (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                background: theme.palette.background.default,
              }}
            >
              {imagesAndVideo.length > 0 && (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                  }}
                >
                  <IconButton
                    onClick={onExitFullScreen}
                    aria-label="Close"
                    size="medium"
                    sx={{
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '30px',
                      left: '30px',
                      zIndex: 1,
                    }}
                  >
                    <img src={closeIcon} alt="Close"/>
                  </IconButton>

                  {renderCarousel()}

                  <IconButton
                    onClick={togglePostOpen}
                    aria-label="Resize"
                    size="medium"
                    sx={{
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '30px',
                      right: '30px',
                    }}
                  >
                    <img src={postOpen ? resizeEnterIcon : resizeLeaveIcon} alt="Resize"/>
                  </IconButton>
                </Box>
              )}

              <Box
                sx={{
                  p: postOpen ? '30px' : 0,
                  background: theme.palette.background.paper,
                  width: imagesAndVideo.length > 0 ? (postOpen ? '60%' : 0) : '100%',
                  transition: 'all 0.3s ease-out',
                  height: imagesAndVideo.length > 0 ? '100vh' : 'auto',
                  overflowY: 'auto',
                  overflowX: 'hidden',
                }}
              >
                {post.popular &&
                  <Box sx={{ fontSize: '34px', lineHeight: '41px',  mb: '38px', textTransform: 'capitalize' }}>
                    {t(translations.community.feed.popularPoll)}
                  </Box>
                }
                {imagesAndVideo.length === 0 &&
                  <IconButton
                    onClick={onExitFullScreen}
                    aria-label="Close"
                    size="medium"
                    sx={{
                      cursor: 'pointer',
                      position: 'absolute',
                      top: '30px',
                      right: '30px',
                      zIndex: 1,
                      }}
                  >
                    <img src="/images/close-report-modal.svg" alt="Close"/>
                  </IconButton>
                }
                <Box sx={{minWidth: imagesAndVideo.length === 0 ? '500px' : 'unset'}}>
                  <PostCard
                    classId={classId}
                    currentUser={currentUser}
                    classTeachers={classTeachers}
                    post={post}
                    isCommentFormSubmitting={isCommentFormSubmitting}
                    onStartEdit={onStartEdit}
                    onPostDelete={onPostDelete}
                    onPostPin={onPostPin}
                    onPostUnpin={onPostUnpin}
                    onCopiedLink={onCopiedLink}
                    onAddPostToFavourites={onAddPostToFavourites}
                    onRemovePostFromFavourites={onRemovePostFromFavourites}
                    onOpenPostReport={onOpenPostReport}
                    onOpenCommentReport={onOpenCommentReport}
                    onTypingActive={onTypingActive}
                    onCommentCreate={onCommentCreate}
                    onCommentUpdate={onCommentUpdate}
                    onCommentDelete={onCommentDelete}
                    onCreateVotePollOption={onCreateVotePollOption}
                    onUpdateVotePollOption={onUpdateVotePollOption}
                    inputBackground={'#2D2E2F'}
                    showMenu={false}
                    showImages={false}
                    marginContent={false}
                    marginPollOptions={false}
                  />
                </Box>
              </Box>
            </Box>
          )}

          {!isLaptop && (
            <Overlay
              open={true}
              onClose={onExitFullScreen}
              title="Post"
              titleBgColor={pinned ? theme.palette.background.default : theme.palette.background.paper}
              childrenBgColor={pinned ? theme.palette.background.paper : theme.palette.background.default}
              closeIcon={backIcon}
            >
              <Box
                sx={{
                  pt: pinned ? 0 : '24px',
                  px: pinned ? 0 : '20px',
                }}
              >
                <PostCard
                  classId={classId}
                  currentUser={currentUser}
                  classTeachers={classTeachers}
                  post={post}
                  isCommentFormSubmitting={isCommentFormSubmitting}
                  onStartEdit={onStartEdit}
                  onPostDelete={onPostDelete}
                  onPostPin={onPostPin}
                  onPostUnpin={onPostUnpin}
                  onCopiedLink={onCopiedLink}
                  onAddPostToFavourites={onAddPostToFavourites}
                  onRemovePostFromFavourites={onRemovePostFromFavourites}
                  onOpenPostReport={onOpenPostReport}
                  onTypingActive={onTypingActive}
                  onOpenCommentReport={onOpenCommentReport}
                  onCommentCreate={onCommentCreate}
                  onCommentUpdate={onCommentUpdate}
                  onCommentDelete={onCommentDelete}
                  onCreateVotePollOption={onCreateVotePollOption}
                  onUpdateVotePollOption={onUpdateVotePollOption}
                  inputBackground={pinned ? '#2D2E2F' : theme.palette.background.paper}
                  showImages={false}
                  marginContent={false}
                  marginPollOptions={false}
                  showMenu={false}
                  showComments={false}
                />
              </Box>

              <Grid
                container
                flexDirection="column"
                spacing="20px"
              >
                {imagesAndVideo.map((file, index) => (
                  <Grid
                    item
                    key={file.fileUri}
                    onClick={() => handleMobileCarouselOpen(index)}
                    sx={{cursor: 'pointer'}}
                  >
                    <PostCardSingleImage file={file}/>
                  </Grid>
                ))}     
              </Grid>
              <Grid sx={{mt: '110px', paddingX: '20px', pb: '20px'}}>
                <PostComments
                  classId={classId}
                  post={post}
                  isCommentFormSubmitting={isCommentFormSubmitting}
                  currentUser={currentUser}
                  onTypingActive={onTypingActive}
                  onCommentCreate={onCommentCreate}
                  onCommentUpdate={onCommentUpdate}
                  onCommentDelete={onCommentDelete}
                  onOpenCommentReport={onOpenCommentReport}
                  inputBackground={pinned ? '#2D2E2F' : theme.palette.background.paper}
                />
              </Grid>             
            </Overlay>
          )}

          <Overlay
            open={mobileCarouselOpen}
            onClose={() => setMobileCarouselOpen(false)}
            title="Post"
            titleBgColor={theme.palette.background.paper}
            childrenBgColor={theme.palette.background.default}
            closeIcon={backIcon}
          >
            {renderCarousel()}
          </Overlay>
        </Dialog>
      }
    </>
  )
}
export default PostCardFullScreen
