import React, { FC } from 'react'

import { PostFile } from 'types/PostFile'
import { PostFileType } from 'types/PostFileType'
import config from 'utils/config'
import playIcon from 'images/play-video-for-preview.svg'
import PostVideoPreview from '../PostVideoPreview'
import { ImageSize } from 'types/ImageSize'

type PostCardSingleImageProps = {
  file: PostFile
}

const PostCardSingleImage: FC<PostCardSingleImageProps> = ({file}) => {
  const {fileUri, fileType, originalFileName, addedAt} = file
  return fileType === PostFileType.IMAGE ? (
    <img alt={originalFileName} src={`${config.cdnBaseApiURI}/${fileUri}?t=${addedAt}${ImageSize.PREVIEW}`}/>
  ) : <PostVideoPreview fileUri={fileUri} playIcon={playIcon} videoHeight="auto"/>
}

export default PostCardSingleImage
