import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IconButton, List, ListItem, ListItemAvatar, ListItemText, Typography, Icon, Box } from '@mui/material'

import { PostFile } from 'types/PostFile'
import { PostFileType } from 'types/PostFileType'
import { getFileAvatar } from '../getFileAvatar'
import config from 'utils/config'
import { translations } from 'locales/i18n'

// TODO refactor component ( remove absolute and use flex)

type PostCardFileListProps = {
  files: PostFile[]
}

const PostCardFileList: FC<PostCardFileListProps> = ({files}) => {
  const {t} = useTranslation()

  const filesOnly = files.filter(({fileType}) => fileType === PostFileType.FILE)

  return filesOnly?.length > 0 ? (
      <List dense={true}>
        {filesOnly.map(({fileUri, fileId, fileSize, originalFileName, fileExtension}) => (
          <ListItem
            key={fileId}
            secondaryAction={
              <IconButton edge="end" aria-label="download" sx={{borderRadius: '3px'}}>
                <a href={`${config.cdnBaseApiURI}/${fileUri}`} target="_blank" download={originalFileName} style={{display: 'flex'}}>
                  <img src="/images/icons/community/download.svg" alt='some'/>
                  <Typography
                    className="u-color-havelock-blue u-ml-10"
                    sx={{display: {xs: 'none', sm: 'block'}}}
                  >
                    {t(translations.community.feed.download)}
                  </Typography>
                </a>
              </IconButton>
            }
          >
            <ListItemAvatar>
              <Icon sx={{width: '100%', height: '100%'}}>
                <img src={getFileAvatar(fileExtension)} alt="File type"/>
              </Icon>
            </ListItemAvatar>
            <ListItemText
              primaryTypographyProps={{
                sx: {
                  maxWidth: "80%",
                  overflow: "hidden",
                  display: "inline-block",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }
              }}
              primary={originalFileName}
              secondary={`${Math.floor(fileSize / 1024)} KB`}
            />
          </ListItem>
        ))}
      </List>
    )
    : <></>
}

export default PostCardFileList
