import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, Button, Typography } from '@mui/material'
import dayjs from 'dayjs'

import config from 'utils/config'
import { translations } from 'locales/i18n'
import CommunityMenu from '../CommunityMenu'
import { MMMM_DD_DATE_FORMAT } from 'utils/constants'
import getStreamMenuItems from './getStreamMenuItems'

type StreamCardProps = {
  classId: string
  stream: ClassStreamSearchView
  showMenu?: boolean
  onEdit?: () => void
  onDelete?: () => void
}

const StreamCard: FC<StreamCardProps> = ({
                                           classId,
                                           stream,
                                           showMenu = false,
                                           onEdit,
                                           onDelete,
                                         }) => {
  const { t } = useTranslation()

  const {
    id: streamId,
    name,
    date,
    previewImageUri,
  } = stream

  const menuItems = getStreamMenuItems({
    classId,
    streamId,
    onEdit: onEdit,
    onDelete: onDelete,
  })

  return (
    <Box
      sx={{
        height: { xs: '262px', lg: '344px', xl: '325px' },
        background: '#1F2021',
        borderRadius: '3px',
      }}
    >
      <Box
        sx={{
          borderRadius: '3px 3px 0px 0px',
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          height: { xs: '150px', lg: '210px', xl: '190px' },
          background: '#000000',
        }}
      >
        <Link to={`/classes/${classId}/streams/${streamId}`}>
          <img
            src={`${config.cdnBaseApiURI}${previewImageUri}`}
            className="u-position-absolute u-w-100 u-h-100"
            style={{ objectFit: 'cover' }}
            alt={name}
          />
        </Link>
        {showMenu && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <CommunityMenu
              menuItems={menuItems}
              keyPrefix={streamId}
              sx={{
                m: '12px',
                background: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '50%',
                width: '28px',
                height: '28px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          p: { xs: '15px', lg: '20px' },
        }}
      >
        <Typography
          sx={{
            fontSize: { xs: '10px', lg: '14px' },
            lineHeight: { xs: '14px', lg: '20px' },
          }}
        >
          {`${dayjs(date).format(MMMM_DD_DATE_FORMAT)}`}
        </Typography>
        <Typography
          sx={{
            mt: '4px',
            fontSize: { xs: '14px', lg: '18px' },
            lineHeight: { xs: '18px', lg: '23px' },
            fontWeight: 'bold',
          }}
          className="u-text-overflow-ellipsis"
        >
          {name}
        </Typography>
        <Link to={`/classes/${classId}/streams/${streamId}`}>
          <Button
            sx={{
              mt: '14px',
              width: '100%',
              padding: '8px 20px',
              backgroundColor: '#3C3D3E',
              color: '#FFFFFF',
              borderRadius: '4px',
              fontSize: { xs: '12px', lg: '14px' },
              lineHeight: { xs: '15px', lg: '17px' },
              textTransform: 'none',
            }}
          >
            {t(translations.common.view)}
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

export default StreamCard
