import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'

import { translations } from 'locales/i18n'

interface QuizAttemptReviewNotAllowedProps {
  quizAttemptId: string
}

const QuizAttemptReviewNotAllowed: FC<QuizAttemptReviewNotAllowedProps> = ({quizAttemptId}) => {
  const {t} = useTranslation()

  return (
    <Box sx={{
      height: 'calc(100vh - 50px)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}>
    <Box>
      <Box display="flex" justifyContent="center">
        <Box>
          <Box
            sx={{
              fontSize: {xs: '18px', sm: '34px'},
              lineHeight: {xs: '23px', sm: '41px'},
              fontWeight: {xs: 'bold'},
            }}
          >
            {t(translations.quizAttemptReview.reviewQuestions)}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box sx={{
          width: {sm: '489px'},
          mt: '33px',
          mx: {xs: '20px'},
          px: {xs: '20px', sm: '76px'},
          pt: {xs: '39px', sm: '57px'},
          pb: '37px',
          height: {xs: '163px', sm: "239px"},
          background: theme => theme.palette.background.paper,
          borderRadius: "3px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}>
          <Box sx={{textAlign: "center"}}>
            <Box sx={{
              fontSize: {xs: '12px', sm: "16px"},
              lineHeight: {xs: '18px', sm: "24px"},
            }}>
              <span>{t(translations.quizAttemptReview.notAllowed)}</span>
              {' '}
              <span style={{fontWeight: 'bold'}}>{t(translations.quizAttemptReview.nQuestions)}</span>
              .
            </Box>
          </Box>
          <Box sx={{
            display: "flex",
            justifyContent: "space-between",
            width: '85%',
            mt: {xs: '29px', sm: "51px"},
          }}>
            <Link to={`/quiz-attempts/${quizAttemptId}/results`} style={{textDecoration: 'none', width: "100%"}}>
              <Button
                sx={{
                  background: "#373737",
                  color: "white",
                  width: "100%",
                  height: '38px',
                  textTransform: "none",
                }}
                className="Button"
              >
                <Typography sx={{
                  fontSize: {xs: '12px', sm: "15px"},
                  lineHeight: {xs: '15px', sm: "18px"},
                  fontWeight: 'bold',
                  width: "100%",
                }}>
                  {t(translations.common.ok)}
                </Typography>
              </Button>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
    </Box>
  )
}

export default QuizAttemptReviewNotAllowed
