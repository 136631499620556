import { clientDataService } from './clientDataService'
import config from 'utils/config'

export const logInCdn = async (token: string): Promise<void> => {
  return await clientDataService<void>({
    method: 'POST',
    url: `${config.cdnBaseApiURI}/auth`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {},
  })
}

export const logOutCdn = async (): Promise<void> => {
  return await clientDataService<void>({
    method: 'POST',
    url: `${config.cdnBaseApiURI}/logout`,
    data: {},
  })
}
