import { useQuery, UseQueryResult } from 'react-query'
import { isEmpty } from 'lodash'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getClassParticipants } from 'services/community/classService'

const useClassParticipants = (
  classId: string,
  searchQuery: string,
): UseQueryResult<ClassParticipants> =>
  useQuery<ClassParticipants>(
    [ReactQueryKeys.CLASS_PARTICIPANTS, classId, searchQuery],
    () => getClassParticipants(classId, searchQuery), {
      enabled: !!classId && !isEmpty(searchQuery),
    },
  )

export default useClassParticipants
