import React from 'react'
import { Typography } from '@mui/material'

const Heading = ({content}) => {
  return (
    <Typography
      sx={{
        fontSize: {xs: '16px', md: '24px'},
        fontWeight: {xs: '800', md: 'normal'},
        lineHeight: {xs: '23px', md: '29px'},
        mt: {xs: '16px', md: '22px'},
        mb: {xs: '15px', md: '25px'}
      }}
    >
      {content}
    </Typography>
  )
}

export default Heading;
