import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { logInUser } from 'services/userService'
import { logInCdn } from 'services/cdnAuthService'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { translations } from 'locales/i18n'
import { TOKEN_KEY } from 'utils/constants'
import config from '../utils/config'

const useLogIn = () => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  return useMutationWithSnackbar(
    (data: LogInUserRequest) => logInUser(data),
    undefined,
    undefined,
    {
      async onSuccess(data: AuthUserResponse) {
        enqueueSnackbar(
          t(translations.messages.logInSuccess),
          { variant: 'success' },
        )
        const { token } = data
        localStorage.setItem(TOKEN_KEY, token)
        await logInCdn(token)
        window.location.href = '/'
      },
    },
  )
}

export default useLogIn
