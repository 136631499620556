import React, { FC, useRef, useState } from 'react'
import { Box, Menu, useMediaQuery } from '@mui/material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

import threeHorizontalDots from 'images/three-horizontal-dots.svg'
import FixedBottomNestedMenu from 'components/shared/fixed-bottom-nested-menu/FixedBottomNestedMenu'
import theme from 'theme'
import CommunityMenuItem from './CommunityMenuItem'

type CommunityMenuProps = {
  menuItems: (MenuItem | false | undefined)[]
  keyPrefix?: string
  sx?: SxProps<Theme>
}

const CommunityMenu: FC<CommunityMenuProps> = ({keyPrefix = '', menuItems, sx}) => {
  const isLaptop = useMediaQuery(theme.breakpoints.up('md'))

  const [menuOpen, setMenuOpen] = useState(false)
  const menuRef = useRef(null)

  const toggleMenuOpen = () => setMenuOpen(!menuOpen)

  const onAction = (action: () => void) => {
    action()
    setMenuOpen(false)
  }

  const handleAction = (action?: () => void) => () => action && onAction(action)

  const finalMenuItems = (menuItems
    .filter((menuItem) => !!menuItem) as MenuItem[])
    .map((menuItem) => (
      {
        ...menuItem,
        onClick: handleAction(menuItem.onClick),
      }
    ))

  return (
    <Box
      ref={menuRef}
      onClick={toggleMenuOpen}
      sx={{
        cursor: 'pointer',
        height: '20px',
        ...sx,
      }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <img src={threeHorizontalDots} alt="Menù"/>
      </Box>
      <Menu
        sx={{
          display: {xs: 'none', md: 'unset'},
          '& .MuiList-root': {
            borderRadius: '3px',
          },
        }}
        anchorEl={menuRef.current}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        onClick={() => setMenuOpen(false)}
        PaperProps={{elevation: 0}}
        transformOrigin={{horizontal: 'right', vertical: 'top'}}
        anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
      >
        {finalMenuItems.map(({icon, title, onClick}) => (
          <CommunityMenuItem key={`${keyPrefix}${title}`} icon={icon || ''} title={title} onClick={onClick}/>
        ))}
      </Menu>

      {!isLaptop && (
        <FixedBottomNestedMenu
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
          menuItems={finalMenuItems}
        />
      )}
    </Box>
  )
}
export default CommunityMenu
