import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ErrorMessage as ErrorMessageFormik } from 'formik'
import cn from 'classnames'

import styles from './error-message-mobile.module.scss'

interface ErrorMessageProps {
  name: string;
  className?: string;
}

const ErrorMessage: FC<ErrorMessageProps> = ({
                                               name,
                                               className,
                                             }) => {
  const {t} = useTranslation('common')

  const getErrorMessage = (errorMessage: string) =>
    errorMessage.includes('.') ? t(`validatorSchema.${errorMessage}`) : errorMessage

  return (
    <ErrorMessageFormik
      name={name}
      render={errorMessage =>
        <div className={cn(styles.error, className)}>
          {getErrorMessage(errorMessage)}
        </div>
      }/>
  )
}

export default ErrorMessage
