import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { groupBy, Dictionary, uniqBy, isEmpty } from 'lodash'
import { QuizType } from 'types/QuizType'
import { QuizAttemptQuestion } from 'types/QuizAttemptQuestion'
import { QuizAttemptInterface } from 'types/QuizAttemptInterface'
import { simpleQuizTypes } from 'components/quiz-attempt/constants'
import getQuizTypeTitle from 'components/shared/getQuizTypeTitle'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'
import { CurrentSectionInterface } from 'pages/QuizAttempt.page'

interface SelectQuizQuestionResult {
  localQuestions: QuizAttemptQuestion[]
  currentQuestion: QuizAttemptQuestion
  buttonText: string
  questionsGroupedBySubject: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  questionsGroupedBySectionId: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  answeredQuestionsGroupedBySection: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  answeredQuestionsGroupedBySubject: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  answeredQuestions: QuizAttemptQuestion[]
  lastElemSectionId: string
  setButtonText: React.Dispatch<React.SetStateAction<string>>
  isFlagged: (number: number) => boolean
  getTitle: () => string
  getQuestionNavigation: (number: number) => string
  handleToggleFlag: (questionNumber: number) => void
  handleSubmitWithSaveAnswer: (data: QuizAttemptAnswerCreateRequest) => void
  handleFinishQuiz: (e: React.FormEvent<HTMLButtonElement>) => void
  onCompleteQuizAttempt: () => void
}

interface SelectQuizQuestionOptions {
  quizAttempt?: QuizAttemptInterface
  questions: QuizAttemptQuestion[]
  currentQuestionNumber: number
  currentSection?: CurrentSectionInterface | null
  onSubmitAnswer: (data: QuizAttemptAnswerCreateRequest) => void
  onCreateQuestionFlag: (data: QuizAttemptQuestionTagRequest) => void
  onDeleteQuestionFlag: (data: QuizAttemptQuestionTagRequest) => void
  onCompleteQuizAttempt: () => void
  handleMoveNextSection: () => void
  handleCompleteQuizAttemptNextSection: (quizId: string, activeId: string) => void
}

const useSelectQuizQuestion = ({
                                quizAttempt,
                                questions,
                                currentQuestionNumber,
                                currentSection,
                                onSubmitAnswer,
                                onCreateQuestionFlag,
                                onDeleteQuestionFlag,
                                onCompleteQuizAttempt,
                                handleMoveNextSection,
                                handleCompleteQuizAttemptNextSection
                              }: SelectQuizQuestionOptions): SelectQuizQuestionResult => {
  const history = useHistory()
  const { t } = useTranslation()
  const { id, quizType, quizRule, quizAttemptDetails } = quizAttempt || {} as QuizAttemptInterface
  const [quizQuestions, setQuestions] = useState<QuizAttemptQuestion[]>([])
  const [activeQuestion, setActiveQuestion] = useState<QuizAttemptQuestion>({} as QuizAttemptQuestion)
  const [flagged, setFlagged] = useState<number[]>([])
  const [buttonText, setButtonText] = useState(`${t(translations.quizAttemptPage.finishQuiz)}`);

  const tagged = (quizQuestions: QuizAttemptQuestion[]) => quizQuestions.filter(({ tagged }) => tagged).map(({ number }) => number)
  const isFlagged = (number: number) => flagged.includes(number)
  const findQuestion = (actionNumber: number) => quizQuestions.find(({ number }) => number === actionNumber) || {} as QuizAttemptQuestion
  
  // lesson quiz questions doesn't have subject and topic
  const lessonQuiz = quizType === QuizType.LESSON
  const practiceQuiz = quizType === QuizType.PRACTICE
  const answeredQuestions = quizQuestions.filter((q) => q.question.options.find((o) => o.studentAnswer))

  const questionsGroupedBySubject = lessonQuiz ? {} : groupBy(quizQuestions, ({ question }) => question.subject.name)
  const questionsGroupedBySectionId = lessonQuiz ? {} : groupBy(quizQuestions, ({ question }) => quizRule?.sections.find((s) => s.subjects.includes(question.subject.id))?.id)
  const answeredQuestionsGroupedBySection = lessonQuiz ? {} : groupBy(answeredQuestions, ({ question }) => quizRule?.sections.find((s) => s.subjects.includes(question.subject.id))?.name)
  const answeredQuestionsGroupedBySubject = lessonQuiz ? {} : groupBy(answeredQuestions, ({ question }) => question.subject.name)
  const currentQuestion = currentQuestionNumber === 0 ? quizQuestions[0] : findQuestion(currentQuestionNumber)
  const firstQuestionSubjectName = quizQuestions[0] ? quizQuestions[0].question.subject?.name : ''
  const lastElemSectionId = !lessonQuiz && !practiceQuiz && quizRule?.sections && quizRule.sections[quizRule.sections.length - 1].id
  const groupName = `${quizAttemptDetails?.groupName}: ${quizAttemptDetails?.quizName}`
  const setInitialState = () => {
    setQuestions(questions)
    setFlagged(tagged(questions))
  }

  useEffect(
    () => {
      !isEmpty(questions) && setInitialState()
    }, [id, questions]
  )

  useEffect(
    () => {
      !isEmpty(activeQuestion) &&
        setQuestions(uniqBy([activeQuestion, ...quizQuestions], 'number').sort((a, b) => a.number - b.number))
    }, [activeQuestion]
  )
  
  const getTitle = () => simpleQuizTypes.includes(quizType)
    ? firstQuestionSubjectName
    : getQuizTypeTitle(quizType, firstQuestionSubjectName, groupName)

  const getQuestionNavigation = (number: number) => `question-${number}`

  const getUpdatedOptions = (answer: number | null, currentQuestion: QuizAttemptQuestion) =>
    currentQuestion.question.options
      .map((item) => (item.number === answer ? {...item, studentAnswer: true} : {...item, studentAnswer: false}))
      .sort((a, b) => a.number - b.number)
  
  const handleFinishQuiz = (e: React.FormEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    if (answeredQuestions.length === quizQuestions.length) {
      onCompleteQuizAttempt()
    }
    else if(quizRule?.dividedBySections && currentSection?.actualSection !== lastElemSectionId) {
      handleMoveNextSection()
    }
     else {
      history.push(`/quiz-attempts/${id}/complete`) 
    }
  }


  const handleSubmitWithSaveAnswer = ({ quizAttemptId, questionNumber, answer }: QuizAttemptAnswerCreateRequest) => {
    const currentQuestion = findQuestion(questionNumber)

    setActiveQuestion({...currentQuestion, question: { ...currentQuestion.question, options: getUpdatedOptions(answer, currentQuestion)}})
    onSubmitAnswer({quizAttemptId, questionNumber, answer})
  }

  const handleToggleFlag = (questionNumber: number) => {
    const data = { quizAttemptId: id, questionNumber } as QuizAttemptQuestionTagRequest
    if (flagged.includes(questionNumber)) {
      onDeleteQuestionFlag(data)
      setFlagged(flagged.filter((item) => item !== questionNumber))
    } else {
      onCreateQuestionFlag(data)
      setFlagged([
        ...flagged,
        questionNumber,
      ])
    }
  }

  return {
    localQuestions: quizQuestions,
    currentQuestion,
    questionsGroupedBySubject,
    answeredQuestionsGroupedBySection,
    answeredQuestionsGroupedBySubject,
    questionsGroupedBySectionId,
    answeredQuestions,
    buttonText,
    lastElemSectionId,
    onCompleteQuizAttempt,
    setButtonText,
    isFlagged,
    getTitle,
    getQuestionNavigation,
    handleToggleFlag,
    handleSubmitWithSaveAnswer,
    handleFinishQuiz
  }
}

export default useSelectQuizQuestion
