import React, { Dispatch, FC, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

import { ClassTab } from 'types/ClassTab'
import { translations } from 'locales/i18n'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { UserProfileRole } from 'types/UserProfileRole'

type ClassTabsProps = {
  tabs: ClassTab[]
  currentTab: ClassTab
  setTab: Dispatch<SetStateAction<ClassTab>>
  currentUser: StudentProfileInterface
}

const ClassTabs: FC<ClassTabsProps> = ({tabs, currentTab, setTab, currentUser}) => {
  const {t} = useTranslation()
  const {mainRole} = currentUser

  return (
    <Box
      sx={{
        display: 'flex',
        borderBottom: '1px solid rgba(255, 255, 255, .1)',
        overflowX: 'auto',
        cursor: 'pointer'
      }}
      className="scrollbar_hidden"
    >
      {tabs
        .filter(tab => mainRole !== UserProfileRole.STUDENT || tab !== ClassTab.SCHEDULED_POSTS)
        .map(tab => (
        <Box
          key={tab}
          sx={{
            m: 0,
            pt: {xs: '13px', sm: '16px', md: '20px'},
            px: {xs: '13px', md: mainRole === UserProfileRole.STUDENT ? '13px' : '6.5px', lg: '15px'},
            pb: '10px',
            fontSize: {xs: '12px', sm: '14px'},
            lineHeight: {xs: '15px', sm: '17px'},
            fontWeight: 'bold',
            opacity: currentTab === tab ? 'unset' : '40%',
            borderBottom: currentTab === tab ? '3px solid #E25F60' : 'unset',
            transition: 'all 0.2s ease',
            whiteSpace: 'nowrap'
          }}
          onClick={() => setTab(tab)}
        >
          {t(translations.community.feed.classTabs[tab])}
        </Box>
      ))}
    </Box>
  )
}

export default ClassTabs
