import { Dispatch, SetStateAction } from 'react'

import { Posts } from 'types/Posts'
import { PostInterface } from 'types/PostInterface'

const getUpdatedPostsOnPostUpdated = (
  posts: Posts | undefined,
  payload: PostInterface,
  setPosts?: Dispatch<SetStateAction<PostInterface[]>>
) => {
  const updatePost = (post: PostInterface) => post.postId === payload.postId ? payload : post

  if (setPosts) {
    setPosts((prevValue) => (
      prevValue.map(updatePost)
    ))
  }

  return {
    ...posts,
    content: posts?.content?.map(updatePost),
  }
}

export default getUpdatedPostsOnPostUpdated
