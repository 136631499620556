import { changePassword } from 'services/userService'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { translations } from 'locales/i18n'

const useChangePassword = () => useMutationWithSnackbar(
  (data: ChangePasswordRequest) => changePassword(data),
  undefined,
  translations.messages.passwordUpdated,
)

export default useChangePassword
