import { UseQueryResult } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import { AxiosError } from 'axios'

import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getStreams } from 'services/community/streamService'

const useStreams = (
  classId: string,
  searchQuery: string,
  myOnly: boolean,
  date: number | null | undefined,
  options?: UseQueryOptions<ClassStreamsView, AxiosError>,
): UseQueryResult<ClassStreamsView> =>
  useQueryWithSnackbar<ClassStreamsView>(
    [ReactQueryKeys.STREAMS, classId, searchQuery, myOnly, date],
    () => getStreams(classId, searchQuery, myOnly, date), {
      enabled: !!classId,
      ...options,
    },
  )

export default useStreams
