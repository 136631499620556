import React, { FC } from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { translations } from 'locales/i18n'

import theme from "theme";
import timer from "images/timer.svg";
import { CurrentSectionInterface } from "pages/QuizAttempt.page";

type TimeIsUpModalProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  disableEnforceFocus?: boolean;
  sections: QuizAttemptSections[] | undefined
  currentSection: CurrentSectionInterface | null | undefined
  handleTimeIsUpModalClose: () => void
  onCompleteQuizAttempt: () => void
  onBackdropClick?: React.ReactEventHandler<{}>
};

const TimeIsUpModal: FC<TimeIsUpModalProps> = ({
  open,
  title,
  onClose,
  disableEnforceFocus,
  onBackdropClick,
  sections,
  currentSection,
  handleTimeIsUpModalClose,
  onCompleteQuizAttempt
}) => {

  const { t } = useTranslation()

  
  return (
    <Modal
      open={open}
      onClose={onClose}
      disableEnforceFocus={disableEnforceFocus}
      onBackdropClick={onBackdropClick}
      aria-labelledby="styled-modal-title"
      aria-describedby="styled-modal-description"
      sx={{
        overflow: "scroll",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          left: "50%",
          outline: "none",
          transform: "translate(-50%, 0%)",
          top: "30%",
          width: { xs: "80%", sm: "450px" },
          bgcolor: theme.palette.background.paper,
          borderRadius: "4px",
          boxShadow: 24,
          p: "40px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            pb: "20px",
          }}
        >
          <img src={timer} alt="Timer" />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <Box
            sx={{
              fontSize: "16px",
              lineHeight: "19px",
              margin: "0 auto",
            }}
          >
            {title}
          </Box>
        </Box>

        <Box
          sx={{
            pt: { xs: "20px", sm: "24px" },
            fontSize: { xs: "18px", sm: "24px" },
            fontWeight: 700,
            lineHeight: "23px",
            textAlign: "center",
            width: { xs: "95%", sm: "100%" },
            margin: { xs: "0 auto", sm: "auto" },
          }}
        >
          {t(translations.quizAttemptPage.SectionTimeIsOver)}
        </Box>
        <Box
          sx={{
            pt: { xs: "20px", sm: "24px" },
            textAlign: "center",
            fontSize: { xs: "12px", sm: "16px" },
            lineHeight: "26px",
            mb: { xs: "30px", sm: 0 },
            margin: "0 auto",
          }}
        >
          {currentSection?.actualSection === undefined ? (
            t(translations.quizAttemptPage.nowYouAreMovingResultPage)
          ) : (
            <>
              <Box>
                {t(translations.quizAttemptPage.NowYouAreMovingNextSection)}
              </Box>
              <br />
              <Box sx={{ fontWeight: 700 }}>{`${
                sections &&
                sections.find(
                  (section) => section.id === currentSection?.actualSection
                )?.name
              }`}</Box>
            </>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            mt: { xs: "30px", sm: "50px" },
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: { xs: "100%", sm: "93%" },
              padding: { xs: "12px 18px", sm: "20px 55px" },
            }}
            onClick={() => {
              handleTimeIsUpModalClose();
              if (currentSection?.actualSection === undefined) {
                onCompleteQuizAttempt();
              }
            }}
          >
            <Typography
              sx={{
                fontSize: "15px",
                lineHeight: "18px",
                textTransform: "none",
              }}
            >
              {currentSection?.actualSection === undefined
                ? t(translations.common.ok)
                : t(translations.quizAttemptPage.standNewSectionButtonLabel)}
            </Typography>
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TimeIsUpModal;
