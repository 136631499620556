import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { translations } from 'locales/i18n'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'

import FormInput from '../../ui/form/form-input/form-input'
import LoadingButton from '../../ui/buttons/loading-button/loading-button'
import ResetPasswordFormSchema from './ResetPasswordValidatorSchema'

type ForgotPasswordProps = {
  userId: string
  code: string
  onSubmit: (data: ResetPasswordRequest) => void
  isLoading: boolean
}

const ResetPassword: FC<ForgotPasswordProps> = ({userId, code, onSubmit, isLoading}) => {
  const {t} = useTranslation()

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box>
          <Box sx={{
            fontSize: {xs: '18px', md: '24px'},
            lineHeight: {xs: '23px', md: '29px'},
            mb: {xs: '7px', md: '22px'},
            fontWeight: {xs: 'bold', md: 'normal'},
          }}>
            {t(translations.resetPasswordPage.title)}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box sx={{
          width: {xs: '100%', sm: '370px'},
          background: theme => theme.palette.background.paper,
          borderRadius: "3px",
          border: '1px solid #363B3F',
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mx: {xs: '16px'},
          px: {xs: '20px', md: '40px'},
          pt: {xs: '10px', md: '20px'},
          pb: {xs: '18px', md: '28px'},
        }}>
          <Formik
            validateOnBlur
            validateOnChange
            initialValues={{
              userId: userId,
              passwordResetCode: code,
              password: '',
              passwordConfirm: '',
            }}
            validationSchema={ResetPasswordFormSchema}
            onSubmit={onSubmit}
          >
            {({handleSubmit, isValid}) => (
              <form
                style={{display: "flex", flexDirection: "column", width: '100%'}}
                onSubmit={handleSubmit}
              >
                <FormInput
                  label={t(translations.resetPasswordPage.password)}
                  name="password"
                  type="password"
                  variant="standard"/>
                <FormInput
                  label={t(translations.resetPasswordPage.passwordConfirm)}
                  name="passwordConfirm"
                  type="password"
                  variant="standard"/>
                <Box sx={{mt: '28px'}}>
                  <LoadingButton
                    loading={isLoading}
                    disabled={isLoading || !isValid}
                    className="Button Button--primary Button_low u-w-100 u-text-transform-capitalize"
                    type="submit"
                    variant="contained"
                    color="primary">
                    {t(translations.resetPasswordPage.submitPassword)}
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
          <Typography className="u-fs-14 u-lh-17 u-mt-30">
            <Link to="/login">
              {t(translations.forgotPasswordPage.backToSignIn)}
            </Link>
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default ResetPassword
