import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import TextPostCard from './TextPostCard'
import PollPostCard from './poll/PollPostCard'
import { PostInterface } from 'types/PostInterface'
import { PostType } from 'types/PostType'
import PostCardHeader from './PostCardHeader'
import PostCardImageList from './PostCardImageList'
import PostCardFileList from './PostCardFileList'
import PostComments from './comments/PostComments'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { translations } from 'locales/i18n'

type PostCardProps = {
  classId: string
  currentUser: StudentProfileInterface
  classTeachers: TeacherInterface[]
  post: PostInterface
  isCommentFormSubmitting: boolean
  onStartEdit: () => void
  onStartFullscreen?: () => void
  onPostDelete: () => void
  onPostPin: () => void
  onPostUnpin: () => void
  onCopiedLink: () => void
  onAddPostToFavourites: () => void
  onRemovePostFromFavourites: () => void
  onOpenPostReport: () => void
  onTypingActive: (status: boolean) => void
  onOpenCommentReport?: (data: PostCommentReport) => void
  onCommentCreate: (data: PostCommentRequest) => Promise<PostCommentResponse>
  onCommentUpdate: (data: PostCommentRequest) => Promise<PostCommentResponse>
  onCommentDelete: (data: DeletePostCommentRequest) => void
  onCreateVotePollOption: (data: VotePollRequest) => void
  onUpdateVotePollOption: (data: VotePollRequest) => void
  inputBackground: string
  showMenu?: boolean
  showImages?: boolean
  showComments?: boolean
  marginContent?: boolean
  marginPollOptions?: boolean
  canVote?: boolean
}

const PostCard: FC<PostCardProps> = ({
                                       classId,
                                       currentUser,
                                       classTeachers,
                                       post,
                                       isCommentFormSubmitting,
                                       onStartEdit,
                                       onStartFullscreen,
                                       onPostDelete,
                                       onPostPin,
                                       onPostUnpin,
                                       onCopiedLink,
                                       onAddPostToFavourites,
                                       onRemovePostFromFavourites,
                                       onOpenPostReport,
                                       onOpenCommentReport,
                                       onTypingActive,
                                       onCommentCreate,
                                       onCommentUpdate,
                                       onCommentDelete,
                                       onCreateVotePollOption,
                                       onUpdateVotePollOption,
                                       inputBackground,
                                       showMenu = true,
                                       showImages = true,
                                       showComments = true,
                                       marginContent = true,
                                       marginPollOptions = true,
                                       canVote = true,
                                     }) => {
  const { t } = useTranslation()

  const { files, pinned, postId } = post

  const newVotePollRequest = (optionId: string) => ({
    classId,
    postId,
    optionId,
  } as VotePollRequest)

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        ml: pinned ? { xs: '-20px', sm: 'unset' } : 'unset',
        width: pinned ? { xs: 'calc(100% + 40px)', sm: 'unset' } : 'unset',
        background: theme => pinned ? theme.palette.background.paper : 'unset',
      }}
      className={`${pinned && 'u-p-20 u-br-3'}`}
      key={postId}
    >
      {pinned && (
        <Box
          sx={{
            display: 'flex',
            padding: 0,
            width: '100px',
            paddingLeft: { xs: '27px', sm: '52px' },
            marginBottom: '10px',
          }}>
          <img src="/images/icons/community/pinned.svg" alt="pinned icon"/>
          <Typography
            sx={{
              pl: '10px',
              textTransform: 'capitalize',
              fontSize: { xs: '12px', lg: '14px' },
              lineHeight: 1.21,
              color: '#606060',
            }}
          >{t(translations.community.postCard.pinned)}</Typography>
        </Box>
      )}
      <PostCardHeader
        classId={classId}
        currentUser={currentUser}
        classTeachers={classTeachers}
        post={post}
        pinned={pinned}
        onStartEdit={onStartEdit}
        onPostDelete={onPostDelete}
        onPostPin={onPostPin}
        onPostUnpin={onPostUnpin}
        onCopiedLink={onCopiedLink}
        onAddPostToFavourites={onAddPostToFavourites}
        onRemovePostFromFavourites={onRemovePostFromFavourites}
        onOpenPostReport={onOpenPostReport}
        showMenu={showMenu}
      />
      <Box
        sx={{
          ml: marginContent ? { xs: '57px', sm: '80px' } : 0,
        }}
      >
        <Box
          sx={{
            cursor: onStartFullscreen ? 'pointer' : 'inherit',
          }}
          onClick={onStartFullscreen}
        >
          {showImages && <PostCardImageList files={files}/>}
        </Box>
        {post.postType === PostType.SIMPLE_POST && <TextPostCard post={post}/>}
        {post.postType === PostType.POLL_POST && (
          <PollPostCard
            post={post}
            currentUser={currentUser}
            onCreateVotePollOption={(optionId) => onCreateVotePollOption(newVotePollRequest(optionId))}
            onUpdateVotePollOption={(optionId) => onUpdateVotePollOption(newVotePollRequest(optionId))}
            marginPollOptions={marginPollOptions}
            canVote={canVote}
          />
        )}
        <PostCardFileList files={files}/>
      </Box>
      {showComments && <Box
          sx={{
            ml: marginContent ? { lg: '80px' } : 0,
          }}
      >
          <PostComments
              classId={classId}
              post={post}
              isCommentFormSubmitting={isCommentFormSubmitting}
              currentUser={currentUser}
              onTypingActive={onTypingActive}
              onCommentCreate={onCommentCreate}
              onCommentUpdate={onCommentUpdate}
              onCommentDelete={onCommentDelete}
              onOpenCommentReport={onOpenCommentReport}
              inputBackground={inputBackground}
          />
      </Box>}
    </Box>
  )
}
export default PostCard
