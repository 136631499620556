import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { updateStream } from 'services/community/streamService'
import { translations } from 'locales/i18n'
import ReactQueryKeys from 'types/ReactQueryKeys'

const useUpdateStream = () => {
  const queryClient = useQueryClient()
  const history = useHistory()

  return useMutationWithSnackbar(
    (data: UpdateStreamRequest) => updateStream(data),
    'updateStream',
    translations.messages.updatedStream, {
      async onSuccess(data) {
          await queryClient.invalidateQueries(ReactQueryKeys.STREAM)
          await history.push(`/classes/${data.classId}/streams/${data.id}`)
      }
    },
  )
}

export default useUpdateStream
