import React, { FC, ChangeEvent } from 'react'
import { Field, FieldProps } from 'formik'
import { Switch, FormControlLabel } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'

const style = {
  width: 42,
  height: 24,
  padding: 0,
  margin: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '1px',
    transitionDuration: '300ms',
    color: '#747474',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#4780D8',
      '& + .MuiSwitch-track': {
        bgcolor: '#373738',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#747474',
      border: '6px solid #747474',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    bgcolor: '#373738',
    opacity: 1,
  },
} as const
 
type FormSwitchProps = {
  name: string
  label?: {text: string, position: 'start' | 'end'}
  value?: boolean
  onChange?: (check: boolean) => void
  sxSwitch?: SxProps<Theme>
  sxLable?:SxProps<Theme>

}

const FormSwitch: FC<FormSwitchProps> = ({ name, label, value=false, sxSwitch, sxLable, onChange }) => (
      <Field name={name}>
      {({ form: { setFieldValue } }: FieldProps) => (
        <FormControlLabel
            sx={sxLable}
            label={label && label.text}
            labelPlacement={label && label.position}
            control={
              <Switch sx={{ ...style, ...sxSwitch }}
                checked={value}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(name, e.target.checked)
                  onChange && onChange(e.target.checked)
                }}
              />
            }
          />
          )}
      </Field>
  );

export default FormSwitch
