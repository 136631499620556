import React, { FC } from 'react'
import { Box, Switch } from '@mui/material'

const style = {
  width: 42,
  height: 24,
  padding: 0,
  margin: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: '1px',
    transitionDuration: '300ms',
    color: '#747474',
    '&.Mui-checked': {
      transform: 'translateX(18px)',
      color: '#4780D8',
      '& + .MuiSwitch-track': {
        bgcolor: '#373738',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#747474',
      border: '6px solid #747474',
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    bgcolor: '#373738',
    opacity: 1,
  },
} as const

type SwitchElementProps = {
  text: string
  onChange?: () => void
}

const SwitchElement: FC<SwitchElementProps> = ({text, onChange}) => {
  return (
    <Box sx={{display: 'flex', justifyContent: 'flex-end', alignItems: 'center'}}>
      <Switch sx={{...style}} onChange={onChange}/>
      <Box component={"span"} className="u-color-mishka"
           sx={{fontWeight: '400', fontSize: {xs: '12px', md: '14px'}, ml: '10px'}}>
        {text}
      </Box>
    </Box>
  )
}

export default SwitchElement
