import React, { useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import { Back } from 'components/shared/back'
import useQuizAttempt from 'hooks/useQuizAttempt'
import useCreateQuizAttemptAnswer from 'hooks/useCreateQuizAttemptAnswer'

import useCompleteQuizAttempt from 'hooks/useCompleteQuizAttempt'
import useCreateQuizAttemptQuestionFlag from 'hooks/useCreateQuizAttemptQuestionFlag'
import useDeleteQuizAttemptQuestionFlag from 'hooks/useDeleteQuizAttemptQuestionFlag'
import useSelectQuizQuestion from 'hooks/useSelectQuizQuestion'
import QuizAttempt from 'components/quiz-attempt'
import LoadingIndicator from 'components/shared/loading-indicator'
import { QUESTION_ANCHOR_PREFIX } from 'utils/constants'
import { QuizType } from 'types/QuizType'
import useQuizAttemptNextSection from 'hooks/useQuizAttemptNextSection'
import { simpleQuizTypes } from 'components/quiz-attempt/constants'
import { getDurationMilli } from 'utils/duration'
import dayjs from 'dayjs'
import { MAX_QUIZ_ATTEMPT_DURATION_MILLI, ONE_MIN_IN_MS } from 'theme/constants'
import StyledModal from 'components/shared/modal/StyledModal'
import { Box } from '@material-ui/core'
import { Button, Typography } from '@mui/material'
import TimeIsUpModal from 'components/shared/modal/TimeIsUpModal'

export interface CurrentSectionInterface {
  actualSection: string,
  sectionStartedAt: number
}

const QuizAttemptPage = (
  props: RouteComponentProps<{ quizAttemptId?: string }>,
) => {
  const history = useHistory()
  const { t } = useTranslation()
  const currentQuestionString = props.location.hash.replace(`#${QUESTION_ANCHOR_PREFIX}`, '')
  const currentQuestionNumber = +currentQuestionString
  const quizAttemptId: string = props.match.params.quizAttemptId || ''
  const quizAttemptQuery = useQuizAttempt(quizAttemptId)
  const quizAttempt = quizAttemptQuery.data
  const quizAttemptDetails = quizAttemptQuery.data?.quizAttemptDetails
  const quizType = quizAttempt?.quizType || {} as QuizType
  const createQuizAttemptAnswerMutation = useCreateQuizAttemptAnswer()
  const completeQuizAttemptMutation = useCompleteQuizAttempt()
  const createQuizAttemptQuestionFlagMutation = useCreateQuizAttemptQuestionFlag()
  const deleteQuizAttemptQuestionFlagMutation = useDeleteQuizAttemptQuestionFlag()
  const completeQuizAttemptSectionMutation = useQuizAttemptNextSection()
  const [currentSection, setCurrentSection] = useState<CurrentSectionInterface | null>()
  const [remainingTime, setRemainingTime] = useState<number>(0)
  const [showTimeIsUp, setShowTimeIsUp] = useState<boolean>(false)
  const [timerSave, setTimerSave] = useState<NodeJS.Timeout | null>(null)
  const [moveNextSection, setMoveNextSection] = useState(false)
  const [timeIsUpMove, setTimeIsUpMove] = useState(false)
  const [timerStyle, setTimerStyle] = useState<string>('#FFFFFF')
  const handleTimeIsUp = () => setTimeIsUpMove(true)
  const handleTimeIsUpModalClose = () => setTimeIsUpMove(false)

  useEffect(() => {
    if (quizAttempt) {

      setCurrentSection({
        actualSection: quizAttempt.activeSectionId || "",
        sectionStartedAt: quizAttempt.sectionStartedAt || quizAttempt.createdAt,
      });
    }

    if(quizAttempt?.quizRule?.dividedBySections && quizAttempt.activeSectionId !== lastElemSectionId) {
      setButtonText(`${t(translations.quizAttemptPage.nextSection)}`)
    }
  }, [quizAttempt]);

  useEffect(() => {
    if (
      quizAttempt &&
      currentSection &&
      quizAttempt?.quizRule?.dividedBySections
    ) {
      setRemainingTime(Timer(quizAttempt, currentSection));
      if (!simpleQuizTypes.includes(quizType)) {
        let timeRemainingTimer = setInterval(() => {
          setRemainingTime((prevState) => {
            let newState = prevState - 1000
            newState <= ONE_MIN_IN_MS
              ? setTimerStyle("#E25F60")
              : setTimerStyle("#FFFFFF");
            if (newState <= 0) {
              timerSave && clearTimeout(timerSave);
              if (currentSection.actualSection !== lastElemSectionId) {
                handleCompleteQuizAttemptNextSection(
                  quizAttempt.id,
                  currentSection.actualSection
                );
                handleTimeIsUp();
              } else {
                handleTimeIsUp();
                setCurrentSection(null);
              }
              return 0;
            } else return newState;
          });
        }, 1000);

        setTimerSave(timeRemainingTimer);

        return () => {
          clearTimeout(timeRemainingTimer);
        };
      }
    } else {
      if (!showTimeIsUp && quizAttempt && !simpleQuizTypes.includes(quizType)) {
        const newRemainingTimeMilli = getDurationMilli(
          quizAttempt.sectionStartedAt + quizAttempt.timeFromStart,
          quizAttempt.createdAt + quizAttempt?.quizRule?.timeLimit * 1000
        );
        setRemainingTime(newRemainingTimeMilli);
        let timeRemainingTimerV1 = setInterval(() => {
          setRemainingTime((prevState) => {
            if (prevState <= 0) {
              setShowTimeIsUp(true);
              return prevState;
            } else return prevState - 1000;
          });
        }, 1000);

        return () => {
          clearTimeout(timeRemainingTimerV1);
        };
      }
    }

  }, [quizAttempt, quizType, showTimeIsUp, currentSection]);
  
  const Timer = (quizAttempt, currentSection: CurrentSectionInterface) => {
    const sectionTimeLimit = quizAttempt.quizRule.sections.find(section => section.id === currentSection.actualSection).timeLimit
    const newRemainingTimeMilli = getDurationMilli(currentSection.sectionStartedAt + quizAttempt.timeFromStart, currentSection.sectionStartedAt + sectionTimeLimit * 1000)
    newRemainingTimeMilli <= ONE_MIN_IN_MS ? setTimerStyle('#E25F60') : setTimerStyle('#FFFFFF')
    return newRemainingTimeMilli
  }


  const handleCompleteQuizAttemptNextSection = (quizId: string, activeId: string) => {
    if (quizAttempt) {
      completeQuizAttemptSectionMutation.mutate(
        {quizAttemptId: quizId, sectionId: activeId}, 
        {onSuccess(data) { 
          setCurrentSection({
            actualSection: data.nextSectionId,
            sectionStartedAt: data.sectionStartedAt,
          });
          handleEditingPostClose();
          if( data.nextSectionId === lastElemSectionId) {
            setButtonText(`${t(translations.quizAttemptPage.finishQuiz)}`)
          }
          window.scrollTo(0, 0);
         }}
      ); 
    }
  }

  const getTimeInMin = (time) => {
    return `${Math.floor(time / 60) < 10 
      ? `0${Math.floor(time / 60)}` 
      : Math.floor(time / 60)}:${time - Math.floor(time / 60) * 60 < 10 
        ? `0${time - Math.floor(time / 60) * 60}`
        : time - Math.floor(time / 60) * 60}`
  }

  const handleSubmitAnswer = (data: QuizAttemptAnswerCreateRequest) => createQuizAttemptAnswerMutation.mutate(data)
  const handleCompleteQuizAttempt = () => completeQuizAttemptMutation.mutate(quizAttemptId)
  const handleCreateQuestionFlag = (data: QuizAttemptQuestionTagRequest) => createQuizAttemptQuestionFlagMutation.mutate(data)
  const handleDeleteQuestionFlag = (data: QuizAttemptQuestionTagRequest) => deleteQuizAttemptQuestionFlagMutation.mutate(data)
  const handleMoveNextSection = () => setMoveNextSection(true)
  const handleEditingPostClose = () => setMoveNextSection(false)

  const questions = quizAttempt?.questions || []

  const {
    currentQuestion,
    localQuestions,
    questionsGroupedBySubject,
    questionsGroupedBySectionId,
    answeredQuestionsGroupedBySection,
    answeredQuestionsGroupedBySubject,
    answeredQuestions,
    buttonText,
    lastElemSectionId,
    setButtonText,
    isFlagged,
    handleToggleFlag,
    handleSubmitWithSaveAnswer,
    handleFinishQuiz,
    getTitle,
    onCompleteQuizAttempt,
    getQuestionNavigation
  } = useSelectQuizQuestion({
    handleMoveNextSection,
    quizAttempt,
    questions,
    currentQuestionNumber,
    currentSection,
    handleCompleteQuizAttemptNextSection,
    onSubmitAnswer: handleSubmitAnswer,
    onCreateQuestionFlag: handleCreateQuestionFlag,
    onDeleteQuestionFlag: handleDeleteQuestionFlag,
    onCompleteQuizAttempt: handleCompleteQuizAttempt,
  })

  if (quizAttempt && quizAttempt.completedAt) {
    history.push(`/quiz-attempts/${quizAttempt.id}/results`)
  }

  const getLinkBack = () => {
    switch (quizAttemptQuery.data?.quizType) {
      case QuizType.LESSON:
        return {
          text: t(translations.navigation.lessonPage),
          url: `/courses/${quizAttemptDetails?.courseId}/lessons/${quizAttemptDetails?.lessonId}`,
        }
      default:
        return {
          text: t(translations.navigation.quizzesPage),
          url: '/quizzes',
        }
    }
  }
  const dataButtonBack = getLinkBack()

  return (
    <DashboardLayout>
      {!quizAttemptQuery.isLoading && dataButtonBack && (
        <Back
          text={dataButtonBack.text}
          url={dataButtonBack.url}
          sx={{ display: { xs: "none", lg: "initial" }, position: "fixed" }}
        />
      )}
      {quizAttempt && currentSection && (
        <QuizAttempt
          remainingTime={remainingTime}
          showTimeIsUp={showTimeIsUp}
          setShowTimeIsUp={setShowTimeIsUp}
          quizAttempt={quizAttempt}
          quizQuestions={localQuestions}
          questionsGrouped={questionsGroupedBySubject}
          questionsGroupedBySectionId={questionsGroupedBySectionId}
          answeredQuestionsGroupedBySubject={answeredQuestionsGroupedBySubject}
          answeredQuestionsGroupedBySection={answeredQuestionsGroupedBySection}
          handleCompleteQuizAttemptNextSection={
            handleCompleteQuizAttemptNextSection
          }
          actualSection={currentSection.actualSection}
          answeredQuestions={answeredQuestions}
          currentQuestion={currentQuestion}
          isFlagged={isFlagged}
          getTitle={getTitle}
          getQuestionNavigation={getQuestionNavigation}
          onSubmitAnswer={handleSubmitWithSaveAnswer}
          onFinishQuiz={handleFinishQuiz}
          onToggleFlag={handleToggleFlag}
          buttonText={buttonText}
          timerStyle={timerStyle}
        />
      )}
      {quizAttemptQuery.isLoading && <LoadingIndicator />}

      {
        <StyledModal
          open={moveNextSection}
          onClose={handleEditingPostClose}
          title={t(translations.messages.moveToNextSectionTitle)}
          disableEnforceFocus={true}
          modalBoxSx={{ top: "30%", width: { xs: "80%", sm: "618px" } }}
          desktopOnly={true}
          displayNone={{ display: { xs: "none", sm: "block" } }}
          stylesTextSx={{
            fontSize: { xs: "18px", sm: "34px" },
            lineHeight: { xs: "23px", sm: "41px" },
            margin: { xs: "0 auto", sm: 0 },
          }}
        >
          <Box
            sx={{
              pt: { xs: "20px", sm: "24px" },
              textAlign: { xs: "center", sm: "inherit" },
              width: { xs: "95%", sm: "100%" },
              margin: { xs: "0 auto", sm: "auto" },
            }}
          >
            {t(translations.messages.moveToNextSectionFirst)}
          </Box>
          <Box
            sx={{
              pt: { xs: "20px", sm: "24px" },
              textAlign: { xs: "center", sm: "inherit" },
              mb: { xs: "30px", sm: 0 },
              margin: { xs: "0 auto", sm: "auto" },
            }}
          >
            {t(translations.messages.moveToNextSectionSecond)}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: { xs: "center", sm: "unset" },
              justifyContent: "space-between",
              width: "100%",
              mt: { xs: "30px", sm: "50px" },
              pb: { xs: "30px", md: "0" },
            }}
          >
            <Button
              variant="contained"
              type="button"
              color="secondary"
              sx={{
                color: "white",
                width: { xs: "100%", sm: "48%" },
                minHeight: { xs: "40px", md: "50px" },
                textTransform: "none",
                padding: { xs: "12px 20px", sm: 0 },
                mb: { xs: "10px", sm: 0 },
              }}
              onClick={() => handleEditingPostClose()}
            >
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "16px" },
                  lineHeight: "1.2",
                }}
              >
                {t(translations.quizAttemptPage.returnToSection)}
              </Typography>
            </Button>
            <Button
              variant="contained"
              sx={{
                width: { xs: "100%", sm: "48%" },
                padding: { xs: "12px 20px", sm: 0 },
              }}
              onClick={() => {
                quizAttempt &&
                  currentSection &&
                  handleCompleteQuizAttemptNextSection(
                    quizAttempt.id,
                    currentSection.actualSection
                  );
                handleEditingPostClose();
              }}
            >
              <Typography
                sx={{
                  fontSize: { xs: "12px", sm: "16px" },
                  lineHeight: "1.2",
                  textTransform: "none",
                }}
              >
                {t(translations.quizAttemptPage.proceedNextSection)}
              </Typography>
            </Button>
          </Box>
        </StyledModal>
      }
      {
        <TimeIsUpModal
          open={timeIsUpMove}
          onClose={handleTimeIsUpModalClose}
          sections={quizAttempt?.quizRule?.sections}
          currentSection={currentSection}
          handleTimeIsUpModalClose={handleTimeIsUpModalClose}
          onCompleteQuizAttempt={onCompleteQuizAttempt}
          onBackdropClick={() => currentSection?.actualSection === undefined && onCompleteQuizAttempt()}
          title={`${t(translations.quizAttemptPage.totalTime)} ${
            getTimeInMin(currentSection?.actualSection === undefined
              ? quizAttempt?.quizRule?.sections.find(
                  (section) => section.id === lastElemSectionId
                )?.timeLimit
              : quizAttempt?.quizRule?.sections.find(
                  (section, index) =>
                    index === quizAttempt?.quizRule?.sections.indexOf(
                      quizAttempt?.quizRule?.sections.find(
                        (section) =>
                          section.id === currentSection?.actualSection
                      )
                    ) - 1 
                )?.timeLimit)
          } ${t(translations.quizAttemptPage.min)}`}
          disableEnforceFocus={true}
        >
        </TimeIsUpModal>
      }
    </DashboardLayout>
  );
}

export default QuizAttemptPage
