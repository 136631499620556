import { useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/i18n'
import config from 'utils/config'

interface UseFileUriResult {
  file: File | null
  change: (file: File | null) => void
  isLoading: boolean
  error: ProgressEvent<FileReader> | string | null
  fileUri: string | null
  fileName: string | null
}

interface UseFileUriOptions {
  maxFileSizeMb?: number
}

const useFileUri = ({maxFileSizeMb = config.image.maxFileSizeMb}: UseFileUriOptions = {}): UseFileUriResult => {
  const [file, setFile] = useState<File | null>(null)
  const [error, setError] = useState<ProgressEvent<FileReader> | string | null>(null)
  const [fileUri, setFileUri] = useState<string | null>(null)
  const [fileName, setFileName] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const {t} = useTranslation()

  const change = useCallback(
    (file: File | null) => {
      setError(null)
      setIsLoading(true)
      setFile(file)

      if (file) {
        const sizeMb = file.size * 0.000001
        if (sizeMb > maxFileSizeMb) {
          setError(t(translations.common.maxImageSizeForUpload, { maxFileSizeMb }))
          setFileUri(null)
          setIsLoading(false)
          return
        }
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = (event) => {
          setFileUri(`${event?.target?.result}`)
          setIsLoading(false)
          setFileName(file.name)
        }
        reader.onerror = err => {
          setError(err)
          setFileUri(null)
          setIsLoading(false)
          setFileName(null)
        }
      } else {
        setFileUri(null)
        setFileName(null)
        setIsLoading(false)
      }
    },
    [maxFileSizeMb, t],
  )

  return {file, change, error, fileUri, isLoading, fileName}
}

export default useFileUri
