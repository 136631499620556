import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import { lowerCase } from 'lodash'

import { PostInterface } from 'types/PostInterface'
import { DATE_TIME_FORMAT } from 'utils/constants'
import { getRoleBackground } from '../getRoleBackground'
import CommunityUserAvatar from '../../CommunityUserAvatar'
import { translations } from 'locales/i18n'

type PostCardHeaderDataProps = {
  post: PostInterface
  menuShown: boolean
}

const PostCardHeaderData: FC<PostCardHeaderDataProps> = ({post, menuShown}) => {
  const {t} = useTranslation()

  const {postAuthor, publishedAt, scheduledAt} = post
  const {name, mainRole, profileImageUri, deleted} = postAuthor
  const fullName = deleted ? t(translations.community.postCard.deletedUser) : name
  const [firstName, lastName] = fullName.split(' ')

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CommunityUserAvatar
        profileImageUri={deleted ? '' : profileImageUri}
        firstName={firstName}
        lastName={lastName}
        sx={{width: {xs: "45px", sm: "70px"}, height: {xs: "45px", sm: "70px"}}}
      />
      <Box
        sx={{
          ml: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              fontSize: {xs: '14px', sm: '20px'},
              lineHeight: {xs: '17px', sm: '24px'},
              letterSpacing: {xs: '0.35', sm: '0.5px'},
              width: {xs: menuShown ? 'min-content' : 'fit-content', md: 'fit-content'},
            }}
          >
            {fullName}
          </Box>
          <Box
            sx={{
              ml: '16px',
              px: '5px',
              minWidth: {xs: '62px', sm: '72px'},
              height: {xs: '17px', sm: '20px'},
              background: getRoleBackground(mainRole),
              borderRadius: '2px',
              fontSize: {xs: '10px', sm: '12px'},
              lineHeight: {xs: '12px', sm: '15px'},
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t(translations.common.roles[lowerCase(mainRole)])}
          </Box>
        </Box>
        <Box
          sx={{
            mt: {xs: '3px', sm: '5px'},
            fontSize: {xs: '10px', sm: '14px'},
            lineHeight: {xs: '12px', sm: '17px'},
            letterSpacing: {xs: '0.24', sm: '0.35px'},
            color: '#606060',
          }}
        >
          {dayjs(scheduledAt > 0 ? scheduledAt : publishedAt).format(DATE_TIME_FORMAT)}
        </Box>
      </Box>
    </Box>
  )
}
export default PostCardHeaderData
