import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, TextField } from '@mui/material'

import searchIcon from 'images/search-icon.svg'
import closeIcon from 'images/close-icon.svg'
import { translations } from 'locales/i18n'

type SearchInputProps = {
  value?: string
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
  onClear?: () => void
}

const SearchInput: FC<SearchInputProps> = ({ value, onChange, onClear }) => {
  const { t } = useTranslation()

  return (
    <Box className="u-position-relative" sx={{ width: { xs: '100%', md: '82%' }, mb: { xs: '0', md: '20px' } }}>
      <TextField
        placeholder={t(translations.common.search)}
        type="text"
        size="small"
        sx={{
          width: '100%',
          '& .MuiInputBase-input': {
            pl: '35px',
            pr: '35px',
            py: '12px',
            background: `url(${searchIcon}) no-repeat 10px 52%`,
            bgcolor: theme => theme.palette.background.paper,
          },
        }}
        value={value}
        onChange={onChange}
      />
      <Button
        className="u-p-0"
        style={{ top: '14px', right: '10px', minWidth: 'unset', position: 'absolute' }}
        onClick={onClear}
      >
        <img src={closeIcon} alt=""/>
      </Button>
    </Box>
  )
}

export default SearchInput
