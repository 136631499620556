import React, { FC } from 'react'
import { isEmpty } from 'lodash'

import UserAvatar from 'components/shared/user-avatar/user-avatar'
import config from 'utils/config'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { ImageSize } from 'types/ImageSize'

type CommunityUserAvatarProps = {
  profileImageUri: string
  firstName: string
  lastName: string
  sx?: SxProps<Theme>
}

const CommunityUserAvatar: FC<CommunityUserAvatarProps> = ({
                                                             profileImageUri,
                                                             firstName,
                                                             lastName,
                                                             sx,
                                                           }) => (
  <UserAvatar
    photo={isEmpty(profileImageUri) ? '' : `${config.cdnBaseApiURI}${profileImageUri}${ImageSize.PREVIEW}`}
    firstName={firstName}
    lastName={lastName}
    sx={{width: {xs: "44px", sm: "48px"}, height: {xs: "44px", sm: "48px"}, ...sx}}
  />
)

export default CommunityUserAvatar
