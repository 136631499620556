import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";

import SimulationCard from "components/quizzes/common/SimulationCard";
import Heading from "./Heading";
import { translations } from "locales/i18n";
import { PackSimulationQuizzesInterface, QuizInterface } from "types/PackSimulationQuizzesInterface";

type PackSimulationsProps = {
  simulations: PackSimulationQuizzesInterface,
  backgroundColor: string[]
  packSimulationId: string
};

const PackSimulations: FC<PackSimulationsProps> = ({
  simulations,
  backgroundColor,
  packSimulationId
}) => {

  const { t } = useTranslation();

  return (
    <Box sx={{ pb: { xs: "30px", md: "40px" } }}>
      <Heading content={simulations.name} />
      <Box>
        <Grid container spacing={{ xs: 1.5, md: 2 }}>
          {
            <>
              {simulations.quizzes.map((item, index) => {
                return (
                    <Grid item xs={12} sm={6} md={4} sx={{ height: "auto" }}>
                      <SimulationCard
                        title={item.name}
                        url={`/quizzes/pack-simulations/${packSimulationId}/quizzes/${item.id}/new`}
                        backgroundColor={backgroundColor[index % backgroundColor.length]}
                        questionsCount={item.questionCount}
                      />
                    </Grid>
                  )
              }) }
             
            </>
          }
        </Grid>
      </Box>
    </Box>
  );
};

export default PackSimulations;
