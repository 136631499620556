import { UseQueryOptions, UseQueryResult } from 'react-query'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { CoursesView } from 'types/CoursesView'
import { getCourses } from 'services/courseService'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { AxiosError } from 'axios'

const useCourses = (options?: UseQueryOptions<CoursesView, AxiosError>): UseQueryResult<CoursesView> => {
  return useQueryWithSnackbar<CoursesView>(
    [ReactQueryKeys.COURSES],
    () => getCourses(),
    options,
  )
}

export default useCourses
