import { UseQueryResult } from 'react-query'

import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getRecentPosts } from 'services/community/postService'
import { Posts } from 'types/Posts'

const useRecentPosts = (classId: string): UseQueryResult<Posts> =>
  useQueryWithSnackbar<Posts>(
    [ReactQueryKeys.RECENT_POSTS, classId],
    () => getRecentPosts(classId), {
      enabled: !!classId,
    },
  )

export default useRecentPosts
