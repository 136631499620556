import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, Grid } from '@mui/material'

import StyledModal from './StyledModal'
import { translations } from 'locales/i18n'

const buttonStyles = {
  textTransform: "none",
  width: '100%',
  height: '54px',
} as const

type LessonNoteResetProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const ModalLessonNoteResetConfirmation: FC<LessonNoteResetProps> = ({open, onClose, onConfirm, children}) => {
  const {t} = useTranslation()

  const handleConfirm = async () => {
    onConfirm()
    onClose()
  }

  return (
    <StyledModal
      title={t(translations.lessonPage.note.resetConfirmation.title)}
      submitTitle={t(translations.common.confirm)}
      onSubmit={handleConfirm}
      open={open}
      onClose={onClose}
      modalBoxSx={{width: {sm: '400px', md: '705px'}}}
    >
      <Box
        sx={{
          mt: '44px',
          px: '20px',
          fontSize: {xs: '12px', sm: '16px'},
          lineHeight: {xs: '18px', sm: '24px'},
        }}
      >
        {t(translations.lessonPage.note.resetConfirmation.text)}
      </Box>
      <Grid container spacing="18px" sx={{
        display: {xs: 'none', sm: 'flex'},
        mt: '30px',
      }}>
        <Grid item sm={6}>
          <Button
            variant="contained"
            sx={{...buttonStyles, backgroundColor: '#2D2E2F'}}
            onClick={onClose}
          >
            {t(translations.common.cancel)}
          </Button>
        </Grid>
        <Grid item sm={6}>
          <Button
            variant="contained"
            sx={{...buttonStyles}}
            onClick={handleConfirm}
          >
            {t(translations.lessonPage.note.resetConfirmation.confirmButton)}
          </Button>
        </Grid>
      </Grid>
    </StyledModal>
  )
}

export default ModalLessonNoteResetConfirmation
