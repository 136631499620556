import React, { FC, useEffect } from "react"
import { isEmpty } from 'lodash'

import useMe from 'hooks/useMe'
import useRefreshToken from 'hooks/useRefreshToken'
import { TOKEN_KEY } from 'utils/constants'
import parseJwt from 'utils/parseJwt'
import config from 'utils/config'

const WithRefreshToken: FC = ({children}) => {
  const {data: me} = useMe()
  const {mutate: refreshToken} = useRefreshToken()

  useEffect(() => {
    const token = localStorage.getItem(TOKEN_KEY)
    const parsed = parseJwt(token)
    const {version, exp} = parsed

    const isTokenExpired = exp < Date.now() / 1000
    const isWrongVersion = version?.toString() !== config.authVersion

    if (!isEmpty(me) && (isEmpty(token) || isTokenExpired || isWrongVersion)) {
      refreshToken()
    }
  }, [refreshToken, me])

  return <>{children}</>
}

export default WithRefreshToken
