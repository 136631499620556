import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getQuizAttempt } from 'services/quizAttemtService'
import { QuizAttemptInterface } from 'types/QuizAttemptInterface'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { useHistory } from 'react-router-dom'

const useQuizAttempt = (
  quizAttemptId: string,
  options?: UseQueryOptions<QuizAttemptInterface, AxiosError>,
): UseQueryResult<QuizAttemptInterface> => 
{
  const history = useHistory()
  return useQueryWithSnackbar<QuizAttemptInterface>(
    [ReactQueryKeys.QUIZ_ATTEMPT, quizAttemptId],
    () => getQuizAttempt(quizAttemptId),
    {
      
      enabled: !!quizAttemptId,
      async onError(error) {
        if(error.response?.data?.payload?.code === "QUIZ_ALREADY_COMPLETED")
        {
          await history.push(`/quiz-attempts/${quizAttemptId}/results`)
        }

      },
      ...options,
    },
    )
  }

export default useQuizAttempt
