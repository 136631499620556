import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Grid,
  Typography,
} from '@mui/material'
import { Formik } from 'formik'
import { isNil } from 'lodash'

import SelectDifficultyLevel from './SelectDifficultyLevel'
import QuizPractiseFormSchema from './QuizPractiseValidatorSchema'
import TopicsRadioGroup from './TopicsRadioGroup'
import { translations } from 'locales/i18n'

type PracticeQuizCreateProps = {
  subject: Subject
  topics: Topic[]
  preSelectedTopicId?: string
  handleSubmit: (data: PracticeQuizCreateRequest) => void
}

const topicIdFieldName = 'topicId'
const difficultyFieldName = 'difficulty'

const PracticeQuizCreate: FC<PracticeQuizCreateProps> = ({subject, topics, preSelectedTopicId, handleSubmit}) => {
  const {t} = useTranslation()

  const [selectedDifficulty, setDifficulty] = useState<number>()
  const [selectAllTopics, setSelectAllTopics] = useState<boolean>(false)

  const handleDifficultyChange = (value: number, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    setDifficulty(value)
    setFieldValue(difficultyFieldName, value)
  }

  const isValid = (values) =>
    (selectAllTopics || !isNil(values[topicIdFieldName]) || !isNil(preSelectedTopicId)) && !isNil(values[difficultyFieldName])

  return (
    <Formik
      validateOnBlur
      initialValues={{
        subjectId: subject.id,
        difficulty: selectedDifficulty,
        topicId: preSelectedTopicId,
      }}
      validationSchema={QuizPractiseFormSchema}
      onSubmit={handleSubmit}
    >
      {({handleSubmit, values, setFieldValue}) => (
        <form onSubmit={handleSubmit}>
          <Grid container className="u-mt-90 u-mb-50" sx={{pl: {xs: '20px', lg: '50px'}, pr: {xs: '20px', lg: '50px'}}}>
            <Grid item xs={12} lg={4}>
              <div>
                <Box
                  sx={{
                    letterSpacing: '2px',
                    fontSize: {xs: '10px', lg: '15px'},
                    lineHeight: {xs: '12px', lg: '18px'},
                    fontWeight: '600',
                  }}
                  className="u-color-sunglo">
                  QUIZ
                </Box>
                <Box
                  sx={{
                    fontSize: {xs: '18px', lg: '44px'},
                    lineHeight: {xs: '22px', lg: '53px'},
                    fontWeight: 'bold',
                    mt: '5px',
                  }}
                >
                  {subject?.name}
                </Box>
                <Typography sx={{
                  fontSize: {xs: '12px', lg: '16px'},
                  lineHeight: {xs: '21px', lg: '24px'},
                  pt: 1,
                }}>
                  {t(translations.subjectPractiseInitPage.selectSections)}<br/>
                  {t(translations.subjectPractiseInitPage.includesQuestions)}
                </Typography>
                {topics && <Grid sx={{ml: {lg: '-20px'}, mr: {lg: '80px'}}}>
                  <TopicsRadioGroup
                    topics={topics}
                    selectedTopicId={values.topicId}
                    selectAllTopics={selectAllTopics}
                    setSelectAllTopics={setSelectAllTopics}
                    fieldName={topicIdFieldName}
                  />
                </Grid>}
              </div>
            </Grid>
            <Grid item xs={12} lg={8} display='flex' justifyContent='center' className='u-mt-32'>
              <SelectDifficultyLevel
                selectedDifficulty={selectedDifficulty}
                setDifficulty={(value) => handleDifficultyChange(value, setFieldValue)}
                isValid={isValid(values)}
              />
            </Grid>
          </Grid>
        </form>
      )}
    </Formik>
  )
}


export default PracticeQuizCreate
