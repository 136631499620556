import React from 'react'
import { RouteComponentProps } from 'react-router'
import { translations } from '../locales/i18n'
import { useTranslation } from 'react-i18next'
import { Grid, Box, useMediaQuery, CircularProgress } from '@mui/material'
import { Back } from '../components/shared/back'
import { StreamTitle } from '../components/community/ui/StyledComponent'

import DashboardLayout from '../components/layouts/dashboard/DashboardLayout'
import StreamForm from '../components/community/streams/StreamForm'
import useTeachers from '../hooks/community/useTeachers'
import useStream from 'hooks/community/useStream'
import useUpdateStream from 'hooks/community/useUpdateStream'
import theme from 'theme'
import useAddFilesToStream from 'hooks/community/useAddFilesToStream'

const StreamCreatePage = (props: RouteComponentProps<{ classId: string, streamId: string }>) => {
  const { t } = useTranslation()
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

  const classId: string = props.match.params.classId || ''
  const streamId: string = props.match.params.streamId || ''
  const teachersQuery = useTeachers(classId)
  const streamQuery = useStream(classId, streamId)
  const stream = streamQuery.data

  const addFilesToStreamMutation = useAddFilesToStream()
  const updateStreamMutation = useUpdateStream()

  const handleAddFilesToStream = (data: AddFileToStreamRequest) => addFilesToStreamMutation.mutateAsync(data)

  const handleUpdateStream = (data: UpdateStreamRequest) => updateStreamMutation.mutateAsync(data)

  return (
    <>
      {isSm &&
        <DashboardLayout>
          <Back text={t(translations.navigation.eventGallery)} url={`/classes/${classId}/streams`}/>
          <Box sx={{
            marginY: '65px',
            pl: '54px',
            pr: '82px'
          }}>
            <StreamTitle sx={{ marginY: '42px' }}>{t(translations.streams.lifeStream)}</StreamTitle>
            {streamQuery.isLoading && <CircularProgress/>}
            {stream && (
              <StreamForm
                classId={classId}
                streamId={streamId}
                teachers={teachersQuery.data}
                stream={stream}
                submitTitle={t(translations.streams.saveStream)}
                onUpdateFiles={(data)=> handleAddFilesToStream(data)}
                onUpdateStream={handleUpdateStream}
              />
            )}
          </Box>
        </DashboardLayout>
      }

      {!isSm &&
        <Box>
          <Grid item xs={12}
            sx={{
              height: '58px',
              width: '100%',
              position: 'fixed',
              top: 0,
              zIndex: 100,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: theme.palette.background.paper,
              boxShadow: '0px 39px 34px rgb(0 0 0 / 45%)'
            }}
          >
            <StreamTitle sx={{mr: '20px'}}>{t(translations.streams.lifeStream)}</StreamTitle>
          </Grid>
          {streamQuery.isLoading && <CircularProgress />}
          {stream && (
            <Box sx={{mt: '57px'}}>
              <StreamForm
                classId={classId}
                streamId={streamId}
                stream={stream}
                teachers={teachersQuery.data}
                submitTitle={t(translations.streams.saveStream)}
                onUpdateFiles={handleAddFilesToStream}
                onUpdateStream={handleUpdateStream}
                sx={{ paddingX: '20px' }}
              />
            </Box>
          )}
        </Box>
      }
    </>
  )
}
export default StreamCreatePage
