import { UseQueryOptions, UseQueryResult } from 'react-query'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getSubjectsStatistics } from 'services/statisticsService'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { AxiosError } from 'axios'

const useSubjectStatistics = (options?: UseQueryOptions<SubjectStatistics[], AxiosError>): UseQueryResult<SubjectStatistics[]> => {
  return useQueryWithSnackbar<SubjectStatistics[]>(
    [ReactQueryKeys.SUBJECT_STATISTICS],
    () => getSubjectsStatistics(),
    options,
  )
}

export default useSubjectStatistics
