import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Collapse } from '@mui/material'

import { PostCommentInterface } from 'types/PostCommentInterface'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import PostCommentCardHeader from './PostCommentCardHeader'
import { UserProfileRole } from 'types/UserProfileRole'
import { translations } from 'locales/i18n'
import PostCommentForm from './comment-form/PostCommentForm'
import { PostCommentAuthor } from 'types/PostCommentAuthor'
import { MENTION_REGEXP } from 'utils/constants'

type PostCommentCardProps = {
  classId: string
  currentUser: StudentProfileInterface
  postId: string
  comment: PostCommentInterface
  isCommentFormSubmitting: boolean
  userToReply?: PostCommentAuthor
  showMenu?: boolean
  showReplies?: boolean
  onTypingActive: (status: boolean) => void
  onCommentReply?: (data: PostCommentRequest) => Promise<PostCommentResponse>
  onCommentUpdate: (data: PostCommentRequest) => Promise<PostCommentResponse>
  onCommentDelete: (data: DeletePostCommentRequest) => void
  onOpenCommentReport?: (data: PostCommentReport) => void
  inputBackground: string
}

const PostCommentCard: FC<PostCommentCardProps> = ({
                                                     classId,
                                                     currentUser,
                                                     postId,
                                                     comment,
                                                     isCommentFormSubmitting,
                                                     userToReply,
                                                     showMenu,
                                                     showReplies = true,
                                                     onTypingActive,
                                                     onCommentReply,
                                                     onCommentUpdate,
                                                     onCommentDelete,
                                                     onOpenCommentReport,
                                                     inputBackground,
                                                   }) => {
  const {t} = useTranslation()

  const {mainRole, profile} = currentUser
  const {firstName, lastName, profileImageUri} = profile
  const isAdmin = mainRole === UserProfileRole.ADMIN
  const isClassMember = currentUser.classes.includes(classId) || isAdmin

  const {id: commentId, parentCommentId, text, replies} = comment
  const textWithHighlightedUserMentions = text.replace(
    MENTION_REGEXP,
    (match) => `<mark style="background: transparent; color: #4780D8">${match}</mark>`,
  )

  const [otherCommentsOpen, setOtherCommentsOpen] = useState(false)
  const [replyOpen, setReplyOpen] = useState(false)
  const [editOpen, setEditOpen] = useState(false)

  const {length: repliesLength} = replies

  const newReply = {
    text: userToReply ? `@${userToReply.nickname}` : '',
    parentCommentId: userToReply ? parentCommentId : commentId,
    author: {
      firstName,
      lastName,
      mainRole,
      profileImageUri,
    } as PostCommentAuthor,
  } as PostCommentInterface

  return (
    <Box>
      <Collapse in={!editOpen}>
        <Box>
          <PostCommentCardHeader
            currentUser={currentUser}
            comment={comment}
            onStartEdit={() => setEditOpen(true)}
            onOpenCommentReport={() => onOpenCommentReport && onOpenCommentReport({postId, ...comment} as PostCommentReport)}
            onDelete={() => onCommentDelete({postId, commentId, parentCommentId} as DeletePostCommentRequest)}
            showMenu={showMenu}
          />
          <Box
            sx={{
              mt: {xs: '8px', sm: '12px'},
              ml: {sm: '58px'},
              fontSize: {xs: '12px', md: '16px'},
              lineHeight: { xs: '18px', md: '25px' },
              wordWrap: 'break-word',
              maxWidth: {md: '475px', xl: '550px'},
            }}
          >
            <Box dangerouslySetInnerHTML={{__html: `<div>${textWithHighlightedUserMentions}</div>`}}/>

            {onCommentReply && showReplies && (
              <Box
                sx={{
                  display: 'flex',
                  fontSize: {xs: '12px', sm: '14px'},
                  lineHeight: {xs: '15px', sm: '17px'},
                  mt: {xs: '19px', sm: '22px'},
                }}
              >
                {repliesLength > 0 && (
                  <Box
                    sx={{color: '#B5B5B5', mr: '23px', cursor: 'pointer'}}
                    onClick={() => setOtherCommentsOpen(!otherCommentsOpen)}
                  >
                    {otherCommentsOpen
                      ? t(translations.community.postCardComment.hideOtherComments)
                      : (repliesLength > 0 ? `${repliesLength} ${t(translations.community.feed.comments)}` : t(translations.community.feed.comment))
                    }
                  </Box>
                )}
                {isClassMember &&
                  <Collapse in={!replyOpen}>
                    <Box
                      sx={{opacity: '40%', cursor: 'pointer', wordWrap: 'break-word'}}
                      onClick={() => setReplyOpen(true)}
                    >
                      {t(translations.community.postCardComment.replyComment)}
                    </Box>
                </Collapse>
                }
              </Box>
            )}

            {(onCommentReply || repliesLength > 0) && showReplies && (
              <Box
                sx={{
                  mt: replyOpen || otherCommentsOpen ? {xs: '19px', sm: '34px'} : 0,
                  pl: userToReply ? 'unset' : '19px',
                  borderLeft: userToReply ? 'unset' : '2px solid rgba(255, 255, 255, .1)',
                }}
              >
                {onCommentReply && (
                  <Collapse in={replyOpen}>
                    <Box sx={{mb: otherCommentsOpen && repliesLength > 0 ? '20px' : 0}}>
                      <PostCommentForm
                        classId={classId}
                        currentUser={currentUser}
                        postId={postId}
                        comment={newReply}
                        isCommentFormSubmitting={isCommentFormSubmitting}
                        onTypingActive={onTypingActive}
                        onSubmit={onCommentReply}
                        onCancel={() => setReplyOpen(false)}
                        inputBackground={inputBackground}
                      />
                    </Box>
                  </Collapse>
                )}
                <Collapse in={otherCommentsOpen}>
                  {replies && replies.map((comment, index) => (
                    <Box key={comment.id} sx={{pt: index === 0 ? 0 : '20px'}}>
                      <PostCommentCard
                        classId={classId}
                        postId={postId}
                        comment={{...comment, parentCommentId: commentId}}
                        isCommentFormSubmitting={isCommentFormSubmitting}
                        currentUser={currentUser}
                        userToReply={comment.author}
                        onTypingActive={onTypingActive}
                        onCommentReply={onCommentReply}
                        onCommentUpdate={onCommentUpdate}
                        onCommentDelete={onCommentDelete}
                        onOpenCommentReport={onOpenCommentReport}
                        showMenu={showMenu}
                        inputBackground={inputBackground}
                      />
                    </Box>
                  ))}
                </Collapse>
              </Box>
            )}
          </Box>
        </Box>
      </Collapse>

      <Collapse in={editOpen}>
        <PostCommentForm
          classId={classId}
          currentUser={currentUser}
          postId={postId}
          comment={comment}
          isCommentFormSubmitting={isCommentFormSubmitting}
          onTypingActive={onTypingActive}
          onSubmit={onCommentUpdate}
          onCancel={() => setEditOpen(false)}
          inputBackground={inputBackground}
        />
      </Collapse>
    </Box>
  )
}
export default PostCommentCard
