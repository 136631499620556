import React, {FC} from 'react'
import {NavLink} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Box, Grid} from '@mui/material'

import SimulationFilter from './SimulationsFilter'
import descriptionIcon from 'images/description.svg'
import {RankingSummaryInterface} from 'types/RankingSummaryInterface'
import i18n from "i18next";
import {translations} from "../../locales/i18n";
import {SimulationQuizShortInfo} from 'types/SimulationQuizShortInfo'
import {isEmpty, isNull} from 'lodash'
import { Specialty } from 'types/Specialty'

type QuizzesOptionsType = {
    idx: number | null
    summary: { quizzes: SimulationQuizShortInfo[] }[]
}

type RankingNavigationProps = {
    hash: string
    summaryTab: RankingSummaryInterface;
    isRecordsHidden: boolean
    selectedSpecialtyIdx: number | null
    onSelectQuizId: (id: string) => void
    onSelectSpecialty: (idx: number) => void
};

const RankingNavigation: FC<RankingNavigationProps> = ({
                                                           hash,
                                                           summaryTab,
                                                           selectedSpecialtyIdx,
                                                           onSelectSpecialty,
                                                           onSelectQuizId,
                                                           isRecordsHidden
                                                       }) => {
    const {t} = useTranslation();

    const getTitle = (specialty) => {
        switch (specialty) {
          case Specialty.HEALTHCARE:
            return t(translations.quizzes.healthcareTests)
          case Specialty.VETERINARY:
            return t(translations.quizzes.veterinaryTests)
          default:
            return t(translations.quizzes.medicalTests)
        }
      }

    const previousYearsSpecialtyOptions = summaryTab.officialNationalQuizzesSummary
        .map((option, idx) => ({id: idx.toString(), name: getTitle(option.specialty)}))

    const packSimulationSpecialtyOptions = summaryTab.packSimulationQuizzesSummary.map((option, idx) => ({
        id: idx.toString(),
        name: option.title
    }))

    const getQuizzesOptions = ({
                                   idx,
                                   summary
                               }: QuizzesOptionsType) => !isNull(idx) && summary[idx]?.quizzes ? summary[idx].quizzes : []

    const navigationJSX = (
        <Box
            sx={{
                width: {xs: "140%", sm: "100%"},
                display: "flex",
                borderBottom: "1px solid #2B2A2A",
            }}
            mt={2}
        >
            <NavLink to="ranking">
                <Box
                    sx={{
                        borderBottom: hash === "" ? "1px solid #415BF0" : "",
                        color: "white",
                    }}
                    mr={4}
                >
                    {t(translations.home.monthlySimulation)}
                </Box>
            </NavLink>
            <NavLink to="#previous">
                <Box
                    sx={{
                        borderBottom: hash === "#previous" ? "1px solid #415BF0" : "",
                        color: "white",
                    }}
                    mr={4}
                >
                    {t(translations.monthlyRanking.previousYearsTesting)}
                </Box>
            </NavLink>
            {!isEmpty(summaryTab.packSimulationQuizzesSummary) && <NavLink to="#pack">
                <Box
                    sx={{
                        borderBottom: hash === "#pack" ? "1px solid #415BF0" : "",
                        color: "white",
                    }}
                >
                    {t(translations.monthlyRanking.packSimulation)}
                </Box>
            </NavLink>}
        </Box>
    );

    return (
        <>
            <Grid
                sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingX: {xs: "20px", sm: "12px"},
                }}
            >
                <Box
                    sx={{
                        fontSize: {xs: "18px", sm: "30px"},
                        lineHeight: {xs: "20px", sm: "38px"},
                        fontWeight: "600",
                    }}
                >
                    {t(translations.home.monthlyRanking)}
                </Box>
                <Box
                    mb={2}
                    sx={{
                        overflowX: {xs: "scroll", md: "unset"},
                        "::-webkit-scrollbar": {
                            display: "none",
                        },
                    }}
                >
                    {navigationJSX}
                </Box>
            </Grid>
            {hash === "#previous" &&
              <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: isRecordsHidden ? 'space-between' : 'flex-end',
                    px: '20px'
                }}
              >
                  {isRecordsHidden && <Grid item md={5} xl={3}>
                  <Box mt={2} sx={{
                      borderRadius: '3px',
                      border: '1px solid #715205',
                      padding: '20px 10px',
                      display: {xs: 'none', md: 'flex'},
                      alignItems: 'center',
                      marginLeft: '-17px',
                      width: '99%'
                  }}>
                    <Box mr={1}>
                      <img
                        src={descriptionIcon}
                        alt="Description"
                      />
                    </Box>
                    <Box sx={{fontSize: '16px'}}>
                    {t(translations.monthlyRanking.ratingTypeTest)}
                    </Box>
                  </Box>
                </Grid>}
                <Grid item xs={12} sm={6} lg={4}>
                  <SimulationFilter
                    defaultLabel={t(translations.monthlyRanking.selectCategoryTest)}
                    options={previousYearsSpecialtyOptions}
                    onChange={(val) => onSelectSpecialty(Number(val))}
                  />
                  <SimulationFilter
                    isUpdatedLabel
                    defaultLabel={t(translations.monthlyRanking.selectTypeTest)}
                    options={getQuizzesOptions({
                        idx: selectedSpecialtyIdx,
                        summary: summaryTab.officialNationalQuizzesSummary
                    })}
                    onChange={onSelectQuizId}
                  />
                </Grid>
              </Grid>
            }
            {hash === "#pack" &&
              <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: isRecordsHidden ? 'space-between' : 'flex-end',
                    px: '20px'
                }}
              >
                  {isRecordsHidden && <Grid item md={5} xl={3}>
                  <Box mt={2} sx={{
                      borderRadius: '3px',
                      border: '1px solid #715205',
                      padding: '20px 10px',
                      display: {xs: 'none', md: 'flex'},
                      alignItems: 'center',
                      marginLeft: '-17px',
                      width: '99%'
                  }}>
                    <Box mr={1}>
                      <img
                        src={descriptionIcon}
                        alt="Description"
                      />
                    </Box>
                    <Box sx={{fontSize: '16px'}}>
                    {t(translations.monthlyRanking.ratingPackSimulation)}
                    </Box>
                  </Box>
                </Grid>}
                <Grid item xs={12} sm={6} lg={4}>
                  <SimulationFilter
                    defaultLabel={t(translations.monthlyRanking.selectPackSimulation)}
                    options={packSimulationSpecialtyOptions}
                    onChange={(val) => onSelectSpecialty(Number(val))}
                  />
                  <SimulationFilter
                    isUpdatedLabel
                    defaultLabel={t(translations.monthlyRanking.selectTheQuiz)}
                    options={getQuizzesOptions({
                        idx: selectedSpecialtyIdx,
                        summary: summaryTab.packSimulationQuizzesSummary
                    })}
                    onChange={onSelectQuizId}
                  />
                </Grid>
              </Grid>
            }
        </>
    );
};

export default RankingNavigation;
