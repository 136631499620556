import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

import CoursesList from './CoursesList'
import { CourseSearchView } from 'types/CourseSearchView'
import { EnrolledCourseState } from 'types/EnrolledCourseState'
import { translations } from 'locales/i18n'

type CoursesInProgressProps = {
  courses: CourseSearchView[]
}

const CoursesInProgress: FC<CoursesInProgressProps> = ({ courses }) => {
  const { t } = useTranslation()

  const inProgressCourses = courses
    .filter(({ courseState }) => courseState === EnrolledCourseState.IN_PROGRESS)

  return (
    <Box
      sx={{
        mt: { xs: '36px', md: '55px' },
        mx: { xs: '20px', sm: 6.5 },
        mb: { xs: '20px', sm: 6.5 },
      }}
    >
      <CoursesList
        courses={inProgressCourses}
        showOnlyUnopenedToggle={false}
        title={t(translations.courses.inProgressTitle)}
      />
    </Box>
  )
}

export default CoursesInProgress
