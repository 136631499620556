import React, { FC } from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import DifficultyLevelCard from './DifficultyLevelCard'
import { translations } from 'locales/i18n'
import difficultyLowLevel from 'images/difficulty-low-level.svg'
import difficultyMediumLevel from 'images/difficulty-medium-level.svg'
import difficultyHighLevel from 'images/difficulty-high-level.svg'
import difficultyLowLevelSelected from 'images/difficulty-low-level-selected.svg'
import difficultyMediumLevelSelected from 'images/difficulty-medium-level-selected.svg'
import difficultyHighLevelSelected from 'images/difficulty-high-level-selected.svg'

type DifficultyLevelCardsProps = {
  selectedDifficulty?: number,
  setDifficulty: (id: number) => void
}

const DifficultyLevelCards: FC<DifficultyLevelCardsProps> = ({selectedDifficulty, setDifficulty}) => {
  const {t} = useTranslation()

  const difficultyLevelCardsData = [
    {
      id: 1,
      name: t(translations.subjectPractiseInitPage.simpleLevel),
      description: t(translations.subjectPractiseInitPage.simpleLevelDesc),
      img: difficultyLowLevel,
      imgSelected: difficultyLowLevelSelected,
    },
    {
      id: 2,
      name: t(translations.subjectPractiseInitPage.mediumLevel),
      description: t(translations.subjectPractiseInitPage.mediumLevelDesc),
      img: difficultyMediumLevel,
      imgSelected: difficultyMediumLevelSelected,
    },
    {
      id: 3,
      name: t(translations.subjectPractiseInitPage.highLevel),
      description: t(translations.subjectPractiseInitPage.highLevelDesc),
      img: difficultyHighLevel,
      imgSelected: difficultyHighLevelSelected,
    },
  ]

  return (
    <Grid container spacing={2}>
      {
        difficultyLevelCardsData.map(({id, name, description, img, imgSelected}) => (
            <Grid item xs={12} lg={4} key={id}>
              <DifficultyLevelCard
                id={id}
                name={name}
                description={description}
                img={selectedDifficulty === id ? imgSelected : img}
                selectedDifficulty={selectedDifficulty}
                setDifficulty={setDifficulty}
              />
            </Grid>
          ),
        )
      }
    </Grid>
  )
}

export default DifficultyLevelCards
