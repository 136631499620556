import { reportLesson } from 'services/lessonService'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { translations } from 'locales/i18n'

const useLessonReport = () => useMutationWithSnackbar(
  (data: ReportLessonRequest) => reportLesson(data),
  undefined,
  translations.lessonPage.reports.sendReport,
)

export default useLessonReport
