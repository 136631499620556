import React, { FC } from 'react'
import { Formik } from 'formik'

import { NoteEditorFormSchema } from './note-editor/NoteEditorFormValidatorSchema'
import FormEditor from 'components/ui/form/form-editor/form-editor'
import { NOTE_EDITOR_CONTENT_CSS, NOTE_EDITOR_SKIN_URL } from 'theme/constants'

type NoteEditorFormProps = {
  height: number | string
  noteTextUpdatedAt?: number
  noteText?: string
  onSubmit: (noteText: string) => void
}

const NoteEditorForm: FC<NoteEditorFormProps> = ({height, noteTextUpdatedAt, noteText, onSubmit}) => {

  return (
    <Formik
      validateOnBlur
      validateOnChange
      enableReinitialize
      initialValues={{
        noteText: noteText,
      }}
      validationSchema={NoteEditorFormSchema}
      onSubmit={({noteText}) => {
        if (noteText) {
          onSubmit(noteText)
        }
      }}
    >
      {(props) => (
        <form onSubmit={props.handleSubmit}>
          <FormEditor
            key={noteTextUpdatedAt}
            height={height}
            className="LessonEditor"
            name="noteText"
            autoSave
            skinUrl={NOTE_EDITOR_SKIN_URL}
            contentCss={NOTE_EDITOR_CONTENT_CSS}
          />
        </form>
      )}
    </Formik>
  )
}

export default NoteEditorForm
