import i18n from 'i18next'

import { QuizType } from 'types/QuizType'
import { translations } from 'locales/i18n'

const getQuizTypeTitle = (quizType: QuizType, subjectName: string = '', groupName: string = '') => {
  switch (quizType) {
    case QuizType.MONTHLY_SIMULATION:
      return i18n.t(translations.quizTypes.freeMonthlySimulation)
    case QuizType.RANDOM_SIMULATION:
      return i18n.t(translations.quizTypes.randomSimulation)
    case QuizType.OFFICIAL_NATIONAL_SIMULATION:
      return i18n.t(translations.quizTypes.officialNationalSimulation)
    case QuizType.PRACTICE:
      return i18n.t(translations.quizTypes.practice)
    case QuizType.LESSON:
      return i18n.t(translations.quizTypes.lesson)
    case QuizType.STATIC_PRACTICE:
      return groupName
    case QuizType.AREA_MONTHLY_PRACTICE:
      return `${i18n.t(translations.quizTypes.freeMonthlySimulation)}: ${subjectName}`
    case QuizType.AREA_PRACTICE:
      return subjectName
    default:
      return i18n.t(translations.quizzes.simulations)
  }
}

export default getQuizTypeTitle
