import React, { FC, memo, useEffect, useState } from 'react'
import { Field, FieldProps, useFormikContext } from 'formik'
import cn from 'classnames'
import { FormLabel } from '@material-ui/core'
import { Editor } from '@tinymce/tinymce-react'
import { IAllProps } from '@tinymce/tinymce-react/lib/cjs/main/ts/components/Editor'
import { IProps } from '@tinymce/tinymce-react/lib/es2015/main/ts/components/Editor'

import config from 'utils/config'
import ErrorMessage from 'components/ui/form/error-message/error-message'
import FormEditorSkeleton from './components/form-editor-skeleton/form-editor-skeleton'
import styles from './form-editor-mobile.module.scss'
import setFieldValueDebounced from '../utils/setFieldValueDebounced'
import { DEBOUNCE_AUTOSAVE_MILLI } from 'utils/constants'

interface FormEditorProps extends IAllProps {
  name: string
  label?: string
  height?: number | string
  placeholder?: string
  autoSave?: boolean
  className?: string
  plugins?: string
  toolbar?: string
  skinUrl?: string
  contentCss?: string
}

const FormEditor: FC<FormEditorProps> = ({
                                           name,
                                           label = '',
                                           height = 500,
                                           placeholder = '',
                                           autoSave = false,
                                           className = '',
                                           plugins,
                                           toolbar,
                                           skinUrl,
                                           contentCss,
                                           ...restProps
                                         }) => {
  const [loading, setLoading] = useState(true)
  const {submitForm, values, setFieldValue} = useFormikContext<any>()

  useEffect(() => {
    if (!autoSave) return

    const handler = setTimeout(submitForm, DEBOUNCE_AUTOSAVE_MILLI)
    return () => clearTimeout(handler)
  }, [autoSave, values[name], submitForm])


  const handleEditorChange = (
    value: string,
  ) => setFieldValueDebounced({name, value, setFieldValue})

  return (
    <Field
      name={name}
      id={name}>
      {({field, form: {touched, errors, setFieldTouched, initialValues}}: FieldProps) => {
        const isError = !!(touched[field.name] && errors[field.name])

        return (
          <div className={styles.container}>

            {label && (
              <FormLabel className={cn(styles.label, isError && styles.error)}>
                {label}
              </FormLabel>
            )}

            {loading && <FormEditorSkeleton height={height}/>}

            <div className={cn(loading && styles.hide, !loading && styles.show, className)} style={{height}}>
              <Editor
                initialValue={initialValues[field.name]}
                apiKey={config.tinyMceApiKey}
                textareaName={name}
                onBlur={() => setFieldTouched(name, true)}
                onEditorChange={(content) => handleEditorChange(content)}
                onInit={() => setLoading(false)}
                init={{
                  placeholder,
                  height: '100%',
                  resize: false,
                  menubar: false,
                  statusbar: false,
                  icons_url: '/js/promedtest/icons.js',
                  icons: 'promedtest',
                  skin_url: skinUrl || '/styles/skins/ui/promed-dark',
                  content_css: contentCss || '/styles/skins/content/promed-dark/content.css',
                  plugins: plugins || 'lists paste',
                  toolbar: toolbar || 'formatselect fontsizeselect | align | bold italic underline strikethrough | forecolor | undo redo',
                  fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 18pt 24pt 30pt 36pt 48pt 60pt 72pt 96pt',
                  default_link_target: '_blank',
                  convert_urls: false,
                } as IProps['init']}
                {...restProps}
              />
            </div>

            <ErrorMessage name={name}/>
          </div>
        )
      }}
    </Field>
  )
}

export default memo(FormEditor)
