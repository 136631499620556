
import { Grid } from "@mui/material"
import { FC } from "react"
import { QuizAttemptStatisticsInterface } from "types/QuizAttemptStatisticsInterface"
import { QuizType } from "types/QuizType"


type PracticeQuizTitle = {
  statistics: QuizAttemptStatisticsInterface
  width?: string
}

const TitilePracticeQuiz: FC<PracticeQuizTitle> = ({ statistics, width }) => {

  return (
  <>
    {statistics.quizType === QuizType.PRACTICE && (statistics.quizAttemptDetails.topic?.name ?
      <Grid container item justifyContent="center" textAlign="center" alignItems="center" width={width} className="u-mb-15 u-fs-34 u-lh-41">
        <span>
          <span className='u-weight-bold'>{`${statistics.quizAttemptDetails.subject?.name}`}</span>
          : <span>{`${statistics.quizAttemptDetails.topic?.name}`}</span>
        </span>
      </Grid>
      :
      <Grid container item justifyContent="center" textAlign="center" alignItems="center" className="u-mb-15 u-fs-34 u-lh-41">
        <span className='u-weight-bold'>{`${statistics.quizAttemptDetails.subject?.name}`}</span>
      </Grid>)
      
    }
  </>
  )
}



export default TitilePracticeQuiz
