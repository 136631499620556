import i18n from 'i18next'

import { translations } from 'locales/i18n'
import { MONTHLY_SIMULATION_QUESTIONS_COUNT, RANDOM_SIMULATION_QUESTIONS_COUNT } from 'theme/constants'

export const randomSimulation = {
  title: i18n.t(translations.quizzes.paidRandomSimulation),
  backgroundColor: '#9B5DE9',
  url: '/quizzes/random/new',
  questionsCount: RANDOM_SIMULATION_QUESTIONS_COUNT,
} as SimulationConfig

export const monthlySimulation = {
  title: i18n.t(translations.quizzes.freeMonthlySimulation),
  backgroundColor: 'linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,45,231,0.7595413165266106) 100%)',
  url: '/quizzes/monthly/new',
  questionsCount: MONTHLY_SIMULATION_QUESTIONS_COUNT,
} as SimulationConfig
