import { object, string, number, mixed, lazy } from 'yup'
import i18n from 'i18next'
import { translations } from 'locales/i18n'
import config from 'utils/config'
import { IMAGE_SUPPORTED_FORMATS } from 'utils/constants'

const StreamFormSchema = object().shape({
  name: string().required(i18n.t(translations.validatorSchema.common.mixed.required)),
  date: number().required(i18n.t(translations.validatorSchema.common.mixed.required)),
  previewImage: lazy((value: object | string) => {

    switch (typeof value) {
      case 'object':
        return mixed()
          .required(i18n.t(translations.validatorSchema.common.mixed.required))
          .test(
            'size',
            i18n.t(translations.validatorSchema.updateProfile.profileImage.test.fileSize),
            value => !value || value && value.size <= config.image.maxFileSizeMb * 1024 * 1024,
          )
          .test(
            'type',
            i18n.t(translations.validatorSchema.updateProfile.profileImage.test.fileFormat),
            value => !value || value && IMAGE_SUPPORTED_FORMATS.includes(value.type),
          );
      case 'string': 
        return string().required(i18n.t(translations.validatorSchema.common.mixed.required))
      }
    }),
  speakerId: string().required(i18n.t(translations.validatorSchema.common.mixed.required)),
  videoUri: string().required(),
  description: string(),
})

export default StreamFormSchema
