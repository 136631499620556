import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import { Formik } from 'formik'
import { isEmpty } from 'lodash'

import { translations } from 'locales/i18n'
import ProfileNavigation from '../ProfileNavigation'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { NotificationSettingView } from 'types/NotificationSettingView'
import { UpdateNotificationSettingRequest } from 'types/requests/UpdateNotificationSettingRequest'
import { NotificationType } from 'types/NotificationType'
import FormSwitch from 'components/ui/form/form-switch/FormSwitch'

type ProfileNotificationPreferencesProps = {
  currentUser: StudentProfileInterface
  notificationSetting: NotificationSettingView[]
  onSubmit: (data: UpdateNotificationSettingRequest) => void
}

const ProfileNotificationPreferences: FC<ProfileNotificationPreferencesProps> = ({
                                                                                   currentUser,
                                                                                   notificationSetting,
                                                                                   onSubmit,
                                                                                 }) => {
  const { t } = useTranslation()

  const getConfig = ({notificationType}: NotificationSettingView) => {
    switch(notificationType) {
      case NotificationType.MENTIONED_IN_POST_COMMENT:
        return t(translations.profileNotificationPage.userMentioned)
      case NotificationType.POST_COMMENT_ADDED:
        return t(translations.profileNotificationPage.userReplies)
      case NotificationType.MONTHLY_SIMULATION_UPDATED:
        return t(translations.notifications.monthlySimulationUpdated)
      default: 
        return ''
    }
  }

  const handleChange = (notificationType: NotificationType, active: boolean) => onSubmit({switchers: [{ notificationType, active }]})

  return (
    <Box
      sx={{
        ml: { xs: '20px', md: '56px' },
        mr: { xs: '20px', md: '0' },
        mt: { xs: '32px', md: '42px' },
        mb: '20px',
      }}
    >
      <Typography
        variant={'h4'}
        sx={{
          fontSize: { xs: '18px', md: '34px' },
          lineHeight: { xs: '20px', md: '41px' },
          fontWeight: { xs: '600', md: 'normal' },
          textTransform: 'none',
        }}
      >
        {t(translations.profilePage.title)}
      </Typography>
      <Grid container sx={{ mt: { xs: '21px', md: '44px' } }}>
        <Grid item xs={12} md={3} sx={{ mb: { xs: 2 } }}>
          <ProfileNavigation/>
        </Grid>
        <Grid item xs={12} md={9} sx={{ width: '100%' }}>
          {!isEmpty(notificationSetting) && <Formik
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={{switchers: notificationSetting}}
            onSubmit={onSubmit}
          >
            {({ handleSubmit, values }) => (
              <form
                style={{ display: 'flex', flexDirection: 'column' }}
                onSubmit={handleSubmit}
              >
                <Box sx={{ justifyContent: { xs: 'center' } }}>
                  <Box
                    sx={{
                      width: { md: '501px', lg: '601px' },
                      background: theme => theme.palette.background.paper,
                      borderRadius: '3px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center' },
                      m: { xs: 'auto', md: 'unset' },
                      padding: {xs: '20px', md: '30px 25px 50px 50px'},
                    }}
                  >
                    <Box sx={{ width: '100%'}}>
                      <Typography
                        sx={{
                          display: 'flex',
                          fontSize: { xs: '18px', sm: '24px' },
                          lineHeight: { xs: '20px', sm: '29px' },
                          fontWeight: { xs: '600', sm: 'normal' },
                        }}
                      >
                        {t(translations.profilePage.notifPreferences)}
                      </Typography>
                      <Typography
                        sx={{
                          mt: { xs: '5px', sm: '9px' },
                          fontSize: { xs: '10px', sm: '14px' },
                          lineHeight: { xs: '12px', sm: '17px' },
                          letterSpacing: { xs: '0.24', sm: '0.35px' },
                          color: '#606060',
                        }}
                      >
                        {t(translations.profileNotificationPage.chooseNotific)}
                      </Typography>
                    </Box>
                    {notificationSetting.map((settingOption, index) => (
                      <Box
                        key={`${settingOption}${index}`}
                        sx={{
                          mt: '30px',
                          width: '100%',
                          position: 'relative'
                        }}>
                        <FormSwitch
                          name={`switchers[${index}].active`}
                          label={{ text: getConfig(settingOption), position: 'start' }}
                          value={values.switchers[index].active}
                          onChange={(checked) => handleChange(settingOption.notificationType, checked)}
                          sxLable={{ width: '100%', justifyContent: 'space-between', display: 'flex', ml: '0px' }}
                          sxSwitch={{ ml: {xs: '20px', md: '40px'} }}
                        />
                      </Box>
                    ))
                    }
                  </Box>
                </Box>
              </form>
            )}
          </Formik>}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProfileNotificationPreferences
