import React, { useState } from 'react'
import { useQueryClient } from 'react-query'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import Lesson from 'components/lesson/Lesson'
import useCourse from 'hooks/useCourse'
import useLesson from 'hooks/useLesson'
import LoadingIndicator from 'components/shared/loading-indicator'
import useStartLessonQuiz from 'hooks/useStartLessonQuiz'
import useUpdateLessonVideoWatchedSeconds from 'hooks/useUpdateLessonVideoWatchedSeconds'
import useUpdateLessonNoteText from 'hooks/useUpdateLessonNoteText'
import useCompleteLesson from 'hooks/useCompleteLesson'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useLessonReport from 'hooks/useLessonReport'
import useResetLessonNoteText from "../hooks/useResetLessonNoteText"
import ModalLessonNoteResetConfirmation from "../components/shared/modal/ModalLessonNoteResetConfirmation"

const LessonPage = (
  props: RouteComponentProps<{ courseId?: string, lessonId?: string }>,
) => {
  const history = useHistory()
  const queryClient = useQueryClient()

  const courseId: string = props.match.params.courseId || ''
  const lessonId: string = props.match.params.lessonId || ''

  const courseQuery = useCourse(courseId)
  const course = courseQuery.data

  const lessonQuery = useLesson(courseId, lessonId)
  const lesson = lessonQuery.data

  if (isEmpty(courseId) || isEmpty(lessonId)) {
    history.push('/')
  }

  const [resettingLessonNoteOpen, setResettingLessonNoteOpen] = useState(false)
  const handleResettingLessonNoteOpen = () => setResettingLessonNoteOpen(true)
  const handleResettingLessonNoteClose = () => setResettingLessonNoteOpen(false)

  const startLessonQuizMutation = useStartLessonQuiz()
  const handleStartQuiz = () => startLessonQuizMutation.mutate({courseId, lessonId} as LessonQuizCreateRequest)

  const updateLessonVideoWatchedSecondsMutation = useUpdateLessonVideoWatchedSeconds()
  const handleUpdateLessonVideoWatchedSeconds = (currentVideoTime: number) =>
    updateLessonVideoWatchedSecondsMutation.mutate({
      courseId,
      lessonId,
      seconds: currentVideoTime,
    } as LessonVideoWatchedSecondsUpdateRequest)

  const updateLessonNoteTextMutation = useUpdateLessonNoteText()
  const handleUpdateLessonNoteText = (noteText: string) => {

    if(noteText !== lesson?.noteText) {
      updateLessonNoteTextMutation.mutateAsync({
            courseId,
            lessonId,
            noteText,
          } as LessonNoteTextUpdateRequest)
    } 
  }
    

  const resetLessonNoteTextMutation = useResetLessonNoteText(courseId, lessonId)
  const handleResetLessonNoteText = () =>
    resetLessonNoteTextMutation.mutate({
      courseId,
      lessonId,
    } as LessonNoteTextResetRequest)

  const completeLessonMutation = useCompleteLesson()
  const handleCompleteLesson = () => completeLessonMutation.mutate({courseId, lessonId} as CourseLessonRequest)

  const handleInvalidateLesson = () =>
    queryClient.invalidateQueries([ReactQueryKeys.LESSON, courseId, lessonId])

  const createLessonReport = useLessonReport()
  const handleCreateReport = (lessonReport: string) =>
    createLessonReport.mutateAsync({
      courseId,
      lessonId,
      reportText: lessonReport
      } as ReportLessonRequest)

  const handleLessonReport = (lessonReport) => handleCreateReport(lessonReport)

  return (
    <DashboardLayout>
      {course && lesson && (
        <>
          <Lesson
            course={course}
            lesson={lesson}
            onStartQuiz={handleStartQuiz}
            onUpdateLessonVideoWatchedSeconds={handleUpdateLessonVideoWatchedSeconds}
            onNoteTextReset={handleResettingLessonNoteOpen}
            onNoteTextSubmit={handleUpdateLessonNoteText}
            onInvalidateLesson={handleInvalidateLesson}
            onCompleteLesson={handleCompleteLesson}
            onSendReport={handleLessonReport}
          />
          <ModalLessonNoteResetConfirmation
            open={resettingLessonNoteOpen}
            onClose={handleResettingLessonNoteClose}
            onConfirm={handleResetLessonNoteText}
          />
        </>
      )}
      {(courseQuery.isLoading || lessonQuery.isLoading) && <LoadingIndicator/>}
    </DashboardLayout>
  )
}

export default LessonPage
