import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import dayjs from 'dayjs'

import UserAvatar from 'components/shared/user-avatar/user-avatar'
import CommunityUserAvatar from 'components/community/CommunityUserAvatar'
import CommunityMenu from 'components/community/CommunityMenu'
import markAsReadIcon from 'images/mark-as-read.svg'
import deleteIcon from 'images/delete.svg'
import { translations } from 'locales/i18n'
import { getDurationMilli } from 'utils/duration'
import { DAY_MILLI, HOUR_MILLI, MINUTE_MILLI } from 'utils/constants'
import { NotificationInterface } from 'types/NotificationInterface'
import { NotificationType } from 'types/NotificationType'

type NotificationProps = {
  notification: NotificationInterface
  showMenu?: boolean
  showHowLongAgo?: boolean
  onMarkAsRead?: () => void
  onDelete?: () => void
}

const Notification: FC<NotificationProps> = ({
                                               notification,
                                               showMenu = false,
                                               showHowLongAgo = false,
                                               onMarkAsRead,
                                               onDelete,
                                             }) => {
  const { t } = useTranslation()
  
  const {
    notificationId,
    type,
    message,
    read,
    createdAt,
    commentOwnerProfileImage,
    commentOwnerNickname,
    commentOwnerDeleted
  } = notification

  const nickname = commentOwnerDeleted ? t(translations.community.postCard.deletedUser) : commentOwnerNickname

  const getDurationFormatted = () => {
    const duration = getDurationMilli(createdAt)
    const utcDuration = dayjs.utc(duration)
    if (duration > DAY_MILLI) {
      return `${utcDuration.format('D')}${t(translations.time.short.days)}`
    } else if (duration > HOUR_MILLI) {
      return `${utcDuration.format('H')}${t(translations.time.short.hours)}`
    } else if (duration > MINUTE_MILLI) {
      return `${utcDuration.format('m')}${t(translations.time.short.minutes)}`
    } else {
      return `${utcDuration.format('s')}${t(translations.time.short.seconds)}`
    }
  }

  const getNotificationTitle = () => {
    switch (type) {
      case NotificationType.MENTIONED_IN_POST_COMMENT:
        return t(translations.notifications.mentionedYouInComment)
      case NotificationType.POST_COMMENT_ADDED:
        return t(translations.notifications.addedCommentToYourPost)
      case NotificationType.MONTHLY_SIMULATION_UPDATED:
        return t(translations.notifications.monthlySimulationUpdated)
    }
  }

  const menuItems = [
    !read && {
      icon: markAsReadIcon,
      title: t(translations.notifications.markAsRead),
      onClick: onMarkAsRead,
    } as MenuItem,
    {
      icon: deleteIcon,
      title: t(translations.notifications.deleteNotification),
      onClick: onDelete,
    } as MenuItem,
  ]

  const isMonthlySimulationNotification = type === NotificationType.MONTHLY_SIMULATION_UPDATED

  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {isMonthlySimulationNotification ?
            <UserAvatar
              photo={`/images/icons/community/simul_notific.svg`}
              sx={{ width: { xs: '35px', sm: '50px' }, height: { xs: '35px', sm: '50px' }, backgroundColor: 'white', borderRadius: '50%' }}
            ></UserAvatar> :
            <CommunityUserAvatar
              profileImageUri={commentOwnerDeleted ? '' : commentOwnerProfileImage}
              firstName={nickname}
              lastName=''
              sx={{ width: { xs: '35px', sm: '50px' }, height: { xs: '35px', sm: '50px' } }}
            />}

          <Box
            sx={{
              ml: '10px',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {isMonthlySimulationNotification ?
              <Box
                sx={{
                  width: '68%'
                }} >

                <Box
                  sx={{
                    fontSize: { xs: '14px', sm: '18px' },
                    lineHeight: { xs: '17px', sm: '22px' },
                    width: { xs: 'min-content', md: 'fit-content' },
                  }}
                >
                  {t(translations.notifications.monthlySimulation)}
                </Box>

                <Box
                  sx={{
                    fontSize: { xs: '12px', md: '16px' },
                    lineHeight: { xs: '15px', md: '19px' },
                    color: '#AAAAAA',
                  }}
                >
                  {`${getNotificationTitle()}:`}
                </Box>
              </Box> :
              <Box>
                <Box
                  sx={{
                    fontSize: { xs: '14px', sm: '18px' },
                    lineHeight: { xs: '17px', sm: '22px' },
                    width: { xs: 'min-content', md: 'fit-content' },
                  }}
                >
                  {nickname}
                </Box>
                <Box
                  sx={{
                    fontSize: { xs: '12px', md: '16px' },
                    lineHeight: { xs: '15px', md: '19px' },
                    color: '#AAAAAA',
                  }}
                >
                  {`${getNotificationTitle()}:`}
                </Box>
              </Box>
            }

          </Box>
        </Box>
        {showMenu && <CommunityMenu menuItems={menuItems} keyPrefix={notificationId} />}
      </Box>
      <Link to={notification.type !== NotificationType.MONTHLY_SIMULATION_UPDATED ? `${`/classes/${notification.classId}/posts/${notification.postId}`}` : '#'}>
        <Box
          sx={{
            mt: { xs: '8px', sm: '6px' },
            ml: { sm: '60px' },
            fontSize: { xs: '12px', md: '16px' },
            lineHeight: { xs: '15px', md: '19px' },
            color: '#4780D8',
            width: { xs: 'calc(100% - 30px)', sm: 'calc(100% - 90px)' },
            wordWrap: 'break-word'
          }}
        >
          {message}
        </Box>
      </Link>
      {showHowLongAgo && (
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              position: 'absolute',
              right: 0,
              bottom: 0,
              color: '#C5C5C5',
            }}
          >
            {getDurationFormatted()}
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Notification
