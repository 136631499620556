import React, { FC } from 'react'
import { IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material'

import { PostFile } from 'types/PostFile'
import { PostFileType } from 'types/PostFileType'
import deleteFileIcon from 'images/delete-file.svg'
import { getFileAvatar } from '../getFileAvatar'

type PostFormFileListProps = {
  files: PostFile[]
  onRemoveFile: (fileId: string) => void
}

const PostFormFileList: FC<PostFormFileListProps> = ({files, onRemoveFile}) => (
  <List dense={true}>
    {files.filter(({fileType, deleted}) => !deleted && fileType === PostFileType.FILE)
      .map(({fileId, fileUri, fileSize, originalFileName, fileExtension}, index) => (
        <ListItem
          sx={{
            background: '#2D2E2F',
            borderRadius: '3px',
            mt: index === 0 ? {xs: '6px', sm: '8px'} : {xs: '10px', sm: '12px'},
            py: {xs: '2px', sm: '4px'},
          }}
          key={fileId}
          secondaryAction={
            <IconButton edge="end" aria-label="Delete file">
              <img
                src={deleteFileIcon}
                alt="Delete file"
                className="u-cursor-pointer"
                onClick={() => onRemoveFile(fileId)}
              />
            </IconButton>
          }
        >
          <ListItemAvatar>
            <IconButton edge="start" aria-label="File type">
              <img src={getFileAvatar(fileExtension)} alt="File type"/>
            </IconButton>
          </ListItemAvatar>
          <ListItemText
            primary={originalFileName}
            secondary={`${Math.floor(fileSize / 1024)} KB`}
          />
        </ListItem>
      ))
    }
  </List>
)

export default PostFormFileList
