import React, { FC } from 'react'
import { Grid } from '@mui/material'

import { QuizAttemptsHistoryContent } from 'types/QuizAttemptsHistoryContent'
import { UUID } from 'domain/types'
import Chart from './Chart'
import StatisticsHeader from './StatisticsHeader'
import StatisticsPastSimulations from './StatisticsPastSimulations'
import StatisticsPerSubjectProMed from './StatisticsPerSubjectProMed'
import StatisticsPerSubjectWeTest from './StatisticsPerSubjectWeTest'
import { isWeTest } from 'theme/constants'

type StatisticsProps = {
  statisticsScoreAverage: ScoreAverageStatistics,
  simulations: QuizAttemptsHistoryContent[]
  subjectStatistics: SubjectStatistics[]
  sections: SectionsStatistics[]
  chartStatistics?: ChartStatistics
  chartSubjectFilter?: string
  onChartFilterChange: (sectionId: UUID ) => void
  onChartDateFilterChange: (date: number) => void
  onStartSubjectQuiz: (subjectId: string) => void
}

const Statistics: FC<StatisticsProps> = ({
                                           statisticsScoreAverage,
                                           simulations,
                                           subjectStatistics,
                                           sections,
                                           chartStatistics,
                                           chartSubjectFilter,
                                           onChartFilterChange,
                                           onChartDateFilterChange,
                                           onStartSubjectQuiz,
                                         }) => {
  return (
    <Grid container sx={{ pt: { xs: '52px', lg: '57px' }, pb: { xs: '20px', lg: 6.5 } }}>
      <StatisticsHeader/>
      <Grid
        container
        spacing="20px"
        sx={{
          mt: { xs: '20px', lg: '30px' },
          mx: { xs: 0, sm: '20px', lg: '50px' },
          overflow: 'hidden'
        }}
      >
        <Grid
          item
          xs={12}
          md={isWeTest() ? 12 : 7}
          lg={isWeTest() ? 12 : 8}
          className="u-pl-0"
        >
          <Chart
            sections={sections}
            chartStatistics={chartStatistics}
            chartSubjectFilter={chartSubjectFilter}
            onChartFilterChange={onChartFilterChange}
            onChartDateFilterChange={onChartDateFilterChange}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={isWeTest() ? 6 : 5}
          lg={4}
          sx={{
            pl: { xs: '0 !important', md: isWeTest() ? 0 : '20px !important' },
          }}
        >
          <StatisticsPastSimulations simulations={simulations}/>
        </Grid>
        <Grid
          item
          xs={12}
          md={isWeTest() ? 6 : 12}
          lg={isWeTest() ? 8 : 12}
          sx={{
            pl: { xs: '0 !important', md: isWeTest() ? '20px !important' : 0 },
          }}
        >
          {isWeTest()
            ? <StatisticsPerSubjectWeTest subjectStatistics={subjectStatistics} onStartQuiz={onStartSubjectQuiz}/>
            : <StatisticsPerSubjectProMed subjectStatistics={subjectStatistics}/>
          }
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Statistics
