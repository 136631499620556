import React, { useState } from 'react'
import { RouteComponentProps } from 'react-router'
import useLesson from 'hooks/useLesson'
import useUpdateLessonNoteText from 'hooks/useUpdateLessonNoteText'
import NoteEditorForm from 'components/lesson/NoteEditorForm'
import ModalLessonNoteResetConfirmation from "../components/shared/modal/ModalLessonNoteResetConfirmation"
import useResetLessonNoteText from "../hooks/useResetLessonNoteText"
import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { translations } from "../locales/i18n"

const LessonEditor = (props: RouteComponentProps<{ courseId?: string, lessonId?: string }>) => {
  const {t} = useTranslation()

  const courseId: string = props.match.params.courseId || ''
  const lessonId: string = props.match.params.lessonId || ''

  const lessonQuery = useLesson(courseId, lessonId)
  const lesson = lessonQuery.data

  const updateLessonNoteTextMutation = useUpdateLessonNoteText()
  const handleUpdateLessonNoteText = (noteText: string) => {
    if(noteText !== lesson?.noteText) {
      updateLessonNoteTextMutation.mutate({
        courseId,
        lessonId,
        noteText,
      } as LessonNoteTextUpdateRequest)
    }
  }
  

  const [resettingLessonNoteOpen, setResettingLessonNoteOpen] = useState(false)
  const handleResettingLessonNoteOpen = () => setResettingLessonNoteOpen(true)
  const handleResettingLessonNoteClose = () => setResettingLessonNoteOpen(false)

  const resetLessonNoteTextMutation = useResetLessonNoteText(courseId, lessonId)
  const handleResetLessonNoteText = () =>
    resetLessonNoteTextMutation.mutate({
      courseId,
      lessonId,
    } as LessonNoteTextResetRequest)

  return (
    <Box sx={{pl: '24px'}}>
      <NoteEditorForm noteTextUpdatedAt={lesson?.noteTextUpdatedAt}
                      noteText={lesson?.noteText}
                      onSubmit={handleUpdateLessonNoteText}
                      height={"calc(100vh - 60px)"}/>
      <Box sx={{
          pt: { xs: '10px', md: '15px' },
          mr: '20px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}>
          <Box
            sx={{
              p: {xs: '8px 10px', md: '8px 20px'},
              border: '1px solid #FFFFFF',
              borderRadius: '3px',
              cursor: 'pointer',
              mr: {md: '20px'},
            }}
            onClick={handleResettingLessonNoteOpen}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <span className="u-fs-16 u-lh-19"
                    style={{letterSpacing: '0.5px'}}>{t(translations.lessonPage.note.resetButton)}</span>
            </Box>
          </Box>
        </Box>
      <ModalLessonNoteResetConfirmation
        open={resettingLessonNoteOpen}
        onClose={handleResettingLessonNoteClose}
        onConfirm={handleResetLessonNoteText}
      />
    </Box>
    )}

export default LessonEditor
