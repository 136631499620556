import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import dayjs from "dayjs";

import { translations } from "locales/i18n";
import { SHORT_DATE_FORMAT } from "utils/constants";
import { QuizAttemptsHistoryContent } from "types/QuizAttemptsHistoryContent";
import getQuizTypeTitle from "components/shared/getQuizTypeTitle";
import { QuizType } from "../../types/QuizType";

type StatisticsPastSimulationsProps = {
  simulations: QuizAttemptsHistoryContent[];
};

const StatisticsPastSimulations: FC<StatisticsPastSimulationsProps> = ({
  simulations,
}) => {
  const { t } = useTranslation();

  const [showAllSimulations, setShowAllSimulations] = React.useState(false);

  const toggleShowAllSimulations = () =>
    setShowAllSimulations(!showAllSimulations);

  const maxInitialSimulationsCount = 6;

  const simulationsNumberToShow = () => {
    if (showAllSimulations) {
      return simulations.length;
    }
    return simulations.length > maxInitialSimulationsCount
      ? maxInitialSimulationsCount
      : simulations.length;
  };

  return (
    <Grid
      container
      display="flex"
      className="u-h-100"
      sx={{
        pt: { xs: "20px", lg: "32px" },
        pb: { xs: "29px", lg: "45px" },
        borderRadius: "4px",
        background: (theme) => theme.palette.background.paper,
      }}
    >
      <Grid container item alignSelf="start">
        <Grid
          item
          sx={{
            fontSize: { xs: "14px", sm: "20px" },
            lineHeight: { xs: "17px", sm: "24px" },
            fontWeight: "bold",
            px: { xs: "20px", lg: "27px" },
          }}
        >
          {t(translations.statistics.pastSimulations)}
        </Grid>
        <Grid
          container
          item
          className="u-mt-25 u-mb-15 u-color-hit-grey"
          alignItems="flex-end"
          sx={{
            fontSize: { xs: "7px", sm: "11px" },
            lineHeight: { xs: "8px", sm: "13px" },
            px: { xs: "20px", lg: "27px" },
          }}
        >
          <Grid item xs={6} sx={{ flexBasis: "45%" }}>
            {t(translations.statistics.simulationName)}
          </Grid>
          <Grid item xs={3} sx={{ flexBasis: "47%" }}>
            {t(translations.statistics.date)}
          </Grid>
          <Grid item xs={3} />
        </Grid>
        <Grid
          container
          item
          sx={{
            px: { xs: "20px", lg: "27px" },
            maxHeight: { xs: "330px", lg: "280px" },
            overflowY: "auto",
          }}
        >
          {simulations
            .slice(0, simulationsNumberToShow())
            .map(({ quizAttemptDetails, date, quizAttemptId, quizType }) => (
              <Grid
                container
                className="u-mb-10 u-weight-500"
                key={quizAttemptId}
                alignItems="flex-end"
                sx={{
                  fontSize: { xs: "12px", sm: "14px" },
                  lineHeight: "22px",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Grid item xs={6} sx={{ flexBasis: "40%" }}>
                  {quizType !== QuizType.PACK_SIMULATION &&
                    (quizType === QuizType.AREA_MONTHLY_PRACTICE
                      ? getQuizTypeTitle(
                          quizType,
                          quizAttemptDetails.subject?.name
                        )
                      : quizAttemptDetails.subject?.name ||
                        getQuizTypeTitle(
                          quizType,
                          "",
                          `${quizAttemptDetails?.groupName}: ${quizAttemptDetails?.quizName}`
                        ))}

                  {quizType === QuizType.PACK_SIMULATION &&
                    `${quizAttemptDetails.packSimulationTitle}: ${quizAttemptDetails.name}`}
                </Grid>
                <Grid item xs={3}>
                  {dayjs(date).format(SHORT_DATE_FORMAT)}
                </Grid>
                <Grid item xs={3} display="flex" justifyContent="flex-end">
                  <Link to={`/quiz-attempts/${quizAttemptId}/results`}>
                    <Button
                      variant="outlined"
                      sx={{
                        margin: 0,
                        padding: "3px 8px",
                        color: "white",
                        border: "1px solid white",
                        textTransform: "none",
                      }}
                    >
                      {t(translations.statistics.open)}
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid
        item
        className="u-color-curious-blue"
        alignSelf="end"
        onClick={toggleShowAllSimulations}
        sx={{
          mt: { xs: "30px", lg: "25px" },
          px: { xs: "20px", lg: "27px" },
          fontSize: { xs: "12px", sm: "16px" },
          lineHeight: { xs: "14px", sm: "19px" },
          cursor: "pointer",
        }}
      >
        {showAllSimulations
          ? t(translations.statistics.skipShowingAllSimulations)
          : t(translations.statistics.showAllSimulations)}
      </Grid>
    </Grid>
  );
};

export default StatisticsPastSimulations;
