import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { Box, FormHelperText, Grid } from '@mui/material'
import { object, string, boolean } from 'yup'

import styles from './Login.module.scss'
import FormInput from '../ui/form/form-input/form-input'
import useLogIn from 'hooks/useLogIn'
import LoadingButton from '../ui/buttons/loading-button/loading-button'
import useMe from 'hooks/useMe'
import { translations } from 'locales/i18n'
import FormCheckbox from 'components/ui/form/form-checkbox/form-checkbox'
import { LOGO_URL } from 'theme/constants'

const validationSchema = object().shape({
  username: string().email().trim().required(),
  password: string().required(),
  rememberMe: boolean().required(),
})

const Login = () => {
  const { t } = useTranslation()

  const meQuery = useMe()
  const me = meQuery.data

  if (me?.profile) {
    window.location.href = '/'
  }

  const [isError, setIsError] = useState(false)

  const logInMutation = useLogIn()

  const handleSubmit = (data: LogInUserRequest) => logInMutation.mutate(
    {
      ...data,
      username: data.username.toLowerCase().trim(),
    },
    {
      onError() {
        setIsError(true)
      },
    },
  )

  return (
    <div className={styles.loginForm}>
      <Formik
        validateOnBlur
        validateOnChange={false}
        initialValues={{ username: '', password: '', rememberMe: false }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <form onSubmit={props.handleSubmit}>
            <Grid container>
              <Grid item xs={12} className={styles.logoWrapper}>
                <img className={styles.logo} src={LOGO_URL} alt="Logo"/>
              </Grid>
              <Grid item xs={12} className={styles.welcomeTitle}>
                <div>{t(translations.loginPage.platformFamily)}</div>
                <div>{t(translations.loginPage.welcomesYou)}</div>
              </Grid>
              <Grid item xs={12} className={styles.welcomeText}>
                <div>{t('loginPage.welcomeText.part1')}</div>
                <div>{t('loginPage.welcomeText.part2')}</div>
              </Grid>
              <Grid item xs={12} className={styles.loginTitle}>
                {t('loginPage.loginTitle')}
              </Grid>
              {isError && (
                <FormHelperText error>{t('loginPage.loginError')}</FormHelperText>
              )}
              <Grid item xs={12} className={styles.loginText}>
                {t('loginPage.loginText')}
              </Grid>
              <Grid item xs={12} style={{ marginTop: '24px' }} className={styles.formInputRow}>
                <FormInput
                  InputLabelProps={{ shrink: true }}
                  inputClassName={styles.username}
                  label={t('loginPage.username')}
                  name="username"
                  variant="standard"/>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '18px' }} className={styles.formInputRow}>
                <FormInput
                  InputLabelProps={{ shrink: true }}
                  inputClassName={styles.password}
                  type="password"
                  label={t('loginPage.password')}
                  name="password"
                  variant="standard"/>
              </Grid>
              <Grid item container sx={{
                mt: { xs: '21px', md: '38px' },
                display: 'flex',
                justifyContent: 'space-between',
              }}>
                <FormCheckbox
                  label={t(translations.loginPage.rememberMe)}
                  name="rememberMe"
                />
                <Box
                  sx={{
                    fontSize: { xs: '12px', md: '16px' },
                    lineHeight: { xs: '14px', md: '19px' },
                  }}
                >
                  <Link to="/forgot-password">
                    {t(translations.loginPage.forgotPassword)}
                  </Link>
                </Box>
              </Grid>
              <Grid item container sx={{ mt: { xs: '25px', md: '33px' } }}>
                <LoadingButton
                  loading={logInMutation.isLoading}
                  className={styles.loginButton}
                  type="submit"
                  variant="contained"
                >
                  {t('loginPage.loginButton')}
                </LoadingButton>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default Login
