import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { darken } from '@material-ui/core'
import { Button, Grid, Typography, Box } from '@mui/material'
import dayjs from 'dayjs'

import { FULL_TIME_FORMAT } from 'utils/constants'
import { translations } from 'locales/i18n'
import { UUID } from 'domain/types'
import LoadingIndicator from 'components/shared/loading-indicator'

type ChartProps = {
  sections: SectionsStatistics[]
  chartStatistics?: ChartStatistics
  chartSubjectFilter?: string
  onChartFilterChange: (sectionId: UUID ) => void
  onChartDateFilterChange: (date: number) => void
}

const Chart: FC<ChartProps> = ({
                                 sections,
                                 chartStatistics,
                                 chartSubjectFilter,
                                 onChartFilterChange,
                                 onChartDateFilterChange
                               }) => {
  const { t } = useTranslation()

  const [activeChartSubjectFilter, setActiveChartSubjectFilter] = useState(chartSubjectFilter || 'All')
  const [activeChartDateFilter, setActiveChartDateFilter] = useState('All')
  const subjectFilters = [
    { id: 'All', name: t(translations.statistics.showAllSubjectsChart) },
    ...sections,
  ]
  const dateFilters = [
    {id: '1 Week', name: t(translations.common.oneWeek), date: dayjs().subtract(1, 'week').unix() * 1000},
    {id: '1 Month', name: t(translations.common.oneMonth), date: dayjs().subtract(1, 'month').unix() * 1000},
    {id: '3 Month', name: t(translations.common.threeMonth), date: dayjs().subtract(3, 'month').unix() * 1000},
    {id: 'All', name: t(translations.common.allPeriods), date: dayjs().millisecond()}
  ]
    const { floor, abs, trunc } = Math

  const handleChartSubjectFilterChange = (section) => {
    setActiveChartSubjectFilter(section)
    onChartFilterChange(section === 'All' ? null : section)
  }

  const handleChartDateFilterChange = (id: string, date: number) => {
    setActiveChartDateFilter(id)
    onChartDateFilterChange(id === 'All' ? 0 : date)
  }

  const timeAverageFormatted = (time) => {
    return dayjs.utc(time)
      .format(FULL_TIME_FORMAT)
      // remove full time format leading zeros (00:42:32 => 42:32), (00:00:18 => 0:18)
      .replace(/^0(?:0:0?)?/, '')
  }

  const [active, setActive]  = useState<number>(0)

  return (
    <Grid
      container
      alignContent="space-between"
      sx={{
          height: '100%',
        borderRadius: { xs: 0, sm: '4px' },
        pt: { xs: '15px', lg: '32px' },
        pb: { xs: '29px', lg: '45px' },
        px: { xs: '20px', lg: '35px' },
        background: theme => theme.palette.background.paper,
        maxHeight: { lg: '800px' },
        overflow: 'hidden'
      }}
    >
        <Grid container>
            <Grid container sx={{ flexWrap: {xs: 'wrap', lg: 'nowrap'}}}>
                <Grid
                    item
                    xs={7}
                    sm={8}
                    sx={{
                        fontSize: { xs: '14px', sm: '20px' },
                        lineHeight: { xs: '17px', sm: '24px' },
                        fontWeight: 'bold',
                    }}
                >
                    {t(translations.statistics.simulationScoreChart)}
                </Grid>
                <Grid container
                      spacing={1}
                      mb={1}
                      sx={{justifyContent: {xs: 'flex-start', lg: 'flex-end'}}}
                >
                    {dateFilters && dateFilters.map(({ id, name, date }) => (
                        <Grid item key={id}>
                            <Button
                                sx={{
                                    background: activeChartDateFilter === id ? '#E25F60' : '',
                                    ':hover': {
                                        background: activeChartDateFilter === id ? darken('#E25F60', 0.3) : theme => theme.palette.secondary.light,
                                    },
                                    textTransform: 'none',
                                    minHeight: '35px',
                                    borderRadius: '3px',
                                    boxShadow: 'none',
                                }}
                                onClick={() => handleChartDateFilterChange(id,date)}
                            >
                                <Typography component={'span'} sx={{
                                    fontSize: { xs: '12px', md: '14px' },
                                    lineHeight: { xs: '18px', md: '22px' },
                                    color: activeChartDateFilter === id ? 'white' : '#ADB6BE',
                                }}>
                                    {name}
                                </Typography>
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid container>
                <Grid container
                      spacing={1}
                      xs={12}
                      sx={{overflowX: {xs: 'scroll', md: 'visible'}, flexWrap: 'nowrap',
                          '::-webkit-scrollbar': {
                          display: 'none',
                          }, marginBottom: {xs: '10px', md: 0}}}
                      alignItems="flex-start">
                    {subjectFilters.map(({ id, name }, index) => (
                        <Grid item key={id}>
                            <Button
                                sx={{
                                    textTransform: 'none',
                                    minHeight: '35px',
                                    borderRadius: 0,
                                    boxShadow: 'none',
                                    borderBottom: active === index ? '2px solid #2093F8' : 'none' ,
                                    ":hover": {
                                        borderBottom: '2px solid #2093F8',
                                        background: 'inherit'
                                    },
                                }}
                                onClick={() => {setActive(index); handleChartSubjectFilterChange(id)}}
                            >
                                <Typography component={'span'} sx={{
                                    fontSize: { xs: '12px', md: '14px' },
                                    lineHeight: { xs: '18px', md: '22px' },
                                    whiteSpace: 'nowrap',
                                    color: active === index ? 'white' : '#ADB6BE',
                                }}>
                                    {name}
                                </Typography>
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
        <Grid container>
          {!chartStatistics && <LoadingIndicator/>}
          {chartStatistics && (
              <Grid container justifyContent='space-between' sx={{paddingTop: {sm: '20px', md: '45px'}}}>
                  <Grid item display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                        sx={{
                            order: { xs: 2, lg: 'unset' },
                        }}
                  >
                      <Typography component={'span'} className="u-weight-bold"
                                  sx={{
                                      fontSize: { xs: '30px', md: '40px' },
                                      lineHeight: { xs: '39px', md: '52px' },
                                  }}
                      >
                          {chartStatistics.scoreAverageOtherStudents / 100}
                      </Typography>
                      <Typography component={'span'} className="u-color-aircraft-exterior-grey"
                                  sx={{
                                      mt: '3px',
                                      fontSize: { xs: '14px', md: '20px' },
                                      lineHeight: { xs: '17px', md: '26px' },
                                      fontWeight: '500',
                                  }}
                      >{t(translations.statistics.usersAverageScore)}</Typography>
                  </Grid>

                    <Grid item display="flex" justifyContent="center"
                          sx={{
                              width: { xs: '100%', lg: 'auto' },
                              order: { xs: 1, lg: 'unset' },
                          }}
                    >
                        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                             className='u-border-dodger-blue'
                             sx={{
                                 width:  { xs: '180px', md: '240px' },
                                 height: { xs: '180px', md: '240px' },
                                 border: '2px solid',
                                 borderRadius: '50%'
                             }}>
                            <Typography component={'p'} className="u-weight-bold u-color-dodger-blue"
                                        sx={{
                                            fontSize: { xs: '30px', md: '40px' },
                                            lineHeight: { xs: '39px', md: '52px' },
                                        }}
                            >
                                {Object.is(trunc(chartStatistics.scoreAverage / 100), -0 )
                                    ? `-${trunc(chartStatistics.scoreAverage / 100)}.`
                                    : `${trunc(chartStatistics.scoreAverage / 100)}.`}
                                <span>{abs(floor(chartStatistics.scoreAverage % 100))}</span>
                            </Typography>
                            <Typography component={'p'} className="u-color-aircraft-exterior-grey"
                                        sx={{
                                            fontSize: { xs: '14px', md: '20px' },
                                            lineHeight: { xs: '17px', md: '26px' },
                                        }}
                            >
                                {t(translations.statistics.yourAverageScore)}
                            </Typography>
                        </Box>
                    </Grid>

                    <Grid item display="flex" flexDirection="column" alignItems="center" justifyContent="center"
                          sx={{
                              order: { xs: 3, lg: 'unset' },
                          }}
                    >
                        <Typography component={'span'} className="u-weight-bold"
                                    sx={{
                                        fontSize: { xs: '30px', md: '40px' },
                                        lineHeight: { xs: '39px', md: '52px' },
                                    }}
                        >
                            {timeAverageFormatted(chartStatistics.timeAverage)}</Typography>
                        <Typography component={'span'} className="u-color-aircraft-exterior-grey"
                                    sx={{
                                        mt: '3px',
                                        fontSize: { xs: '14px', md: '20px' },
                                        lineHeight: { xs: '17px', md: '26px' },
                                        fontWeight: '500',
                                    }}
                        >{t(translations.statistics.yourAverageTime)}</Typography>
                    </Grid>
                </Grid>
            )}
        </Grid>
    </Grid>
  )
}

export default Chart
