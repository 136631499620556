import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

import { translations } from 'locales/i18n'
import pencilIcon from 'images/pencil.svg'
import deleteIcon from 'images/delete.svg'
import { UserProfileRole } from 'types/UserProfileRole'
import PostCommentCardHeaderData from './PostCommentCardHeaderData'
import { PostCommentInterface } from 'types/PostCommentInterface'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import CommunityMenu from 'components/community/CommunityMenu'

type PostCommentCardHeaderProps = {
  comment: PostCommentInterface
  onStartEdit?: () => void
  onDelete?: () => void
  currentUser: StudentProfileInterface
  showMenu?: boolean
  onOpenCommentReport?: () => void
}

const PostCommentCardHeader: FC<PostCommentCardHeaderProps> = ({
                                                                 currentUser,
                                                                 comment,
                                                                 onStartEdit,
                                                                 onDelete,
                                                                 onOpenCommentReport,
                                                                 showMenu = true,
                                                               }) => {
  const {t} = useTranslation()
  const {author, id: commentId} = comment

  const {profile, mainRole} = currentUser
  const isAdmin = mainRole === UserProfileRole.ADMIN
  const isTeacher = mainRole === UserProfileRole.TEACHER
  const canEdit = isAdmin || isTeacher || profile.nickname === author.nickname

  const menuItems = [
    onStartEdit && canEdit && ({
      icon: pencilIcon,
      title: t(translations.community.postCardComment.editComment),
      onClick: onStartEdit,
    } as MenuItem),
    onDelete && canEdit && {
      icon: deleteIcon,
      title: t(translations.community.postCardComment.deleteComment),
      onClick: onDelete,
    } as MenuItem,
    onOpenCommentReport && {
      icon: '/images/icons/community/post-report-icon.svg',
      title: t(translations.community.postCardComment.reportComment),
      onClick: onOpenCommentReport,
    } as MenuItem,
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
      }}
    >
      <PostCommentCardHeaderData comment={comment}/>
      {showMenu && menuItems.length > 0 && (onStartEdit || onDelete) && (
        <CommunityMenu menuItems={menuItems} keyPrefix={commentId}/>
      )}
    </Box>
  )
}
export default PostCommentCardHeader
