import React, { ChangeEvent, FC } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItem, TextField } from '@mui/material'
import { SortableHandle } from 'react-sortable-hoc'
import MoreVertIcon from '@mui/icons-material/MoreVert'

import { translations } from 'locales/i18n'
import removeIcon from 'images/close-icon.svg'

type PollVariantProps = {
  id: string
  text: string
  voteCount?: number
  divider: boolean
  onRemoveOption: () => void
  onUpdateText: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
}

const PollVariant: FC<PollVariantProps> = ({
                                             id,
                                             text,
                                             voteCount,
                                             divider,
                                             onRemoveOption,
                                             onUpdateText,

                                           }) => {
  const {t} = useTranslation()
  const DragHandle = SortableHandle(() => <MoreVertIcon sx={{cursor: 'pointer'}} />)
  return (
    <ListItem
      key={`poll-option-${id}`}
      sx={{
        zIndex: 10000,
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        minHeight: {xs: '48px', sm: '60px'},
        pl: {xs: '20px', sm: '26px'},
        pr: '15px',
      }}
      divider={divider}
    >
      <DragHandle/>
      <TextField
        variant="outlined"
        type="text"
        multiline={true}
        placeholder={t(translations.community.postForm.optionDefaultValue)}
        defaultValue={text}
        fullWidth={true}
        onChange={onUpdateText}
        sx={{
          '& .MuiOutlinedInput-root': {
            padding: 0,
          },
          '& .MuiOutlinedInput-input': {
            color: '#FFFFFF',
            padding: 0,
            fontSize: {xs: '12px', sm: '16px'},
            lineHeight: {xs: '22px', sm: '28px'},
          },
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none',
          },
        }}
      />
      {(!voteCount || voteCount === 0) && (
        <img
          src={removeIcon}
          alt={t(translations.common.remove)}
          className="u-cursor-pointer u-pl-10"
          onClick={onRemoveOption}
        />
      )}
    </ListItem>
  )
}

export default PollVariant
