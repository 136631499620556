import React, { memo } from 'react'
import { Link } from 'react-router-dom'
import { Grid, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import leftArrow from 'images/left-arrow.svg'

type Props = {
  text: string
  url: string
  sx?: SxProps<Theme>
}

export const Back = memo(({text, url, sx}: Props) => (
    <Link to={url}>
      <Grid
        className="u-position-absolute u-cursor-pointer u-fs-15 u-lh-18"
        sx={{ mx: {xs: '20px', sm: 6.5}, top: {xs: '60px', md: '76px'}, zIndex: '5', ...sx }}
      >
        <img
          src={leftArrow}
          className="u-pr-10"
          alt=""
          role="presentation"
        />
        <Typography
          component={"span"}
          sx={{
            fontSize: {xs: '12px', md: '15px'},
            lineHeight: {xs: '14px', md: '18px'},
            textTransform: 'uppercase'
            }}>
              {text}
        </Typography>
      </Grid>
    </Link>
    )
)
