import React, { FC } from 'react'
import { Box } from '@mui/material'

import CoursesList from './CoursesList'
import { CourseSearchView } from 'types/CourseSearchView'
import ContinueLearning from '../home/ContinueLearning'

type CoursesProps = {
  courses: CourseSearchView[]
}

const Courses: FC<CoursesProps> = ({courses}) => {
  return (
    <Box sx={{mt: {xs: '36px', md: '55px'}}}>
      <Box sx={{px: {xs: '20px', sm: 6.5}, mb: {xs: '20px', sm: 6.5}}}>
        <ContinueLearning courses={courses}/>
        <CoursesList courses={courses} showOnlyUnopenedToggle={true}/>
      </Box>
    </Box>
  )
}

export default Courses
