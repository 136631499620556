import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { isEmpty } from 'lodash'

import Notification from './Notification'
import { NotificationInterface } from 'types/NotificationInterface'
import { translations } from 'locales/i18n'
import CommunityMenu from 'components/community/CommunityMenu'
import markAsReadIcon from 'images/mark-as-read.svg'
import settingsIcon from 'images/settings.svg'

type NotificationsProps = {
  notifications: NotificationInterface[]
  onMarkAllAsRead: () => void
  onMarkAsRead: (id: string) => void
  onDeleteNotification: (id: string) => void
}

const Notifications: FC<NotificationsProps> = ({
                                                 notifications,
                                                 onMarkAllAsRead,
                                                 onMarkAsRead,
                                                 onDeleteNotification,
                                               }) => {
  const {t} = useTranslation()
  const history = useHistory()

  const menuItems = [
    {
      icon: markAsReadIcon,
      title: t(translations.notifications.markAllAsRead),
      onClick: onMarkAllAsRead,
    } as MenuItem,
    {
      icon: settingsIcon,
      title: t(translations.notifications.setUpNotifications),
      onClick: () => history.push('/profile/notification-preferences'),
    } as MenuItem,
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        mt: {xs: '36px', md: '55px'},
        mb: {xs: '20px', sm: 6.5},
      }}>
      <Box
        sx={{
          mt: {xs: '20px', sm: '26px'},
          px: {xs: '20px', sm: 0},
          width: {xs: '100%', sm: 'calc(100% - 104px)', md: '500px'},
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            mb: {xs: '14px', sm: '16px'},
          }}
        >
          <Box
            sx={{
              fontSize: {xs: '16px', md: '20px'},
              lineHeight: {xs: '19px', md: '24px'},
              letterSpacing: {xs: '0.38px', md: '0.5px'},
              fontWeight: 'bold',
              opacity: '80%',
            }}
          >
            {t(translations.notifications.title)}
          </Box>
          <CommunityMenu menuItems={menuItems}/>
        </Box>
        <Box>
          {!isEmpty(notifications) ?
            notifications.map(notification => (
              <Box
                key={notification.notificationId}
                sx={{
                  background: theme => notification.read ? theme.palette.background.paperLighter : theme.palette.background.paperLightest,
                  px: {xs: '11px', sm: '15px'},
                  py: {xs: '19px', sm: '24px'},
                  ':not(:last-child)': {
                    borderBottom: theme => `1px solid ${theme.palette.secondary.light}`,
                  },
                  ':first-child': {
                    borderRadius: '3px 3px 0 0',
                  },
                  ':last-child': {
                    borderRadius: '0 0 3px 3px',
                  },
                }}
              >
                <Notification
                  notification={notification}
                  showMenu={true}
                  showHowLongAgo={true}
                  onMarkAsRead={() => onMarkAsRead(notification.notificationId)}
                  onDelete={() => onDeleteNotification(notification.notificationId)}
                />
              </Box>
            )
            ) : (
                <Box
                  sx={{
                      marginTop: '50%',
                      textAlign: 'center',
                      fontSize: { xs: '12px', md: '16px' },
                      lineHeight: { xs: '15px', md: '19px' },
                      color: '#AAAAAA',
                    }}
                >
                  {t(translations.notifications.noNotifications)}
                </Box>
            )
          }
        </Box>
      </Box>
    </Box>
  )
}

export default Notifications
