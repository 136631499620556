import { useMutation } from 'react-query'

import useInvalidateQueriesAfterLogin from './useInvalidateQueriesAfterLogin'
import { refreshToken } from 'services/userService'
import { logInCdn } from 'services/cdnAuthService'
import { TOKEN_KEY } from 'utils/constants'

const useRefreshToken = () => {
  const invalidate = useInvalidateQueriesAfterLogin()

  return useMutation<AuthUserResponse>(() => refreshToken(), {
    async onSuccess(data: AuthUserResponse) {
      await invalidate()

      const {token} = data
      localStorage.setItem(TOKEN_KEY, token)
      await logInCdn(token)
    },
  })
}

export default useRefreshToken
