import React, { FC } from 'react'
import { Box, Grid } from '@mui/material'

import Welcome from './Welcome'
import { StudentProfileInterface } from 'types/StudentProfileInterface'

type GreetingProps = {
  currentUser: StudentProfileInterface
}

const Greeting: FC<GreetingProps> = ({currentUser}) => {
  return (
    <div style={{position: 'relative'}}>
      <Grid container sx={{pt: {xs: 20, lg: 25}, pb: {xs: 10, lg: 25}}}>
        <Grid item xs={12} lg={6}>
          <Box sx={{mb: {xs: '40px', lg: '0'}, mx: {xs: '20px', sm: '52px'}}}>
            <Welcome currentUser={currentUser}/>
          </Box>
        </Grid>
        {/*<Grid item xs={12} lg={6}>*/}
        {/*    <Counter/>*/}
        {/*</Grid>*/}
      </Grid>
    </div>
  )
}

export default Greeting
