import { setLocale } from 'yup'
import { TFunction } from 'i18next'

export function buildYupLocale(_: unknown, t: TFunction): void {
  setLocale({
    mixed: {
      required: t('validatorSchema.common.mixed.required'),
    },
  })
}
