import { ChangeEvent, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import arrayMove from 'array-move'
import { isEmpty } from 'lodash'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'
import { PostInterface } from 'types/PostInterface'
import { MAX_POLL_OPTIONS_COUNT } from 'utils/constants'
import { PostRequest } from 'types/requests/PostRequest'
import { PostFile } from 'types/PostFile'
import { PostType } from 'types/PostType'

interface UseEditPostResult {
  postText: string
  setPostText: (value: string) => void
  uploadingFiles: File[]
  currentFiles: PostFile[]
  showAddOption: boolean
  pollVariants: PollOption[]
  handleAddFiles: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => Promise<void>,
  handleRemoveFile: (fileId: string) => void
  handleAddOption: () => void
  handleRemoveOption: (fileId: string) => void
  handleUpdateOptionText: (variantId: string, newText: string) => void
  handleSubmit: (data: PostRequest) => void
  handleCancel: () => void
  handleUpdateOptionPosition: (oldIndex: number, newIndex: number) => void
}

type useEditPostProps = {
  classId: string
  post: PostInterface
  onSubmit: (data: PostRequest) => Promise<PostInterface>
  onAddFile: (data: AddFileToPostRequest) => Promise<PostFile>
  onCancel: () => void
}

/**
 * This component was added because of adaptive design. There is no opportunity to make post form responsive
 *
 * @param communityClass
 * @param post - post for edit
 * @param onSubmit - update post function
 * @param onCancel - cancel editing function
 * @param onAddFile - add file to the editing post function
 */
const useEditPost = ({
                       classId,
                       post,
                       onSubmit,
                       onCancel,
                       onAddFile,
                     }: useEditPostProps): UseEditPostResult => {
  const {postId, pollOptions, files, postType} = post
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()

  const newPollVariant = () => ({
    id: v4(),
    optionText: '',
  } as PollOption)

  const [postText, setPostText] = useState<string>('')
  const [uploadingFiles, setUploadingFiles] = useState<File[]>([])
  const [currentFiles, setCurrentFiles] = useState<PostFile[]>([])
  const [newPollOptions, setNewPollOptions] = useState<PollOption[]>([])

  const setInitialState = () => {
    setPostText(post.postText)
    setUploadingFiles([])
    setCurrentFiles(files || [])
    setNewPollOptions(pollOptions || [newPollVariant()])
  }

  useEffect(
    () => {
      setInitialState()
    },
    [postId, post.postText, pollOptions],
  )

  const showAddOption = newPollOptions.length < MAX_POLL_OPTIONS_COUNT

  const handleAddFiles = async (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const newFiles = Array.from((e.target as HTMLInputElement).files || [])
    setUploadingFiles(newFiles)
  }

  useEffect(
    () => {
      if (uploadingFiles.length > 0) {
        Promise.allSettled(uploadingFiles.map(async (file) => {
          const uploadedFile = await onAddFile({classId, id: postId, file})
          setCurrentFiles((prevState) => [...prevState, uploadedFile])
          return uploadedFile
        })).finally(() => setUploadingFiles([]))
      }
    },
    [uploadingFiles],
  )

  const handleRemoveFile = (fileId: string) =>
    setCurrentFiles(currentFiles.map((file) => file.fileId === fileId ? {
        ...file,
        deleted: true,
      } : file,
    ))

  const handleAddOption = () => {
    setNewPollOptions([
      ...newPollOptions,
      newPollVariant(),
    ])
  }

  const handleRemoveOption = (variantId: string) =>
    setNewPollOptions(newPollOptions.map((option) =>
      option.id === variantId ? {...option, deleted: true} : option),
    )

  const handleUpdateOptionText = (variantId: string, newText: string) =>
    setNewPollOptions(newPollOptions.map((variant) => variant.id === variantId ? {
      ...variant,
      optionText: newText,
    } : variant))

  const handleUpdateOptionPosition = (oldIndex: number, newIndex: number) =>
    setNewPollOptions(arrayMove(newPollOptions, oldIndex, newIndex))

  const handleCancel = () => {
    onCancel()
  }

  const handleSubmit = (data: PostRequest) => {
    const formattedText = data.text.replace(/<\/?[^>]+(>|$)/g, '').replace(/&nbsp;/g, '').trim()
    const currentOption = newPollOptions.filter(option => !option.deleted)
    if(isEmpty(formattedText)) {
      enqueueSnackbar(
        t(translations.messages.errors.POST_IS_EMPTY),
        {variant: 'error'},
      )
    }
    else if (postType === PostType.POLL_POST && currentOption.length < 2) {
      enqueueSnackbar(
        t(translations.messages.errors.updatePoll.MINIMUM_2_OPTIONS_REQUIRED),
        {variant: 'error'},
      )
      return
    }
    else {
       onSubmit({
        ...data,
        postType,
        files: currentFiles,
        pollOptions: newPollOptions,
      }).then(handleCancel)
    }
  }

  return {
    postText,
    setPostText,
    uploadingFiles,
    currentFiles,
    showAddOption,
    pollVariants: newPollOptions,
    handleAddFiles,
    handleRemoveFile,
    handleAddOption,
    handleRemoveOption,
    handleUpdateOptionText,
    handleUpdateOptionPosition,
    handleSubmit,
    handleCancel,
  }
}

export default useEditPost
