import React from 'react'
import { CircularProgress, Grid } from '@mui/material'

const LoadingIndicator = () => (
  <Grid className="u-w-100 u-mt-25 u-mb-20 u-text-center">
    <CircularProgress/>
  </Grid>
)

export default LoadingIndicator
