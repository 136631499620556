import React, { FC, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import cn from 'classnames'
import { isEmpty, isString } from 'lodash'

import styles from './user-avatar-mobile.module.scss'
import { translations } from 'locales/i18n'

interface UserAvatarProps {
  className?: string
  firstName?: string
  lastName?: string
  photo: ReactNode | string
  onClick?: () => void
  sx?: SxProps<Theme>
}

const UserAvatar: FC<UserAvatarProps> = ({
                                           className,
                                           firstName,
                                           lastName,
                                           photo,
                                           onClick,
                                           sx,
                                         }) => {
  const {t} = useTranslation()
 //TODO add preview for img
  return (
    <Box
      onClick={onClick}
      sx={{width: {xs: "62px", sm: "90px"}, height: {xs: "62px", sm: "90px"}, ...sx}}
      className={cn(styles.userAvatar, className)}
    >
      {isString(photo)
        ? <img
          src={isEmpty(photo) ? `https://eu.ui-avatars.com/api/?name=${firstName}+${lastName}&background=4780D8&color=fff` : `${photo}`}
          alt={isEmpty(firstName) && isEmpty(lastName) ? t(translations.common.userAvatar) : `${firstName} ${lastName}`}/>
        : `${photo}`}
    </Box>
  )
}

export default UserAvatar
