import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Scrollspy from 'react-scrollspy'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'
import { Dictionary } from 'lodash'

import FinishQuiz from 'components/quizzes/FinishQuiz'
import QuestionNavigation from 'components/quizzes/question-navigation'
import QuizQuestionCard from './quiz-question-card/QuizQuestionCard'
import QuizAttemptSections from './QuizAttemptSections'
import QuizAttemptHeaderMobile from './QuizAttemptHeaderMobile'
import close from 'images/close.svg'
import { QuizAttemptQuestion } from 'types/QuizAttemptQuestion'
import { QuizAttemptInterface } from 'types/QuizAttemptInterface'
import { translations } from 'locales/i18n'
import { simpleQuizTypes } from './constants'
import theme from 'theme'
import { QuizType } from 'types/QuizType'
import descriptIcon from 'images/description.svg'



type QuizAttemptProps = {
  quizAttempt: QuizAttemptInterface
  quizQuestions: QuizAttemptQuestion[]
  answeredQuestions: QuizAttemptQuestion[]
  questionsGrouped: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  questionsGroupedBySectionId: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  answeredQuestionsGroupedBySection: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  answeredQuestionsGroupedBySubject: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  currentQuestion: QuizAttemptQuestion
  actualSection: string
  remainingTime: number
  timerStyle: string
  buttonText: string
  handleCompleteQuizAttemptNextSection: (quizId: string, activeId: string) => void
  isFlagged: (number: number) => boolean
  getTitle: () => string
  showTimeIsUp: boolean
  setShowTimeIsUp: React.Dispatch<React.SetStateAction<boolean>>
  getQuestionNavigation: (number: number) => string
  onSubmitAnswer: (data: QuizAttemptAnswerCreateRequest) => void
  onToggleFlag: (questionNumber: number) => void
  onFinishQuiz: (e: React.FormEvent<HTMLButtonElement>) => void
}

const questionsHalfPeriod = 7

const QuizAttempt: FC<QuizAttemptProps> = ({
                                            quizAttempt,
                                            quizQuestions,
                                            answeredQuestions,
                                            questionsGrouped,
                                            answeredQuestionsGroupedBySection,
                                            answeredQuestionsGroupedBySubject,
                                            questionsGroupedBySectionId,
                                            currentQuestion,
                                            actualSection,
                                            remainingTime,
                                            showTimeIsUp,
                                            buttonText,
                                            timerStyle,
                                            setShowTimeIsUp,
                                            isFlagged,
                                            getTitle,
                                            getQuestionNavigation,
                                            handleCompleteQuizAttemptNextSection,
                                            onFinishQuiz,
                                            onSubmitAnswer,
                                            onToggleFlag,
                                          }) => {
  const { t } = useTranslation()
  const isLaptop = useMediaQuery(theme.breakpoints.up('lg'))

  const { quizType, quizRule } = quizAttempt

  const [dropdownMenuOpen, setDropdownMenuOpen] = useState<boolean>(false)
  const toggleDropdownMenuOpen = () => setDropdownMenuOpen(!dropdownMenuOpen)
  
  const [currentQuestions, setCurrentQuestions] = useState<QuizAttemptQuestion[]>(quizQuestions)

  useEffect(() => {
    if (actualSection && quizRule.dividedBySections) {
      setCurrentQuestions(questionsGroupedBySectionId[actualSection] || [])
    } else {
      setCurrentQuestions(quizQuestions)
    }
  }, [quizQuestions, actualSection])

  const showGradient = quizQuestions.length  > 2 * questionsHalfPeriod

  const showRemainingTime = !simpleQuizTypes.includes(quizType) && remainingTime > 0

  return (
    <Box sx={{ mt: 3.5 }} className="u-mb-30">
      {showTimeIsUp && (
        <Box
          className="u-w-100 u-position-fixed u-cursor-pointer"
          sx={{
            top: '50px',
            background: '#F8F8F8',
            color: '#9396A6',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            zIndex: 10,
            px: { xs: '20px', sm: 0 },

          }}
          onClick={() => setShowTimeIsUp(false)}
        >
          {t(translations.quizAttemptPage.tourTimeIsUp)}
          <img
            src={close}
            className="u-pl-10"
            alt=""
            role="presentation"
          />
        </Box>
      )}
      <Grid container>
        <Grid item xs={12} lg={3}
              sx={{
                pl: { xs: '20px', lg: '50px' },
              }}>
          <Box
            sx={{
              display: { xs: 'none', lg: 'initial' },
              mt: 9,
              width: '300px',
              position: 'fixed',
              top: '100px',
            }}>
            <Typography
              sx={{
                fontSize: '15px',
                letterSpacing: '2px',
                lineHeight: '18px',
                color: '#E25F60',
              }}
            >
              QUIZ
            </Typography>
            <Box className="u-mt-11 u-mb-11 u-lh-53 u-weight-bold">
              {quizType === QuizType.LESSON && 
                quizAttempt.quizAttemptDetails.lessonName
              }
              <Box className={quizType === QuizType.STATIC_PRACTICE ? 'u-fs-41' : 'u-fs-44'}>
              {quizType === QuizType.PACK_SIMULATION ? `${quizAttempt.quizAttemptDetails?.packSimulationTitle}: ${quizAttempt.quizAttemptDetails?.name}`  : getTitle()} 
              </Box>
            </Box>
            {quizType === QuizType.PRACTICE && 
              <Box className="u-fs-20 u-lh-22">{quizAttempt.quizAttemptDetails.topic?.name}</Box>
            }
            <QuizAttemptSections
              quizQuestions={quizQuestions}
              activeSectionId={actualSection}
              sections={quizRule?.sections}
              quizType={quizType}
              isDividedBySections={quizRule?.dividedBySections}
              questionsGroupedBySubject={questionsGrouped}
              getQuestionNavigation={getQuestionNavigation}
              currentQuestion={currentQuestion}
              answeredQuestionsGroupedBySection={answeredQuestionsGroupedBySection}
              answeredQuestionsGroupedBySubject={answeredQuestionsGroupedBySubject}
            /> 
            {quizType !== QuizType.PRACTICE && quizType !== QuizType.LESSON &&<Box mt={2} sx={{borderRadius: '3px',
              border: '1px solid #715205', 
              padding: '20px 10px',
              display: 'flex', 
              alignItems: 'center',
              marginLeft: '-17px',
              width: '99%'}}>
            <Box mr={1}>
              <img
                src={descriptIcon}
                alt="Description"
              />
            </Box>
            <Box sx={{fontSize: '16px'}}>
             {quizRule?.dividedBySections ?
              `${t(translations.quizAttemptPage.ruleDescriptionCannotSwitch)}`
              : `${t(translations.quizAttemptPage.ruleDescriptionCanSwitch)}`}
            </Box>
          </Box>
          }
          </Box>
        </Grid>
        <Grid container item xs={12} lg={9} sx={{
          px: '20px',
          display: 'flex',
          justifyContent: 'center',
        }}>
          <Grid item>
            <Box sx={{
              display: { xs: 'none', lg: 'initial' },
              position: 'fixed',
              top: `${showTimeIsUp ? '90' : '50'}px`,
              zIndex: 10,
              width: '713px',
            }}>
              <FinishQuiz
                questionsAmount={quizQuestions.length}
                answeredQuestionsAmount={answeredQuestions.length}
                onFinishQuiz={onFinishQuiz}
                remainingTime={remainingTime}
                showRemainingTime={showRemainingTime}
                buttonText={buttonText}
                timerStyle={timerStyle}
                quizType={quizType}
              />
            </Box>
            {!isLaptop && (
              <Box sx={{
                position: 'fixed',
                top: `${showTimeIsUp ? '90' : '50'}px`,
                zIndex: 10,
              }}>
                <QuizAttemptHeaderMobile
                  actualSection={actualSection}
                  isDividedBySections={quizRule?.dividedBySections}
                  sections={quizRule?.sections}
                  dropdownMenuOpen={dropdownMenuOpen}
                  questionsGroupedBySectionId={questionsGroupedBySectionId}
                  toggleDropdownMenuOpen={toggleDropdownMenuOpen}
                  title={getTitle()}
                  quizType={quizType}
                  questions={quizQuestions}
                  currentQuestion={currentQuestion}
                  answeredQuestions={answeredQuestions}
                  questionsGroupedBySubject={questionsGrouped}
                  answeredQuestionsGroupedBySection={answeredQuestionsGroupedBySection}
                  answeredQuestionsGroupedBySubject={answeredQuestionsGroupedBySubject}
                  getQuestionNavigation={getQuestionNavigation}
                  remainingTime={remainingTime}
                  showRemainingTime={showRemainingTime}
                  onFinishQuiz={onFinishQuiz}
                  isFlagged={isFlagged}
                  buttonText={buttonText}
                  timerStyle={timerStyle}
                />
              </Box>
            )}
            <Scrollspy className="u-pl-0" items={quizQuestions.map(({ number }) => getQuestionNavigation(number))}>
              <Box sx={{
                mt: { xs: '222px', lg: '78px' },
              }}>
                {currentQuestions.map(q => 
                <Box key={q.number}>
                  <Box sx={{ mb: '20px' }}>
                    <Box>
                      <QuizQuestionCard
                        question={q.question}
                        questionIndex={currentQuestions.indexOf(q)+1}
                        questionNumber={q.number}
                        quizAttemptId={quizAttempt.id}
                        onToggleFlag={onToggleFlag}
                        onSubmitAnswer={onSubmitAnswer}
                      />
                    </Box>
                  </Box>
                </Box>)}
              </Box>
            </Scrollspy>
          </Grid>
          {isLaptop && (
            <Grid item sx={{ ml: { lg: '40px' } }}>
              <Box
                sx={{
                  mt: 9,
                  pr: '32px',
                  position: 'fixed',
                  top: '100px',
                  height: 'calc(100% - 264px)',
                  overflow: 'auto',
                }}
                className={showGradient ? 'Gradient_bottom' : ''}
              >
                <QuestionNavigation
                  questionsGroupedBySectionId={questionsGroupedBySectionId}
                  questions={quizQuestions}
                  actualSection={actualSection}
                  isDividedBySections={quizRule?.dividedBySections}
                  isFlagged={isFlagged}
                  getQuestionNavigation={getQuestionNavigation}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}

export default QuizAttempt
