import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { updatePost } from 'services/community/postService'
import { PostRequest } from 'types/requests/PostRequest'
import { translations } from 'locales/i18n'

const useUpdatePost = () => useMutationWithSnackbar(
  (data: PostRequest) => updatePost(data),
  undefined,
  translations.messages.postUpdated,
)

export default useUpdatePost
