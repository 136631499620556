import config from '../utils/config'

export const white = '#FFFFFF'
export const inputLabelColor = '#B2B5BD'

declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    accent: string
    accentLight: string
    accentLearning: string
    navigationSelected: string
    accentCard: string
    cardGradient: string
    videoGradient: string
    flag: string
  }

  interface TypeBackground {
    default: string
    paper: string
    paperLighter: string
    paperLightest: string
  }
}


export const isWeTest = () => config.platform === 'WE_TEST'
export const LOGO_URL = isWeTest() ? '/we-test-logo.svg' : '/logo.svg'
export const PLATFORM_NAME = isWeTest() ? 'WeTest' : 'Pro-Med'
export const MONTHLY_SIMULATION_QUESTIONS_COUNT = isWeTest() ? 10 : 60
export const RANDOM_SIMULATION_QUESTIONS_COUNT = 50
export const STATIC_PRACTICE_SIMULATION_COUNT = 50
export const ONE_MIN_IN_MS = 60000
export const MAX_SIMULATION_MINUTES_ALLOWED = isWeTest() ? 30 : 100
export const MAX_QUIZ_ATTEMPT_DURATION_MILLI = MAX_SIMULATION_MINUTES_ALLOWED * 60 * 1000

export const HOME_IMAGE = isWeTest() ? '/images/icons/navbar/home-we.svg' : '/images/icons/navbar/home.svg'
export const PROFILE_IMAGE = isWeTest() ? '/images/icons/navbar/profile-we.svg' : '/images/icons/navbar/profile.svg'
export const COURSES_IMAGE = isWeTest() ? '/images/icons/navbar/courses-we.svg' : '/images/icons/navbar/courses.svg'
export const QUIZZES_IMAGE = isWeTest() ? '/images/icons/navbar/quizzes-we.svg' : '/images/icons/navbar/quizzes.svg'

export const STATISTICS_MAX_POINTS = isWeTest() ? 10 : 90
export const STATISTICS_CHART_DOMAIN = isWeTest() ? [0, 1000] : [0, 5000]
export const STATISTICS_CHART_TICKS = isWeTest() ? [0, 200, 400, 600, 800, 1000] : [0, 1000, 2000, 3000, 4000, 5000]

export const NOTE_EDITOR_SKIN_URL = isWeTest() ? '/styles/skins/ui/wetest-dark' : '/styles/skins/ui/promed-dark'
export const NOTE_EDITOR_CONTENT_CSS = isWeTest() ? '/styles/skins/content/wetest-dark/content.css' : '/styles/skins/content/promed-dark/content.css'
