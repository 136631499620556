import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import { Back } from 'components/shared/back'
import { translations } from 'locales/i18n'
import Simulations from 'components/quizzes-page/Simulations'
import Subjects from 'components/quizzes-page/Subjects'
import { monthlySimulation } from 'components/quizzes-page/simulations-config'
import useStartQuizPractice from 'hooks/useStartQuizPractice'

const QuizzesWeTestPage = () => {
  const { t } = useTranslation()

  const startQuizPracticeMutation = useStartQuizPractice()
  const handleSubjectClick = (data: PracticeQuizCreateRequest) => startQuizPracticeMutation.mutate(data)

  const Header = () => (
    <Box
      sx={{
        fontSize: { xs: '18px', md: '34px' },
        lineHeight: { xs: '24px', md: '41px' },
        mt: { xs: '40px', md: '70px' },
      }}
      className="u-weight-bold">
      {t(translations.dashboard.menu.quizzes)}
    </Box>
  )

  return (
    <DashboardLayout>
      <Back text={t(translations.navigation.homePage)} url={'/'}/>
      <Box sx={{ mx: { xs: '20px', sm: 6.5 }, mb: { xs: '20px', sm: 6.5 } }}>
        <Header/>
        <Simulations simulation={monthlySimulation}/>
        <Subjects onSubjectClick={(subjectId) => handleSubjectClick({ subjectId } as PracticeQuizCreateRequest)}/>
      </Box>
    </DashboardLayout>
  )
}

export default QuizzesWeTestPage
