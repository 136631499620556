enum ReactQueryKeys {
  ME = 'me',
  CLASSES = 'classes',
  COURSES = 'courses',
  COURSE = 'course',
  SEARCH_LESSONS = 'searchLessons',
  LESSON = 'lesson',
  RANKING_SUMMARY = 'rankingSummary',
  RANKING_RECORDS = 'rankingRecords',
  QUIZZES = 'allQuizzes',
  QUIZ_ATTEMPT = 'quizAttempt',
  SUBJECTS = 'subjects',
  SUBJECT = 'subject',
  SUBJECT_TOPICS = 'subjectTopics',
  QUIZ_ATTEMPT_RESULTS = 'quizAttemptResults',
  QUIZ_ATTEMPT_REVIEW = 'quizAttemptReview',
  QUIZ_ATTEMPT_STATISTICS = 'quizAttemptStatistics',
  QUIZ_ATTEMPTS_HISTORY = 'quizAttemptsHistory',
  CHART_STATISTICS = 'chartStatistics',
  SECTION_STATISTICS = 'sectionStatistics',
  STATIC_PRACTICE_GROUP_INFO = 'staticPracticeGroupInfo',
  SUBJECT_STATISTICS = 'subjectStatistics',
  SCORE_AVERAGE_STATISTICS = 'scoreAverageStatistics',
  ARCHIVE_SIMULATION_CATEGORIES = 'archiveSimulationCategories',
  ARCHIVE_SIMULATION_CATEGORY = 'archiveSimulationCategory',
  POSTS = 'posts',
  CLASS_PARTICIPANTS = 'classParticipants',
  POST = 'post',
  RECENT_POSTS = 'recentPosts',
  TEACHERS = 'teachers',
  POST_COMMENTS = 'postComments',
  POST_COMMENT = 'postComment',
  NOTIFICATIONS = 'notifications',
  NOTIFICATION_SETTING = 'notification_setting',
  STREAMS = 'streams',
  STREAM = 'stream',
  STATIC_PRACTICE_GROUP ="staticPracticeGroup",
  BLOGS = 'blogs',
  BLOG = 'blog',
  HOME_DATA = 'homeData',
  QUIZ_INFO = 'quizInfo',
  PACK_SIMULATION_QUIZZES = 'packSimulationQuizzes'
}

export default ReactQueryKeys
