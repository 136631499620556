import React, { FC, FormEvent } from 'react'
import { FormControlLabel, Grid, Radio } from '@mui/material'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { useTranslation } from 'react-i18next'
import { useFormikContext } from 'formik'

import { translations } from 'locales/i18n'
import FormRadioGroup from 'components/ui/form/form-radio-group/FormRadioGroup'

type TopicsRadioGroupProps = {
  topics: Topic[],
  selectAllTopics: boolean,
  selectedTopicId?: string,
  setSelectAllTopics: (value: boolean) => void
  fieldName: string
}

const useStyles = makeStyles(theme =>
  createStyles({
    bigRadioButton: {
      '& svg': {
        width: '25px',
        height: '25px',
      },
    },
  }),
)

const TopicsRadioGroup: FC<TopicsRadioGroupProps> = ({
                                                       topics,
                                                       selectedTopicId,
                                                       selectAllTopics,
                                                       setSelectAllTopics,
                                                       fieldName,
                                                     }) => {
  const classes = useStyles()
  const { t } = useTranslation()
  const { setFieldValue } = useFormikContext<PracticeQuizCreateRequest>()

  const handleSelectAllTopicsClick = (e: FormEvent<HTMLDivElement>) => {
    e.preventDefault()
    setSelectAllTopics(!selectAllTopics)
    setFieldValue(fieldName, undefined)
  }

  return (
    <Grid>
      <Grid
        className="u-mt-12 u-pl-18 u-cursor-pointer u-br-3"
        onClick={handleSelectAllTopicsClick}
        container
        sx={{
          background: theme => theme.palette.background.paper,
        }}
      >
        <Grid>
          <FormControlLabel
            sx={{
              '& .MuiFormControlLabel-label': {
                fontSize: { xs: '12px', lg: '16px' },
                lineHeight: { xs: '17px', lg: '22px' },
              },
            }}
            control={<Radio/>}
            label={t(translations.subjectPractiseInitPage.selectAll)}
            checked={selectAllTopics}
            className={classes.bigRadioButton}
          />
        </Grid>
        {selectAllTopics &&
        <Grid className="u-pr-20 u-color-silver u-fs-14 u-lh-22 u-d-flex" flexGrow={1} alignItems="center"
              justifyContent="flex-end">
          {t(translations.subjectPractiseInitPage.unselect)}
        </Grid>}
      </Grid>
      <Grid className="u-pl-18">
        <FormRadioGroup
          className={classes.bigRadioButton}
          name={fieldName}
          inputs={topics.map(({ name, id }) => ({
            label: name,
            value: id,
            checked: selectAllTopics || selectedTopicId === id,
          }))}
        />
      </Grid>
    </Grid>
  )
}

export default TopicsRadioGroup
