import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { deletePost } from 'services/community/postService'
import { translations } from 'locales/i18n'

const useDeletePost = () => useMutationWithSnackbar(
  (data: PostActionRequest) => deletePost(data),
  undefined,
  translations.messages.postDeleted,
)

export default useDeletePost
