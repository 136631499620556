import { object, string, ref } from 'yup'
import i18n from 'i18next'
import { translations } from 'locales/i18n'

const LoginInformationFormSchema = object().shape({
  currentPassword: string().required(),
  newPassword: string()
    .min(8, i18n.t(translations.validatorSchema.resetPassword.password.min))
    .max(64, i18n.t(translations.validatorSchema.resetPassword.password.max))
    .matches(/^.*[a-zA-Z].*[0-9].*$/, i18n.t(translations.validatorSchema.resetPassword.password.matches))
    .required(),
  passwordConfirm: string()
    .oneOf([ref('newPassword', ), null], i18n.t(translations.validatorSchema.resetPassword.passwordConfirm.oneOf))
    .required(),
})

export default LoginInformationFormSchema
