import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { QuizAttemptReviewInterface } from 'types/QuizAttemptReviewInterface'
import { getQuizAttemptReview } from 'services/quizAttemtService'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useQuizAttemptReview = (
  quizAttemptId: string,
  options?: UseQueryOptions<QuizAttemptReviewInterface, AxiosError>,
): UseQueryResult<QuizAttemptReviewInterface> =>
  useQueryWithSnackbar<QuizAttemptReviewInterface>(
    [ReactQueryKeys.QUIZ_ATTEMPT_REVIEW, quizAttemptId],
    () => getQuizAttemptReview(quizAttemptId),
    {
      enabled: !!quizAttemptId,
      ...options,
    },
    'getQuizAttemptReview',
  )


export default useQuizAttemptReview
