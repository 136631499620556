import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, Grid } from "@mui/material";

import SimulationCard from "components/quizzes/common/SimulationCard";
import Heading from "./Heading";
import { translations } from "locales/i18n";
import { MonthlySimulationType, StaticExercisesType } from "types/QuizInterface";
import { quizCardBackgroundColors } from 'components/quizzes/common/quizCardBackgroundColors'

type SimulationsProps = {
  simulation: SimulationConfig;
  monthlySimulation?: MonthlySimulationType;
  staticPacks?: StaticExercisesType[];
  randomSimulationQuestionCount?: number;
};

const Simulations: FC<SimulationsProps> = ({
  simulation,
  monthlySimulation,
  staticPacks,
  randomSimulationQuestionCount
}) => {
  const { t } = useTranslation();

  return (
    <Box sx={{ pb: { xs: "30px", md: "40px" } }}>
      <Heading content={t(translations.quizzes.simulations)} />
      <Box>
        <Grid container spacing={{ xs: 1.5, md: 2 }}>
          {
            <>
              {randomSimulationQuestionCount && (
                <Grid item xs={12} sm={6} md={4} sx={{ height: "auto" }}>
                  <SimulationCard
                    title={simulation.title}
                    url={simulation.url}
                    backgroundColor={simulation.backgroundColor}
                    questionsCount={randomSimulationQuestionCount}
                  />
                </Grid>
              )}
              {monthlySimulation && (
                <Grid item xs={12} sm={6} md={4} sx={{ height: "auto" }}>
                  <SimulationCard
                    title={t(translations.quizzes.freeMonthlySimulation)}
                    url={"/quizzes/monthly/new"}
                    backgroundColor={"#2093F8"}
                    questionsCount={
                      monthlySimulation.questionCount
                    }
                  />
                </Grid>
              )}
              {
                staticPacks && staticPacks.map((item, index) => (
                  <Grid key={item.id} item xs={12} sm={6} md={4} sx={{ height: "auto" }}>
                  <SimulationCard
                    title={item.name}
                    url={`/quizzes/pack-simulations/${item.id}`}
                    backgroundColor={quizCardBackgroundColors[index % quizCardBackgroundColors.length]}
                    quizCount={item.quizCount}
                  />
                </Grid>
                ))
              }
            </>
          }
        </Grid>
      </Box>
    </Box>
  );
};

export default Simulations;
