import React, { useState } from 'react'
import { Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import Statistics from 'components/statistics'
import { Back } from 'components/shared/back'
import useChartStatistics from 'hooks/useChartStatistics'
import useSubjectStatistics from 'hooks/useSubjectStatistics'
import { MIN_SIMULATIONS_COUNT_TO_SHOW_STATS } from 'utils/constants'
import { translations } from 'locales/i18n'
import LoadingIndicator from 'components/shared/loading-indicator'
import useQuizAttemptsHistory from 'hooks/useQuizAttemptsHistory'
import { UUID } from '../domain/types'
import useStatisticsScoreAverage from 'hooks/useStatisticsScoreAverage'
import useSubjects from 'hooks/useSubjects'
import useStartQuizPractice from '../hooks/useStartQuizPractice'
import useSectionsStatistics from 'hooks/useSectionsStatistics'

const StatisticsPage = () => {
  const { t } = useTranslation()
  const [chartSubjectFilter, setChartSubjectFilter] = useState<UUID>('')
  const [chartDateFilter, setDateFilter] = useState<number>(0) 

  const statisticsScoreAverageQuery = useStatisticsScoreAverage()
  const subjectStatisticsQuery = useSubjectStatistics()
  const sectionStatisticsQuery = useSectionsStatistics()
  const sections = sectionStatisticsQuery.data
  const chartStatisticsQuery = useChartStatistics(chartSubjectFilter, chartDateFilter)
  const quizAttemptsHistoryQuery = useQuizAttemptsHistory()
  const subjectsQuery = useSubjects()

  const statisticsScoreAverage = statisticsScoreAverageQuery.data
  const chartStatistics = chartStatisticsQuery.data
  const subjectStatistics = subjectStatisticsQuery.data
  const simulations = quizAttemptsHistoryQuery.data
  const subjects = subjectsQuery.data

  
  const showStatistics = statisticsScoreAverage
    && subjectStatistics
    && subjects
    && simulations
    && simulations.content.length >= MIN_SIMULATIONS_COUNT_TO_SHOW_STATS

  const showLoader = statisticsScoreAverageQuery.isLoading
    || chartStatisticsQuery.isLoading
    || subjectStatisticsQuery.isLoading
    || subjectsQuery.isLoading

  const startQuizPracticeMutation = useStartQuizPractice()
  const handleStartQuizPractice = (subjectId: string) =>
    startQuizPracticeMutation.mutate({ subjectId } as PracticeQuizCreateRequest)

  return (
    <DashboardLayout>
      <Back text={t(translations.navigation.homePage)} url={'/'} sx={{ mx: { xs: '20px', lg: 6.5 } }}/>
      {showStatistics && sections &&  <Statistics
          statisticsScoreAverage={statisticsScoreAverage}
          sections={sections}
          chartStatistics={chartStatistics}
          simulations={simulations.content}
          subjectStatistics={subjectStatistics}
          chartSubjectFilter={chartSubjectFilter}
          onChartFilterChange={setChartSubjectFilter}
          onChartDateFilterChange={setDateFilter}
          onStartSubjectQuiz={handleStartQuizPractice}
      />}
      {showLoader && <LoadingIndicator/>}
      {!showStatistics && !showLoader && <Grid className="u-mt-70 u-ml-50">
        {t(translations.statistics.notEnoughData)}
      </Grid>}
    </DashboardLayout>
  )
}

export default StatisticsPage
