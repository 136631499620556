import React, { FC } from 'react'
import { Box } from '@mui/material'
import { PostInterface } from 'types/PostInterface'

type TextPostCardProps = {
  post: PostInterface
}

const TextPostCard: FC<TextPostCardProps> = ({post}) => (
  <Box>
    <Box
      sx={{
        fontSize: {xs: '12px', md: '16px'},
        lineHeight: {xs: '18px', md: '24px'},
        maxWidth: '100%',
        textOverflow: 'ellipsis',
        whiteSpace: 'normal',
        wordBreak: 'break-word'
      }}
      dangerouslySetInnerHTML={{__html: post.postText}}
    />
  </Box>
)

export default TextPostCard
