import React, { FC } from 'react'
import { Grid, useMediaQuery } from '@mui/material'

import { PostFile } from 'types/PostFile'
import { PostFileType } from 'types/PostFileType'
import config from 'utils/config'
import deleteImageIcon from 'images/delete-image.svg'
import deleteFileIcon from 'images/delete-file.svg'
import playIcon from 'images/play-video-for-preview.svg'
import playIconMobile from 'images/play-video-for-preview-mobile.svg'
import theme from 'theme'
import PostVideoPreview from '../PostVideoPreview'
import { ImageSize } from 'types/ImageSize'

type PostFormImageListProps = {
  files: PostFile[]
  onRemoveFile: (fileId: string) => void
}

const PostFormImageList: FC<PostFormImageListProps> = ({files, onRemoveFile}) => {
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))
  const types = [PostFileType.IMAGE, PostFileType.VIDEO]
  return (
    <Grid container spacing="10px">
      {files.filter(({fileType, deleted}) => !deleted && types.includes(fileType))
        .map(({fileUri, fileId, originalFileName, fileType, addedAt }) => (
          <Grid
            key={fileId}
            item
            sx={{
            width: {xs: '105px', sm: '164px'},
            height: {xs: '105px', sm: '164px'},
            position: 'relative',
          }}>
            <img
              src={isSm ? deleteImageIcon : deleteFileIcon}
              style={{
                position: 'absolute',
                top: 20,
                right: 10,
                cursor: 'pointer',
                zIndex: 2,
              }}
              alt="Remove file"
              onClick={() => onRemoveFile(fileId)}
            />
            {fileType === PostFileType.IMAGE
              ? (
                <img
                  key={fileId}
                  src={`${config.cdnBaseApiURI}/${fileUri}?t=${addedAt}${ImageSize.PREVIEW}`}
                  alt={originalFileName}
                  style={{
                    width: '100%',
                    height: '100%',
                    aspectRatio: '4 / 3',
                    objectFit: 'cover',
                    borderRadius: '3px',
                  }}
                />
              )
              : <PostVideoPreview
                fileUri={fileUri}
                playIcon={isSm ? playIcon : playIconMobile}
                borderRadius={true}
              />
            }
          </Grid>
        ))
      }
    </Grid>
  )
}

export default PostFormImageList
