import { reportQuestion } from 'services/quizAttemtService'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { translations } from 'locales/i18n'

const useQuizReport = () => useMutationWithSnackbar(
  (data: ReportQuizAttemt) => reportQuestion(data),
  'reportQuestion',
  translations.lessonPage.reports.sendReport,
)

export default useQuizReport
