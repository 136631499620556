import { clientDataService } from './clientDataService'
import {UUID} from "../domain/types";

export const getChartStatistics = (sectionId: UUID, periodStart: number): Promise<ChartStatistics> => {
  return clientDataService<ChartStatistics>({
    url: `/v3/statistics`,
    params: {
      sectionId,
      periodStart
    }
  })
}

export const getSubjectsStatistics = (): Promise<SubjectStatistics[]> => {
  return clientDataService<SubjectStatistics[]>({
    url: '/statistics/subjects',
  })
}

export const getSectionsStatistics = (): Promise<SectionsStatistics[]> => {
  return clientDataService<SectionsStatistics[]>({
    url: `/v2/sections`,
  })
}

export const getScoreAverageStatistics = (): Promise<ScoreAverageStatistics> =>
  clientDataService<ScoreAverageStatistics>({
    url: '/v3/statistics/score-average',
  })
