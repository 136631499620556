import { ChangeEvent, useEffect, useState } from "react";
import { isNull, isEmpty } from "lodash";
import { FormikHelpers } from "formik/dist/types";

interface UseUploadVideoProps {
  classId: string;
  streamId: string;
  previewImage: File | string | null;
  videoField: string;
  initialVideoUri: string;
  initialFiles: UploadFilesResponse[];
  initialImageUri: string;
  date: number;
  onAddFiles: (data: AddFileToStreamRequest) => Promise<any>;
  onSubmit: (data: UpdateStreamRequest) => Promise<CreateStreamResponse>;
}

interface UseUploadVideoResult {
  uploadingFile: File[];
  currentVideoUri: string;
  currentFiles: UploadFilesResponse[];
  handleDelete: (id: string) => void;

  handleAddFiles: (
    e: ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<UpdateStreamRequest>["setFieldValue"]
  ) => Promise<void>;
  handleSubmit: (data: UpdateStreamRequest) => void;
  handleCancel: (
    setFieldValue: FormikHelpers<UpdateStreamRequest>["setFieldValue"]
  ) => void;
}

const useUploadVideo = ({
  classId,
  streamId,
  previewImage,
  date,
  videoField,
  initialVideoUri,
  initialImageUri,
  onAddFiles,
  onSubmit,
  initialFiles,
}: UseUploadVideoProps): UseUploadVideoResult => {
  const [uploadingFile, setUploadingFile] = useState<File[]>([]);
  const [imageFile, setImageFile] = useState<File | string | null>(null);
  const [currentVideoUri, setCurrentVideoUri] = useState<string>("");
  const [currentFiles, setCurrentFiles] = useState<UploadFilesResponse[]>([]);

  useEffect(() => {
    setImageFile(previewImage);
  }, [previewImage]);

  useEffect(() => {
    setCurrentVideoUri(initialVideoUri);
  }, [initialVideoUri]);

  useEffect(() => {
    setCurrentFiles(initialFiles);
  }, [initialFiles]);

  const handleDelete = (id: string) => {
    setCurrentFiles((prevState) =>
      prevState.map((item) =>
        item.id === id ? { ...item, isDeleted: true } : item
      )
    );
  };

  const handleAddFiles = async (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    setFieldValue: FormikHelpers<UpdateStreamRequest>["setFieldValue"]
  ) => {
    const newFiles = Array.from((e.target as HTMLInputElement).files || []);
    await setUploadingFile(newFiles);
    await setFieldValue(videoField, (e.target as HTMLInputElement).files);
  };
  useEffect(() => {
    if (uploadingFile.length > 0) {
      Promise.allSettled(
        uploadingFile.map(async (file) => {
          const uploadedFile = await onAddFiles({ classId, streamId, file: file });
            if(uploadedFile.type === 'VIDEO') {
              setCurrentVideoUri("");
              setCurrentVideoUri(uploadedFile.fileUri);
            } 
            else {
              setCurrentFiles((prevState) => [...prevState, uploadedFile])
            }
          
          return uploadedFile;
        })
      ).finally(() => setUploadingFile([]));
    }
  }, [uploadingFile]);

  const files = currentFiles.map((item) => ({
    fileUri: item.fileUri,
    fileId: item.id,
    deleted: item.isDeleted || false,
  }));

  const setInitialState = () => {
    setUploadingFile([]);
    setCurrentVideoUri("");
    setCurrentFiles([]);
    setImageFile(null);
  };

  const handleCancel = (
    setFieldValue: FormikHelpers<UpdateStreamRequest>["setFieldValue"]
  ) => {
    setUploadingFile([]);
    setCurrentVideoUri("");
    setFieldValue(videoField, "");
  };

  const handleSubmit = (data: UpdateStreamRequest) => {
    const {
      classId,
      streamId,
      name,
      description,
      speakerId,
      sendNotifications,
      sendEmails,
    } = data;

    const dataWithoutImage = {
      classId,
      streamId,
      name,
      date,
      description,
      speakerId,
      sendNotifications,
      sendEmails,
      files,
    };

    const resultData =
      !isEmpty(initialImageUri) && isNull(imageFile)
        ? dataWithoutImage
        : { ...dataWithoutImage, previewImage: imageFile };
    onSubmit(resultData).then(setInitialState);
  };

  return {
    uploadingFile,
    currentVideoUri,
    currentFiles,
    handleAddFiles,
    handleSubmit,
    handleCancel,
    handleDelete,
  };
};

export default useUploadVideo;
