import { useQueryClient } from 'react-query'
import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { translations } from 'locales/i18n'
import { deleteStream } from 'services/community/streamService'
import ReactQueryKeys from '../../types/ReactQueryKeys'

const useDeleteStream = () => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
  (data: DeleteStreamRequest) => deleteStream(data),
  undefined,
  translations.messages.streamDeleted, {
    async onSuccess() {
      await queryClient.invalidateQueries(ReactQueryKeys.STREAMS)
    },
  }
)}

export default useDeleteStream
