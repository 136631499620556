import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { translations } from 'locales/i18n'
import { Box, Button, Typography } from '@mui/material'
import { ArrowForward } from '@mui/icons-material'
import { isEmpty } from 'lodash'
import { CourseView } from 'types/CourseView'
import { LessonView } from 'types/LessonView'
import { EnrolledLessonState } from 'types/EnrolledLessonState'
import LessonTabs from './LessonTabs'
import LessonMaterial from './LessonMaterial'
import LessonNotes from './LessonNotes'
import ReactQueryKeys from 'types/ReactQueryKeys'
import VideoPlayer from 'components/video-player/VideoPlayer'
import LessonCard from 'components/shared/cards/LessonCard'
import BasicButton from 'components/shared/basic-button/BasicButton'
import ModalReport from 'components/shared/modal/ModalReport'
import SwipeableViews from '../shared/swipeable-views/SwipeableViews'
import config from 'utils/config'

type LessonProps = {
  course: CourseView
  lesson: LessonView
  onStartQuiz: () => void
  onUpdateLessonVideoWatchedSeconds: (currentVideoTime: number) => void
  onNoteTextReset: () => void
  onNoteTextSubmit: (noteText: string) => void
  onInvalidateLesson: () => void
  onCompleteLesson: () => void
  onSendReport: (reportText: string) => void
}

const Lesson: FC<LessonProps> = ({
                                   course,
                                   lesson,
                                   onStartQuiz,
                                   onUpdateLessonVideoWatchedSeconds,
                                   onNoteTextReset,
                                   onNoteTextSubmit,
                                   onInvalidateLesson,
                                   onCompleteLesson,
                                   onSendReport,
                                 }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const history = useHistory()
  const [reportModalOpen, setReportModalOpen] = useState(false)
  const [lessonReport, setLessonReport] = useState('')

  const { id: courseId, name: courseName, lessons, previewImageUri } = course
  const {
    id: lessonId,
    name: lessonName,
    videoSecondsViewed,
    videoUri,
    videoVariants,
    videoChapters,
    text,
    noteText,
    noteTextUpdatedAt,
    quizId,
    state,
  } = lesson

  const currentLessonIndex = lessons.map(({ id }) => id).indexOf(lessonId)
  const nextLessons = lessons.slice(currentLessonIndex + 1, lessons.length)

  const handleCompleteLesson = async () => {
    onCompleteLesson()
    if (nextLessons.length > 0) {
      const nextLesson = nextLessons[0]
      history.push(`/courses/${nextLesson.courseId}/lessons/${nextLesson.id}`)
    }
  }

  const handleReportMessage = (e) => {
    const { value } = e.target
    if (!isEmpty(value.trim())) {
      setLessonReport(value.trim())
    }
  }

  const handleSendMessage = () => {
    if (!isEmpty(lessonReport)) {
      onSendReport(lessonReport)
      setReportModalOpen(false)
    }
  }

  const lessonConfig = quizId ?
    { text: 'takeQuiz', completedText: 'quizRepeat', action: onStartQuiz }
    : { text: 'completeLesson', completedText: 'completed', action: handleCompleteLesson }

  return (
    <>
      <Box className="LessonGrid">
        <Box className="LessonGrid-player">
          {videoUri && <VideoPlayer
              title={courseName}
              titleDescription={lessonName}
              videoFileUri={`${config.cdnBaseApiURI}${videoUri}`}
              videoVariants={videoVariants}
              videoChapters={videoChapters}
              videoStartTimeSeconds={videoSecondsViewed}
              onWatchUpdate={onUpdateLessonVideoWatchedSeconds}
              invalidate={() => queryClient.invalidateQueries([ReactQueryKeys.LESSON, courseId, lessonId])}
          />}
          {!videoUri && (
            <LessonNotes lesson={lesson}
                         onNoteTextReset={onNoteTextReset}
                         onNoteTextSubmit={onNoteTextSubmit}
                         onInvalidateLesson={onInvalidateLesson}/>
          )}
          <Box sx={{ p: { xs: '20px', md: '25px' } }}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: theme => ({ xs: 'none', lg: `1px solid ${theme.palette.background.paper}` }),
                pb: { xs: 0, lg: '20px' },
              }}
            >
              {quizId && (
                <Box>
                  <Typography
                    sx={{ fontSize: { xs: '12px', md: '22px' }, lineHeight: { xs: '14px', md: '27px' }, pb: 0.5 }}>
                    {t(translations.lessonPage.feelReady)}
                  </Typography>
                  <Typography className="u-color-dusty-grey"
                              sx={{ fontSize: { xs: '10px', md: '14px' }, lineHeight: { xs: '14px', md: '20px' } }}>
                    {`${t(translations.lessonPage.youNeedToAnswer)} `}
                    <Typography component={'span'} sx={{ fontSize: 'inherit' }}>
                      {t(translations.lessonPage.nQuestions, { number: 15 })}
                    </Typography>
                    {` ${t(translations.lessonPage.toComplete)}`}
                  </Typography>
                </Box>
              )}
              <Box sx={{ alignSelf: 'center', display: 'flex', flexGrow: 1, justifyContent: 'flex-end' }}>
                {state === EnrolledLessonState.COMPLETED ? (
                  <BasicButton
                    variant="contained"
                    type="button"
                    color="secondary"
                    className="u-text-transform-capitalize"
                    onClick={lessonConfig.action}
                    text={t(translations.lessonPage[lessonConfig.completedText])}
                  >
                    <img src="/images/icons/repeat.svg" alt="repeat quiz" className='u-pl-4'/>
                  </BasicButton>
                ) : (
                  <Button
                    variant="contained"
                    type="button"
                    className="u-text-transform-none"
                    sx={{
                      fontSize: { xs: '12px', md: '16px' },
                      lineHeight: { xs: '14px', md: '19px' },
                      borderRadius: '3px',
                      color: 'white',
                      p: { xs: '8px 10px', md: '8px 20px' },
                    }}
                    onClick={lessonConfig.action}
                  >
                    {t(translations.lessonPage[lessonConfig.text])}
                    <ArrowForward sx={{ pl: 0.5 }}/>
                  </Button>
                )}
              </Box>
            </Box>
            <Box sx={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingTop: '35px',
            }}>
              {/* Todo when we add Related Material and describe Lesson */}
              <Typography>{' ' || 'Related Material'}</Typography>
              <BasicButton
                variant="contained"
                type="button"
                color="secondary"
                text={t(translations.lessonPage.reports.reportVideo)}
                className="u-text-transform-capitalize"
                onClick={() => setReportModalOpen(true)}
              />

              <ModalReport
                open={reportModalOpen}
                onClose={() => setReportModalOpen(false)}
                title={t(translations.lessonPage.reports.reportVideo)}
                mistakeMessage={t(translations.lessonPage.reports.mistakeMessage)}
                content={
                  <img
                    src={`${config.cdnBaseApiURI}${lesson.previewImageUri || previewImageUri}`}
                    alt="preview"
                    width="100%"
                    height="100%"
                  />
                }
                onChangeStudentMessage={handleReportMessage}
                onSendReport={handleSendMessage}
              />
            </Box>
          </Box>
        </Box>
        {!isEmpty(text) && <Box className="LessonGrid-material" sx={{ p: { xs: '0 20px', lg: '0 25px' } }}>
            <LessonMaterial text={text}/>
        </Box>}
        <Box className="LessonGrid-tabs" sx={{ p: { lg: '20px' } }}>
          <LessonTabs
            currentLessonId={lessonId}
            course={course}
            showNotesTab={!!videoUri}
            noteText={noteText}
            noteTextUpdatedAt={noteTextUpdatedAt}
            onNoteTextReset={onNoteTextReset}
            onNoteTextSubmit={onNoteTextSubmit}
          />
        </Box>
      </Box>
      {nextLessons.length > 0 && (
        <Box>
          <Box sx={{ p: { xs: '20px', md: '25px' } }}>
            <Typography sx={{
              mt: 1,
              mb: 2,
              fontSize: { xs: '18px', md: '30px' },
              lineHeight: { xs: '23px', md: '55px' },
              fontWeight: '800',
            }}>
              {t(translations.lessonPage.nextLessons)}
            </Typography>
          </Box>

          <Box sx={{ ml: { xs: '20px', md: '25px' }, mb: { xs: '30px' } }}>
            <SwipeableViews
              spaceBetween={20}
              slidesPerView="auto"
              lastItemSx={{ mr: { xs: '20px', md: '25px' } }}
              views={nextLessons}
              slideRenderer={(index) => {
                const {
                  courseId,
                  id: lessonId,
                  lecturer,
                  name: lessonName,
                  previewImageUri,
                  videoUri,
                  videoLength,
                  videoSecondsViewed,
                  state
                } = nextLessons[index]
                return (
                  <Box sx={{ maxWidth: { xs: '161px', md: '407px' } }}>
                    <LessonCard
                      courseId={courseId}
                      lessonId={lessonId}
                      lecturer={lecturer}
                      name={lessonName}
                      lessonState={state}
                      previewImageUri={previewImageUri}
                      videoUri={videoUri}
                      videoLength={videoLength}
                      videoSecondsViewed={videoSecondsViewed}
                    />
                  </Box>
                )
              }}
            />
          </Box>
        </Box>
      )}
    </>
  )
}

export default Lesson
