import { LegacyRef, useCallback, useEffect, useRef, useState } from 'react'
import { isEmpty } from 'lodash'

import useFileUri from './useFileUri'

interface UsePhotoUploadResult {
  deletePhoto: boolean
  inputFileLabelRef: LegacyRef<HTMLLabelElement>
  photoSrc: string | null
  handlePhotoClick: () => void
  handleFileChange: (file: File | null) => void
  deletePhotoButtonEnable: boolean
  setDeletePhoto: (value: boolean) => void
  setDeletePhotoButtonEnable: (value: boolean) => void
}

const usePhotoUpload = (photoUri: string): UsePhotoUploadResult => {
  const {change: handleFileChange, fileUri} = useFileUri()
  const [photoSrc, setPhotoSrc] = useState<string | null>(null)
  const inputFileLabelRef = useRef<any>(null)
  const [deletePhotoButtonEnable, setDeletePhotoButtonEnable] = useState(!isEmpty(photoUri))
  const [deletePhoto, setDeletePhoto] = useState(false)

  useEffect(() => {
    if (fileUri) {
      setPhotoSrc(fileUri)
      setDeletePhoto(false)
      setDeletePhotoButtonEnable(true)
    } else if (deletePhoto) {
      setPhotoSrc(null)
    } else {
      setPhotoSrc(photoUri)
    }
  }, [deletePhoto, deletePhotoButtonEnable, handleFileChange, fileUri, photoUri])

  const handlePhotoClick = useCallback(
    () => {
      inputFileLabelRef?.current?.click()
    },
    [],
  )

  return {
    deletePhoto,
    inputFileLabelRef,
    photoSrc,
    handlePhotoClick,
    handleFileChange,
    deletePhotoButtonEnable,
    setDeletePhoto,
    setDeletePhotoButtonEnable,
  }
}

export default usePhotoUpload
