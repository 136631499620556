import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, Button, Card, Grid, LinearProgress, Typography } from '@mui/material'
import { camelCase } from 'lodash'
import config from 'utils/config'
import playIcon from 'images/play-icon.svg'
import { CourseSearchView } from 'types/CourseSearchView'
import { EnrolledCourseState } from 'types/EnrolledCourseState'
import { translations } from 'locales/i18n'
import { getCourseContinueLink, getCourseLink } from "../../services/courseLinkService";

type CourseCardProps = {
  course: CourseSearchView
}

const CourseCard: FC<CourseCardProps> = ({course}) => {
  const {t} = useTranslation()

  const {
    coursePreviewImageUri,
    courseState,
    courseLessonsCount,
    courseCompletedLessonsCount,
  } = course
  const isInProgress = courseState === EnrolledCourseState.IN_PROGRESS

  const getStateBoxClassName = () => {
    switch (courseState) {
      case EnrolledCourseState.IN_PROGRESS:
        return 'u-bg-carrot-orange'
      case EnrolledCourseState.UNOPENED:
        return 'u-bg-inherit u-border-1px-solid u-border-white'
      case EnrolledCourseState.COMPLETED:
        return 'u-bg-aqua-forest'
      default:
        return ''
    }
  }

  const renderState = () => {
    const boxClassname = getStateBoxClassName()
    return <Box className={`${boxClassname} u-mb-20`}
                sx={{
                  borderRadius: {xs: '20px', md: '28px'},
                  width: 'max-content',
                  height: {xs: '18.2px', md: '25px'},
                  p: '0 17px',
                  display: 'flex',
                  alignItems: 'center',
                }}
    >
      <Typography component={"span"} sx={{
        fontSize: {xs: '10px', md: '14px'},
        lineHeight: {xs: '12px', md: '17px'},
      }}>{t(translations.courses.state[camelCase(courseState)])}</Typography>
    </Box>
  }

  return (
    <Box className="u-h-auto">
      <Card sx={{borderRadius: '3px'}}>
        <Box
          className="u-position-relative"
          sx={{
            overflow: 'hidden',
            width: '100%',
            height: {xs: '170px', sm: '250px', md: '210px', lg: '240px'},
            background: theme => theme.palette.background.default,
          }}
        >
          <Link to={getCourseLink(course)}>
            <img
              src={`${config.cdnBaseApiURI}${coursePreviewImageUri}`}
              className="u-position-absolute u-w-100 u-h-100"
              style={{objectFit: 'cover'}}
              alt=""
            />
          </Link>
          <Box
            className="u-position-relative"
            sx={{
              zIndex: '1',
              padding: {xs: '20px 10px', md: '25px 20px'},
              pointerEvents: 'none'
            }}
          >
            {renderState()}
          </Box>
        </Box>
        <Box sx={{p: {xs: '20px 10px 10px', md: '25px 20px 20px'}, maxHeight: '115px'}}>
          <LinearProgress variant="determinate" className="ProgressBar"
                          sx={{width: '100%', bgcolor: 'white', '& .MuiLinearProgress-bar': {bgcolor: '#5EA66E'}}}
                          value={courseLessonsCount === 0 ? 0 : courseCompletedLessonsCount / courseLessonsCount * 100}/>
          <Grid container sx={{mt: {xs: '10px', md: '18px'}}} flexDirection="row" justifyContent="space-between">
            <Grid item xs={6}>
              <Typography component={"span"} className="u-weight-bold u-lh-27"
                          sx={{
                            fontSize: {
                              xs: '14px',
                              md: '16px',
                            },
                          }}>{`${courseCompletedLessonsCount}/${courseLessonsCount}`}</Typography>
              <Typography component={"span"} className="u-weight-600 u-lh-19" sx={{
                fontSize: {xs: '12px', md: '16px'},
                display: 'block',
                opacity: '0.5',
              }}>{t(translations.courses.lessons)}</Typography>
            </Grid>
            <Grid item xs={6}>
              {isInProgress ? (
                <Link to={getCourseContinueLink(course)}>
                  <Button
                    variant="contained"
                    type="button"
                    sx={{
                      width: '100%',
                      height: '100%',
                      fontSize: {xs: '12px', md: '16px'},
                      lineHeight: {xs: '14px', md: '19px'},
                      textTransform: 'none',
                    }}
                  >
                    {t(translations.courses.continue)}
                  </Button>
                </Link>
              ) : (
                <Link to={getCourseLink(course)}>
                  <Button sx={{marginLeft: 'auto', marginRight: '-15px', display: 'flex', alignSelf: 'end'}}>
                    <img
                      src={playIcon}
                      alt="Enroll course"
                    />
                  </Button>
                </Link>
              )}
            </Grid>
          </Grid>
        </Box>
      </Card>
    </Box>
  )
}

export default CourseCard
