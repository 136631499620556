import {clientDataService} from './clientDataService'
import {RankingSummaryInterface} from 'types/RankingSummaryInterface'


export const getRankingRecords = (quizId: string): Promise<RankingRecordsInterface> => {
  return clientDataService<RankingRecordsInterface>({
    url: `/ranking/records?quizId=${quizId}`,
  })
}

export const getRankingSummary = () => {
  return clientDataService<RankingSummaryInterface>({
    url: '/ranking/quizzes-summary',
  })
}

