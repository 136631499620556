import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, CircularProgress, Collapse, Typography } from '@mui/material'

import { PostInterface } from 'types/PostInterface'
import commentIcon from 'images/comment.svg'
import { translations } from 'locales/i18n'
import PostCommentFormStarter from './comment-form/PostCommentFormStarter'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import usePostComments from 'hooks/community/usePostComments'
import PostCommentCard from './PostCommentCard'

type PostCardCommentsProps = {
  classId: string
  currentUser: StudentProfileInterface
  post: PostInterface
  isCommentFormSubmitting: boolean
  onTypingActive: (status: boolean) => void
  onCommentCreate: (data: PostCommentRequest) => Promise<PostCommentResponse>
  onCommentUpdate: (data: PostCommentRequest) => Promise<PostCommentResponse>
  inputBackground: string
  onCommentDelete: (data: DeletePostCommentRequest) => void
  onOpenCommentReport?: (data: PostCommentReport) => void
}

const PostComments: FC<PostCardCommentsProps> = ({
                                                   classId,
                                                   currentUser,
                                                   post,
                                                   isCommentFormSubmitting,
                                                   onTypingActive,
                                                   onCommentCreate,
                                                   onCommentUpdate,
                                                   onCommentDelete,
                                                   onOpenCommentReport,
                                                   inputBackground,
                                                 }) => {
  const {t} = useTranslation()

  const [commentsOpen, setCommentsOpen] = useState(false)

  const {commentsCount, postId} = post
  const commentsQuery = usePostComments(classId, postId, commentsOpen)
  const comments = commentsQuery.data
  const isClassMember = currentUser.classes.includes(classId) || currentUser.mainRole === 'ADMIN'
  const toggleCommentsOpen = () => setCommentsOpen(!commentsOpen)

  return (
    <Box
      sx={{
        mt: {xs: '38px', sm: '26px'},
      }}
    >
      <Box
        sx={{
          display: 'flex',
          cursor: 'pointer',
        }}
        onClick={toggleCommentsOpen}
      >
        <img src={commentIcon} alt="Comment" className="u-pr-10"/>
        <Typography
          sx={{
            fontSize: {xs: '10px', sm: '14px'},
            lineHeight: {xs: '12px', sm: '17px'},
            color: '#B5B5B5',
          }}
        >
          {commentsCount > 0 ? `${commentsCount} ${t(translations.community.feed.comments)}` : t(translations.community.feed.comment)}
        </Typography>
      </Box>

      <Collapse in={commentsOpen}>
        {isClassMember &&
          <PostCommentFormStarter
            classId={classId}
            currentUser={currentUser}
            post={post}
            isCommentFormSubmitting={isCommentFormSubmitting}
            onTypingActive={onTypingActive}
            onSubmit={onCommentCreate}
            inputBackground={inputBackground}
          />
        }
        {commentsQuery.isLoading && <CircularProgress/>}
        {comments && comments.map((comment) => (
          <Box key={comment.id} sx={{pt: '30px'}}>
            <PostCommentCard
              classId={classId}
              postId={postId}
              comment={comment}
              isCommentFormSubmitting={isCommentFormSubmitting}
              currentUser={currentUser}
              onTypingActive={onTypingActive}
              onCommentReply={onCommentCreate}
              onCommentUpdate={onCommentUpdate}
              onCommentDelete={onCommentDelete}
              onOpenCommentReport={onOpenCommentReport}
              showMenu={isClassMember}
              inputBackground={inputBackground}
            />
          </Box>
        ))}
      </Collapse>
    </Box>
  )
}
export default PostComments
