import { clientDataService } from './clientDataService'
import { CoursesView } from 'types/CoursesView'
import { CourseView } from 'types/CourseView'

export const getCourses = (): Promise<CoursesView> =>
  clientDataService<CoursesView>({
    url: '/courses?size=1000&sort=courseNumber',
  })

export const getCourse = (courseId: string): Promise<CourseView> =>
  clientDataService<CourseView>({
    url: `/courses/${courseId}`,
  })

export const updateCourseVideoWatchedTime = (data: CourseVideoWatchedSecondsUpdateRequest): Promise<void> =>
  clientDataService<void>({
    url: `/courses/${data.courseId}/video-viewed-seconds`,
    method: 'POST',
    data,
  })
