import { UseQueryResult } from 'react-query'

import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getPostComments } from 'services/community/postCommentService'
import { PostCommentInterface } from 'types/PostCommentInterface'

const usePostComments = (classId: string, postId: string, commentsOpen: boolean): UseQueryResult<PostCommentInterface[]> =>
  useQueryWithSnackbar<PostCommentInterface[]>(
    [ReactQueryKeys.POST_COMMENTS, classId, postId],
    () => getPostComments(classId, postId),
    {
      enabled: commentsOpen,
    },
  )

export default usePostComments
