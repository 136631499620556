import { useQueryClient } from 'react-query'
import { Message } from '@stomp/stompjs'
import { WsMessage } from 'types/ws/WsMessage'
import { WsMessageType } from 'types/ws/WsMessageType'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { PostCommentInterface } from 'types/PostCommentInterface'
import getUpdatedCommentsOnPostCommentAdded
  from 'services/react-query/post-comments/getUpdatedCommentsOnPostCommentAdded'
import getUpdatedCommentsOnPostCommentDeleted
  from 'services/react-query/post-comments/getUpdatedCommentsOnPostCommentDeleted'
import getUpdatedCommentsOnPostCommentUpdated
  from 'services/react-query/post-comments/getUpdatedCommentsOnPostCommentUpdated'

type UseListenToClassPostCommentsResult = {
  handleMessage: (message: Message) => void
}

type UseListenToClassPostCommentsProps = {
  classId: string
}

const useListenToClassPostComments = ({classId}: UseListenToClassPostCommentsProps): UseListenToClassPostCommentsResult => {
  const queryClient = useQueryClient()

  const getPostCommentsKey = (postId: string) => [ReactQueryKeys.POST_COMMENTS, classId, postId]
  const getPostComments = (postId: string) => queryClient.getQueryData<PostCommentInterface[]>(getPostCommentsKey(postId))

  const getUpdatedPostComments = (wsMessage: WsMessage<any & { postId: string }>) => {
    const {type, payload} = wsMessage
    const {postId} = payload
    const postComments = getPostComments(postId)

    switch (type) {
      case WsMessageType.POST_COMMENT_ADDED:
        return getUpdatedCommentsOnPostCommentAdded(postComments, payload)
      case WsMessageType.POST_COMMENT_DELETED:
        return getUpdatedCommentsOnPostCommentDeleted(postComments, payload)
      case WsMessageType.POST_COMMENT_UPDATED:
        return getUpdatedCommentsOnPostCommentUpdated(postComments, payload)
    }
  }

  const handleMessage = (message: Message) => {
    const messageBody = JSON.parse(message.body)
    const wsMessage = messageBody as WsMessage<any>

    const {postId} = wsMessage.payload
    if (postId) {
      const updatedPostComments = getUpdatedPostComments(wsMessage)
      updatedPostComments && queryClient.setQueryData(getPostCommentsKey(postId), updatedPostComments)
    }
  }

  return {handleMessage}
}

export default useListenToClassPostComments
