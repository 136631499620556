import React, { FC } from 'react'
import { Box, List, ListItem, ListItemText } from '@mui/material'
import ContentWithMath from './ContentWithMath'

const indexToLetter = (index: number) => String.fromCharCode(index + 65)

type AnswerReviewProps = {
  selected: boolean
  correct: boolean
  text: string
  index: number
  selectionPercentage: number
  isAnyAnswerSelected: boolean
  className?: string
}

const AnswerOptionReview: FC<AnswerReviewProps> = ({
                                                     selected,
                                                     correct,
                                                     text,
                                                     index,
                                                     selectionPercentage,
                                                     isAnyAnswerSelected,
                                                     className,
                                                   }) => {
  const wrong = !correct && selected
  const isHighlight = (isAnyAnswerSelected && selected && !correct)

  const getImage = () => {
    if (correct) {
      return '/images/icons/correctAnswer.svg'
    } else if (wrong) {
      return '/images/icons/wrongAnswer.svg'
    }
    return '/images/icons/Oval9.svg'
  }

  return (
    <List className="u-p-0">
      <ListItem
        button
        sx={{
          border: {
            xs: `${isHighlight ? '1px solid #F14D4F' : "divider"}`,
            md: `${isHighlight ? '3px solid #F14D4F' : "divider"}`,
          },
          p: {xs: "18px 21px", md: "27px 26px 26px 46px"},
        }}
        className={className}
        divider={index !== 4}

      >
        <ListItemText
          disableTypography={true}
          primary={
            <Box sx={{display: "flex", alignItems: "center"}}>
              <Box sx={{
                display: "flex",
                minWidth: {xs: "16px", md: "25px"},
                maxWidth: {xs: "16px", md: "25px"},
                minHeight: {xs: "16px", md: "25px"},
                maxHeight: {xs: "16px", md: "25px"},
                mr: {xs: "16px", md: "25px"},
              }}>
                <img
                  src={getImage()}
                  alt="oval9"
                  className="u-w-100 u-h-100"
                />
              </Box>

              <Box sx={{
                minHeight: {xs: "13px", md: "20px"},
                minWidth: {xs: "13px", md: "20px"},
                border: {xs: "1px solid", md: "1.7px solid"},
                borderRadius: {xs: "2px", md: "4px"},
                mr: {xs: "14px", md: "25px"},
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
              }}
              >
                <Box sx={{
                  width: "100%",
                  textAlign: "center",
                  fontSize: {xs: "7px", md: "11px"},
                  lineHeight: {xs: 1, md: 'inherit'},
                }}>
                  {indexToLetter(index)}
                </Box>
              </Box>
              <ContentWithMath
                content={text}
                contentSx={{
                  fontSize: {xs: '12px', md: '18px'},
                  lineHeight: {xs: '19px', md: '28px'},
                }}
              />
              {selectionPercentage !== 0 && (
                <Box display="flex" flexGrow={1} justifyContent="flex-end" sx={{
                  fontSize: {xs: '14px', md: '18px'},
                  lineHeight: {xs: '20px', md: '28px'},
                  pr: {md: '10px'},
                }}>
                  {`${selectionPercentage}%`}
                </Box>
              )}
            </Box>
          }/>
      </ListItem>
    </List>
  )
}

export default AnswerOptionReview
