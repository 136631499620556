import { createTheme, darkScrollbar, responsiveFontSizes } from '@mui/material'
import { inputLabelColor, white } from './constants'
import { lighten } from '@material-ui/core'

const theme = createTheme({
  typography: {
    allVariants: {
      color: white,
      fontFamily: 'Inter',
    },
  },
  palette: {
    mode: 'dark',
    common: {
      accent: '#3A9A6E',
      accentLight: '#33B77A',
      accentLearning: '#E86393',
      navigationSelected: '#439FE0',
      cardGradient: 'linear-gradient(180deg, #37679D 0%, #E25F60 97.49%)',
      videoGradient: 'linear-gradient(180deg, rgba(7, 21, 33, 0) {start}%, #071521 {finish}%)',
      accentCard: '#439FE0',
      flag: '#FFFFFF',
    },
    action: {
      active: white,
      hover: 'rgba(255, 255, 255, 0.08)',
      selected: 'rgba(255, 255, 255, 0.16)',
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
    },
    background: {
      default: '#071521',
      paper: '#123754',
      paperLighter: '#153147',
      paperLightest: '#182C3B',
    },
    primary: {
      main: '#3A9A6E',
    },
    secondary: {
      main: 'rgba(255, 255, 255, 0.1)',
      light: 'rgba(255, 255, 255, 0.3)',
      dark: 'rgba(255, 255, 255, 0.03)',
    },
    text: {
      primary: white,
      secondary: inputLabelColor,
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
  },

  components: {
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar({
          track: lighten('#123754', 0.25),
          thumb: lighten('#123754', 0.25),
          active: lighten('#123754', 0.25),
        }),
      },
    },
  },
})

export default responsiveFontSizes(theme)
