import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Box, TextField, Typography, Dialog } from '@mui/material'
import { useFormik } from 'formik'
import * as yup from 'yup'

import useMe from 'hooks/useMe'
import { translations } from 'locales/i18n'
import styles from './WelcomePopup.module.scss'
import useUpdateProfile from 'hooks/useUpdateProfile'
import { LOGO_URL } from 'theme/constants'

const validationSchema = yup.object({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  // email: yup.string(), -- field disabled for MVP
  nickname: yup.string().required(),
})

const WelcomePopUp = () => {
  const { t } = useTranslation()

  const [open, setOpen] = useState(false)

  // const [userEmail, setUserEmail] = useState("") -- field disabled for MVP

  useMe({
    async onSuccess(data) {
      setOpen(data?.completeProfileRequired)
    },
  })

  const updateProfileMutation = useUpdateProfile()

  const handleSubmit = (data: ProfileUpdateRequest) => {
    updateProfileMutation.mutateAsync(data)
      .then(() => setOpen(false))
  }

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      nickname: '',
      deleteProfileImage: false,
      // email: userEmail,   -- field disabled for MVP
    },
    onSubmit: handleSubmit,
    validationSchema: validationSchema,
    enableReinitialize: true,
  })

  // -- field disabled for MVP
  // useEffect(() => {
  //     getMe().then(res => setUserEmail(res.profile.email))
  // }, [])

  return (
    <Dialog className={styles.WelcomePopup} open={open}
            sx={{ mt: { xs: '50px', md: '60px' }, mb: { xs: '0', md: '15px' } }}>
      {/*height: "866px" for window with email and 730px without one*/}
      <Box sx={{
        height: { xs: 'auto', md: '730px' },
        width: { xs: '100%', md: '601px' },
        background: theme => theme.palette.background.paper,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: '3px',
        overflowY: 'auto',
      }}>
        <Box sx={{
          width: '78%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          mt: { xs: '25px', md: '45px' },
          mb: { xs: '35px', md: '63px' },
        }}>
          <Box sx={{ alignItems: 'flex-start' }}>
            <Box sx={{ width: { xs: '44px', md: '59px' }, height: { xs: '25px', md: '33px' } }}>
              <img src={LOGO_URL} alt="logo" className="u-h-100 u-w-100"/>
            </Box>
            <Typography sx={{
              width: { xs: '80%', md: '60%' },
              fontSize: { xs: '18px', md: '34px' },
              lineHeight: { xs: '20px', md: '35px' },
              mt: '30px',
              fontWeight: '600',
            }}>Aggiungi i tuoi dati personali</Typography> {/*Add your personal details*/}
            {/*Add your personal details*/}
            <Typography sx={{
              fontSize: { xs: '12px', md: '16px' },
              lineHeight: { xs: '18px', md: '23px' },
              mt: '16px',
              width: '82%',
              opacity: '0.8',
            }}>
              Abbiamo bisogno di questi dati per tua comodità, in modo che tu possa ritrovarti nelle statistiche
              {/*We need this data for your convenience, so that you can find yourself in statistics*/}
            </Typography>
          </Box>
          <Box sx={{ width: '100%' }}>
            <form
              style={{ display: 'flex', flexDirection: 'column' }}
              onSubmit={formik.handleSubmit}
            >
              <TextField
                className={styles.textfield}
                id="firstName"
                name="firstName"
                label={t(translations.profilePage.firstName)}
                sx={{ '&.MuiTextField-root': { mt: { xs: '25px', md: '45px' } } }}
                InputLabelProps={{ sx: { fontSize: { xs: '9px', md: '12px' } } }}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                onBlur={formik.handleBlur}
                margin={'normal'}
                variant={'standard'}
                autoComplete={'false'}
              />
              <TextField
                className={styles.textfield}
                id="lastName"
                name="lastName"
                label={t(translations.profilePage.lastName)}
                sx={{ '&.MuiTextField-root': { mt: { xs: '25px', md: '45px' } } }}
                InputLabelProps={{ sx: { fontSize: { xs: '9px', md: '12px' } } }}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                onBlur={formik.handleBlur}
                margin={'normal'}
                variant={'standard'}
                autoComplete={'false'}
              />
              {/* -- field disabled for MVP*/}
              {/*<TextField*/}
              {/*    className={styles.textfield}*/}
              {/*    id="email"*/}
              {/*    name="email"*/}
              {/*    label="EMAIL"*/}
              {/*    sx={{'&.MuiTextField-root': {mt: {xs: '25px', md: '45px'}}}}*/}
              {/*    InputLabelProps={{style: {fontSize: "12px"}}}*/}
              {/*    value={formik.values.email}*/}
              {/*    onChange={formik.handleChange}*/}
              {/*    error={formik.touched.email && Boolean(formik.errors.email)}*/}
              {/*    onBlur={formik.handleBlur}*/}
              {/*    margin={"normal"}*/}
              {/*    variant={"standard"}*/}
              {/*    autoComplete={"false"}*/}
              {/*/>*/}
              <TextField
                className={styles.textfield}
                id="nickname"
                name="nickname"
                label={t(translations.profilePage.nickname).toUpperCase()}
                sx={{ '&.MuiTextField-root': { mt: { xs: '25px', md: '45px' } } }}
                InputLabelProps={{ sx: { fontSize: { xs: '9px', md: '12px' } } }}
                value={formik.values.nickname}
                onChange={formik.handleChange}
                error={formik.touched.nickname && Boolean(formik.errors.nickname)}
                onBlur={formik.handleBlur}
                margin={'normal'}
                variant={'standard'}
                autoComplete={'false'}
              />
              <Button type="submit" variant={'contained'} sx={{
                minHeight: { xs: '44px', md: '54px' },
                fontSize: { xs: '12px', md: '15px' },
                lineHeight: { xs: '14px', md: '18px' },
                marginTop: '35px',
                textTransform: 'none',
                borderRadius: '3px',
              }}>Salva</Button>
            </form>
          </Box>
        </Box>
      </Box>
    </Dialog>
  )
}

export default WelcomePopUp
