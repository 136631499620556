import { number, object, string } from 'yup'
import dayjs from 'dayjs'
import i18n from 'i18next'

import { translations } from 'locales/i18n'

const PostFormSchema = object().shape({
  text: string().required().trim(),
  publishAt: number().nullable().test(
    '',
    i18n.t(translations.validatorSchema.postForm.publishAt.test),
    (val) => !val || val > dayjs().unix() * 1000),
})

export default PostFormSchema
