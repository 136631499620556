import Login from "../components/login/Login"
import LoginLayout from "../components/layouts/login/LoginLayout"

const LoginPage = () => (
  <LoginLayout>
    <Login />
  </LoginLayout>
)

export default LoginPage
