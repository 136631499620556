import React, { FC } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material'
import { translations } from 'locales/i18n'

type LinkItem = {
  text: string;
  icon: string;
  link: string;
  tab: string;
}

type TopicMenuProps = {
  classId: string
  isClassMember?: boolean
}

const TopicMenu: FC<TopicMenuProps> = ({classId, isClassMember = false}) => {
  const {t} = useTranslation()
  const location = useLocation()

  const {pathname, search} = location
  const path = `${pathname}${search}`

  const communityLinks = [
    {
      text: t(translations.community.feed.news),
      icon: '/images/icons/community/news.svg',
      link: `/classes/${classId}`,
      tab: '',
    },
    // {
    //   text: t(translations.community.feed.hot),
    //   icon: '/images/icons/community/fire.svg',
    //   link: `/classes/${classId}/hot`,
    //   tab: 'hot',
    // },
   isClassMember && {
      text: t(translations.community.feed.pastStreams),
      icon: '/images/icons/community/live_streams.svg',
      link: `/classes/${classId}/streams`,
      tab: 'past-streams',
    },
    isClassMember && {
      text: t(translations.community.feed.favourites),
      icon: '/images/icons/community/bookmark.svg',
      link: `/classes/${classId}?favouritesOnly=true`,
      tab: 'favourites',
    },
  ]

  return (
    <List
      component="div"
      sx={{
        pt: {xs: 0, md: '45px'},
        pb: {xs: '6px', md: 'unset'},
        display: 'flex',
        flexDirection: {xs: 'row', md: 'column'},
        justifyContent: {xs: 'space-evenly', md: 'unset'},
        position: {xs: 'fixed', md: 'unset'},
        bottom: {xs: 0, md: 'unset'},
        left: {xs: 0, md: 'unset'},
        width: {xs: '100%', md: 'unset'},
        zIndex: {xs: 1200, md: 'unset'},
        background: theme => ({xs: theme.palette.background.paper, md: 'unset'}),
        borderTop: {xs: '1px solid #4A4A4A', md: 'unset'},
      }}
    >
      {(communityLinks
        .filter((linkItem) => !!linkItem) as LinkItem[])
        .map(({ text, icon, link }, index) => (
        <Link to={link} key={index}>
          <ListItem
            component="div"
            button={true}
            className="Navigation"
            sx={{
              pl: {md: 0},
              height: '50px',
              opacity: `${path === link ? '100%' : '60%'}`,
              display: 'flex',
              flexDirection: {xs: 'column', md: 'row'},
            }}
            key={text + index}
          >
            <ListItemIcon sx={{minWidth: '65px', justifyContent: 'center'}}>
              <img src={icon} alt={text} className="Navigation-icon"/>
            </ListItemIcon>
            <ListItemText disableTypography={true} primary={text} className="Navigation-text" sx={{fontSize: {xs:'10px', md: '16px'}}} />
          </ListItem>
        </Link>
      ))}
    </List>
  )
}

export default TopicMenu
