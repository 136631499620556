import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import { lighten } from '@material-ui/core'

import ready from 'images/ready-grey.svg'
import shelfBook from 'images/shelf-book.svg'
import { translations } from 'locales/i18n'
import BasicButton from 'components/shared/basic-button/BasicButton'
import ContentWithMath from './ContentWithMath'

type AnswerExplanationProps = {
  answerExplanation: string,
  correctAnswerText: string,
  onStartReportClick?: () => void
}

const AnswerExplanation: FC<AnswerExplanationProps> = ({
                                                         answerExplanation,
                                                         correctAnswerText,
                                                         onStartReportClick,
                                                       }) => {
  const { t } = useTranslation()
  
  return (
    <Grid container sx={{
      display: 'flex',
      background: theme => lighten(theme.palette.background.paper, 0.1),
      p: { xs: '24px 15px 20px 20px', md: '37px 38px 26px 46px' },
    }}
    >
      <Box className="u-w-100" flexDirection="column">
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: { xs: '5px', md: '7px' },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              mr: { xs: '8px', md: '11px' },
              height: { xs: '6px', md: '8px' },
              width: { xs: '7px', md: '9px' },
            }}>
              <img
                src={ready}
                alt=""
                role="presentation"
                className="u-w-100 u-h-100"
              />
            </Box>
            <Typography
              className="u-weight-bold"
              sx={{
                color: '#9B9FA9',
                letterSpacing: '0.6px',
                fontSize: { xs: '10px', md: '12px' },
                lineHeight: { xs: '12px', md: '15px' },
              }}
            >
              {t(translations.quizAttemptReview.correctAnswer)}
            </Typography>
          </Box>
          <BasicButton
            variant="contained"
            type="button"
            color="secondary"
            text={t(translations.quizAttemptResults.reports.reportQuestion)}
            className="u-text-transform-capitalize"
            onClick={onStartReportClick}
          />
        </Box>
        <Box sx={{mb: {xs: '26px', md: '43px'}}}>
          <ContentWithMath
            content={correctAnswerText}
            contentSx={{fontSize: {xs: '12px', md: '16px'}, lineHeight: {xs: '19px', md: '24px'}}}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mb: { xs: '17px', md: '20px' },
          }}
        >
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            mr: { xs: '7px', md: '10px' },
            height: { xs: '8px', md: '10px' },
            width: { xs: '8px', md: '10px' },
          }}>
            <img
              src={shelfBook}
              alt=""
              role="presentation"
              className="u-w-100 u-h-100"
            />
          </Box>
          <Typography
            className="u-weight-bold"
            sx={{
              color: '#9B9FA9',
              letterSpacing: '0.6px',
              fontSize: { xs: '10px', md: '12px' },
              lineHeight: { xs: '12px', md: '15px' },
            }}
          >
            {t(translations.quizAttemptReview.explanation)}
          </Typography>
        </Box>
      </Box>
      <ContentWithMath
        content={answerExplanation}
        contentSx={{fontSize: {xs: '12px', md: '16px'}, lineHeight: {xs: '21px', md: '24px'}}}
      />
    </Grid>
  )
}

export default AnswerExplanation
