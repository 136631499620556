import React, { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Box,
  Button,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
} from "@mui/material";
import dayjs from "dayjs";

import theme from "theme";
import config from "utils/config";
import CommunityMenu from "components/community/CommunityMenu";
import VideoPlayer from "components/video-player/VideoPlayer";
import { translations } from "locales/i18n";
import downloadVideoIcon from "images/download-video.svg";
import CommunityUserAvatar from "components/community/CommunityUserAvatar";
import getStreamMenuItems from "./getStreamMenuItems";
import {
  H_MM_TIME_FORMAT,
  SHORT_DATE_FORMAT,
  WEEK_DAY_DATE_FORMAT,
} from "utils/constants";
import { getFileAvatar } from "../posts/getFileAvatar";
import { isEmpty } from "lodash";

type StreamShowProps = {
  classId: string;
  stream: ClassStreamUserView;
  showMenu: boolean;
  onEditStream: () => void;
  onDelete: () => void;
};

const StreamShow: FC<StreamShowProps> = ({
  classId,
  stream,
  showMenu,
  onEditStream,
  onDelete,
}) => {
  const { t } = useTranslation();
  const isXs = useMediaQuery(theme.breakpoints.up("xs"));
  const isMd = useMediaQuery(theme.breakpoints.up("md"));
  const isLg = useMediaQuery(theme.breakpoints.up("lg"));

  const {
    id: streamId,
    name,
    description,
    previewImageUri,
    videoUri,
    classStreamSpeaker,
    videoVariants,
    date,
    files,
  } = stream;
  const {
    firstName,
    lastName,
    profileImageUri: speakerProfileImageUri,
  } = classStreamSpeaker;

  const menuItems = getStreamMenuItems({
    classId,
    streamId,
    onEdit: onEditStream,
    onDelete: onDelete,
  });

  const [selectedVideoUri, setSelectedVideoUri] = useState("");

  return (
    <Grid
      container
      sx={{
        justifyContent: { xs: "center" },
        paddingTop: "42px",
      }}
    >
      <Grid
        item
        xs={12}
        md={4}
        sx={{
          maxHeight: "380px",
          pr: { md: "20px", lg: "35px" },
        }}
      >
        <img
          src={`${config.cdnBaseApiURI}${previewImageUri}`}
          alt={name}
          style={{
            borderRadius: isMd ? "3px" : "unset",
            width: "100%",
            height: "auto",
            objectFit: "cover",
          }}
        />
      </Grid>

      <Grid
        item
        xs={12}
        md={8}
        sx={{
          backgroundColor: "#1F2021",
          maxWidth: "940px",
          padding: "20px",
          borderRadius: "3px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Typography
              sx={{
                fontSize: { xs: "20px", md: "26px" },
                lineHeight: { xs: "26px", md: "32px" },
                letterSpacing: { xs: "0.67px", md: "unset" },
                fontWeight: "bold",
                maxWidth: { xs: "calc(100% - 30px)", sm: "80%", lg: "70%" },
                mr: "30px",
              }}
            >
              {name}
            </Typography>
            <Box
              sx={{
                fontSize: { xs: "14px", lg: "16px" },
                lineHeight: { xs: "20px", lg: "22px" },
                letterSpacing: "0.4px",
              }}
            >
              <Typography>
                {`${dayjs(date).format(WEEK_DAY_DATE_FORMAT)}, ${dayjs(
                  date
                ).format(SHORT_DATE_FORMAT)}`}
              </Typography>
            </Box>
          </Box>
          {showMenu && (
            <CommunityMenu
              menuItems={menuItems}
              keyPrefix={streamId}
              sx={{
                background: "rgba(0, 0, 0, 0.5)",
                borderRadius: "50%",
                width: "28px",
                height: "28px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          )}
        </Box>
        <Box
          sx={{
            pt: { xs: "21px", md: "30px" },
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              lineHeight: "15px",
              letterSpacing: "0.5px",
              textTransform: "uppercase",
              opacity: "54%",
              pb: "10px",
            }}
          >
            {t(translations.streams.speaker)}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CommunityUserAvatar
              profileImageUri={speakerProfileImageUri}
              firstName={firstName}
              lastName={lastName}
            />
            <Typography
              sx={{
                ml: { xs: "10px", md: "15px" },
                fontSize: "16px",
                lineHeight: "19px",
                fontWeight: "500",
              }}
            >
              {`${firstName} ${lastName}`}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            mt: { xs: "20px", md: "24px" },
            ml: "-20px",
            width: "calc(100% + 40px)",
          }}
        >
          <VideoPlayer
            videoVariants={videoVariants}
            videoFileUri={`${config.cdnBaseApiURI}${videoUri}`}
            controlsSx={{
              background: {
                xs: "linear-gradient(180deg, rgba(31, 32, 33, 0) 24.56%, #1F2021 71.5%)",
                md: "linear-gradient(180deg, rgba(31, 32, 33, 0) 0%, #1F2021 56.25%)",
              },
            }}
            showSpeedVariants={isLg || (isXs && !isMd)}
            show15s={isLg || (isXs && !isMd)}
            onVideoQualityChanged={setSelectedVideoUri}
          />
        </Box>

        <Box
          sx={{
            pt: { xs: 0, md: "13px", lg: "20px" },
            pb: { xs: "15px", md: "24px" },
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "15px",
                letterSpacing: "0.5px",
                textTransform: "uppercase",
                opacity: "54%",
                pb: "10px",
              }}
            >
              {t(translations.streams.shortDescription)}
            </Typography>
            <Typography
              sx={{
                fontSize: { xs: "12px", md: "16px" },
                lineHeight: { xs: "18px", md: "22px" },
                letterSpacing: { xs: "0.28px", md: "unset" },
                pb: "10px",
                pr: "10px"
              }}
            >
              {description}
            </Typography>
          </Box>
          <Button
            sx={{
              fontSize: { xs: "12px", md: "16px" },
              lineHeight: { xs: "14px", md: "19px" },
              height: "44px",
              borderRadius: "3px",
              bgcolor: "#4780D8",
              color: "white",
              p: { xs: "8px 10px", md: "8px 20px" },
              textTransform: "none",
              minWidth: {xs: "60px" ,sm: "200px"}
            }}
          >
            <a
              href={selectedVideoUri}
              target="_blank"
              download
              style={{ display: "flex" }}
            >
              <img src={downloadVideoIcon} alt="Download video" />
              <Typography
                sx={{ ml: "10px", display: { xs: "none", sm: "block" } }}
              >
                {t(translations.streams.downloadVideo)}
              </Typography>
            </a>
          </Button>
        </Box>
        {!isEmpty(files) && (
          <Box>
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "15px",
                letterSpacing: "0.5px",
                textTransform: "uppercase",
                opacity: "54%",
                pb: "20px",
              }}
            >
              ATTACHED FILES
            </Typography>

            <Grid
              container
              lg={10}
              sm={12}
              xs={12}
              spacing={{ xs: 2, md: 2 }}
              columns={{ xs: 3, sm: 4, lg: 12 }}
              sx={{ flexWrap: "wrap" }}
            >
              {files.map((item, index) => (
                <Grid
                  item
                  xs={12}
                  sm={2}
                  lg={4}
                  key={index}
                  sx={{
                    pt: "5px",
                    "&:hover": { backgroundColor: "rgba(255, 255, 255, 0.08)" },
                    borderRadius: "3px",
                  }}
                >
                  <a
                    href={`${config.cdnBaseApiURI}/${item.fileUri}`}
                    target="_blank"
                    rel="noreferrer"
                    download={item.originalName}
                    style={{ display: "flex" }}
                  >
                    <Box
                      sx={{
                        borderRadius: "3px",
                        py: { xs: "2px", sm: "4px" },
                        display: "flex",
                      }}
                      key={1}
                    >
                      <ListItemAvatar>
                        <IconButton
                          edge="start"
                          aria-label="File type"
                          sx={{
                            "&:hover": {
                              backgroundColor: "initial",
                            },
                          }}
                        >
                          <img
                            src={getFileAvatar(item.extension)}
                            alt="File type"
                          />
                        </IconButton>
                      </ListItemAvatar>
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              width: "120px",
                              overflow: "hidden",
                              display: "inline-block",
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {item.originalName}
                          </Typography>
                        }
                        secondary={`${Math.floor(item.size / 1024)} KB`}
                      />
                    </Box>
                  </a>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
export default StreamShow;
