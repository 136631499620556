import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getNotifications } from 'services/community/notificationService'
import { NotificationInterface } from 'types/NotificationInterface'

const useNotifications = (options?: UseQueryOptions<NotificationInterface[], AxiosError>): UseQueryResult<NotificationInterface[]> => {
  return useQueryWithSnackbar<NotificationInterface[]>(
    [ReactQueryKeys.NOTIFICATIONS],
    () => getNotifications(),
    options,
  )
}

export default useNotifications
