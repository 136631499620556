import React, { FC } from 'react'
import { Box, ListItem } from '@mui/material'

import PollVotesList from 'components/community/posts/post-card/poll/PollVotesList'
import { StudentProfileInterface } from 'types/StudentProfileInterface'

type PollPostCardOptionProps = {
  pollOption: PollOption
  totalVotes: number
  maxVotesCount: number
  currentUser: StudentProfileInterface
  onCreateVotePollOption: (optionId: string) => void
  onUpdateVotePollOption: (optionId: string) => void
  selectedOptionId?: string
  canVote: boolean
  pinned: boolean
}

const PollPostCardOption: FC<PollPostCardOptionProps> = ({
                                                           pollOption,
                                                           totalVotes,
                                                           maxVotesCount,
                                                           currentUser,
                                                           onCreateVotePollOption,
                                                           onUpdateVotePollOption,
                                                           selectedOptionId,
                                                           canVote,
                                                           pinned,
                                                         }) => {
  const {id: optionId, optionText, voteCount, votedUsers} = pollOption
  const ableToVote = canVote && selectedOptionId !== optionId

  const handleVoteOption = () => {
    if (ableToVote) {
      if (selectedOptionId) {
        onUpdateVotePollOption(optionId)
      } else {
        onCreateVotePollOption(optionId)
      }
    }
  }

  return (
    <ListItem
      sx={{
        p: 0,
        mt: '10px',
        border: 'none',
        flexDirection: {xs: 'column', sm: 'row'},
        justifyContent: 'space-between',
        minHeight: {xs: '43px', md: '80px'},
      }}
    >
      <Box sx={{display: 'flex', alignItems: 'center', width: "100%"}}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            cursor: ableToVote ? 'pointer' : 'inherit',
          }}
          onClick={handleVoteOption}
        >
          <Box
            sx={{
              display: "flex",
              minWidth: {xs: "16px", md: "25px"},
              maxWidth: {xs: "16px", md: "25px"},
              minHeight: {xs: "16px", md: "25px"},
              maxHeight: {xs: "16px", md: "25px"},
              mr: {xs: "16px", md: "25px"},
            }}
          >
            <img
              src={optionId === selectedOptionId ? "/images/icons/correctAnswer.svg" : "/images/icons/Oval9.svg"}
              alt="oval9"
              className="u-w-100 u-h-100"
            />
          </Box>
          <Box
            sx={{
              width: '100%',
            }}
          >
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              position: 'relative',
              width: '100%',
              minHeight: {xs: '43px', md: '80px'},
              px: {xs: "10px", md: "25px"},
              border: '1px solid rgba(255,255,255,0.2)',
              borderRadius: '3px',
              fontSize: {xs: '12px', md: '18px'},
              lineHeight: {xs: '19px', md: '28px'},
            }}>
              <Box sx={{
                py: {xs: '5px', md: '10px'},
                height: '100%',
              }}
              >
                {optionText}
              </Box>
              <Box sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: selectedOptionId ? `${(voteCount || 0) / totalVotes * 100}%` : 0,
                height: '100%',
                backgroundColor: 'rgba(255,255,255,0.2)',
              }}
              />
            </Box>
          </Box>
        </Box>
        {selectedOptionId && (
          <PollVotesList
            currentUser={currentUser}
            votedUsers={votedUsers}
            maxVotesCount={maxVotesCount}
            pinned={pinned}
          />
        )}
      </Box>
    </ListItem>
  )
}

export default PollPostCardOption
