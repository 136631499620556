import React, { useState, useCallback } from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { debounce } from 'lodash'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'
import { Box, Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import { Dayjs } from 'dayjs'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import StreamList from 'components/community/streams/StreamList'
import Searchbar from 'components/community/posts/SearchBar'
import TopicMenu from 'components/community/TopicMenu'
import { Back } from 'components/shared/back'
import { StreamTitle } from 'components/community/ui/StyledComponent'
import { DEBOUNCE_TYPING_MILLI } from 'utils/constants'
import useStreams from 'hooks/community/useStreams'
import useCreateStream from 'hooks/community/useCreateStream'
import useMe from 'hooks/useMe'
import useDeleteStream from 'hooks/community/useDeleteStream'
import { UserProfileRole } from 'types/UserProfileRole'

const StreamListPage = (props: RouteComponentProps<{ classId?: string }>) => {
  const {t} = useTranslation()
  const history = useHistory()
  const classId: string = props.match.params.classId || ''

  const [myStreamsOnly, setMyStreamsOnly] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [dateFilterOpen, setDateFilterOpen] = useState(false)
  const [date, setDate] = useState<Dayjs | null | undefined>()

  const toggleMyStreamsOnly = () => setMyStreamsOnly(prevState => !prevState)
  const toggleDateFilterOpen = () => setDateFilterOpen(prevState => !prevState)
  const clearFilters = () => {
    setMyStreamsOnly(false)
    setDate(null)
  }

  const streamsQuery = useStreams(classId, searchQuery, myStreamsOnly, date && date.unix() * 1000)
  const streams = streamsQuery.data?.content || []

  const meQuery = useMe()
  const { mainRole, classes } = meQuery.data || {}
  const isClassMember = classes?.includes(classId) || mainRole === 'ADMIN'
  const createStreamMutation = useCreateStream()
  const deleteStreamMutation = useDeleteStream()

  const handleDeleteStream = (data: DeleteStreamRequest) => deleteStreamMutation.mutate(data)
  const handleEditStream = (streamId: string) => history.push(`/classes/${classId}/streams/${streamId}/edit`)

  const handleStreamCreate = () => createStreamMutation.mutateAsync(
    { classId } as CreateStreamRequest
  )

  const setSearchQueryDebounced = useCallback(
    debounce(
      nextValue => setSearchQuery(nextValue),
      DEBOUNCE_TYPING_MILLI,
    ),
    [],
  )

  return (
    <DashboardLayout>
      <Back
        text={t(translations.navigation.community)}
        url={`/classes/${classId}`}
        sx={{mx: {xs: '20px', md: 6.5}}}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: {xs: 'column', md: 'row'},
          justifyContent: {xs: 'center', md: 'space-between'},
          padding: {xs: '0px 20px', md: '0px 55px'},
          marginTop: {xs: '20px', md: '66px'},
        }}
      >
        <Box sx={{maxWidth: {md: '235px', lg: 'unset'}, pt: {xs: '20px', md: 0}}}>
          <StreamTitle sx={{pb: '22px'}}>{t(translations.community.feed.pastStreams)}</StreamTitle>
          <Searchbar setSearchQuery={setSearchQueryDebounced}/>
          {(mainRole === "TEACHER" || mainRole === "ADMIN" ) &&
            <Button
              className='u-bg-sunglo'
              sx={{
                width: {xs: '60px', md: '100%'},
                height: {xs: '60px', md: 'unset'},
                paddingY: '10px',
                marginTop: '46px',
                color: '#FFFFFF',
                borderRadius: {xs: '50%', md: '3px'},
                justifyContent: 'center',
                alignItems: 'center',
                textTransform: 'none',
                position: {xs: 'fixed', md: 'unset'},
                bottom: {xs: '130px', md: 'unset'},
                right: {xs: '20px', md: 'unset'},
                zIndex: 2,
            }}
            onClick={handleStreamCreate}
            >
              <AddIcon/>
              <Box sx={{display: {xs: 'none', md: 'block'}}}>
                {t(translations.streams.addStream)}
              </Box>
          </Button>
        }
          <TopicMenu classId={classId} isClassMember={isClassMember}/>
        </Box>
        <StreamList
          classId={classId}
          streams={streams}
          isLoading={streamsQuery.isLoading}
          myStreamsOnly={myStreamsOnly}
          toggleMyStreamsOnly={toggleMyStreamsOnly}
          date={date}
          setDate={setDate}
          dateFilterOpen={dateFilterOpen}
          setDateFilterOpen={setDateFilterOpen}
          toggleDateFilterOpen={toggleDateFilterOpen}
          onClearFilters={clearFilters}
          showMenu={mainRole !== UserProfileRole.STUDENT}
          onDeleteStream={handleDeleteStream}
          onEditStream={handleEditStream}
        />
      </Box>
    </DashboardLayout>
  )
}

export default StreamListPage
