import { Dispatch, SetStateAction } from 'react'

import { Posts } from 'types/Posts'
import { PostInterface } from 'types/PostInterface'

const getUpdatedPostsOnCreateVotePoll = (
  posts: Posts | undefined,
  payload: WsMessagePayloadPollVoteAdded,
  setPosts?: Dispatch<SetStateAction<PostInterface[]>>,
) => {
  const {postId, optionId, votedUser} = payload

  const updatePost = (post: PostInterface) => post.postId === postId
    ? {
      ...post,
      pollOptions: post.pollOptions?.map((option) =>
        option.id === optionId
          ? {
            ...option,
            voteCount: option.voteCount + 1,
            votedUsers: [...(option.votedUsers || []), votedUser],
          }
          : {
            ...option,
            voteCount: (option.votedUsers || []).find(({id}) => id === votedUser.id) ? option.voteCount - 1 : option.voteCount,
            votedUsers: (option.votedUsers || []).filter(({id}) => id !== votedUser.id),
          }),
    }
    : post

  if (setPosts) {
    setPosts((prevValue) => (
      prevValue.map(updatePost)
    ))
  }

  return ({
    ...posts,
    content: posts?.content?.map(updatePost),
  } as Posts)
}

export default getUpdatedPostsOnCreateVotePoll
