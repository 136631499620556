import textFileIcon from 'images/file-text.svg'
import excelFileIcon from 'images/file-excel.svg'
import pdfFileIcon from 'images/file-pdf.svg'
import defaultFileIcon from 'images/file-default.svg'

export const getFileAvatar = (fileExtension: string) => {
  switch (fileExtension) {
    case 'pdf':
      return pdfFileIcon

    case 'xlsx':
    case 'xlsm':
    case 'xlsb':
    case 'xltx':
    case 'xltm':
    case 'xls':
    case 'xlt':
    case 'xml':
    case 'xlam':
    case 'xla':
    case 'xlw':
    case 'xlr':
      return excelFileIcon

    case 'prn':
    case 'txt':
    case 'csv':
    case 'dif':
    case 'slk':
    case 'doc':
    case 'dot':
    case 'wbk':
    case 'docx':
    case 'docm':
    case 'dotx':
    case 'dotm':
    case 'docb':
    case 'wll':
    case 'wwl':
    case 'odp':
      return textFileIcon

    default:
      return defaultFileIcon
  }
}
