import { clientDataService } from './clientDataService'

export const getBlogs = (page: number, size: number): Promise<BlogsView> =>
  clientDataService<BlogsView>({
    url: `/blogs?page=${page}&size=${size}&sort=publishedAt,DESC`,
  })

export const getBlog = (blogId: string): Promise<BlogInterface> =>
  clientDataService<BlogInterface>({
    url: `/blogs/${blogId}`,
  })
