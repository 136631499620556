import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { translations } from 'locales/i18n'
import wrongAnswer from 'images/wrong-answer.svg'
import wrongAnswerMobile from 'images/wrong-answer-mobile.svg'
import ContentWithMath from 'components/quiz-attempt-review/quiz-question-card/ContentWithMath'

type QuestionProps = {
  questionText: string
  isAnyAnswerSelected?: boolean
}

const Question: FC<QuestionProps> = ({questionText, isAnyAnswerSelected = true}) => {
  const {t} = useTranslation()

  return (
    <Box>
      <Box sx={{ pt: '16px' }}>
        <ContentWithMath
          content={questionText}
          contentSx={{
            fontSize: {xs: "12px", md: "18px"},
            lineHeight: {xs: "21px", md: "30px"},
            width: {xs: "100%", md: "85%"},
          }}
        />
      </Box>

      {!isAnyAnswerSelected && (
        <Box className="u-bg-carnation"
             sx={{
               borderRadius: {xs: '20px', md: '28px'},
               width: 'max-content',
               height: {xs: '24px', md: '42px'},
               pl: {xs: '6px', md: '11px'},
               pr: {xs: '10px', md: '17px'},
               display: 'flex',
               alignItems: 'center',
               mt: {xs: '12px', sm: '25px'},
             }}
        >
          <Box sx={{display: {xs: 'none', md: 'flex'}}}>
            <img
              src={wrongAnswer}
              alt={t(translations.quizAttemptReview.notGivenAnswer)}
              className="u-pr-7"
            />
          </Box>
          <Box sx={{display: {xs: 'flex', md: 'none'}}}>
            <img
              src={wrongAnswerMobile}
              alt={t(translations.quizAttemptReview.notGivenAnswer)}
              className="u-pr-10"
            />
          </Box>
          <Typography component={"span"} sx={{
            fontSize: {xs: '10px', md: '16px'},
            lineHeight: {xs: '12px', md: '19px'},
            fontWeight: 'bold',
          }}>{t(translations.quizAttemptReview.notGivenAnswer)}</Typography>
        </Box>
      )}
    </Box>
  )
}

export default Question
