import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Sentry.setContext('staging', { "environment": "staging" })
Sentry.setContext('development', { "environment": "development" })

const Logger = () => Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: process.env.NODE_ENV,
  debug: false,
  maxBreadcrumbs: 50,

  tracesSampleRate: 0,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
})

export default Logger
