import React, { FC } from 'react'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { Box, Typography, TextField, Modal, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'
import { CancelButton, UpdateButton } from 'components/profile/buttons'
import theme from 'theme'
import Overlay from './Overlay'

type ModalReportProps = {
  open: boolean
  onClose: () => void
  title: string
  submitTitle?: string
  titleBgColor?: string
  childrenBgColor?: string
  mistakeMessage?: string
  content: React.ReactNode
  onChangeStudentMessage?: (Object) => void
  onSendReport?: (Object) => void
  mobileOnly?: boolean
  sx?: SxProps<Theme>
}

const useStyles = makeStyles(theme =>
  createStyles ({
    modalReport: {
      position: 'fixed',
      overflow:'scroll',
      height:'100%',
      width: '100%',
      display:'block'
    }
  }));

const ModalReport: FC<ModalReportProps> = ({
                                            open,
                                            onClose,
                                            title,
                                            submitTitle,
                                            titleBgColor = theme.palette.background.default,
                                            mistakeMessage,
                                            content,
                                            childrenBgColor = theme.palette.background.paper,
                                            onSendReport,
                                            onChangeStudentMessage,
                                            mobileOnly = false,
                                            sx,
                                            }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

return (
  <>
    {isSm && !mobileOnly && (
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className={classes.modalReport}
        sx={{display: {xs: 'none', sm: 'block'}}}
      >
        <Box sx={{
                  position: 'absolute',
                  zIndex: '100',
                  top: '5%',
                  left: '50%',
                  width: '100%',
                  maxWidth: {xs: '500px', sm: '721px'},
                  transform: 'translate(-50%, 0%)',
                  bgcolor: 'background.paper',
                  border: 'none',
                  outline: 'none',
                  borderRadius: '3px',
                  boxShadow: 24,
                  padding: {xs: '20px', sm: '40px 60px', md: '40px 90px'},
                  ...sx,                 
                }}
        >
          <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
            <Box sx={{ fontSize: '34px', lineHeight: '41px'}}>
              {title}
            </Box>
            <Box onClick={onClose} sx={{width: '50px', height: '50px', padding: '10px', marginRight: {xs: '-30px', md: '-50px'}}}>
              <img src="/images/close-report-modal.svg" className="u-cursor-pointer u-w-100" alt="Close"/>
            </Box>
          </Box>
          <Typography sx={{ fontWeight: 400, paddingTop: '10px' }}>
            {mistakeMessage}
          </Typography>
          <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                marginY: '20px',
                maxWidth: {xs: '100%', sm: '721px'},
               // backgroundColor: '#212324',    
              }}
          >
            {content}
          </Box>
          {onChangeStudentMessage &&
            <TextField
              name="message"
              label={t(translations.lessonPage.reports.message)}
              placeholder={t(translations.lessonPage.reports.enterText)}
              variant="standard"
              autoComplete="off"
              InputLabelProps={{
                style: {
                          fontSize: '11px',
                          lineHeight: '1.18',
                          color: '#B2B5BD',
                      }}}
              sx={{ width: '100%' }}
              onChange={onChangeStudentMessage}
            />}
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: { xs: '25px', md: '50px' }}}>
            <CancelButton
              sx={{ color: 'white', marginRight: '10px', fontSize: { xs: '12px', sm: '16px' } }}
              onClick={onClose}
            >
              {t(translations.common.cancel)}
            </CancelButton>
            <UpdateButton
              sx={{ color: 'white', fontSize: { xs: '12px', sm: '16px' } }}
              onClick={onSendReport}
            >
              {submitTitle || t(translations.lessonPage.reports.sendReport)}
            </UpdateButton>
          </Box>
        </Box>
      </Modal>
    )}

    {!isSm && open && (
      <Overlay
        open={open}
        onClose={onClose}
        title={title}
        titleBgColor={titleBgColor}
        childrenBgColor={childrenBgColor}
      >
        <Box sx={{height: 'calc(100% - 60px)', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
          {content}
          <Box sx={{paddingX: '20px', paddingBottom: '20px'}}>
            <TextField
              name="message"
              label={t(translations.lessonPage.reports.message)}
              placeholder={t(translations.lessonPage.reports.enterText)}
              variant="standard"
              InputLabelProps={{
                    style: {
                        fontSize: '11px',
                        lineHeight: '1.18',
                        color: '#B2B5BD',
                    }}}
              sx={{ width: '100%' }}
              onChange={onChangeStudentMessage}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: { xs: '25px', md: '50px' } }}>
              <CancelButton
                sx={{ color: 'white', marginRight: '10px', fontSize: { xs: '12px', sm: '16px' } }}
                onClick={onClose}
              >
                {t(translations.common.cancel)}
              </CancelButton>
              <UpdateButton
                sx={{ color: 'white', fontSize: { xs: '12px', sm: '16px' } }}
                onClick={onSendReport}
              >
                {submitTitle || t(translations.lessonPage.reports.sendReport)}
              </UpdateButton>
             </Box>
          </Box>
        </Box>
      </Overlay>
    )}
  </>
)
}

export default ModalReport
