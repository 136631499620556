import React, { FC, RefObject, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography, IconButton, Slider, Select, MenuItem, useMediaQuery } from '@mui/material'
import { KeyboardArrowDownRounded, Settings } from '@mui/icons-material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { isEmpty } from 'lodash'

import playerFullScreenIcon from 'images/player-full-screen-icon.svg'
import playerFullScreenIconMobile from 'images/player-full-screen-icon-mobile.svg'
import playerQuitFullScreenIcon from 'images/player-quit-full-screen-icon.svg'
import playerQuitFullScreenIconMobile from 'images/player-quit-full-screen-icon-mobile.svg'
import playerNextIcon from 'images/player-next-icon.svg'
import playerNextIconMobile from 'images/player-next-icon-mobile.svg'
import playerPrevIcon from 'images/player-prev-icon.svg'
import playerPrevIconMobile from 'images/player-prev-icon-mobile.svg'
import playerVolumeIcon from 'images/player-volume-icon.svg'
import playerMuteIcon from 'images/player-mute-icon.svg'
import playerPauseIcon from 'images/player-pause-icon.svg'
import playerPlayIcon from 'images/player-play-icon.svg'
import playerQualityScale from 'images/player-quality-scale.svg'
import playerPlaySpeed from 'images/player-play-speed.svg'
import threeDots from 'images/three-horizontal-dots.svg'
import { translations } from 'locales/i18n'
import FixedBottomNestedMenu from 'components/shared/fixed-bottom-nested-menu/FixedBottomNestedMenu'
import SliderValueTooltip from './SliderValueTooltip'
import theme from 'theme'

const playbackSpeed = [1, 1.25, 1.5, 1.75, 2]

const style = {
  slider: {
    '& .MuiSlider-track': {
      bgcolor: '#F17576',
      borderColor: '#F17576',
      height: { xs: '1px', md: '4px' },
    },
    '& .MuiSlider-rail': {
      height: { xs: '1px', md: '4px' },
    },
    '& .MuiSlider-thumb': {
      color: '#F17576',
      borderColor: '#F17576',
      width: '15px',
      height: '15px',
      ':hover': { boxShadow: '0px 0px 0px 8px rgb(241 117 118 / 16%)' },
    },
    '& .MuiSlider-valueLabel': {
      fontSize: '16px',
      lineHeight: '32px',
      background: 'unset',
    },
    '& .MuiSlider-mark': {
      backgroundColor: '#EC4647',
      height: 'inherit',
      width: '5px',
      '&.MuiSlider-markActive': {
        opacity: 1,
        backgroundColor: 'currentColor',
      },
    },
    '&': {
      color: 'rgba(255, 255, 255, 0.5)',
      borderRadius: 0,
      p: {
        md: 0.5,
      },
    },
  },
  select: {
    width: 85,
    height: 24,
    textAlign: 'right',
    borderRadius: 0,
    '& .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded': {
      borderRadius: '0',
    },
    '& .MuiSelect-select': {
      padding: '10px',
      margin: 0,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },

} as const

const iconStyles = {
  width: { xs: '30px', md: '50px' },
  height: { xs: '30px', md: '50px' },
} as const

type VideoPlayerControlsProps = {
  title?: string
  titleDescription?: string
  qualityScale?: string
  qualityScales?: string[]
  videoChapters?: AddVideoChapterData[]
  playing: boolean
  muted: boolean
  fullscreen: boolean
  played: number
  durationSeconds: number
  playbackRate: number
  totalDurationFormatted: string
  elapsedTimeFormatted: string
  seeking: boolean
  innerRef: RefObject<any>
  onPlayPause: () => void
  onRewind: () => void
  onFastforward: () => void
  onMute: () => void
  onPlaybackRateChange: (value: number) => void
  onToggleFullScreen: () => void
  onSeek: (evt, newValue) => void
  onSeekMouseUp: (evt, newValue) => void
  onSeekMouseDown: () => void
  onQualityScaleChange?: (scale: string) => void
  controlsSx?: SxProps<Theme>
  showSpeedVariants: boolean
  show15s: boolean
}

const VideoPlayerControls: FC<VideoPlayerControlsProps> = ({
                                                        title,
                                                        titleDescription,
                                                        qualityScale,
                                                        qualityScales = [],
                                                        videoChapters = [],
                                                        playing,
                                                        muted,
                                                        fullscreen,
                                                        played,
                                                        durationSeconds,
                                                        playbackRate,
                                                        totalDurationFormatted,
                                                        elapsedTimeFormatted,
                                                        innerRef,
                                                        onPlayPause,
                                                        onRewind,
                                                        onFastforward,
                                                        onMute,
                                                        onPlaybackRateChange,
                                                        onToggleFullScreen,
                                                        onSeek,
                                                        onSeekMouseUp,
                                                        onSeekMouseDown,
                                                        onQualityScaleChange,
                                                        controlsSx,
                                                        showSpeedVariants,
                                                        show15s,
                                                      }) => {
  const { t } = useTranslation()
  const isLaptop = useMediaQuery(theme.breakpoints.up('md'))

  const playbackRateRef = useRef(null)
  const qualityScaleRef = useRef(null)
  const [playbackRateAnchorEl, setPlaybackRateAnchorEl] = useState(null)
  const [qualityScaleAnchorEl, setQualityScaleAnchorEl] = useState(null)
  const [openRestMenu, setOpenRestMenu] = useState(false)

  const restMenuItems = [
    qualityScales?.length > 0 && ({
      icon: playerQualityScale,
      title: t(translations.videoPlayer.videoQuality),
      nestedItems: qualityScales.map((scale) => (
        {
          title: scale,
          selected: scale === qualityScale,
          onClick: () => onQualityScaleChange && onQualityScaleChange(scale),
        } as MenuItem
      )),
    } as MenuItem),
    showSpeedVariants && ({
      icon: playerPlaySpeed,
      title: t(translations.videoPlayer.playSpeed),
      nestedItems: playbackSpeed.map((speed) => (
        {
          title: `${speed}x`,
          selected: speed === playbackRate,
          onClick: () => onPlaybackRateChange(speed),
        } as MenuItem
      )),
    } as MenuItem),
  ].filter((menuItem) => !!menuItem) as MenuItem[]

  const valueLabelFormat = (percents: number) =>
    <SliderValueTooltip percents={percents} durationSeconds={durationSeconds} videoChapters={videoChapters}/>

  React.useEffect(() => {
    if (playbackRateRef.current) {
      setPlaybackRateAnchorEl(playbackRateRef.current)
    }
  }, [playbackRateRef])

  React.useEffect(() => {
    if (qualityScaleRef.current) {
      setQualityScaleAnchorEl(qualityScaleRef.current)
    }
  }, [qualityScaleRef])

  return (
    <Box
      {...{ ref: innerRef }}
      className="u-position-absolute u-w-100"
      sx={{
        bottom: 0,
        display: 'flex',
        flexDirection: 'column',
        overflow: 'visible',
      }}
    >
      <Box
        className="VideoPlayer-controls u-w-100"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          pl: { xs: '20px', sm: '25px' },
          pr: { xs: '20px', sm: '25px' },
          height: { xs: '100px', sm: '130px', md: '160px' },
          background: {
            xs: theme.palette.common.videoGradient.replace('{start}', '24.56').replace('{finish}', '71.5'),
            md: theme.palette.common.videoGradient.replace('{start}', '0').replace('{finish}', '56.25'),
          },
          ...controlsSx,
        }}
      >
        {videoChapters?.length === 0 && (
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            {title && (
              <Typography sx={{
                fontSize: '30px',
                lineHeight: '38px',
                fontWeight: '800',
              }}>{title}</Typography>
            )}
            {titleDescription &&
            <Typography sx={{ fontSize: '14px', lineHeight: '17px' }}>{titleDescription}</Typography>}
          </Box>
        )}
        <Box sx={{ order: { xs: 3, md: 'initial' } }}>
          <Slider
            onChange={onSeek}
            onChangeCommitted={onSeekMouseUp}
            onMouseDown={onSeekMouseDown}
            value={played * 100}
            min={0}
            max={100}
            aria-label="Current time"
            sx={{ ...style.slider }}
            size={isLaptop ? 'medium' : 'small'}
            marks={videoChapters.map(({ startSecond }) => ({ value: startSecond / durationSeconds * 100 }))}
            valueLabelFormat={valueLabelFormat}
            valueLabelDisplay={videoChapters?.length ? 'auto' : 'off'}
          />
        </Box>
        <Grid container justifyContent="space-between"
              sx={{ width: 'auto' }}>
          <Grid item sx={{ width: { md: 'auto' }, flexGrow: { xs: 3 } }}>
            <Box sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: { xs: 'space-between', md: 'flex-start' },
            }}>
              <IconButton
                onClick={onPlayPause}
                aria-label="play"
                size="medium"
                sx={{
                  ...iconStyles,
                  mr: '20px',
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                {playing ? <img
                  src={playerPauseIcon}
                  alt="Pause the video"
                /> : <img
                  src={playerPlayIcon}
                  alt="Play the video"
                />}
              </IconButton>
              <IconButton
                onClick={onMute}
                aria-label="volume"
                size="medium"
                sx={{
                  ...iconStyles,
                  mr: '20px',
                  display: { xs: 'none', md: 'flex' },
                }}
              >
                {muted ? <img src={playerMuteIcon} className="u-w-100" alt=""/> :
                  <img src={playerVolumeIcon} className="u-w-100" alt=""/>}
              </IconButton>
              <Box sx={{ marginRight: '15px' }}>
                <Typography
                  sx={{
                    fontSize: { xs: '10px', md: '16px' },
                    lineHeight: { xs: '12px', md: '19px' },
                  }}
                >
                  {elapsedTimeFormatted} / {totalDurationFormatted}
                </Typography>
              </Box>
              {showSpeedVariants && (
                <Box sx={{ marginRight: '20px', display: { xs: 'none', md: 'block' } }}>
                  <Select
                    ref={playbackRateRef}
                    sx={{ ...style.select }}
                    inputProps={{ 'aria-label': 'Speed' }}
                    defaultValue={1}
                    IconComponent={KeyboardArrowDownRounded}
                    MenuProps={{
                      anchorOrigin: {
                        vertical: 'top',
                        horizontal: 'center',
                      },
                      transformOrigin: {
                        vertical: 'bottom',
                        horizontal: 'center',
                      },
                      container: playbackRateAnchorEl,
                    }}
                  >
                    {playbackSpeed.map((speed) => {
                      return (
                        <MenuItem onClick={() => onPlaybackRateChange(speed)} className="VideoPlayer-select" key={speed}
                                  value={speed}>{`${speed}x`}</MenuItem>
                      )
                    })}
                  </Select>
                </Box>
              )}
              {show15s && (
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <IconButton onClick={onRewind} aria-label="prev" size="medium" sx={{ ...iconStyles }}>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                      <img src={playerPrevIcon} alt=""/>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                      <img src={playerPrevIconMobile} alt=""/>
                    </Box>
                  </IconButton>
                  <Typography
                    sx={{
                      mx: { xs: 0, md: '10px' },
                      fontSize: { xs: '10px', md: '16px' },
                      lineHeight: { xs: '12px', md: '19px' },
                    }}
                  >
                    15s
                  </Typography>
                  <IconButton onClick={onFastforward} aria-label="next" size="medium" sx={{ ...iconStyles }}>
                    <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                      <img src={playerNextIcon} alt=""/>
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                      <img src={playerNextIconMobile} alt=""/>
                    </Box>
                  </IconButton>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item sx={{ display: 'flex' }}>
            {qualityScales?.length > 0 && (
              <IconButton aria-label="quality" size="medium" sx={{
                ...iconStyles,
                '& .MuiOutlinedInput-root': { overflow: 'unset !important' },
              }}>
                <Select
                  ref={qualityScaleRef}
                  sx={{
                    ...style.select,
                    width: '38px',
                    '& .MuiSelect-select': { color: 'transparent' },
                    display: { xs: 'none', md: 'block' },
                  }}
                  inputProps={{ 'aria-label': 'Video quality' }}
                  IconComponent={Settings}
                  defaultValue={qualityScale}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'top',
                      horizontal: 'center',
                    },
                    transformOrigin: {
                      vertical: 'bottom',
                      horizontal: 'center',
                    },
                    container: qualityScaleAnchorEl,
                  }}
                >
                  {qualityScales.map((scale) => {
                    return (
                      <MenuItem
                        className="VideoPlayer-select"
                        key={scale}
                        value={scale}
                        onClick={() => onQualityScaleChange && onQualityScaleChange(scale)}
                      >
                        {scale}
                      </MenuItem>
                    )
                  })}
                </Select>
              </IconButton>
            )}
            <IconButton sx={{ ...iconStyles, pt: 0, pb: 0 }} onClick={onToggleFullScreen} aria-label="next"
                        size="medium">
              <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
                <img src={fullscreen ? playerQuitFullScreenIcon : playerFullScreenIcon} alt=""/>
              </Box>
              <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                <img src={fullscreen ? playerQuitFullScreenIconMobile : playerFullScreenIconMobile} alt=""/>
              </Box>
            </IconButton>
          </Grid>
          {!isEmpty(restMenuItems) && (
            <Grid item sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton sx={{ ...iconStyles, pr: 0 }} onClick={() => setOpenRestMenu(!openRestMenu)} aria-label="next"
                          size="medium">
                <img src={threeDots} alt="Rest menu"/>
              </IconButton>
            </Grid>
          )}
        </Grid>
        <FixedBottomNestedMenu
          open={openRestMenu}
          onClose={() => setOpenRestMenu(false)}
          menuItems={restMenuItems}
        />
      </Box>
    </Box>
  )
}

export default VideoPlayerControls
