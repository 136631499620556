import React, { useState, useRef, FC, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Collapse, IconButton, Typography } from '@mui/material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import ReactPlayer from 'react-player'
import screenfull from 'screenfull'
import { debounce, isFinite } from 'lodash'

import playButtonRed from 'images/play-button-red.svg'
import pauseButton from 'images/pause.svg'
import VideoPlayerControls from './VideoPlayerControls'
import { getDurationMilliFormatted, secondsToFullTimeFormat } from 'utils/duration'
import { DEBOUNCE_AUTOSAVE_MILLI, FULL_TIME_FORMAT } from 'utils/constants'
import { VideoQualityVariant } from 'types/VideoQualityVariant'
import { VideoQualityVariantType } from 'types/VideoQualityVariantType'
import config from 'utils/config'
import { translations } from 'locales/i18n'
import dropdownOpenedIcon from 'images/dropdown-opened.svg'
import dropdownClosedIcon from 'images/dropdown-closed.svg'

type VideoPlayerProps = {
  title?: string
  titleDescription?: string
  videoFileUri: string
  videoVariants?: VideoQualityVariant[]
  videoChapters?: AddVideoChapterData[]
  videoStartTimeSeconds?: number
  onWatchUpdate?: (currentVideoTime: number) => void
  invalidate?: () => void
  controlsSx?: SxProps<Theme>
  showSpeedVariants?: boolean
  show15s?: boolean
  onVideoQualityChanged?: (score: string) => void
}

const QUALITY_1080P = '1080p'
const QUALITY_720P = '720p'

const VideoPlayer: FC<VideoPlayerProps> = ({
                                             title,
                                             titleDescription,
                                             videoFileUri,
                                             videoVariants = [],
                                             videoChapters = [],
                                             videoStartTimeSeconds = 0,
                                             onWatchUpdate,
                                             invalidate,
                                             controlsSx,
                                             showSpeedVariants = true,
                                             show15s = true,
                                             onVideoQualityChanged
                                           }) => {
  const {t} = useTranslation()

  const qualityScales = videoVariants?.length > 0 ? [t(translations.videoPlayer.original), QUALITY_1080P, QUALITY_720P] : []

  const getVideoVariant = (scale: string) => {
    switch (scale) {
      case QUALITY_1080P:
        return videoVariants.find(({variant}) => variant === VideoQualityVariantType.VARIANT_1080P)
      case QUALITY_720P:
        return videoVariants.find(({variant}) => variant === VideoQualityVariantType.VARIANT_720P)
      default:
        return videoVariants.find(({variant}) => variant === VideoQualityVariantType.VARIANT_ORIGINAL)
    }
  }


  const getVideoVariantUri = (scale: string) => {
    const videoVariant = getVideoVariant(scale)
    return videoVariant ? `${config.cdnBaseApiURI}${videoVariant.videoUri}` : videoFileUri
  }

  const [chaptersMenuOpen, setChaptersMenuOpen] = useState(false)
  const [playedTime, setPlayedTime] = useState(0)
  const [qualityScale, setQualityScale] = useState(QUALITY_1080P)
  const [scaledVideoFileUri, setScaledVideoFileUri] = useState(getVideoVariantUri(QUALITY_1080P))
  const [state, setState] = useState({
    playing: false,
    muted: false,
    playbackRate: 1.0,
    played: 0,
    seeking: false,
    fullscreen: false,
  })

  const {playing, muted, playbackRate, played, seeking, fullscreen} = state

  const videoRef = useRef<ReactPlayer>(null)
  const videoContainerRef = useRef<HTMLDivElement>()
  const controlsRef = useRef<HTMLDivElement>(null)

  const toggleFullScreenState = () =>
    setState((prevState) => ({...prevState, fullscreen: !prevState.fullscreen}))

  useEffect(
    () => {
      document.addEventListener("fullscreenchange", toggleFullScreenState)

      return () => {
        document.removeEventListener("fullscreenchange", toggleFullScreenState)
      }
    },
    [],
  )

  const handlePlayPause = () => {
    setState({...state, playing: !state.playing})
  }

  const handleMute = () => {
    setState({...state, muted: !state.muted})
  }

  const handleRewind = () => {
    videoRef.current?.seekTo(videoRef.current.getCurrentTime() - 15)
  }

  const handleFastforward = () => {
    videoRef.current?.seekTo(videoRef.current.getCurrentTime() + 15)
  }

  const handlePlaybackRate = (value) => {
    setState({...state, playbackRate: value})
  }

  const handleFullScreen = () => {
    if (screenfull.isEnabled) {
      screenfull.toggle(videoContainerRef.current)
    }
  }

  const handleProgress = (changeState) => {
    if (playedTime > 3 && controlsRef.current) {
      controlsRef.current.style.visibility = 'hidden'
      setPlayedTime(0)
    }

    if (controlsRef.current && controlsRef.current.style.visibility === 'visible') {
      setPlayedTime(playedTime + 1)
    }

    if (!state.seeking) {
      changeState &&  setState({...state, ...changeState})
    }
  }

  const handleSeekChange = (evt, newValue) => {
    setState({...state, played: Number(parseFloat((newValue / 100).toString()))})
  }

  const handleSeekMouseUp = (evt, newValue) => {
    setState({...state, seeking: false})
    isFinite(newValue) && videoRef.current?.seekTo(newValue / 100)
  }

  const handleSeekMouseDown = () => {
    setState({...state, seeking: true})
  }

  const handleMouseMove = () => {
    if (controlsRef.current) {
      controlsRef.current.style.visibility = 'visible'
      setPlayedTime(0)
    }
  }

  const handleQualityScaleChange = (scale: string) => {
    setQualityScale(scale)
    invalidate && invalidate()
  }
  useEffect(() => {
    setScaledVideoFileUri(getVideoVariantUri(qualityScale))
  }, [qualityScale, videoFileUri])

  useEffect(() => {
    onVideoQualityChanged && onVideoQualityChanged(scaledVideoFileUri)
  }, [onVideoQualityChanged, scaledVideoFileUri])
  
  const currentTimeSeconds = videoRef.current ? videoRef.current.getCurrentTime() : 0
  const durationSeconds = videoRef.current ? videoRef.current.getDuration() : 0
  const elapsedTimeFormatted = getDurationMilliFormatted(currentTimeSeconds * 1000, FULL_TIME_FORMAT)
  const totalDurationFormatted = getDurationMilliFormatted(durationSeconds * 1000, FULL_TIME_FORMAT)

  const handleSeekToChapter = (startSecond: number) =>
    videoRef.current?.seekTo(startSecond / durationSeconds)

  useEffect(() => {
    if (videoStartTimeSeconds > 0 && durationSeconds !== 0 && durationSeconds !== null) {
      handleSeekMouseDown()
      handleSeekMouseUp(null, videoStartTimeSeconds / durationSeconds * 100)
    }
  }, [videoStartTimeSeconds, durationSeconds])

  const onWatchUpdateDebounced = useCallback(
    debounce(
      (nextValue: number) => {
        if (onWatchUpdate) {
          onWatchUpdate(nextValue)
        }
      },
      DEBOUNCE_AUTOSAVE_MILLI,
    ),
    [],
  )

  useEffect(
    () => {
      if (playing && onWatchUpdate) {
        onWatchUpdateDebounced(currentTimeSeconds)
      }
    },
    [onWatchUpdate, playing, currentTimeSeconds],
  )

  return (
    <Box>
      <Box className="VideoPlayer u-position-relative" ref={videoContainerRef} sx={{display: 'flex'}}
           onMouseMove={handleMouseMove}
      >
        <ReactPlayer
          key={scaledVideoFileUri}
          ref={videoRef}
          url={scaledVideoFileUri}
          playing={playing}
          muted={muted}
          playbackRate={playbackRate}
          onProgress={handleProgress}
          width="100%"
          height="100%"
        />
        {fullscreen && <Box className="u-position-absolute u-top u-h-100 u-w-100" onClick={handlePlayPause}/>}
        {!fullscreen && (
          <IconButton className="VideoPlayer-button" onClick={handlePlayPause}
                      aria-label="next" size="large"
                      sx={{
                        position: 'absolute',
                        top: `calc(50% - 8px)`,
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                      }}>
            {playing ?
              <img
                src={pauseButton}
                alt="Pause video"
              />
              : <img
                src={playButtonRed}
                alt="Play video"
              />
            }
          </IconButton>
        )}
        <VideoPlayerControls
          title={title}
          titleDescription={titleDescription}
          qualityScale={qualityScale}
          qualityScales={qualityScales}
          videoChapters={videoChapters}
          muted={muted}
          fullscreen={fullscreen}
          playing={playing}
          played={played}
          durationSeconds={durationSeconds}
          playbackRate={playbackRate}
          elapsedTimeFormatted={elapsedTimeFormatted}
          totalDurationFormatted={totalDurationFormatted}
          seeking={seeking}
          innerRef={controlsRef}
          onPlayPause={handlePlayPause}
          onRewind={handleRewind}
          onFastforward={handleFastforward}
          onMute={handleMute}
          onPlaybackRateChange={handlePlaybackRate}
          onToggleFullScreen={handleFullScreen}
          onSeek={handleSeekChange}
          onSeekMouseUp={handleSeekMouseUp}
          onSeekMouseDown={handleSeekMouseDown}
          onQualityScaleChange={handleQualityScaleChange}
          controlsSx={controlsSx}
          showSpeedVariants={showSpeedVariants}
          show15s={show15s}
        />
      </Box>

      {videoChapters?.length > 0 && (
        <Box
          sx={{
            mt: '10px',
            pl: {xs: '20px', md: '25px'},
            pr: {xs: '20px', md: '25px'},
          }}
        >
          <Box>
            <Typography sx={{
              fontSize: {xs: '16px', md: '30px'},
              lineHeight: {xs: '22px', md: '38px'},
              fontWeight: 'bold',
            }}>{title}</Typography>
            <Typography
              sx={{
                fontSize: {xs: '12px', md: '14px'},
                lineHeight: '17px',
              }}
            >{titleDescription}</Typography>
          </Box>
          <Box
            sx={{
              mt: '24px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Collapse in={chaptersMenuOpen} collapsedSize={40}>
              <Box sx={{
                width: '100%',
                height: 'auto',
              }}>
                {videoChapters.map(({startSecond, name}, index) => (
                  <Box
                    key={`vc-${startSecond}`}
                    sx={{
                      display: 'flex',
                      fontSize: {xs: '12px', md: '19px'},
                      lineHeight: {xs: '18px', md: '32px'},
                    }}
                  >
                    <Box sx={{
                      minWidth: {xs: '25px', md: '40px'},
                      textAlign: 'start',
                    }}>{index + 1}.</Box>
                    <Box
                      sx={{
                        pr: {xs: '14px', md: '20px'},
                        color: '#5890E6',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleSeekToChapter(startSecond)}
                    >
                      {secondsToFullTimeFormat(startSecond)}
                    </Box>
                    <Box sx={{pr: '20px'}}>{name}</Box>
                  </Box>
                ))}
              </Box>
            </Collapse>
            <Box
              sx={{
                minWidth: '32px',
                width: '32px',
                height: '32px',
                borderRadius: '4px',
                background: theme => theme.palette.background.paper,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
              onClick={() => setChaptersMenuOpen(!chaptersMenuOpen)}
            >
              <img src={chaptersMenuOpen ? dropdownOpenedIcon : dropdownClosedIcon} alt="Collapse"/>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default VideoPlayer
