import { clientDataService } from './clientDataService'
import { QuizAttemptInterface } from 'types/QuizAttemptInterface'
import { QuizAttemptResultsInterface } from 'types/QuizAttemptResultsInterface'
import { QuizAttemptStatisticsInterface } from 'types/QuizAttemptStatisticsInterface'
import { QuizAttemptReviewInterface } from 'types/QuizAttemptReviewInterface'

export const getQuizAttempt = (quizAttemptId: string): Promise<QuizAttemptInterface> => {
  return clientDataService<QuizAttemptInterface>({
    url: `/v3/quiz-attempts/${quizAttemptId}`,
  })
}

export const getQuizAttemptResults = (quizAttemptId: string): Promise<QuizAttemptResultsInterface> => {
  return clientDataService<QuizAttemptResultsInterface>({
    url: `/v3/quiz-attempts/${quizAttemptId}/results`,
  })
}

export const getQuizAttemptReview = (quizAttemptId: string): Promise<QuizAttemptReviewInterface> => {
  return clientDataService<QuizAttemptReviewInterface>({
    url: `/v3/quiz-attempts/${quizAttemptId}/review-answers`,
  })
}

export const getQuizAttemptStatistics = (quizAttemptId: string): Promise<QuizAttemptStatisticsInterface> => {
  return clientDataService<QuizAttemptStatisticsInterface>({
    url: `/v3/quiz-attempts/${quizAttemptId}/statistics`,
  })
}

export const completeQuizAttemptNextSection = (data: { quizAttemptId: string, sectionId: string }): Promise<QuizAttemptNextSectionInterface> => {
  return clientDataService<QuizAttemptNextSectionInterface>({
    url: `/v3/quiz-attempts/${data.quizAttemptId}/sections/${data.sectionId}/complete`,
    method: 'POST',
  })
}


export const getQuizAttemptsHistory = (): Promise<QuizAttemptsHistory> => {
  return clientDataService<QuizAttemptsHistory>({
    url: '/quiz-attempts/history?size=1000&sort=completedAt,DESC',
  })
}

export const updateQuizAttemptQuestionAnswer = (data: QuizAttemptAnswerCreateRequest): Promise<void> => {
  return clientDataService<void>({
    url: `/v3/quiz-attempts/${data.quizAttemptId}/answer`,
    method: 'PATCH',
    data,
  })
}

export const createQuizAttemptQuestionFlag = (data: QuizAttemptQuestionTagRequest): Promise<void> => {
  return clientDataService<void>({
    url: `/quiz-attempts/${data.quizAttemptId}/questions/${data.questionNumber}/tag`,
    method: 'POST',
  })
}

export const deleteQuizAttemptQuestionFlag = (data: QuizAttemptQuestionTagRequest): Promise<void> => {
  return clientDataService<void>({
    url: `/quiz-attempts/${data.quizAttemptId}/questions/${data.questionNumber}/tag`,
    method: 'DELETE',
  })
}

export const completeQuizAttemptQuestion = (quizAttemptId: string): Promise<void> => {
  return clientDataService<void>({
    url: `/v3/quiz-attempts/${quizAttemptId}/complete`,
    method: 'POST',
  })
}

export const reportQuestion = (data: ReportQuizAttemt): Promise<void> =>
   clientDataService<void>({
     url: `/questions/${data.questionId}/report`,
     method: 'POST',
     data,
   })
