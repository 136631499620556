import React, { ChangeEvent, forwardRef, InputHTMLAttributes, memo } from 'react'
import { Field, FieldProps, useFormikContext } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { InputLabelProps, TextField, TextFieldProps } from '@mui/material'
import cn from 'classnames'

import styles from './form-input-mobile.module.scss'
import ErrorMessage from '../error-message/error-message'
import { textFieldStyles } from './form-input-sx'

type FormInputProps = TextFieldProps & {
  name: string
  id?: string
  label?: string
  type?: InputHTMLAttributes<unknown>['type']
  multiple?: boolean
  rows?: number
  fullWidth?: boolean
  disableUnderline?: boolean
  placeholder?: string
  hidden?: boolean
  onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  inputClassName?: string
  disableAutocomplete?: boolean
  InputLabelProps?: Partial<InputLabelProps>
}

const FormInput = forwardRef(({
                                type = 'text',
                                multiple = false,
                                rows,
                                fullWidth = true,
                                name,
                                id,
                                label = '',
                                disableUnderline = false,
                                placeholder = '',
                                hidden = false,
                                onChange,
                                inputClassName,
                                InputLabelProps,
                                disableAutocomplete = false,
                                ...restProps
                              }: FormInputProps,
                              inputRef,
  ) => {
    const {values} = useFormikContext<any>()

    const handlerChange = (
      e: ChangeEvent<HTMLInputElement>,
      setFieldValue: FormikHelpers<unknown>['setFieldValue'],
      setFieldTouched: FormikHelpers<unknown>['setFieldTouched'],
    ) => {
      if (onChange) {
        onChange(e)
      }

      if (type === 'file') {
        setFieldValue(name, ((e.target as HTMLInputElement).files ?? [])[0])
        if (multiple) {
          setFieldValue(name, [...(values[name] ?? []), ...(Array.from((e.target as HTMLInputElement).files ?? []))])
        } else {
          setFieldValue(name, ((e.target as HTMLInputElement).files ?? [])[0])
        }
        setTimeout(() => setFieldTouched(name, true), 10)
      } else {
        setFieldValue(name, e.target.value)
      }
    }

    return (
      <Field
        name={name}
        id={name}>
        {({field, form: {touched, errors, setFieldValue, setFieldTouched, values, initialValues}}: FieldProps) => {
          const isError = !!(touched[field.name] && errors[field.name])

          return (
            <div className={cn(styles.formInput, hidden && styles.hidden)}>
              <TextField
                inputRef={inputRef}
                onBlur={() => setFieldTouched(name, true)}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handlerChange(e, setFieldValue, setFieldTouched)}
                error={isError}
                type={type}
                autoComplete={disableAutocomplete ? `new-${name}` : undefined}
                rows={rows}
                fullWidth={fullWidth}
                id={id}
                name={name}
                inputProps={{multiple}}
               // InputProps={{disableUnderline}}
                label={label}
                placeholder={placeholder}
                defaultValue={initialValues[name] || values[name]}
                sx={textFieldStyles}
                InputLabelProps={InputLabelProps || {
                  style: {
                    fontSize: "11px",
                    lineHeight: '13px',
                    color: '#B2B5BD',
                  },
                }}
                className={inputClassName}
                margin={"normal"}
                {...restProps}
              />
              <ErrorMessage name={name}/>
            </div>
          )
        }}
      </Field>
    )
  },
)

export default memo(FormInput)
