import React from 'react'
import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {Box, Button, Typography} from "@mui/material"

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import {translations} from 'locales/i18n'

const AccessDeniedPage = () => {
  const {t} = useTranslation()

  return (
    <DashboardLayout>
      <Box sx={{height: 'calc(100vh - 60px)', padding: '60px'}}>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: "center",
          textAlign: 'center',
          height: '100%',
          width: {xs: '100%', sm: '60%'},
          margin: 'auto',
        }}>
          <img src="images/402.svg" alt="402 error" style={{width: 'inherit'}}/>
          <Typography
            sx={{
              fontSize: {xs: '12px', sm: '18px'},
              lineHeight: {xs: '20px', sm: '30px'},
              maxWidth: '360px',
            }}
          >
            {t(translations.accessDeniedPage.contentNotAvailable)}
          </Typography>
          <Typography
            sx={{
              mt: '13px',
              fontSize: {xs: '12px', sm: '18px'},
              lineHeight: {xs: '20px', sm: '30px'},
            }}
          >
            {t(translations.accessDeniedPage.youCanReviewYourSubscription)}
          </Typography>
            <a href="https://promedtest.it/scuola-online/#prezzi" target="_blank" style={{textDecoration: 'none'}}>
            <Button
              color="primary"
              className="u-text-transform-none u-color-white u-bg-havelock-blue"
              sx={{
                mt: {xs: 4, sm: 6},
                fontSize: {xs: '12px', sm: '16px'},
                lineHeight: {xs: '16px', sm: '19px'},
                padding: '10px 13px',
                minWidth: '178px',
              }}
            >
              {t(translations.accessDeniedPage.upgradePlan)}
            </Button>
            </a>
            <Link to="/" style={{textDecoration: 'none'}}>
            <Typography
              sx={{
                mt: '23px',
                fontSize: {xs: '12px', sm: '16px'},
                lineHeight: {xs: '16px', sm: '19px'},
              }}
            >
              {t(translations.common.goHome)}
            </Typography>
            </Link>
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default AccessDeniedPage
