import React from 'react'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import Courses from 'components/courses/Courses'
import useCourses from 'hooks/useCourses'
import LoadingIndicator from 'components/shared/loading-indicator'
import {Back} from 'components/shared/back'

const CoursesPage = () => {
  const coursesQuery = useCourses()
  const courses = coursesQuery.data?.content

  return (
    <DashboardLayout>
      <Back text="HOME" url={'/'} />
      {courses && <Courses courses={courses}/>}
      {coursesQuery.isLoading && <LoadingIndicator />}
    </DashboardLayout>
  )
}

export default CoursesPage
