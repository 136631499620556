import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import { Box, CircularProgress, Typography } from '@mui/material'

import { Back } from 'components/shared/back'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import BlogList from 'components/blogs/BlogList'
import useBlogs from 'hooks/useBlogs'
import { translations } from 'locales/i18n'

const pageSize = 12

const BlogListPage = () => {
  const { t } = useTranslation()
  const location = useLocation()

  const query = new URLSearchParams(location.search)
  const pageInitial: number = parseInt(query.get('page') || '1')

  const [isFirstFetch, setFirstFetch] = useState(true)
  const [page, setPage] = useState(pageInitial)
  const [totalPages, setTotalPages] = useState<number>(0)
  const [totalElements, setTotalElements] = useState<number>(0)

  const blogsQuery = useBlogs(page - 1, pageSize, {
    onSettled() {
      setFirstFetch(false)
    },
    onSuccess(data) {
      setTotalPages(data.totalPages)
      setTotalElements(data.totalElements)
    },
  })

  const restElementsSize = totalElements % pageSize
  const previewBlogsElementsSize = page === totalPages && restElementsSize > 0 ? restElementsSize : pageSize
  const previewBlogs = Array.from({ length: previewBlogsElementsSize }, () => ({} as BlogSearchView))
  const blogs = blogsQuery.data?.content || previewBlogs

  return (
    <DashboardLayout>
      <Back text={t(translations.navigation.homePage)} url={'/'}/>
      <Box sx={{ mt: { xs: '36px', md: '55px' } }}>
        <Box sx={{ px: { xs: '20px', sm: 6.5 }, mb: { xs: '20px', sm: 6.5 } }}>
          <Typography
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: '18px', md: '34px' },
              lineHeight: { xs: '22px', md: '41px' },
              mt: { xs: '10px', md: '20px' },
              mb: { xs: '10px', md: '20px' },
            }}
          >
            {t(translations.dashboard.menu.blogs)}
          </Typography>
          {!isFirstFetch && (
            <BlogList
              blogs={blogs}
              isLoading={blogsQuery.isLoading}
              page={page}
              setPage={setPage}
              totalPages={totalPages}
            />
          )}
          {isFirstFetch && <CircularProgress/>}
        </Box>
      </Box>
    </DashboardLayout>
  )
}

export default BlogListPage
