import { clientDataService } from './clientDataService'
import jsonToFormData from 'utils/jsonToFormData'
import { StudentProfileInterface } from 'types/StudentProfileInterface'

export const getMe = (): Promise<StudentProfileInterface> =>
  clientDataService<StudentProfileInterface>({
    url: '/users/me',
  })

export const forgotPassword = (data: ForgotPasswordRequest): Promise<void> =>
  clientDataService<void>({
    method: 'POST',
    url: '/users/reset-password/send',
    data,
  })

export const resetPassword = (data: ResetPasswordRequest): Promise<void> =>
  clientDataService<void>({
    method: 'PUT',
    url: '/users/reset-password/verify',
    data,
  })

export const updateMyProfile = (data: ProfileUpdateRequest): Promise<void> =>
  clientDataService<void>({
    method: 'PUT',
    url: '/students/me',
    data: jsonToFormData(data),
  })

export const logInUser = (data: LogInUserRequest): Promise<AuthUserResponse> =>
  clientDataService<AuthUserResponse>({
    method: 'POST',
    url: '/auth',
    data,
  })

export const logOutUser = (): Promise<void> =>
  clientDataService<void>({
    method: 'POST',
    url: '/logout',
    data: {},
  })

export const changePassword = (data: ChangePasswordRequest): Promise<void> =>
  clientDataService<void>({
    method: 'PUT',
    url: '/students/me/change-password',
    data,
  })

export const refreshToken = (): Promise<AuthUserResponse> =>
  clientDataService<AuthUserResponse>({
    method: 'POST',
    url: '/auth/refresh-token',
    data: {},
  })
