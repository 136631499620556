import { UseQueryResult } from 'react-query'

import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { getBlog } from 'services/blogService'

const useBlog = (blogId): UseQueryResult<BlogInterface> => useQueryWithSnackbar(
  [ReactQueryKeys.BLOG, blogId],
  () => getBlog(blogId),
  {
    enabled: !!blogId,
  },
)

export default useBlog
