import React, { FC, forwardRef, useEffect, useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { useInputTrigger } from 'react-input-trigger'
import { isEmpty } from 'lodash'
import { FormikHelpers } from 'formik/dist/types'

import theme from 'theme'
import { ARROW_DOWN_CODE, ARROW_UP_CODE, AT_CODE, IT_AT_CODE, LEPTOP_IT_AT_CODE, ENTER_CODE } from 'utils/constants'
import CommunityUserAvatar from 'components/community/CommunityUserAvatar'
import FixedBottomModal from 'components/shared/modal/FixedBottomModal'
import useClassParticipants from 'hooks/community/useClassParticipants'

type WithUserMentionProps = {
  classId: string
  inputId: string
  inputName: string
  inputValue: string
  setFieldValue?: FormikHelpers<any>['setFieldValue']
  children: React.ReactNode
}

const WithUserMention = forwardRef(({
                                      classId,
                                      inputId,
                                      inputName,
                                      inputValue,
                                      setFieldValue,
                                      children,
                                    }: WithUserMentionProps,
                                    inputRef,
                                    ) => {
  const isLaptop = useMediaQuery(theme.breakpoints.up('md'))

  const {triggerState, inputTriggerHandler, endTrigger} = useInputTrigger(
    [{
      key: '@',
      id: 'mention',
    },
    {
      key: 'ò',
      id: 'mention',
      altKey: true
    }
    ],
    {escToCancel: true},
  )

  const [suggestorOpen, setSuggestorOpen] = useState(false)
  const [selectionStart, setSelectionStart] = useState<number>(0)
  const [currentSelected, setCurrentSelected] = useState<number>(0)

  const resetState = () => {
    setSuggestorOpen(false)
    setSelectionStart(0)
    setCurrentSelected(0)
  }

  const {hookType} = triggerState ? triggerState : {hookType: ''}
  const {cursor, text} = triggerState && triggerState.hookType === 'typing' ? triggerState : {
    cursor: {top: 0, left: 0, height: 0},
    text: { value: '', content: '' }
  }
  const {top, left, height} = cursor

  const input = document.getElementById(inputId) as HTMLInputElement
  const currentSelectionStart = input?.selectionStart || 0
  const preSelectionValue = inputValue.slice(0, selectionStart)
  const postSelectionValue = inputValue.slice(currentSelectionStart, inputValue.length)

  const classParticipantsQuery = useClassParticipants(classId, suggestorOpen ? text.content.trim() : '')

  const usersToShow = classParticipantsQuery.data?.content || []

  useEffect(() => {
    if (hookType === 'start') {
      setSuggestorOpen(true)
    }

    if (hookType === 'cancel' || isEmpty(triggerState)) {
      resetState()
      endTrigger()
    }
    
    if (hookType === 'typing' && isEmpty(text.value)) {
      endTrigger()
    }

  }, [hookType, text.value])

  const setInputValue = (value: string) => {
    setFieldValue && setFieldValue(inputName, value)
    if (input) {
      input.value = value
    }
  }

  const selectUser = (user) => {
    if (user) {
      const {nickname} = user
      const newText = `${preSelectionValue}${nickname}${postSelectionValue}`

      setInputValue(newText)
      resetState()      
      endTrigger()
      if (input) {
        const position = preSelectionValue.length + nickname.length
        input.setSelectionRange(position, position)
        input.focus()
      }
    }
  }

  const handleSuggestorKeyDown = (e) => {
    if (suggestorOpen) {
      const {which} = e

      if ([ARROW_UP_CODE, ARROW_DOWN_CODE, ENTER_CODE].includes(which)) {
        e.preventDefault()
      }

      if (which === ARROW_UP_CODE) {
        setCurrentSelected(currentSelected === 0 ? usersToShow.length - 1 : (currentSelected - 1) % usersToShow.length)
      }

      if (which === ARROW_DOWN_CODE) {
        setCurrentSelected((currentSelected + 1) % usersToShow.length)
      }

      if (which === ENTER_CODE) {
        selectUser(usersToShow[currentSelected])
      }
    }
  }

  const handleInputKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    inputTriggerHandler(e)
    const {which} = e

    if (which === AT_CODE || which === IT_AT_CODE || which === LEPTOP_IT_AT_CODE) {
      // @ts-ignore
      setSelectionStart(e.target.selectionStart)
    }
  }

  const childrenWithInputTrigger = React.Children.map(children, child => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {onKeyDown: inputTriggerHandler, onKeyUp: handleInputKeyUp})
    }
    return child
  })

  const renderUser = ({firstName, lastName, nickname, id, profileImageUri}: ClassParticipantInterface, index) => (
    <Box
      key={id+index}
      sx={{
        padding: {xs: '10px 0', md: '10px 20px'},
        background: {md: index === currentSelected ? '#28292a' : ''},
        '&:hover': {
          cursor: 'pointer',
          background: '#262727',
        },
      }}
      onClick={() => selectUser(usersToShow[index])}
    >
      <Box sx={{display: 'flex', alignItems: 'center'}}>
        <CommunityUserAvatar
          profileImageUri={profileImageUri}
          firstName={firstName}
          lastName={lastName}
          sx={{width: '30px', height: '30px'}}
        />
        <Box
          sx={{
            display: 'flex',
            fontSize: {xs: '14px', md: '12px'},
            lineHeight: {xs: '16px', md: '14px'},
            pl: '13px',
          }}
        >
          <Box sx={{fontWeight: 500}}>{`${firstName} ${lastName}`}</Box>
          <Box sx={{px: '9px'}}>·</Box>
          <Box>{nickname}</Box>
        </Box>
      </Box>
    </Box>
  )

  const renderUsers = () => (
    <Box
      sx={{
        position: {xs: 'unset', md: 'absolute'},
        zIndex: 2,
        width: {xs: '100%', md: 'auto'},
        minWidth: {md: '305px'},
        maxHeight: '200px',
        overflowY: 'auto',
        borderRadius: {md: '3px'},
        background: {md: '#2D2E2F'},
        boxShadow: {md: 'rgba(0, 0, 0, 0.4) 0px 1px 4px'},

        display: (suggestorOpen && !isEmpty(inputValue)) ? 'block' : 'none',
        top: {xs: 'unset', md: top + height},
        left: {xs: 'unset', md: left},
      }}
    >
      {usersToShow.map((user, index) => renderUser(user, index))}
    </Box>
  )

  return (
    <Box
      sx={{
        position: 'relative',
      }}
      onKeyDown={handleSuggestorKeyDown}
    >
      {childrenWithInputTrigger}

      {isLaptop && renderUsers()}

      {!isLaptop && (
        <FixedBottomModal open={suggestorOpen} onClose={() => setSuggestorOpen(false)}>
          {renderUsers()}
        </FixedBottomModal>
      )}
    </Box>
  )
})

export default WithUserMention
