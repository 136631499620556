import React, { FC, useState } from 'react'
import {
  Box, Button,
  Button as MuiButton, CircularProgress,
  Grid,
  styled,
  Typography,
} from '@mui/material'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { translations } from 'locales/i18n'
import usePhotoUpload from 'hooks/usePhotoUpload'
import useUpdateProfile from 'hooks/useUpdateProfile'
import UpdateProfileFormSchema from './UpdateProfileValidatorSchema'
import FormInput from '../../ui/form/form-input/form-input'
import UserAvatar from '../../shared/user-avatar/user-avatar'
import config from 'utils/config'
import ErrorMessage from '../../ui/form/error-message/error-message'
import { filterNullAttributes } from 'utils/object'
import ProfileNavigation from '../ProfileNavigation'
import { CancelButton, UpdateButton } from '../buttons'
import { StudentProfileInterface } from 'types/StudentProfileInterface'

type ProfileProps = {
  currentUser: StudentProfileInterface
  onResetForm: () => void
  resetingForm: boolean
}

const profileImageField = 'profileImage'

const Profile: FC<ProfileProps> = ({ currentUser, onResetForm, resetingForm }) => {
  const { t } = useTranslation()

  const { profile } = currentUser
  const { firstName, lastName, nickname, profileImageUri } = profile

  const [editProfile, setEditProfile] = useState(false)
  const {
    deletePhoto,
    inputFileLabelRef,
    photoSrc,
    handlePhotoClick,
    handleFileChange,
    deletePhotoButtonEnable,
    setDeletePhoto,
    setDeletePhotoButtonEnable,
  } = usePhotoUpload(isEmpty(profileImageUri) ? '' : `${config.cdnBaseApiURI}${profileImageUri}`)

  const updateProfileMutation = useUpdateProfile()

  const handleSubmit = (data: ProfileUpdateRequest, formikHelpers: FormikHelpers<ProfileUpdateRequest>) => {
    setEditProfile(!editProfile)
    const dataToSend = filterNullAttributes(data) as unknown as ProfileUpdateRequest
    updateProfileMutation.mutate({
      ...dataToSend,
      deleteProfileImage: deletePhoto,
    })
    formikHelpers.setFieldValue(profileImageField, null)
  }

  const handleCancelProfileImageEdit = (
    setFieldValue: FormikHelpers<ProfileUpdateRequest>['setFieldValue'],
    setFieldTouched: FormikHelpers<ProfileUpdateRequest>['setFieldTouched'],
  ) => {
    handleFileChange(null)
    setFieldValue(profileImageField, null)
    setTimeout(() => setFieldTouched(profileImageField, true), 10)
  }

  const handleCancelProfileEdit = (
    setFieldValue: FormikHelpers<ProfileUpdateRequest>['setFieldValue'],
    setFieldTouched: FormikHelpers<ProfileUpdateRequest>['setFieldTouched'],
  ) => {
    setEditProfile(false)
    onResetForm()
    handleCancelProfileImageEdit(setFieldValue, setFieldTouched)
  }

  return (
    <Box
      sx={{
        ml: { xs: '20px', md: '56px' },
        mr: { xs: '20px', md: '0' },
        mt: { xs: '32px', md: '42px' },
      }}
    >
      <Box>
        <Typography variant={'h4'} sx={{
          fontSize: { xs: '18px', md: '34px' },
          lineHeight: { xs: '20px', md: '41px' },
          fontWeight: { xs: '600', md: 'normal' },
          textTransform: 'none',
        }}>
          {t(translations.profilePage.title)}
        </Typography>
      </Box>
      <Grid container sx={{ mt: { xs: '21px', md: '44px' } }}>
        <Grid item xs={12} md={3} sx={{ mb: { xs: 2 } }}>
          <ProfileNavigation/>
        </Grid>
        <Grid item xs={12} md={9} sx={{ width: '100%' }}>
          {!resetingForm && (
            <Formik
              validateOnBlur
              validateOnChange={false}
              initialValues={{
                firstName: firstName,
                lastName: lastName,
                nickname: nickname,
                deleteProfileImage: false,
              }}
              validationSchema={UpdateProfileFormSchema}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {({ handleSubmit, setFieldValue, setFieldTouched, isValid }) => (
                <form
                  style={{ display: 'flex', flexDirection: 'column' }}
                  onSubmit={handleSubmit}
                >
                  <Box sx={{ justifyContent: { xs: 'center' } }}>
                    <Box sx={{
                      width: { md: '501px', lg: '601px' },
                      background: theme => theme.palette.background.paper,
                      borderRadius: '3px',
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: { xs: 'center' },
                      m: { xs: 'auto', md: 'unset' },
                    }}>
                      <Box sx={{
                        width: '100%',
                        pl: { xs: '20px', md: '50px' },
                        pr: { xs: '20px', md: '50px' },
                      }}>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          width: '100%',
                          mt: '20px',
                        }}>
                          <Typography
                            sx={{
                              fontSize: { xs: '18px', md: '24px' },
                              lineHeight: { xs: '20px', md: '29px' },
                            }}>{t(translations.profilePage.profile)}</Typography>
                          {
                            !editProfile
                            && (
                              <Button
                                variant={'outlined'}
                                sx={{
                                  fontSize: { xs: '12px', md: '14px' },
                                  lineHeight: { xs: '14px', md: '17px' },
                                  textTransform: 'none',
                                  height: '40px',
                                  minWidth: '123px',
                                  borderRadius: '3px',
                                }}
                                onClick={() => setEditProfile(true)}
                              >
                                {t(translations.profilePage.editProfile)}
                              </Button>
                            )
                          }
                        </Box>
                        <Box sx={{ display: 'flex', mt: { xs: '10px', md: '30px' } }}>
                          <UserAvatar
                            photo={photoSrc}
                            firstName={firstName}
                            lastName={lastName}
                          />
                          {
                            editProfile &&
                            <Box sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              justifyContent: 'space-between',
                              ml: { xs: '13px', sm: '25px' },
                            }}>
                                <Typography sx={{
                                  fontSize: { xs: '10px', sm: '12px' },
                                  lineHeight: { xs: '14px', sm: '17px' },
                                  letterSpacing: '0.2px',
                                  width: '55%',
                                  mb: { xs: '11px', md: '18px' },
                                }}>
                                  {t(translations.profilePage.changeImage)}
                                </Typography>
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <UploadButton
                                        variant="outlined"
                                        sx={{
                                          width: { xs: '127px', md: '150px' },
                                          minHeight: { xs: '35px', md: '50px' },
                                          fontWeight: 'bold',
                                          fontSize: { xs: '12px', sm: '16px' },
                                          lineHeight: { xs: '14px', sm: '19px' },
                                          borderRadius: '3px',
                                        }}
                                        onClick={handlePhotoClick}
                                    >
                                      {t(translations.common.upload)}
                                    </UploadButton>
                                    <label
                                        hidden
                                        ref={inputFileLabelRef}
                                        htmlFor="profileImage">
                                        Photo
                                    </label>
                                    <FormInput
                                        type="file"
                                        id="profileImage"
                                        name="profileImage"
                                        hidden
                                        onChange={(event) => {
                                          const target = event.target as HTMLInputElement
                                          if (target && target.files) {
                                            handleFileChange(target.files[0])
                                          }
                                        }}
                                    />
                                  {deletePhotoButtonEnable && (
                                    <Box
                                      sx={{
                                        fontWeight: 'bold',
                                        fontSize: { xs: '10px', md: '12px' },
                                        lineHeight: { xs: '12px', md: '15px' },
                                        color: '#F0393B',
                                        textTransform: 'none',
                                        display: 'flex',
                                        flexGrow: { xs: 1, md: 'unset' },
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        ml: '20px',
                                        cursor: 'pointer',
                                      }}
                                      onClick={() => {
                                        setDeletePhoto(true)
                                        setDeletePhotoButtonEnable(false)
                                        handleCancelProfileImageEdit(setFieldValue, setFieldTouched)
                                      }}
                                    >
                                      {t(translations.common.remove)}
                                    </Box>
                                  )}
                                </Box>
                            </Box>
                          }
                        </Box>
                        <ErrorMessage name="profileImage" className="u-w-100 u-mt-8"/>
                      </Box>
                      <Box sx={{
                        borderTop: '1px solid #646464',
                        mt: { xs: '30px', md: '49px' },
                        width: '100%',
                      }}/>
                      <Box sx={{
                        width: '100%',
                        mt: { xs: '10px', md: '20px' },
                        mb: { xs: '22px', md: '42px' },
                        pl: { xs: '20px', md: '50px' },
                        pr: { xs: '20px', md: '50px' },
                      }}>
                        <FormInput
                          label={t(translations.profilePage.firstName)}
                          name="firstName"
                          variant="standard"
                          disabled={!editProfile}
                        />
                        <FormInput
                          label={t(translations.profilePage.lastName)}
                          name="lastName"
                          variant="standard"
                          disabled={!editProfile}
                        />
                        <FormInput
                          label={t(translations.profilePage.nickname)}
                          name="nickname"
                          variant="standard"
                          disabled={!editProfile}
                        />
                        {
                          editProfile && <Box sx={{
                            display: 'flex',
                            mt: { xs: '27px', md: '47px' },
                            fontWeight: 'bold',
                          }}>
                              <UpdateButton
                                  type="submit"
                                  variant={'contained'}
                                  size="large"
                                  disabled={!isValid}
                                  sx={{
                                    py: '15px',
                                    px: { xs: '25px', md: '30px' },
                                    fontSize: { xs: '12px', sm: '16px' },
                                    lineHeight: { xs: '14px', sm: '19px' },
                                    width: { xs: '174px', md: '214px' },
                                    minHeight: { xs: '44px', md: '50px' },
                                    borderRadius: '3px',
                                    boxShadow: 'none',
                                  }}
                              >
                                {t(translations.profilePage.updateProfile)}
                              </UpdateButton>
                              <CancelButton
                                  type="button"
                                  variant="contained"
                                  size="large"
                                  sx={{
                                    ml: { xs: '12px', md: '15px' },
                                    fontSize: { xs: '12px', sm: '16px' },
                                    lineHeight: { xs: '14px', sm: '19px' },
                                    width: { xs: '101px', md: '122px' },
                                    minHeight: { xs: '44px', md: '50px' },
                                    borderRadius: '3px',
                                    boxShadow: 'none',
                                  }}
                                  onClick={() => handleCancelProfileEdit(setFieldValue, setFieldTouched)}
                              >
                                {t(translations.common.cancel)}
                              </CancelButton>
                          </Box>
                        }
                      </Box>
                    </Box>
                  </Box>
                </form>
              )}
            </Formik>
          )}
          {resetingForm && <CircularProgress/>}
        </Grid>
      </Grid>
    </Box>

  )
}

const UploadButton = styled(MuiButton)(({ theme }) => ({
  textTransform: 'none',
  width: '140px',
  '&.MuiButton-root': {
    border: '1px solid white',
    color: 'white',
  },
}))


export default Profile

