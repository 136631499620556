import {UseQueryOptions, UseQueryResult} from 'react-query'
import {AxiosError} from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import {getRankingRecords} from 'services/rankingService'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useRankingRecords = (quizId: string, options?: UseQueryOptions<RankingRecordsInterface, AxiosError>): UseQueryResult<RankingRecordsInterface> => {
    return useQueryWithSnackbar<RankingRecordsInterface>(
        [ReactQueryKeys.RANKING_RECORDS, quizId],
        () => getRankingRecords(quizId),
        {
            enabled: !!quizId,
            ...options,
        },
    )
}

export default useRankingRecords
