import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import CircularChart from 'components/shared/circular-chart/CircularChart'
import { translations } from 'locales/i18n'

const StatisticsHeader = () => {
  const { t } = useTranslation()

  const { floor, abs, trunc } = Math

  return (
    <Grid container item display="flex" alignItems="flex-start" sx={{ ml: { xs: '20px', lg: '50px' } }}>
      <Grid item xs={11}>
        <Grid
          xs={12}
          sx={{
            fontSize: { xs: '18px', sm: '30px' },
            lineHeight: { xs: '22px', sm: '38px' },
            fontWeight: 'bold',
          }}
        >
          {t(translations.statistics.title)}
        </Grid>
        <Grid
          xs={12}
          sx={{
            mt: '10px',
            maxWidth: { lg: '440px' },
            fontSize: { xs: '12px', sm: '16px' },
            lineHeight: { xs: '21px', sm: '24px' },
            fontWeight: '500',
          }}>
          {t(translations.statistics.description)}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default StatisticsHeader
