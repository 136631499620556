import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ActivationState, Client, StompSubscription } from '@stomp/stompjs'

import getWsClient from 'services/webSocketService'
import { isDevelopment } from 'utils/constants'
import { Subscription } from 'types/Subscription'

const useSubscribe = (subscriptions: Subscription[]) => {
  const location = useLocation()
  const clientRef = useRef<Client>()
  const [stopmSubscriptions, setStompSubscriptions] = useState<StompSubscription[]>([])
  const deactivatePromiseRef = useRef<Promise<void>>()

  const activateWs = async () => {
    await deactivatePromiseRef.current

    clientRef.current = getWsClient()

    const {current: client} = clientRef

    setTimeout(() => {
      if (client?.state === ActivationState.INACTIVE) {
        client.activate()

        client.onConnect = function () {
          subscriptions.forEach(({url, handleMessage}) => {
            const subscription = client.subscribe(url, (message) => {
              if (isDevelopment()) {
                console.log('messageBody', JSON.parse(message.body))
              }
              handleMessage(message)
            })
            setStompSubscriptions(prevState => [...prevState, subscription])
          })
        }
      }
    }, 1000)
  }

  useEffect(() => {
    activateWs()
  }, [location.pathname])

  useEffect(() => {
    return () => {
      stopmSubscriptions.forEach(subscription => subscription.unsubscribe())
      deactivatePromiseRef.current = clientRef.current?.deactivate()
      setStompSubscriptions([])
    }
  }, [location.pathname])
}

export default useSubscribe
