import React from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { Error as IconError } from '@material-ui/icons'
import DateAdapter from '@mui/lab/AdapterDayjs'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import itLocale from 'date-fns/locale/it'
import { SnackbarProvider } from 'notistack'

import Routes from './routes/Routes'
import theme from 'theme'
import 'styles/global.scss'
import 'utils/dayjs-config'
import 'utils/react-virtuozo-support'
import SnackbarCloseButton from './shared/snackbar/snackbar-close-button/snackbar-close-button'
import SnackbarIcon from './shared/snackbar/snackbar-icon/snackbar-icon'
import WithRefreshToken from 'hocs/WithRefreshToken'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})

export const Root = () => (
  <ThemeProvider theme={theme}>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider
        maxSnack={1}
        autoHideDuration={5000}
        action={key => <SnackbarCloseButton snackbarKey={key}/>}
        iconVariant={{
          error: <SnackbarIcon><IconError/></SnackbarIcon>,
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}>
        <CssBaseline/>
        <WithRefreshToken>
          <LocalizationProvider dateAdapter={DateAdapter} locale={itLocale}>
            <Routes/>
          </LocalizationProvider>
        </WithRefreshToken>
      </SnackbarProvider>
    </QueryClientProvider>
  </ThemeProvider>
)
