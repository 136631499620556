import { Dispatch, SetStateAction } from 'react'

import { Posts } from 'types/Posts'
import { PostInterface } from 'types/PostInterface'

const getUpdatedPostsOnPostCommentCountChange = (
  posts: Posts | undefined,
  payload: any & { postId: string },
  diff: number,
  setPosts?: Dispatch<SetStateAction<PostInterface[]>>,
) => {
  const {postId} = payload

  const updatePost = (post: PostInterface) => post.postId === postId
    ? {
      ...post,
      commentsCount: post.commentsCount + diff,
    }
    : post

  if (setPosts) {
    setPosts((prevValue) => prevValue.map(updatePost))
  }

  return ({
    ...posts,
    content: posts?.content?.map(updatePost),
  } as Posts)
}

export default getUpdatedPostsOnPostCommentCountChange
