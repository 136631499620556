export enum HttpStatus {
  UNAUTHORIZED = 401,
  PAYMENT_REQUIRED = 402
}

export const ENTER_CODE = 13
export const ARROW_UP_CODE = 38
export const ARROW_DOWN_CODE = 40
export const AT_CODE = 50
export const IT_AT_CODE = 186
export const LEPTOP_IT_AT_CODE = 192
export const MENTION_REGEXP = /\@\w+/gi

export const TOKEN_KEY = 'token'

export const isProduction = () => process.env.NODE_ENV === 'production'
export const isDevelopment = () => process.env.NODE_ENV === 'development'

export const NOTIFICATIONS_WS_URI = '/user/queue/notifications'
export const NOTIFICATION_APPEARING_TIMEOUT_MILLI = 100
export const NOTIFICATION_HIDE_TIMEOUT_MILLI = 5000

export const DAY_MILLI = 24 * 60 * 60 * 1000
export const HOUR_MILLI = 60 * 60 * 1000
export const MINUTE_MILLI = 60 * 1000

export const DEBOUNCE_TYPING_MILLI = 500
export const DEBOUNCE_AUTOSAVE_MILLI = 1000

export const SHORT_DATE_FORMAT = 'DD.MM.YYYY'
export const FULL_DATE_FORMAT = 'MMMM DD, YYYY'
export const MMMM_DD_DATE_FORMAT = 'MMMM DD'
export const MEDIUM_DATE_FORMAT = 'DD MMM YYYY'
export const WEEK_DAY_DATE_FORMAT = 'dddd'
export const TIME_FORMAT = 'H:mm:ss'
export const H_MM_TIME_FORMAT = 'H:mm'
export const MM_SS_TIME_FORMAT = 'mm:ss'
export const FULL_TIME_FORMAT = 'HH:mm:ss'
export const HUMAN_TIME_FORMAT = 'hr min'
export const DATE_TIME_FORMAT = 'H:mm MMM DD, YYYY'
export const ITALIAN_TIME_FORMAT = 'h [ore e] m [minuti]'
export const ITALIAN_MM_SS_FORMAT = 'm [minuti e] s [secondi]'

export const IMAGE_SUPPORTED_FORMATS = [
  'image/jpeg',
  'image/png',
  'image/heic',
  'image/heif'
]

export const MIN_SIMULATIONS_COUNT_TO_SHOW_STATS = 3
export const MAX_POLL_OPTIONS_COUNT = 10
export const VOTED_USERS_COUNT_TO_SHOW = 3

export const QUESTION_ANCHOR_PREFIX = 'question-'

export const combine = (g, f) => (x) => g(f(x))
