import React, { FC } from 'react'
import { Box, Grid } from '@mui/material'

import { CommunitySelection } from './CommunitySelection'
import { translations } from 'locales/i18n'
import { useTranslation } from 'react-i18next'
import config from 'utils/config'


type ClassesStudentPropsType = {
  userClasses: ClassesValueInterface[]
}

export const ClassesStudent: FC<ClassesStudentPropsType> = ({userClasses}) => {
  const {t} = useTranslation()

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center'
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: {xs: '20px', md: '40px'},
          marginBottom: {xs: '20px', md: '40px'},
          width: {xs: '300px', sm: '934px'}
        }}>
        <Grid container direction={'column'}>
          <Grid container spacing='20px'>
            {userClasses &&
            userClasses.filter((el) => el.public).map((el) =>
              <Grid item key={el.id} xs={12} sm={6}>
              <CommunitySelection
                className={t(translations.community.scuolaOnline)}
                userClass={el}
                backgroundImage={el.previewImage ? `url(${config.cdnBaseApiURI}${el.previewImage})` : 'url(/images/community/richard-horvath.png)'}
              />
              </Grid>)}
            {userClasses &&
            userClasses.filter((el) => !el.public).map((el) =>
              <Grid item key={el.id} xs={12} sm={6} >
              <CommunitySelection
                className={el.name}
                userClass={el}
                backgroundImage={el.previewImage ? `url(${config.cdnBaseApiURI}${el.previewImage})` : 'url(/images/community/Bitmap2.png)'}
              />
              </Grid>)}
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
