import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Button, CircularProgress, Collapse } from '@mui/material'

import { PostType } from 'types/PostType'
import { translations } from 'locales/i18n'
import StyledModal from 'components/shared/modal/StyledModal'
import CreatePostForm from './CreatePostForm'
import { PostInterface } from 'types/PostInterface'
import { PostFile } from 'types/PostFile'
import { CreatePostResponse } from 'types/CreatePostResponse'
import { PostRequest } from 'types/requests/PostRequest'
import useEditPost from 'hooks/useEditPost'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import CommunityUserAvatar from '../../CommunityUserAvatar'

type CreatePostProps = {
  classId: string
  currentUser: StudentProfileInterface
  onPostCreate: (data: PostRequest) => Promise<CreatePostResponse>
  onPostUpdate: (data: PostRequest) => Promise<PostInterface>
  onPollUpdate: (data: PostRequest) => Promise<PostInterface>
  onAddFileToPost: (data: AddFileToPostRequest) => Promise<PostFile>
}

const CreatePost: FC<CreatePostProps> = ({
                                           classId,
                                           currentUser,
                                           onPostCreate,
                                           onPostUpdate,
                                           onPollUpdate,
                                           onAddFileToPost,
                                         }) => {
  const { t } = useTranslation()

  const [creatingPostOpen, setCreatingPostOpen] = useState(false)
  const [postType, setPostType] = useState(PostType.SIMPLE_POST)
  const [post, setPost] = useState<PostInterface>()

  const handleStartPostCreating = (type: PostType) => {
    setPostType(type)
    setCreatingPostOpen(true)
    onPostCreate({ postType, classId, text: '' })
      .then(({ id, text, type, publishedAt }) => setPost({
        postId: id,
        postText: text,
        postType: type,
        publishedAt,
      } as PostInterface))
  }
  const handleCancelPostCreating = () => {
    setCreatingPostOpen(false)
    setPost(undefined)
  }

  const {
    postText,
    setPostText,
    uploadingFiles,
    currentFiles,
    showAddOption,
    pollVariants,
    handleAddFiles,
    handleRemoveFile,
    handleAddOption,
    handleRemoveOption,
    handleUpdateOptionText,
    handleUpdateOptionPosition,
    handleSubmit,
    handleCancel,
  } = useEditPost({
    classId,
    post: post || {} as PostInterface,
    onSubmit: postType === PostType.POLL_POST ? onPollUpdate : onPostUpdate,
    onCancel: handleCancelPostCreating,
    onAddFile: onAddFileToPost,
  })

  const { profile } = currentUser
  const { firstName, lastName, profileImageUri } = profile

  const createPostForm =
    post ? <CreatePostForm
        classId={classId}
        currentUser={currentUser}
        post={post}
        postType={postType}
        postText={postText}
        setPostText={setPostText}
        setPostType={setPostType}
        uploadingFiles={uploadingFiles}
        currentFiles={currentFiles}
        showAddOption={showAddOption}
        pollVariants={pollVariants}
        handleAddFiles={handleAddFiles}
        handleRemoveFile={handleRemoveFile}
        handleAddOption={handleAddOption}
        handleRemoveOption={handleRemoveOption}
        handleUpdateOptionText={handleUpdateOptionText}
        handleUpdateOptionPosition={handleUpdateOptionPosition}
        handleSubmit={handleSubmit}
        handleCancel={handleCancel}
      />
      : <CircularProgress/>

  return (
    <Box
      sx={{
        width: '100%',
        margin: 0,
        borderRadius: '3px',
        background: theme => theme.palette.background.paper,
      }}
    >
      <Collapse in={!creatingPostOpen}>
        <Box sx={{ width: '100%', height: 'auto' }}>
          <Box sx={{
            display: 'flex',
            height: { xs: '52px', sm: '71px' },
            alignItems: 'center',
            px: { xs: '12px', sm: '20px' },
            borderRadius: '3px',
          }}>
            <CommunityUserAvatar
              profileImageUri={profileImageUri}
              firstName={firstName}
              lastName={lastName}
              sx={{ width: { xs: '30px', sm: '42px' }, height: { xs: '30px', sm: '42px' } }}
            />
            <Button
              className="u-pt-10 u-pb-10"
              sx={{
                display: 'flex',
                flexGrow: 1,
                alignItems: 'center',
                justifyContent: 'center',
                height: { xs: '30px', sm: '42px' },
                ml: { xs: '12px', sm: '15px' },
                py: '11px',
                bgcolor: '#2D2E2F',
                borderRadius: '3px',
                fontSize: { xs: '12px', sm: '16px' },
                lineHeight: { xs: '14px', sm: '19px' },
                cursor: 'pointer',
                textTransform: 'none',
                color: 'white',
                fontWeight: 'normal',
              }}
              onClick={() => handleStartPostCreating(PostType.SIMPLE_POST)}
            >
              {t(translations.community.feed.addPost)}
            </Button>
            <Box
              sx={{
                ml: { xs: '12px', sm: '15px' },
                bgcolor: '#2D2E2F',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: { xs: '50px', sm: '42px' },
                height: { xs: '29px', sm: '42px' },
                borderRadius: { sm: '50%' },
                cursor: 'pointer',
              }}
              onClick={() => handleStartPostCreating(PostType.POLL_POST)}
            >
              <img
                src='/images/icons/community/pull.svg'
                alt="Add a poll"
              />
            </Box>
          </Box>
        </Box>
      </Collapse>
      <Collapse in={creatingPostOpen} sx={{ display: { xs: 'none', sm: 'block' } }}>
        {createPostForm}
      </Collapse>

      <StyledModal
        open={creatingPostOpen}
        onClose={handleCancelPostCreating}
        title={t(translations.community.postCard.addPost)}
        mobileOnly={true}
      >
        {createPostForm}
      </StyledModal>
    </Box>
  )
}
export default CreatePost
