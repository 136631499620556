import React, { FC, ReactComponentElement } from "react";
import { Box, Typography } from "@mui/material";

import QuestionsCountWithPlayIcon from "../QuestionsCountWithPlayIcon";
import { CardType } from "types/CardType";
import {translations} from "locales/i18n";
import { useTranslation } from "react-i18next";

interface QuizCardProps {
  type: string;
  title: ReactComponentElement<any> | string;
  backgroundColor: string;
  questionsCount: number;
  onClick: () => void;
  isHide?: boolean;
}

const QuizCard: FC<QuizCardProps> = ({
  type,
  title,
  backgroundColor,
  questionsCount,
  onClick,
  isHide,
}) => {
  const { t } = useTranslation();
  
  return (
    <Box
      sx={{
        minHeight: { xs: "130px", sm: "165px" },
        padding: { xs: "11px 13px", sm: "17px 25px" },
        background: backgroundColor,
        borderRadius: "3px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Box>
        <Typography
          sx={{
            fontSize: { xs: "8px", sm: "12px" },
            lineHeight: { xs: "10px", sm: "15px" },
            letterSpacing: "2px",
          }}
          className="u-opacity-50 u-weight-600 u-pb-5"
        >
          {type}
        </Typography>
        <Typography
          className="u-mt-4 u-weight-bold"
          sx={{
            fontSize: { xs: "18px", md: "24px" },
            lineHeight: { xs: "23px", md: "29px" },
            textOverflow: "ellipsis",
            overflow: type === CardType.TEST ? "visible" : "hidden",
            width: "100%",
            height: "1.2em",
            whiteSpace: "nowrap",
          }}
        >
          {title}
        </Typography>
      </Box>
      <QuestionsCountWithPlayIcon
        isHide={isHide}
        questionsCount={questionsCount}
        textButton={t(translations.quizzes.open)}
        quizType={type}
      />
    </Box>
  );
};
export default QuizCard;
