import React, { FC } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

import QuestionsCountWithPlayIcon from "../QuestionsCountWithPlayIcon";
import { useTranslation } from "react-i18next";
import { translations } from "locales/i18n";

type SimulationCardProps = {
  title: string;
  url: string;
  backgroundColor?: string;
  questionsCount?: number;
  quizCount?: number;
};

const SimulationCard: FC<SimulationCardProps> = ({
  backgroundColor,
  title,
  url,
  questionsCount,
  quizCount,
}) => {
  const {t} = useTranslation()
  
  return (
    <Link to={url}>
      <Box
        sx={{
          color: "white",
          background: backgroundColor,
          padding: { xs: "12px 20px", sm: "21px 24px" },
          minHeight: { xs: "160px", sm: "233px", xl: "270px" },
          borderRadius: "4px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
        className="u-cursor-pointer"
      >
        <Box
          sx={{
            fontWeight: "bold",
            fontSize: { xs: "18px", sm: "24px" },
            lineHeight: { xs: "24px", sm: "29px" },
            width: { xs: "75%", md: "70%", lg: "50%" },
          }}
        >
          {title}
        </Box>
        <QuestionsCountWithPlayIcon
          quizCount={quizCount}
          questionsCount={questionsCount}
          textButton={questionsCount ? `${t(translations.home.startSimulation)}` : `${t(translations.quizzes.openSimulations)}`}
        />
      </Box>
    </Link>
  );
};

export default SimulationCard;
