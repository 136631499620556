import { clientDataService } from '../clientDataService'
import { PostInterface } from 'types/PostInterface'
import { Posts } from 'types/Posts'
import jsonToFormData from 'utils/jsonToFormData'
import { PostFile } from 'types/PostFile'
import { CreatePostResponse } from 'types/CreatePostResponse'
import { PostRequest } from 'types/requests/PostRequest'
import { ClassTab } from 'types/ClassTab'

export const getPosts = (classId: string, classTab: ClassTab, page: number, searchQuery: string, favouritesOnly: boolean, pinnedOnly: boolean = false): Promise<Posts> =>
  clientDataService<Posts>({
    url: `/classes/${classId}/posts
?pinnedOnly=${pinnedOnly}
&favoritesOnly=${favouritesOnly}
&text=${searchQuery}
&size=10
&page=${page}
${classTab === ClassTab.MY_POSTS ? '&mineOnly=true' : ''}
${classTab === ClassTab.TEACHERS_POSTS ? '&teachersOnly=true' : ''}
${classTab === ClassTab.SCHEDULED_POSTS ? '&scheduledOnly=true' : ''}
${classTab === ClassTab.VIDEO ? '&fileType=VIDEO' : ''}
${classTab === ClassTab.PHOTO ? '&fileType=IMAGE' : ''}
${classTab === ClassTab.FILE ? '&fileType=FILE' : ''}
`,
  })

export const getPost = ({classId, postId, pinnedOnly = false}: GetPostRequest): Promise<Posts> =>
  clientDataService<Posts>({
    url: `/classes/${classId}/posts?postId=${postId}&pinnedOnly=${pinnedOnly}`,
  })

export const getRecentPosts = (classId: string): Promise<Posts> =>
  clientDataService<Posts>({
    url: `/classes/${classId}/posts?size=3&type=POLL_POST`,
  })

export const createPost = (data: PostRequest): Promise<CreatePostResponse> =>
  clientDataService<CreatePostResponse>({
    url: `/classes/${data.classId}/posts`,
    method: 'POST',
    data,
  })

export const updatePost = (data: PostRequest): Promise<PostInterface> =>
  clientDataService<PostInterface>({
    method: 'PATCH',
    url: `/v2/classes/${data.classId}/posts/${data.id}/post`,
    data,
  })

export const updatePoll = (data: PostRequest): Promise<PostInterface> =>
  clientDataService<PostInterface>({
    url: `/v2/classes/${data.classId}/posts/${data.id}/poll`,
    method: 'PATCH',
    data,
  })

export const publishPost = (data: PublishPostRequest): Promise<void> =>
  clientDataService<void>({
    method: 'PUT',
    url: `/classes/${data.classId}/posts/${data.postId}/publish`,
  })

export const schedulePost = (data: SchedulePostRequest): Promise<void> =>
  clientDataService<void>({
    method: 'PUT',
    url: `/classes/${data.classId}/posts/${data.postId}/schedule`,
    data,
  })

export const createVotePollOption = (data: VotePollRequest): Promise<void> =>
  clientDataService<void>({
    url: `/classes/${data.classId}/posts/${data.postId}/poll/options/${data.optionId}/vote`,
    method: 'POST',
    data,
  })

export const updateVotePollOption = (data: VotePollRequest): Promise<void> =>
  clientDataService<void>({
    url: `/classes/${data.classId}/posts/${data.postId}/poll/options/${data.optionId}/vote`,
    method: 'PUT',
    data,
  })

export const deletePost = ({classId, postId}: PostActionRequest): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `/classes/${classId}/posts/${postId}`,
  })

export const pinPost = ({classId, postId}: PostActionRequest): Promise<void> =>
  clientDataService<void>({
    method: 'PUT',
    url: `/classes/${classId}/posts/${postId}/pin`,
  })

export const unpinPost = ({classId, postId}: PostActionRequest): Promise<void> =>
  clientDataService<void>({
    method: 'PUT',
    url: `/classes/${classId}/posts/${postId}/unpin`,
  })

export const addPostToFavorite = ({classId, postId}: PostActionRequest): Promise<void> =>
  clientDataService<void>({
    method: 'POST',
    url: `/classes/${classId}/posts/${postId}/favorites`,
  })

export const removePostFromFavorite = ({classId, postId}: PostActionRequest): Promise<void> =>
  clientDataService<void>({
    method: 'DELETE',
    url: `/classes/${classId}/posts/${postId}/favorites`,
  })

export const addFileToPost = (data: AddFileToPostRequest): Promise<PostFile> =>
  clientDataService<PostFile>({
    method: 'POST',
    url: `/classes/${data.classId}/posts/${data.id}/upload-file`,
    data: jsonToFormData(data),
  })

export const reportPost = (data: ReportPostRequest): Promise<void> =>
  clientDataService<void>({
    method: 'POST',
    url: `/classes/${data.classId}/posts/${data.postId}/report`,
    data,
  })
