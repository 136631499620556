import React, { FC } from 'react'
import { Box } from '@mui/material'

const PasswordLayout: FC = ({children}) => {
  return (
    <Box sx={{
      minHeight: 'calc(100vh - 50px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}>
      <Box sx={{
        justifyContent: 'center',
        width: {xs: '100%'},
      }}>
        {children}
      </Box>
    </Box>
  )
}

export default PasswordLayout
