import React, { FC } from 'react'
import { Button, ButtonProps } from '@mui/material'
import theme from 'theme'

const style = {
  color: 'white',
  border: '1px solid white',
  borderRadius: '3px',
  fontSize: { xs: '12px', md: '16px' },
  lineHeight: { xs: '14px', md: '19px' },
  fontWeight: '500',
  padding: { xs: '8px 15px', md: '10px 20px' },
  minWidth: { xs: '82px', md: '117px' },
  minHeight: { xs: '35px', md: '43px' },
  ':hover': { bgcolor: theme.palette.background.paper },
} as const

interface BasicButtonProps extends ButtonProps {
  text: string
  children?: React.ReactNode
  className?: string
  onClick?: () => void
}

const BasicButton: FC<BasicButtonProps> = ({
                                             text,
                                             children,
                                             className,
                                             onClick,
                                             ...rest
                                           }) =>
  <Button
    sx={{ ...style }}
    className={className}
    onClick={onClick}
    {...rest}
  >
    {text}
    {children}
  </Button>

export default BasicButton
