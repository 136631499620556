import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { Box, Button, Grid, Typography } from '@mui/material'
import CircularChart from '../shared/circular-chart/CircularChart'
import { CourseSearchView } from 'types/CourseSearchView'
import { translations } from 'locales/i18n'
import SwipeableViews from '../shared/swipeable-views/SwipeableViews'
import config from 'utils/config'
import { getCourseContinueLink, getCourseLink } from '../../services/courseLinkService'

type CoursesSwipableProps = {
  courses?: CourseSearchView[]
}

const CoursesSwipable: FC<CoursesSwipableProps> = ({ courses = [] }) => {
  const { t } = useTranslation()

  const slideRenderer = (index) => {
    const course = courses[index]
    const {
      courseId,
      coursePreviewImageUri,
      courseLessonsCount,
      courseCompletedLessonsCount,
      lastVisitedLessonName,
    } = course

    return (
      <Grid container mb={4} key={courseId}>
        <Grid item xs={12} md={8} lg={6} className="u-position-relative"
              sx={{
                overflow: 'hidden',
                width: '100%',
                minHeight: { xs: '240px', md: '354px' },
                background: theme => theme.palette.background.default,
              }}
        >
          <Link to={getCourseContinueLink(course)}>
            <img style={{ objectFit: 'cover', aspectRatio: '4 / 3' }}
                 src={`${config.cdnBaseApiURI}${coursePreviewImageUri}`} className="u-position-absolute u-w-100 u-h-100"
                 alt=""/>
          </Link>
        </Grid>
        <Grid item xs={12} md={4} lg={6}
              sx={{
                minHeight: { xs: '165px', md: '354px' },
                background: theme => theme.palette.background.paper,
              }}
        >
          <Box className="u-color-white u-h-100" sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: { xs: '20px', md: '30px' },
          }}>
            <Box sx={{ mb: { sm: '30px', md: '0' } }}>
              <CircularChart
                percentage={{ value: courseCompletedLessonsCount, maxValue: courseLessonsCount }}
                className="CircularChart_jungle-green CircularChart_wide_stroke CircularChart_white-bg u-mb-20"
                text={<text x="18" y="23" className="Percentage">
                  <tspan className="u-fs-12 u-lh-25">
                    {courseLessonsCount === 0 ? '0%' : `${Math.floor(courseCompletedLessonsCount / courseLessonsCount * 100)}%`}
                  </tspan>
                </text>}
                height="47px"
              />
              <Link to={getCourseLink(course)}>
                <Typography className="u-lh-24" sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                  {courses[index].courseName}
                </Typography>
              </Link>
              <Typography className="u-weight-bold" sx={{
                fontSize: { xs: '18px', md: '30px' },
                lineHeight: { xs: '25px', md: '35px' },
                width: { md: '80%' },
                mb: { xs: '20px' },
              }}>{lastVisitedLessonName}</Typography>
            </Box>
            <Link to={getCourseContinueLink(course)}>
              <Button
                variant="contained"
                type="button"
                sx={{
                  py: '10px',
                  width: '205px',
                  height: '45px',
                  textTransform: 'none',
                  fontSize: { xs: '12px', md: '16px' },
                  lineHeight: '19px',
                  fontWeight: 'normal !important',
                }}
              >
                {t(translations.courses.continueTheCourse)}
              </Button>
            </Link>
          </Box>
        </Grid>
      </Grid>
    )
  }

  return (
    <SwipeableViews views={courses} slideRenderer={slideRenderer}/>
  )

}

export default CoursesSwipable
