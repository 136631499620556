import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { Box, Typography, Button, LinearProgress, Card } from '@mui/material'
import { isEmpty, camelCase } from 'lodash'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'

import playButtonRed from 'images/play-button-red.svg'
import textLessonBg from 'images/lesson-text-bg.svg'
import { EnrolledLessonState } from 'types/EnrolledLessonState'
import { getDurationMilliFormatted } from 'utils/duration'
import { FULL_TIME_FORMAT, MM_SS_TIME_FORMAT } from 'utils/constants'
import config from 'utils/config'

type LessonCardProps = {
  courseId: string
  lessonId: string
  lecturer: string
  name: string
  lessonState: EnrolledLessonState
  previewImageUri: string
  videoUri?: string
  videoLength: number
  videoSecondsViewed?: number
}

const LessonCard: FC<LessonCardProps> = ({
                                           courseId,
                                           lessonId,
                                           lecturer,
                                           name,
                                           lessonState,
                                           previewImageUri,
                                           videoUri,
                                           videoLength,
                                           videoSecondsViewed,
                                         }) => {
  const isVideoLesson = !isEmpty(videoUri)
  const {t} = useTranslation()

  const getStateBoxClassName = () => {
    switch (lessonState) {
      case EnrolledLessonState.IN_PROGRESS:
        return 'u-bg-carrot-orange'
      case EnrolledLessonState.COMPLETED:
        return 'u-bg-aqua-forest'
      default:
        return ''
    }
  }

  const renderState = () => (
    <Box className={`${getStateBoxClassName()}`}
         sx={{
            borderRadius: {xs: '20px', md: '28px'},
            width: 'max-content',
            height: {xs: '18.2px', md: '25px'},
            p: '0 17px',
            display: 'flex',
            alignItems: 'center',
            }}
    >
      <Typography component={"span"} sx={{
        fontSize: {xs: '10px', md: '14px'},
        lineHeight: {xs: '12px', md: '17px'},
      }}>{t(translations.courses.state[camelCase(lessonState)])}</Typography>
    </Box>
  )

  return (
    <Box className="u-w-100 u-h-100">
      <Card sx={{ borderRadius: '3px' }}>
        <Link to={`/courses/${courseId}/lessons/${lessonId}`}>
          <Box className="u-position-relative u-cursor-pointer">
            <Box sx={{
              minHeight: '168px',
              background: !previewImageUri ? 'linear-gradient(180deg, #37679D 0%, #E86393 97.49%)' : '',
              width: '100%',
              height: { xs: '70%', md: '150px', lg: '204px' },
              position: 'relative',
            }}>
              <Box
                sx={{
                  position: 'absolute',
                  zIndex: 1,
                  padding: {xs: '20px 25px', md: '15px 0', lg: '15px 20px'},
                  pointerEvents: 'none'
                }}
              >
                {(lessonState === EnrolledLessonState.COMPLETED || lessonState === EnrolledLessonState.IN_PROGRESS) &&
                  renderState()
                }
              </Box>
              <img
                src={previewImageUri ? `${config.cdnBaseApiURI}${previewImageUri}` : textLessonBg}
                className="u-w-100"
                alt="Lesson preview"
                style={{ minHeight: '168px', height: 'inherit', objectFit: 'cover', aspectRatio: '4 / 2' }}
              />
              {isVideoLesson && <LinearProgress
                  variant="determinate"
                  className="ProgressBar"
                  sx={{
                    width: '100%',
                    bgcolor: 'transparent',
                    '& .MuiLinearProgress-bar': { bgcolor: '#E25F60' },
                    position: 'absolute',
                    bottom: 0,
                  }}
                  value={(videoSecondsViewed || 0) / videoLength * 100}
              />}
            </Box>
            {isVideoLesson && (
              <Box>
                <Button sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  <img src={playButtonRed} alt="Play video"/>
                </Button>

                <Box sx={{
                  bgcolor: theme => theme.palette.background.paper,
                  borderRadius: '23px',
                  position: 'absolute',
                  bottom: '15px',
                  right: '3%',
                  p: '5px 10px',
                }}>
                  <Typography sx={{
                    fontSize: { xs: '12px', md: '14px' },
                    lineHeight: { xs: '17px', md: '20px' },
                    fontWeight: '400',
                  }}>{getDurationMilliFormatted(videoLength * 1000, (videoLength > 3600 ? FULL_TIME_FORMAT : MM_SS_TIME_FORMAT))}</Typography>
                </Box>
              </Box>
            )} 
          </Box>
        </Link>
        
        <Box
          sx={{ py: { xs: '8px', md: '12px' }, px: { xs: '10px', md: '20px' }, minHeight: { xs: '69px', sm: '84px' } }}
        >
          <Typography className="u-lh-24 u-text-overflow-ellipsis" sx={{
            fontSize: { xs: '14px', md: '18px' },
            lineHeight: { xs: '17px', md: '22px' },
            fontWeight: 'bold',
          }}>{name}</Typography>
          <Typography className="u-lh-24 u-opacity-50 u-text-overflow-ellipsis" sx={{
            fontSize: { xs: '12px', md: '14px' },
            lineHeight: { xs: '17px', md: '20px' },
          }}>{lecturer}</Typography>
        </Box>
      </Card>
    </Box>
  )
}

export default LessonCard
