import { SnackbarKey, useSnackbar } from 'notistack'
import React, { FC } from 'react'

import close from 'images/close-white.svg'

type SnackbarCloseButtonProps = {
  snackbarKey: SnackbarKey
}

const SnackbarCloseButton: FC<SnackbarCloseButtonProps> = ({snackbarKey}) => {
  const {closeSnackbar} = useSnackbar()

  return (
    <img
      src={close}
      alt="Close"
      className="u-cursor-pointer u-pr-8"
      onClick={() => closeSnackbar(snackbarKey)}
    />
  )
}

export default SnackbarCloseButton
