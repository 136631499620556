import React, {useEffect, useState} from 'react'
import {translations} from 'locales/i18n'
import {useTranslation} from 'react-i18next'
import {Box} from '@mui/material'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import RankingNavigation from 'components/ranking/RankingNavigation'
import RankingRecords from 'components/ranking/RankingRecords'
import {Back} from 'components/shared/back'
import useRankingRecords from 'hooks/useRankingRecords'
import useRankingSummary from 'hooks/useRankingSummary'
import LoadingIndicator from 'components/shared/loading-indicator'
import ReactQueryKeys from "../types/ReactQueryKeys";
import {useQueryClient} from "react-query";
import {useLocation} from "react-router-dom";
import {isEmpty} from 'lodash'


const RankingPage = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const {hash} = useLocation();

  const rankingSummaryQuery = useRankingSummary()
  const rankingSummary = rankingSummaryQuery.data
  const monthlySummaryQuizId = rankingSummary?.monthlySimulationQuizSummary.activeQuizId

  const [selectedSpecialtyIdx, setSelectedSpecialtyIdx] = useState<number | null>(null)
  const [selectedQuizzesID, setSelectedQuizzesID] = useState('')

  useEffect(() => {
    const currentID = hash === '' && monthlySummaryQuizId ? monthlySummaryQuizId : ''
    setSelectedQuizzesID(currentID)
    setSelectedSpecialtyIdx(null)
  }, [hash, monthlySummaryQuizId]);

  const rankingRecordsQuery = useRankingRecords(selectedQuizzesID)
  const rankingRecords = rankingRecordsQuery.data

  const handleSelectSpecialty = (idx: number) => {
    setSelectedSpecialtyIdx(idx)
    setSelectedQuizzesID('')
  }

  const handleSelectQuizzes = async (id: string) => {
    setSelectedQuizzesID(id)
    await queryClient.invalidateQueries([ReactQueryKeys.RANKING_RECORDS])
  }

  return (
    <DashboardLayout>
      <Back text={t(translations.navigation.homePage)} url={'/'} sx={{mx: {xs: '20px', sm: '48px'}}}/>
      <Box sx={{mt: {xs: '40px', sm: '70px'}, overflow: 'hidden'}}>
        <Box sx={{ml: {xs: 0, sm: 6.5}, mr: {xs: 0, sm: 6.5}, mb: "20px"}}>
          {rankingSummary &&
              <RankingNavigation
                hash={hash}
                summaryTab={rankingSummary}
                isRecordsHidden={isEmpty(selectedQuizzesID)}
                selectedSpecialtyIdx={selectedSpecialtyIdx}
                onSelectSpecialty={handleSelectSpecialty}
                onSelectQuizId={handleSelectQuizzes}
              />}

          {!isEmpty(selectedQuizzesID) && rankingRecords &&
              <RankingRecords rankingItems={rankingRecords}/>}
        </Box>
      </Box>
      {(rankingRecordsQuery.isLoading) &&
          <LoadingIndicator/>}
    </DashboardLayout>
  )
}


export default RankingPage
