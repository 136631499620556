import React, { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { translations } from 'locales/i18n'
import { Box, CircularProgress } from '@mui/material'
import { Virtuoso } from 'react-virtuoso'
import { isEmpty } from 'lodash'

import { PostInterface } from 'types/PostInterface'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { PostType } from 'types/PostType'
import { PostFile } from 'types/PostFile'
import { PostRequest } from 'types/requests/PostRequest'

import useEditPost from 'hooks/useEditPost'
import PostCard from './post-card/PostCard'
import PostCardHeaderData from './post-card/PostCardHeaderData'
import PostCommentCard from './post-card/comments/PostCommentCard'
import PostForm from './post-form/PostForm'
import PostCommentForm from './post-card/comments/comment-form/PostCommentForm'
import PostCardFullScreen from './post-card/PostCardFullScreen'
import ModalReport from 'components/shared/modal/ModalReport'
import StyledModal from 'components/shared/modal/StyledModal'
import ModalConfirmation from 'components/shared/modal/ModalConfirmation'
import { PostActionType } from 'types/PostActionType'
import theme from 'theme'

type PostsListProps = {
  classId: string
  currentUser: StudentProfileInterface
  classTeachers: TeacherInterface[]
  isPostsLoading: boolean
  isCommentFormSubmitting: boolean
  posts?: PostInterface[]
  pinnedPosts?: PostInterface[]
  postToOpen?: PostInterface
  commentToOpen?: PostCommentReport
  fullscreenPostOpen: boolean
  onFullscreenPostOpen: (data: boolean) => void
  onPostUpdate: (data: PostRequest) => Promise<PostInterface>
  onPollUpdate: (data: PostRequest) => Promise<PostInterface>
  onCreateVotePollOption: (data: VotePollRequest) => void
  onUpdateVotePollOption: (data: VotePollRequest) => void
  onPostDelete: (post: PostInterface) => void
  onPostPin: (post: PostInterface) => void
  onPostUnpin: (post: PostInterface) => void
  onAddPostToFavourites: (post: PostInterface) => void
  onRemovePostFromFavourites: (post: PostInterface) => void
  onReportPost: (post: ReportPostRequest) => void
  onReportComment: (post: ReportCommentRequest) => void
  onAddFileToPost: (data: AddFileToPostRequest) => Promise<PostFile>
  onTypingActive: (status: boolean) => void
  onCommentCreate: (data: PostCommentRequest) => Promise<PostCommentResponse>
  onCommentUpdate: (data: PostCommentRequest) => Promise<PostCommentResponse>
  onCommentDelete: (data: DeletePostCommentRequest) => void
  onNextPage: () => void
  lastPage: boolean
}

const PostsList: FC<PostsListProps> = ({
                                         classId,
                                         currentUser,
                                         classTeachers,
                                         isPostsLoading,
                                         isCommentFormSubmitting,
                                         posts = [],
                                         pinnedPosts = [],
                                         postToOpen,
                                         commentToOpen,
                                         fullscreenPostOpen,
                                         onFullscreenPostOpen,
                                         onPostUpdate,
                                         onPollUpdate,
                                         onCreateVotePollOption,
                                         onUpdateVotePollOption,
                                         onPostDelete,
                                         onPostPin,
                                         onPostUnpin,
                                         onAddPostToFavourites,
                                         onRemovePostFromFavourites,
                                         onReportPost,
                                         onReportComment,
                                         onAddFileToPost,
                                         onTypingActive,
                                         onCommentCreate,
                                         onCommentUpdate,
                                         onCommentDelete,
                                         onNextPage,
                                         lastPage,
                                       }) => {
  const {t} = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const isClassMember = currentUser.classes.includes(classId) || currentUser.mainRole === 'ADMIN'
  const [editingPostOpen, setEditingPostOpen] = useState(false)
  const [editingCommentOpen, setEditingCommentOpen] = useState(false)
  const [deletingPostOpen, setDeletingPostOpen] = useState(false)
  const [postReportOpen, setPostReportOpen] = useState(false)
  const [postReportMessage, setPostReportMessage] = useState<string>('')
  const [postForActionId, setPostForActionId] = useState<string>()
  const [commentForAction, setCommentForAction] = useState<PostCommentReport>({} as PostCommentReport)

  const postForAction = [...posts, ...pinnedPosts]?.find(({postId}) => postId === postForActionId) || postToOpen || {} as PostInterface

  const handleEditingPostOpen = () => setEditingPostOpen(true)
  const handleEditingPostClose = () => setEditingPostOpen(false)

  const handleDeletingPostOpen = () => setDeletingPostOpen(true)
  const handleDeletingPostClose = () => setDeletingPostOpen(false)

  const handleFullscreenPostOpen = () => onFullscreenPostOpen(true)
  const handleFullscreenPostClose = () => onFullscreenPostOpen(false)

  const notEmptyPostForAction = !isEmpty(postForAction) && !isEmpty(postForAction.postId)

  useEffect(() => {
    if (postToOpen && !isEmpty(postToOpen)) {
      setPostForActionId(postToOpen.postId)
      const {actionType} = postToOpen
      if (actionType === PostActionType.EDIT) {
        handleEditingPostOpen()
      } else if (actionType === PostActionType.DELETE) {
        handleDeletingPostOpen()
      } else if (actionType === PostActionType.REPORT) {
        setPostReportOpen(true)
      } else if (actionType === PostActionType.FULLSCREEN) {
        handleFullscreenPostOpen()
      }
    }
  }, [postToOpen])

  useEffect(() => {
    if (commentToOpen && !isEmpty(commentToOpen)) {
      setCommentForAction(commentToOpen)
      setEditingCommentOpen(true)
      onTypingActive(true)
    }

  }, [commentToOpen])

  const startAction = (action: () => void) => setTimeout(action, 5)

  const handleEditingPost = (post: PostInterface) => {
    setPostForActionId(post.postId)
    startAction(handleEditingPostOpen)
  }

  const handleDeletingPost = (post: PostInterface) => {
    setPostForActionId(post.postId)
    startAction(handleDeletingPostOpen)
  }

  const handleFullscreenPost = (post: PostInterface) => {
    setPostForActionId(post.postId)
    startAction(handleFullscreenPostOpen)
  }

  const handlePostUpdate = (data: PostRequest) =>
    data.postType === PostType.POLL_POST ? onPollUpdate(data) : onPostUpdate(data)

  const handleUpdatePostReportMessage = (e) => {
    const {value} = e.target
    if (!isEmpty(value.trim())) {
      setPostReportMessage(value.trim())
    }
  }

  const handleOpenPostReport = (post: PostInterface) => {
    setPostReportOpen(true)
    setPostForActionId(post.postId)
  }

  const handleOpenCommentReport = (comment: PostCommentReport) => {
    setPostReportOpen(true)
    setCommentForAction(comment)
  }

  const handleClosePostReport = () => {
    setPostReportMessage('')
    setCommentForAction({} as PostCommentReport)
    setPostReportOpen(false)
  }

  const handleCloseCommentEdit = () => {
    setCommentForAction({} as PostCommentReport)
    setEditingCommentOpen(false)
     onTypingActive(false)
  }

  const handlePostReportMessage = () => {
    if (!isEmpty(postReportMessage) && postForActionId && isEmpty(commentForAction)) {
      onReportPost({classId, postId: postForActionId, reportText: postReportMessage})
    }
    setPostReportMessage('')
    setPostReportOpen(false)
  }

  const handleCommentReportMessage = () => {  
    !isEmpty(postReportMessage) && !isEmpty(commentForAction) &&
      onReportComment({
        classId,
        postId: commentForAction.postId,
        commentId: commentForAction.id,
        reportText: postReportMessage,
      })

    setCommentForAction({} as PostCommentReport)
    setPostReportMessage('')
    setPostReportOpen(false)
  }

  const handleLinkCopied = () => enqueueSnackbar(
    t(translations.messages.linkCopied),
    { variant: 'success' },
  )

  const {
    postText,
    setPostText,
    uploadingFiles,
    currentFiles,
    showAddOption,
    pollVariants,
    handleAddFiles,
    handleRemoveFile,
    handleAddOption,
    handleRemoveOption,
    handleUpdateOptionText,
    handleUpdateOptionPosition,
    handleSubmit,
    handleCancel,
  } = useEditPost({
    classId,
    post: postForAction,
    onSubmit: handlePostUpdate,
    onCancel: handleEditingPostClose,
    onAddFile: onAddFileToPost,
  })

  const postCard = (post: PostInterface, isFirst: boolean = false) => {
    const {pinned} = post

    return (
      <Box
        key={post.postId}
        sx={{
          pt: pinned ? {xs: '10px', md: '20px'} : {xs: '17px', md: '30px'},
          pb: pinned ? 0 : {xs: '17px', md: '30px'},
          borderTop: pinned || isFirst ? 'none' : '1px solid rgba(255, 255, 255, .1)',
        }}
      >
        <PostCard
          classId={classId}
          currentUser={currentUser}
          classTeachers={classTeachers}
          post={post}
          isCommentFormSubmitting={isCommentFormSubmitting}
          onStartEdit={() => handleEditingPost(post)}
          onStartFullscreen={() => handleFullscreenPost(post)}
          onPostDelete={() => handleDeletingPost(post)}
          onPostPin={() => onPostPin(post)}
          onPostUnpin={() => onPostUnpin(post)}
          onCopiedLink={handleLinkCopied}
          onAddPostToFavourites={() => onAddPostToFavourites(post)}
          onRemovePostFromFavourites={() => onRemovePostFromFavourites(post)}
          onOpenPostReport={() => handleOpenPostReport(post)}
          onOpenCommentReport={handleOpenCommentReport}
          onTypingActive={onTypingActive}
          onCommentCreate={onCommentCreate}
          onCommentUpdate={onCommentUpdate}
          onCommentDelete={onCommentDelete}
          onCreateVotePollOption={onCreateVotePollOption}
          onUpdateVotePollOption={onUpdateVotePollOption}
          inputBackground={pinned ? '#2D2E2F' : theme.palette.background.paper}
          showMenu={isClassMember}
        />
      </Box>
    )
  }

  return (
    <Box>
      {isPostsLoading && <CircularProgress/>}

      {pinnedPosts && pinnedPosts.map((post) => postCard(post, false))}
      <Virtuoso
        useWindowScroll
        style={{height: '100%', minHeight: '500px'}}
        data={posts?.filter(({pinned}) => !pinned)}
        endReached={onNextPage}
        itemContent={(index, post) => postCard(post, index === 0)}
        components={{
          Footer: () => lastPage ? <></> : <CircularProgress/>,
        }}
      />

      {notEmptyPostForAction && (
        <StyledModal
          open={editingPostOpen}
          onClose={handleEditingPostClose}
          title={t(translations.community.postCard.editPost)}
          disableEnforceFocus={true}
        >
          <Box sx={{pt: {xs: '20px', sm: 0},mb: {xs: '100px', sm: 0} }}>
            <Box sx={{my: '20px'}}>
              <PostCardHeaderData post={postForAction} menuShown={false}/>
            </Box>
            <PostForm
              classId={classId}
              currentUser={currentUser}
              post={postForAction}
              postType={postForAction.postType}
              postText={postText}
              setPostText={setPostText}
              uploadingFiles={uploadingFiles}
              currentFiles={currentFiles}
              showAddOption={showAddOption}
              pollVariants={pollVariants}
              handleAddFiles={handleAddFiles}
              handleRemoveFile={handleRemoveFile}
              handleAddOption={handleAddOption}
              handleRemoveOption={handleRemoveOption}
              handleUpdateOptionText={handleUpdateOptionText}
              handleUpdateOptionPosition={handleUpdateOptionPosition}
              handleSubmit={handleSubmit}
              handleCancel={handleCancel}
            />
          </Box>
        </StyledModal>
      )}

      {!isEmpty(commentForAction) && (
        <StyledModal
          open={editingCommentOpen}
          onClose={handleCloseCommentEdit}
          title={t(translations.community.postCardComment.editComment)}
          disableEnforceFocus={true}
        >
          <Box sx={{px: {xs: '20px', sm: 0}}}>
            <Box sx={{mt: '20px'}}>
              <PostCommentForm
                classId={classId}
                currentUser={currentUser}
                postId={commentForAction.postId}
                comment={commentForAction}
                isCommentFormSubmitting={isCommentFormSubmitting}
                onTypingActive={onTypingActive}
                onSubmit={onCommentUpdate}
                onCancel={handleCloseCommentEdit}
                inputBackground="#2D2E2F"
              />
            </Box>
          </Box>
        </StyledModal>
      )}

      <ModalConfirmation
        open={deletingPostOpen}
        onClose={handleDeletingPostClose}
        onConfirm={() => onPostDelete(postForAction)}
        title={t(translations.common.confirmation)}
        text={t(translations.community.feed.deleteConfirmation)}
      />

      {notEmptyPostForAction && isEmpty(commentForAction) &&
        <ModalReport
          open={postReportOpen}
          onClose={handleClosePostReport}
          title={t(translations.community.postCard.reportPost)}
          mistakeMessage={t(translations.community.reports.mistakeMessage)}
          content={
            <Box sx={{px: {xs: '20px', sm: 0}, width: '100%', backgroundColor: theme.palette.background.paper}}>
              <PostCard
                classId={classId}
                currentUser={currentUser}
                classTeachers={classTeachers}
                post={postForAction}
                isCommentFormSubmitting={isCommentFormSubmitting}
                onStartEdit={() => handleEditingPost(postForAction)}
                onPostDelete={() => handleDeletingPost(postForAction)}
                onPostPin={() => onPostPin(postForAction)}
                onPostUnpin={() => onPostUnpin(postForAction)}
                onCopiedLink={handleLinkCopied}
                onAddPostToFavourites={() => onAddPostToFavourites(postForAction)}
                onRemovePostFromFavourites={() => onRemovePostFromFavourites(postForAction)}
                onOpenPostReport={() => handleOpenPostReport(postForAction)}
                onTypingActive={onTypingActive}
                onCommentCreate={onCommentCreate}
                onCommentUpdate={onCommentUpdate}
                onCommentDelete={onCommentDelete}
                onCreateVotePollOption={onCreateVotePollOption}
                onUpdateVotePollOption={onUpdateVotePollOption}
                inputBackground={postForAction.pinned ? '#2D2E2F' : theme.palette.background.paper}
                showMenu={false}
                showComments={false}
                marginPollOptions={false}
                canVote={false}
              />
            </Box>
          }
          onChangeStudentMessage={handleUpdatePostReportMessage}
          onSendReport={handlePostReportMessage}
        />
      }
      {!isEmpty(commentForAction) &&
        <ModalReport
          open={postReportOpen}
          onClose={handleClosePostReport}
          title={t(translations.community.postCardComment.reportComment)}
          mistakeMessage={t(translations.community.reports.mistakeMessage)}
          content={
            <Box sx={{px: {xs: '20px', sm: 0}, width: '100%', backgroundColor: theme.palette.background.paper}}>
              <PostCommentCard
                classId={classId}
                postId={commentForAction.postId || ''}
                comment={commentForAction}
                isCommentFormSubmitting={isCommentFormSubmitting}
                currentUser={currentUser}
                onTypingActive={onTypingActive}
                onCommentReply={onCommentCreate}
                onCommentUpdate={onCommentUpdate}
                onCommentDelete={onCommentDelete}
                inputBackground="#2D2E2F"
                showReplies={false}
                showMenu={false}
              />
            </Box>
          }
          onChangeStudentMessage={handleUpdatePostReportMessage}
          onSendReport={handleCommentReportMessage}
        />
      }

      {notEmptyPostForAction && (
        <PostCardFullScreen
          classId={classId}
          currentUser={currentUser}
          classTeachers={classTeachers}
          post={postForAction}
          isCommentFormSubmitting={isCommentFormSubmitting}
          fullscreenPostOpen={fullscreenPostOpen}
          onExitFullScreen={handleFullscreenPostClose}
          onStartEdit={() => handleEditingPost(postForAction)}
          onPostDelete={() => handleDeletingPost(postForAction)}
          onPostPin={() => onPostPin(postForAction)}
          onPostUnpin={() => onPostUnpin(postForAction)}
          onCopiedLink={handleLinkCopied}
          onAddPostToFavourites={() => onAddPostToFavourites(postForAction)}
          onRemovePostFromFavourites={() => onRemovePostFromFavourites(postForAction)}
          onOpenPostReport={() => setPostReportOpen(true)}
          onOpenCommentReport={handleOpenCommentReport}
          onTypingActive={onTypingActive}
          onCommentCreate={onCommentCreate}
          onCommentUpdate={onCommentUpdate}
          onCommentDelete={onCommentDelete}
          onCreateVotePollOption={onCreateVotePollOption}
          onUpdateVotePollOption={onUpdateVotePollOption}
        />
      )}
    </Box>
  )
}

export default PostsList
