import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  AppBar as MuiAppBar,
  Button,
  Toolbar,
  styled,
  Theme,
  CSSObject,
  AppBarProps as MuiAppBarProps,
  Box, MenuItem,
} from '@mui/material'
import { isEmpty } from 'lodash'

import { isDevelopment, NOTIFICATIONS_WS_URI } from 'utils/constants'
import { LanguageSwitch } from 'components/shared/language-switch'
import AccountMenu from './AccountMenu'
import HomeButton from './HomeButton'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import bellIcon from 'images/bell.svg'
import redCircleIcon from 'images/circle-notifications.svg'
import { translations } from 'locales/i18n'
import useListenToNotifications from 'hooks/community/useListenToNotifications'
import useSubscribe from 'hooks/community/useSubscribe'
import useNotifications from 'hooks/community/useNotifications'
import NotificationPopup from 'components/shared/notifications/NotificationPopup'
import { Subscription } from 'types/Subscription'

interface AppBarProps extends MuiAppBarProps {
  navCollapsed: boolean;
}

const AppBar = styled(MuiAppBar, {shouldForwardProp: (prop) => prop !== 'navCollapsed'})<AppBarProps>
(({theme, navCollapsed}) => (
  {
    backgroundColor: theme.palette.background.default,
    boxShadow: '0px 39px 34px rgba(0, 0, 0, 0.45)',
    overflow: 'hidden',
    ...(!navCollapsed && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(navCollapsed && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }))


const openedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
})


type TopBarProps = {
  navCollapsed: boolean
  toggleNav: () => void
  currentUser?: StudentProfileInterface,
  additionalSubscriptions?: Subscription[]
}

const TopBar: FC<TopBarProps> = ({
                                   navCollapsed,
                                   toggleNav,
                                   currentUser,
                                   additionalSubscriptions = [],
                                 }) => {
  const {t} = useTranslation()

  const notificationsQuery = useNotifications()
  const notifications = notificationsQuery.data

  const {
    notificationMessage,
    setNotificationMessage,
    handleMessage,
  } = useListenToNotifications()

  useSubscribe([
    {
      url: NOTIFICATIONS_WS_URI,
      handleMessage,
    },
    ...additionalSubscriptions,
  ])

  const hasNotifications = !isEmpty(notifications?.filter(({read}) => !read)) || !isEmpty(notificationMessage)

  return (
    <AppBar
      position="fixed"
      elevation={0}
      navCollapsed={navCollapsed}
      sx={{
        height: '50px',
        bgcolor: theme => theme.palette.background.paper,
      }}
    >
      <NotificationPopup
        currentUser={currentUser}
        notificationMessage={notificationMessage}
        setNotificationMessage={setNotificationMessage}
      />
      <Box sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', height: 'inherit'}}>
        <Box sx={{display: 'flex', flexDirection: 'row'}}>
          <Box sx={{display: {xs: 'flex', sm: 'none'}, p: 0, height: '100%', minHeight: '45px'}} component={Button}
               onClick={toggleNav}>
            <Box sx={{width: '65px', justifyContent: 'center', padding: 0}}>
              <img src="/images/icons/navbar/menu.svg" alt={t('dashboard.menu.menu')}/>
            </Box>
          </Box>
          <Box sx={{display: {xs: 'flex', sm: 'none'}}}>
            <HomeButton/>
          </Box>
        </Box>
        <Toolbar sx={{justifyContent: 'flex-end', pb: 1, px: {xs: '20px', md: '55px'}}}>
          <Box mr={4}>
            {isDevelopment() && <LanguageSwitch/>}
          </Box>
          {currentUser && (
            <Box my="auto">
              <AccountMenu currentUser={currentUser}/>
            </Box>
          )}
          <MenuItem
            sx={{
              ml: {xs: '16px', sm: '44px'},
              px: 0,
              display: 'flex',
              alignItems: 'center',
              '&.MuiButtonBase-root:hover': {
                background: 'transparent',
              },
            }}
            className="Navigation"
          >
            <Link to="/notifications">
              <img src={bellIcon} alt={t(translations.notifications.title)} className="Navigation-icon"/>
              <img
                src={redCircleIcon}
                alt={t(translations.notifications.title)}
                style={{
                  display: hasNotifications ? 'block' : 'none',
                  position: 'absolute',
                  top: '6px',
                  left: '-2.5px',
                }}
              />
            </Link>
          </MenuItem>
        </Toolbar>
      </Box>
    </AppBar>
  )
}

export default TopBar
