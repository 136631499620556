import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { updatePoll } from 'services/community/postService'
import { PostRequest } from 'types/requests/PostRequest'
import { translations } from 'locales/i18n'

const useUpdatePoll = () => useMutationWithSnackbar(
  (data: PostRequest) => updatePoll(data),
  'updatePoll',
  translations.messages.pollUpdated,
)

export default useUpdatePoll
