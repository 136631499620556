import React, { FC } from 'react'
import { Box, Button } from '@mui/material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'

import closeMobile from 'images/close.svg'
import theme from 'theme'

type OverlayProps = {
  open: boolean
  onClose: () => void
  onSubmit?: () => Promise<void>
  submitTitle?: string
  title: string
  titleBgColor?: string
  childrenBgColor?: string
  childrenSx?: SxProps<Theme>
  closeIcon?: string
}

const Overlay: FC<OverlayProps> = ({
                                     open,
                                     title,
                                     titleBgColor = theme.palette.background.default,
                                     onClose,
                                     onSubmit,
                                     submitTitle,
                                     children,
                                     childrenBgColor = theme.palette.background.paper,
                                     childrenSx,
                                     closeIcon,
                                   }) => {
  const handleSubmit = async () => {
    if (onSubmit) {
      await onSubmit()
    }
    onClose()
  }

  return (
    <>
      {open && (
        <Box
          sx={{
            bgcolor: childrenBgColor,
          }}
          className="Overlay"
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: titleBgColor,
              height: '58px',
              px: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                position: 'absolute',
                left: '15px',
              }}
              onClick={onClose}
            >
              <img src={closeIcon || closeMobile} alt="Close"/>
            </Box>
            <Box sx={{
              width: '100%',
              textAlign: 'center',
            }}>{title}</Box>
            {submitTitle && (
              <Button
                sx={{
                  position: 'absolute',
                  right: '20px',
                  display: 'flex',
                  height: '30px',
                  py: '11px',
                  bgcolor: '#E25F60',
                  borderRadius: '3px',
                  fontWeight: '500',
                  fontSize: '14px',
                  lineHeight: '17px',
                  cursor: 'pointer',
                  textTransform: 'none',
                  color: 'white',
                }}
                onClick={handleSubmit}
              >
                {submitTitle}
              </Button>
            )}
          </Box>
          <Box sx={childrenSx}>
            {children}
          </Box>
        </Box>
      )}
    </>
  )
}

export default Overlay
