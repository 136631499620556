import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import LoadingIndicator from 'components/shared/loading-indicator'
import Blog from 'components/blog/Blog'
import useBlog from 'hooks/useBlog'

const BlogShow = (
  props: RouteComponentProps<{ blogId?: string }>,
) => {
  const history = useHistory()
  const blogId: string = props.match.params.blogId || ''

  const blogQuery = useBlog(blogId)
  const blog = blogQuery.data

  if (isEmpty(blogId)) {
    history.push('/')
  }

  return (
    <DashboardLayout>
      {blog && <Blog blog={blog}/>}
      {blogQuery.isLoading && <LoadingIndicator/>}
    </DashboardLayout>
  )
}

export default BlogShow
