import React from 'react'
import { Box, Typography } from '@mui/material'

const CommunityHead = () => (
  <Box p={0} sx={
    { position: 'relative',
      backgroundImage: `url(/images/community/welcome.png)`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: {xs: 'auto', lg: 'top left'},
      backgroundSize: {xs: 'cover', lg: '105%'},
      height: {xs: '120px', md: '252px'},
    }
  }>
    <Typography sx={{
      position: 'absolute',
      left: {xs: '20px', md:'55px'},
      bottom: '30px',
      margin: 0,
      fontSize: {xs: '18', md: '36px'},
      fontWeight: 800,
      lineHeight: '1.21'
    }}>
      Scuola Online - Community
    </Typography>
  </Box>
)

export default CommunityHead
