import { Box, styled } from '@mui/material'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import Greeting from 'components/home/Greeting'
import LearningNavigator from 'components/home/LearningNavigator'
import ContinueLearning from 'components/home/ContinueLearning'
import WelcomePopUp from 'components/common/WelcomePopUp'
import useMe from 'hooks/useMe'
import config from 'utils/config'
import AreaLabel from 'components/home/AreaLabel'
import { isWeTest } from 'theme/constants'
import OfficialNational from 'components/home/OfficialNational'
import useHomeData from 'hooks/useHomeData'
import PackSimulations from 'components/home/PackSimulations'

const VideoBackground = styled('video')(() => ({
  display: 'block',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '98%',
  objectFit: 'cover',
}))

const Overlay = styled('div')(() => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  backgroundImage: 'linear-gradient(to bottom, rgba(34,35,46,0.1) 55%, #000000)'
}))
const HomePage = () => {
  const meQuery = useMe()
  const me = meQuery.data
  const homePageData = useHomeData().data
  const courses = homePageData?.enrolledCoursesInProgress.content
  
  return (
    <DashboardLayout>
      <Box p={0}>
        <Box sx={{ position: 'relative', overflow: 'hidden' }}>
          <VideoBackground
            poster={isWeTest() ? '/images/rna-we.png' : '/images/rna.png'}
            muted
            autoPlay
            playsInline
            loop
          >
            <source src={`${config.cdnBaseApiURI}/public/video-background.mp4?t=1`} type="video/mp4"/>
          </VideoBackground>
          <Overlay/>
          {isWeTest() && me && <AreaLabel currentUser={me}/>}
          {me && <Greeting currentUser={me}/>}
        </Box>
        <LearningNavigator hasCourses={me?.subscription.hasCourses} paid={me?.subscription.paid} classes={me?.classes}/>
        <Box
          sx={{ pt: { xs: 4, md: 6 }, px: { xs: '20px', sm: 6.5 } }}
        >
          {courses && <ContinueLearning courses={courses}/>}
        </Box>
        <OfficialNational officialNationalQuizzes={homePageData?.officialNationalQuizzesSummary}></OfficialNational>
        <PackSimulations packSimulations={homePageData?.packSimulations}></PackSimulations>
      </Box>
      <WelcomePopUp/>
    </DashboardLayout>
  )
}

export default HomePage
