import React, { FC } from "react";
import { Box, MenuItem } from "@mui/material";
import deleteIcon from "images/delete.svg";

type PostMenuItemProps = {
  icon: string;
  title: string;
  onClick?: () => void;
};

const CommunityMenuItem: FC<PostMenuItemProps> = ({ icon, title, onClick }) => {
  const handleCheckIcon = (additionStyle: string, currentStyle: string) =>
    icon === deleteIcon ? additionStyle : currentStyle;
  return (
    <MenuItem
      sx={{
        display: "flex",
        alignItems: "center",
      }}
      onClick={onClick}
      className="Navigation"
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "25px",
          textAlign: "center",
          mr: "10px",
        }}
      >
        <img
          src={icon}
          alt={title}
          className={handleCheckIcon(
            "Navigation-deletedIcon",
            "Navigation-icon"
          )}
        />
      </Box>
      <Box
        className={handleCheckIcon(
          "Navigation-text deleted",
          "Navigation-text"
        )}
      >
        {title}
      </Box>
    </MenuItem>
  );
};
export default CommunityMenuItem;
