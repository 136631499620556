import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import { lowerCase } from 'lodash'

import { DATE_TIME_FORMAT } from 'utils/constants'
import { getRoleBackground } from '../../getRoleBackground'
import { PostCommentInterface } from 'types/PostCommentInterface'
import CommunityUserAvatar from 'components/community/CommunityUserAvatar'
import { translations } from 'locales/i18n'

type PostCommentCardHeaderDataProps = {
  comment: PostCommentInterface
}

const PostCommentCardHeaderData: FC<PostCommentCardHeaderDataProps> = ({comment}) => {
  const {t} = useTranslation()

  const {author, createdAt} = comment
  const { mainRole, profileImageUri, deleted } = author
  const fullName = deleted ? t(translations.community.postCard.deletedUser) : `${author.firstName} ${author.lastName}`
  const [firstName, lastName] = fullName.split(' ')

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CommunityUserAvatar
        profileImageUri={deleted ? '' : profileImageUri}
        firstName={firstName}
        lastName={lastName}
        sx={{width: {xs: "35px", sm: "48px"}, height: {xs: "35px", sm: "48px"}}}
      />
      <Box
        sx={{
          ml: '10px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
          }}
        >
          <Box
            sx={{
              fontSize: {xs: '14px', sm: '20px'},
              lineHeight: {xs: '17px', sm: '24px'},
              letterSpacing: {xs: '0.35px', sm: '0.5px'},
              width: {xs: 'min-content', lg: 'fit-content'},
            }}
          >
            {`${firstName} ${lastName}`}
          </Box>
          <Box
            sx={{
              ml: '16px',
              mr: '10px',
              minWidth: {xs: '62px', sm: '72px'},
              height: {xs: '17px', sm: '20px'},
              background: getRoleBackground(mainRole),
              borderRadius: '2px',
              fontSize: {xs: '10px', sm: '12px'},
              lineHeight: {xs: '12px', sm: '15px'},
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {t(translations.common.roles[lowerCase(mainRole)])}
          </Box>
        </Box>
        <Box
          sx={{
            mt: {xs: '5px', sm: '9px'},
            fontSize: {xs: '10px', sm: '14px'},
            lineHeight: {xs: '12px', sm: '17px'},
            letterSpacing: {xs: '0.24', sm: '0.35px'},
            color: '#606060',
          }}
        >
          {dayjs(createdAt || new Date()).format(DATE_TIME_FORMAT)}
        </Box>
      </Box>
    </Box>
  )
}
export default PostCommentCardHeaderData
