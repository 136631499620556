import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {Box, Button, Typography} from "@mui/material";
import {Splide, SplideSlide} from "@splidejs/react-splide";

import {translations} from "locales/i18n";
import {isWeTest} from "theme/constants";

const style = {
  button: {
    color: "#E25F60",
    border: "1px solid #E25F60",
    fontSize: { xs: "14px", lg: "16px" },
    lineHeight: { xs: "14px", md: "19px" },
    padding: {
      xs: "10px 10px 10px 10px",
      md: "10px 20px 10px 20px",
    },
    textTransform: 'none',

    background: (theme) =>
      isWeTest() ? theme.palette.common.accentLight : "transparent",
  },
} as const;

interface LearningNavigatorInterface {
  hasCourses?: boolean;
  classes?: string[];
  paid?: boolean;
}

interface SlideInterface {
  title: string;
  texts: string[];
  buttonText: string;
  link: string;
    isInternalLink: boolean
}

const LearningNavigator: FC<LearningNavigatorInterface> = ({
  hasCourses,
  classes,
  paid,
}) => {
  const { t } = useTranslation();

  const slides = [
    {
      title: "monthlySimulation",
      texts: ["monthlySimulationDescription"],
      buttonText: "startSimulation",
      link: "/quizzes/monthly/new",
      isInternalLink: true,
    } as SlideInterface,
    hasCourses &&
      ({
        title: "yourCourses",
        texts: ["coursesDescription"],
        buttonText: "yourCourses",
        link: "/courses",
        isInternalLink: true,
      } as SlideInterface),
    classes &&
      ({
        title: "yourClasses",
        texts: ["classesDescription"],
        buttonText: "yourClasses",
        link: "/classes",
        isInternalLink: true,
      } as SlideInterface),
    !paid &&
      ({
        title: "subscribeAndLearn",
        texts: [
          "ampleOpportunities",
        ],
        buttonText: "explorePlans",
        link: "https://promedtest.it/",
        isInternalLink: false,
      } as SlideInterface),
    {
      title: "monthlyRanking",
      texts: ["rankingDescription"],
      buttonText: "monthlyRanking",
      link: "/ranking",
      isInternalLink: true,
    } as SlideInterface,
  ];

  const finalSlides = (
    slides.filter((slide) => !!slide) as SlideInterface[]
  ).map((slide) => ({
    ...slide,
  }));

  const isMultiplySlides = finalSlides.length > 3;

  return (
      <Box sx={{ margin: "0 auto", width: {xs: "100%", md: isMultiplySlides ? "84%" : "100%" } }}>
        <Splide
          options={{
            type: finalSlides.length > 2 ? "loop" : "slide",
            perPage: finalSlides.length >= 3 ? 3 : 2,
            focus: "center",
            arrows: isMultiplySlides,
            pagination: isMultiplySlides,
            drag: isMultiplySlides,
            breakpoints: {
              767: {
                perPage: 1,
                arrows: false,
                pagination: true,
                type: "loop",
                drag: true,
              },
            },
          }}
          aria-label="slider"
        >
          {finalSlides.map((slide, index) => (
            <SplideSlide key={index}>
              <Box
                display="flex"
                flexDirection="column"
                sx={{
                  minHeight: { xs: "209px", md: "265px", lg: "234px" },
                  minWidth: { xs: "150px" },
                  height: "auto",
                  padding: { xs: "15px 20px", md: "20px 30px" },
                  zIndex: 100,
                  overflow: "hidden",
                }}
              >
                <Typography
                  sx={{
                    fontSize: { xs: "16px", md: "18px" },
                    lineHeight: { xs: "22px", md: "27px" },
                    fontWeight: "800",
                    width: { lg: "85%" },
                  }}
                >
                  {t(translations.home[slide.title])}
                </Typography>
                {slide.texts.map((text, index) => (
                  <Typography
                    key={index}
                    sx={{
                      fontSize: { xs: "11px", md: "14px" },
                      lineHeight: { xs: "17px", md: "21px" },
                      pt: index === 0 ? "20px" : 0,
                      width: { xs: "80%", md: "100%", lg: "90%" },
                    }}
                  >
                    {t(translations.home[text])}
                  </Typography>
                ))}
                <Box display="flex" flexGrow={1} alignItems="flex-end">
                    {
                        slide.isInternalLink ? (
                            <Link to={slide.link}>
                              <Button className="slideButton" sx={{...style.button}}>
                                {t(translations.home[slide.buttonText]) || slide.buttonText}
                                </Button>
                            </Link>
                        ) : (
                            <a href={slide.link}>
                              <Button className="slideButton" sx={{...style.button}}>
                                {t(translations.home[slide.buttonText]) || slide.buttonText}
                                </Button>
                            </a>
                        )
                    }
                </Box>
              </Box>
            </SplideSlide>
          ))}
        </Splide>
      </Box>
  );
};

export default LearningNavigator;
