import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import useQueryWithSnackbar from 'hooks/useQueryWithSnackbar'
import { getPost } from 'services/community/postService'
import { Posts } from 'types/Posts'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { UserProfileRole } from 'types/UserProfileRole'

const usePost = (
  { classId, postId, pinnedOnly }: GetPostRequest,
  postMode: PostOpenMode,
  mainRole?: UserProfileRole,
  options?: UseQueryOptions<Posts, AxiosError>,
): UseQueryResult<Posts> =>
  useQueryWithSnackbar<Posts>(
    [ReactQueryKeys.POST, classId, postId, pinnedOnly],
    () => getPost({ classId, postId, pinnedOnly }),
    {
      enabled: !!classId && !!postId && (postMode === 'show' || mainRole === UserProfileRole.ADMIN),
      ...options,
    },
  )

export default usePost
