import React, { useCallback, useEffect, useState } from 'react'
import { RouteComponentProps } from 'react-router'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { useQueryClient } from 'react-query'
import * as queryString from 'querystring'
import { Box, Button } from '@mui/material'
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded'
import { debounce, isEmpty, uniqBy } from 'lodash'
import { Message } from '@stomp/stompjs'
import { goToTop } from 'react-scrollable-anchor'
import usePosts from 'hooks/community/usePosts'
import usePostComment from 'hooks/community/usePostComment'
import useRecentPosts from 'hooks/community/useRecentPosts'
import useMe from 'hooks/useMe'
import useCreatePost from 'hooks/community/useCreatePost'
import useUpdatePost from 'hooks/community/useUpdatePost'
import useDeletePost from 'hooks/community/useDeletePost'
import useUpdatePoll from 'hooks/community/useUpdatePoll'
import usePinPost from 'hooks/community/usePinPost'
import useUnpinPost from 'hooks/community/useUnpinPost'
import useAddPostToFavorite from 'hooks/community/useAddPostToFavorite'
import useRemovePostFromFavorite from 'hooks/community/useRemovePostFromFavorite'
import useAddFileToPost from 'hooks/community/useAddFileToPost'
import useCreatePostComment from 'hooks/community/useCreatePostComment'
import useUpdatePostComment from 'hooks/community/useUpdatePostComment'
import useDeletePostComment from 'hooks/community/useDeletePostComment'
import useCreateVotePollOption from 'hooks/community/useCreateVotePollOption'
import usePostReport from 'hooks/community/usePostReport'
import useCommentReport from 'hooks/community/useCommentReport'
import usePost from 'hooks/community/usePost'
import useTeachers from 'hooks/community/useTeachers'
import useListenToClassPosts from 'hooks/community/useListenToClassPosts'
import useListenToClassPostComments from 'hooks/community/useListenToClassPostComments'
import useUpdateVotePollOption from 'hooks/community/useUpdateVotePollOption'
import usePublishPost from 'hooks/community/usePublishPost'
import useSchedulePost from 'hooks/community/useSchedulePost'
import { PostRequest } from 'types/requests/PostRequest'
import { PostInterface } from 'types/PostInterface'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { PostActionType } from 'types/PostActionType'
import { ClassTab } from 'types/ClassTab'
import { translations } from 'locales/i18n'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import CommunityHead from 'components/community/CommunityHead'
import TopicNavigation from 'components/community/TopicNavigation'
import CreatePost from 'components/community/posts/create-post/CreatePost'
import PostsList from 'components/community/posts/PostsList'
import PopularPollsList from 'components/community/popular/PopularPollsList'
import ClassTabs from 'components/community/ClassTabs'
import { DEBOUNCE_TYPING_MILLI } from 'utils/constants'
import { Back } from '../components/shared/back'

const CommunityClassPage = (
  props: RouteComponentProps<{ classId?: string, postId?: string, commentId?: string }>,
) => {
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar()
  const queryClient = useQueryClient()
  const classId: string = props.match.params.classId || ''
  const postId = props.match.params.postId || ''
  const commentId = props.match.params.commentId || ''
  const queryParams = queryString.parse(props.location.search.replace('?', ''))
  const postOpenMode = queryParams.postMode === 'edit' ? 'edit' : 'show'
  const favouritesOnly = queryParams.favouritesOnly === 'true'
  const [page, setPage] = useState<number>(0)
  const [lastPage, setLastPage] = useState<boolean>(false)
  const [fullscreenPostOpen, setFullscreenPostOpen] = useState<boolean>(false)
  const [posts, setPosts] = useState<PostInterface[]>([])
  const [searchQuery, setSearchQuery] = useState('')
  const [postToOpen, setPostToOpen] = useState<PostInterface>({} as PostInterface)
  const [commentToOpen, setOpenComment] = useState<PostCommentReport>({} as PostCommentReport)
  const [classTab, setClassTab] = useState<ClassTab>(ClassTab.ALL_POSTS)
  const [showButton, setShowButton] = useState<boolean>(false)
  const [typingActive, setTypingActive] = useState<boolean>(false)

  const postsQuery = usePosts(classId, classTab, page, searchQuery, favouritesOnly, false, {
    async onSuccess(data) {
      setPosts((prevState) => uniqBy([...prevState, ...data.content], 'postId'))
      setLastPage(!!data.last)
    },
  })
  const pinnedPostsQuery = usePosts(classId, classTab, 0, searchQuery, favouritesOnly, true)
  const recentPostsQuery = useRecentPosts(classId)

  const getPostsKey = (classId: string, classTab: ClassTab, page: number, searchQuery: string, favouritesOnly: boolean, pinned: boolean) =>
    [ReactQueryKeys.POSTS, classId, classTab, page, searchQuery, favouritesOnly, pinned]

  const postsKey = getPostsKey(classId, classTab, page, searchQuery, favouritesOnly, false)
  const pinnedPostsKey = getPostsKey(classId, classTab, 0, searchQuery, favouritesOnly, true)

  const showToTop = () => window.pageYOffset > 500 ? setShowButton(true) : setShowButton(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', showToTop)
    return () => window.removeEventListener('scroll', showToTop) 
  }, []);

  useEffect(() => {
    setPosts([])
    setPage(0)
    queryClient.invalidateQueries([ReactQueryKeys.POSTS, classId])
  }, [favouritesOnly, searchQuery, classTab])

  const teachersQuery = useTeachers(classId)

  const handleNextPage = () => !lastPage && !postsQuery.isLoading && setPage((prevState) => prevState + 1)

  const pinnedPosts = pinnedPostsQuery.data?.content
  const recentPosts = recentPostsQuery.data?.content || []

  const {handleMessage: handlePostMessage} = useListenToClassPosts({
    classId,
    classTab,
    currentPage: page,
    searchQuery,
    favouritesOnly,
    pinned: false,
    typingActive,
    getPostsKey,
    setPosts,
  })

  const {handleMessage: handlePinnedPostMessage} = useListenToClassPosts({
    classId,
    classTab,
    currentPage: 0,
    searchQuery,
    favouritesOnly,
    pinned: true,
    typingActive,
    getPostsKey,
  })

  const {handleMessage: handlePostCommentMessage} = useListenToClassPostComments({classId})

  const setSearchQueryDebounced = useCallback(
    debounce(
      nextValue => setSearchQuery(nextValue),
      DEBOUNCE_TYPING_MILLI,
    ),
    [],
  )

  const meQuery = useMe()
  const me = meQuery.data
  const {mainRole} = me || {}
  const isClassMember = me?.classes.includes(classId) || mainRole === 'ADMIN'
  const createPostMutation = useCreatePost()
  const publishPostMutation = usePublishPost()
  const schedulePostMutation = useSchedulePost()
  const updatePostMutation = useUpdatePost()
  const deletePostMutation = useDeletePost()
  const updatePollMutation = useUpdatePoll()
  const createVotePollOptionMutation = useCreateVotePollOption()
  const updateVotePollOptionMutation = useUpdateVotePollOption()
  const pinPostMutation = usePinPost()
  const unpinPostMutation = useUnpinPost()
  const addPostToFavoriteMutation = useAddPostToFavorite()
  const removePostFromFavoriteMutation = useRemovePostFromFavorite()
  const addFileToPostMutation = useAddFileToPost()
  const createPostCommentMutation = useCreatePostComment()
  const updatePostCommentMutation = useUpdatePostComment()
  const deletePostCommentMutation = useDeletePostComment()
  const reportPostMutation = usePostReport()
  const reportCommentMutation = useCommentReport()

  const postWithActionType = (post: PostInterface) =>
    ({...post, actionType: postOpenMode === 'edit' ? PostActionType.EDIT : PostActionType.FULLSCREEN})

  usePost({classId, postId}, postOpenMode, mainRole, {
    async onSuccess(data) {
      isEmpty(commentId) && setPostToOpen(postWithActionType(data?.content[0]))
    },
  })
  usePost({classId, postId, pinnedOnly: true}, postOpenMode, mainRole, {
    async onSuccess(data) {
      if(!postToOpen.postId && isEmpty(commentId)) {
        setPostToOpen(postWithActionType(data?.content[0]))
        
        if(isEmpty(data?.content) && !isEmpty(postToOpen)) {
          enqueueSnackbar(
            t(translations.messages.errors.POST_NOT_FOUND),
            { variant: 'error' },
          )
        } 
      }
    },
  })

  usePostComment({classId, postId, commentId}, mainRole, {
    async onSuccess(data) {
      if (!isEmpty(data)) {
        const comment = data.find((c) => c.id === commentId)
        comment && setOpenComment({ postId, ...comment })
      }
    },
  })

  const handleActiveTyping = (status: boolean) => setTypingActive(status)

  const handlePostCreate = (data: PostRequest) => createPostMutation.mutateAsync(data)

  const publishOrSchedulePost = (data: PostRequest) => {
    const {classId, id: postId, publishAt} = data
    return publishAt
      ? schedulePostMutation.mutateAsync({classId, postId, publishAt} as SchedulePostRequest, {
        async onSuccess() {
          classTab === ClassTab.SCHEDULED_POSTS && await queryClient.invalidateQueries(postsKey)
        },
      })
      : publishPostMutation.mutateAsync({classId, postId} as PublishPostRequest)
  }

  const handlePostUpdateWithMutation = (mutation, data: PostRequest) => {
    const wasPublishedBefore = !!posts.find(({postId}) => postId === data.id)
    return mutation.mutateAsync(data, {
      async onSuccess() {
        !wasPublishedBefore && await publishOrSchedulePost(data)
        await queryClient.invalidateQueries(postsKey)
        await queryClient.invalidateQueries(pinnedPostsKey)
      },
    })
  }

  const handlePostUpdate = (data: PostRequest) => handlePostUpdateWithMutation(updatePostMutation, data)

  const handlePollUpdate = (data: PostRequest) => handlePostUpdateWithMutation(updatePollMutation, data)

  const handleCreateVotePollOption = (data: VotePollRequest) => createVotePollOptionMutation.mutateAsync(data)

  const handleUpdateVotePollOption = (data: VotePollRequest) => updateVotePollOptionMutation.mutateAsync(data, {
    async onSuccess() {
      await queryClient.invalidateQueries(postsKey)
      await queryClient.invalidateQueries(pinnedPostsKey)
    },
  })

  const withClassId = (data: any) => ({...data, classId})

  const postActionRequest = (post: PostInterface) => ({classId, postId: post.postId} as PostActionRequest)

  const handleDeletePost = (post: PostInterface) => deletePostMutation.mutate(postActionRequest(post))

  const handlePinPost = (post: PostInterface) =>
    pinPostMutation.mutate(postActionRequest(post), {
      async onSuccess() {
        enqueueSnackbar(
          t(translations.messages.postPinned),
          {variant: 'success'},
        )
        await queryClient.invalidateQueries(pinnedPostsKey)
      },
    })

  const handleUnpinPost = (post: PostInterface) => unpinPostMutation.mutate(postActionRequest(post))

  const handleCreatePostComment = (data: PostCommentRequest) => createPostCommentMutation.mutateAsync(data)

  const handleUpdatePostComment = (data: PostCommentRequest) => updatePostCommentMutation.mutateAsync(data)

  const handleDeletePostComment = (data: DeletePostCommentRequest) => deletePostCommentMutation.mutate(withClassId(data))

  const handleAddPostToFavorite = (post: PostInterface) =>
    addPostToFavoriteMutation.mutate(postActionRequest(post), {
        async onSuccess() {
          enqueueSnackbar(
            t(translations.messages.postAddedToFavorites),
            {variant: 'success'},
          )
          setPosts((prevValue) => prevValue.map((p) =>
            post.postId === p.postId ? {...p, favorite: true} : p,
          ))
        },
      },
    )

  const handleRemovePostFromFavorite = (post: PostInterface) =>
    removePostFromFavoriteMutation.mutate(postActionRequest(post), {
        async onSuccess() {
          enqueueSnackbar(
            t(translations.messages.postRemovedFromFavorites),
            {variant: 'success'},
          )
          setPosts((prevValue) => favouritesOnly
            ? prevValue.filter((p) => post.postId !== p.postId)
            : prevValue.map((p) => post.postId === p.postId ? {...p, favorite: false} : p),
          )
          await queryClient.invalidateQueries(pinnedPostsKey)
        },
      },
    )

  const handleAddFileToPost = (data: AddFileToPostRequest) => addFileToPostMutation.mutateAsync(data)

  const handlePostReport = (data: ReportPostRequest) => reportPostMutation.mutateAsync(data)
  const handleCommentReport = (data: ReportCommentRequest) => reportCommentMutation.mutateAsync(data)

  const handleEditPopularPoll = (post: PostInterface) =>
    setPostToOpen({popular: true, actionType: PostActionType.EDIT, ...post})

  const handleReportPopularPoll = (post: PostInterface) =>
    setPostToOpen({popular: true, actionType: PostActionType.REPORT, ...post})

  const handleDeletePopularPoll = (post: PostInterface) =>
    setPostToOpen({popular: true, actionType: PostActionType.DELETE, ...post})

  const handleFullscreenPopularPost = (post: PostInterface) =>
    setPostToOpen({popular: true, actionType: PostActionType.FULLSCREEN, ...post})

  const classSubscription = {
    url: `/topic/classes/${classId}`,
    handleMessage: (message: Message) => {
      handlePostMessage(message)
      handlePinnedPostMessage(message)
      handlePostCommentMessage(message)
    },
  }

  return (
    <DashboardLayout additionalSubscriptions={[classSubscription]}>
      <Back text="COMMUNITIES" url={'/classes'} />
      <CommunityHead/>
      <Box
        sx={{
          display: 'flex',
          flexDirection: {xs: 'column', md: 'row'},
          justifyContent: {xs: 'center', md: 'space-between'},
          padding: {xs: '0px 20px', md: '0px 55px'},
          marginTop: {xs: '20px', md: '40px'},
        }}
      >
        <Box sx={{pt: {xs: 0, lg: '38px'}}}>
          <TopicNavigation
            classId={classId}
            currentUser={me}
            teachers={teachersQuery.data}
            recentPosts={recentPosts}
            setSearchQuery={setSearchQueryDebounced}
            onFullscreenPost={handleFullscreenPopularPost}
            onEditPopularPoll={handleEditPopularPoll}
            onReportPopularPoll={handleReportPopularPoll}
            onDeletePopularPoll={handleDeletePopularPoll}
          />
        </Box>
        {me && (
          <>
            <Box sx={{
              width: '100%',

              pt: {xs: '10px', md: 0, lg: '38px'},
              pl: {md: '40px'},
            }}>
              {isClassMember &&
                <CreatePost
                  classId={classId}
                  currentUser={me}
                  onPostCreate={handlePostCreate}
                  onPostUpdate={handlePostUpdate}
                  onPollUpdate={handlePollUpdate}
                  onAddFileToPost={handleAddFileToPost}
                />
              }
              <ClassTabs
                tabs={[
                  ClassTab.ALL_POSTS,
                  ClassTab.MY_POSTS,
                  ClassTab.TEACHERS_POSTS,
                  ClassTab.SCHEDULED_POSTS,
                  ClassTab.VIDEO,
                  ClassTab.PHOTO,
                  ClassTab.FILE,
                ]}
                currentTab={classTab}
                setTab={setClassTab}
                currentUser={me}
              />
              <PostsList
                classId={classId}
                currentUser={me}
                classTeachers={teachersQuery.data || []}
                isPostsLoading={postsQuery.isLoading || pinnedPostsQuery.isLoading}
                isCommentFormSubmitting={createPostCommentMutation.isLoading || updatePostCommentMutation.isLoading}
                posts={posts}
                pinnedPosts={pinnedPosts}
                postToOpen={postToOpen}
                commentToOpen={commentToOpen}
                fullscreenPostOpen={fullscreenPostOpen}
                onFullscreenPostOpen={setFullscreenPostOpen}
                onPostUpdate={handlePostUpdate}
                onPollUpdate={handlePollUpdate}
                onCreateVotePollOption={handleCreateVotePollOption}
                onUpdateVotePollOption={handleUpdateVotePollOption}
                onPostDelete={handleDeletePost}
                onPostPin={handlePinPost}
                onPostUnpin={handleUnpinPost}
                onAddPostToFavourites={handleAddPostToFavorite}
                onRemovePostFromFavourites={handleRemovePostFromFavorite}
                onReportPost={handlePostReport}
                onReportComment={handleCommentReport}
                onAddFileToPost={handleAddFileToPost}
                onTypingActive={handleActiveTyping}
                onCommentCreate={handleCreatePostComment}
                onCommentUpdate={handleUpdatePostComment}
                onCommentDelete={handleDeletePostComment}
                onNextPage={handleNextPage}
                lastPage={lastPage}
              />

            </Box>

            {!isEmpty(recentPosts) &&
              <Box sx={{ display: { xs: 'none', xl: 'block' }, marginLeft: { xl: '42px' } }}>
                <PopularPollsList
                  currentUser={me}
                  recentPosts={recentPosts}
                  onFullscreenPost={handleFullscreenPopularPost}
                  onEditPopularPoll={handleEditPopularPoll}
                  onReportPopularPoll={handleReportPopularPoll}
                  onDeletePopularPoll={handleDeletePopularPoll}
                  showMenu={isClassMember}
                />
              </Box>
            }
          </>
        )}
        <Button
          sx={{
            display: showButton ? 'flex' : 'none',
            position: 'fixed',
            bottom: {xs: '80px', md: '20px'},
            right: {xs: '20px', md: '50px'},
            minWidth: '50px',
            height: '50px',
            backgroundColor: '#2D2E2F',
            border: '1px solid #363B3F',
            borderRadius: '50%',
            color: '#6F7981',
          }}
          onClick={() => goToTop()}
        >
          <ArrowUpwardRoundedIcon/>
        </Button>
      </Box>
    </DashboardLayout>
  )
}

export default CommunityClassPage
