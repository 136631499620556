import { clientDataService } from '../clientDataService'
import jsonToFormData from 'utils/jsonToFormData'

export const getStreams = (
  classId: string,
  searchQuery: string,
  mineOnly: boolean,
  date: number | null | undefined,
): Promise<ClassStreamsView> =>
  clientDataService<ClassStreamsView>({
    url: `/classes/${classId}/streams
?size=1000
&mineOnly=${mineOnly}
&name=${searchQuery}
${date ? `&date=${date}` : ''}
&sort=date,DESC
`,
  })

export const getStream = (classId: string, streamId: string): Promise<ClassStreamUserView> =>
  clientDataService<ClassStreamUserView>({
    url: `/classes/${classId}/streams/${streamId}`,
  })

export const createStream = (data: CreateStreamRequest): Promise<CreateStreamResponse> =>
  clientDataService<CreateStreamResponse>({
    url: `/classes/${data.classId}/streams`,
    method: 'POST',
    data,
  })

export const addFilesToStream = (data: AddFileToStreamRequest): Promise<UploadFilesResponse> =>
  clientDataService<UploadFilesResponse>({
    url: `/classes/${data.classId}/streams/${data.streamId}/upload-file`,
    method: 'POST',
    data: jsonToFormData(data)
  })

export const updateStream = (data: UpdateStreamRequest): Promise<CreateStreamResponse> =>
  clientDataService<CreateStreamResponse>({
    url: `/classes/${data.classId}/streams/${data.streamId}`,
    method: 'PATCH',
    data: jsonToFormData(data),
  })

export const deleteStream = (data: DeleteStreamRequest): Promise<void> =>
  clientDataService<void>({
    url: `/classes/${data.classId}/streams/${data.streamId}`,
    method: 'DELETE',
  })
