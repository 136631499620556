import React from 'react'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import ProfileLoginInformation from 'components/profile/login-information/ProfileLoginInformation'
import useMe from 'hooks/useMe'
import LoadingIndicator from 'components/shared/loading-indicator'
import useChangePassword from 'hooks/useChangePassword'

const ProfileLoginInformationPage = () => {
  const meQuery = useMe()
  const me = meQuery.data

  const changePasswordMutation = useChangePassword()

  const handleSubmit = (data: ChangePasswordRequest) => changePasswordMutation.mutateAsync(data)

  return (
    <DashboardLayout>
      {me && <ProfileLoginInformation currentUser={me} onSubmit={handleSubmit}/>}
      {meQuery.isLoading && <LoadingIndicator/>}
    </DashboardLayout>
  )
}

export default ProfileLoginInformationPage
