import React from 'react'

import { UpdateNotificationSettingRequest } from 'types/requests/UpdateNotificationSettingRequest'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import ProfileNotificationPreferences from 'components/profile/notification-preferences/ProfileNotificationPreferences'
import useMe from 'hooks/useMe'
import LoadingIndicator from 'components/shared/loading-indicator'
import useNotificationSetting from 'hooks/community/useNotificationSetting'
import useUpdateNotificationSettings from 'hooks/community/useUpdateNotificationSettings'

const ProfileNotificationPreferencesPage = () => {
  const meQuery = useMe()
  const me = meQuery.data

  const notificationSetting = useNotificationSetting().data || []
  const updateNotificationSettingsMutation = useUpdateNotificationSettings()

  const handleSubmit = (data: UpdateNotificationSettingRequest) => updateNotificationSettingsMutation.mutate(data)

  return (
    <DashboardLayout>
      {me && <ProfileNotificationPreferences currentUser={me} notificationSetting={notificationSetting} onSubmit={handleSubmit}/>}
      {meQuery.isLoading && <LoadingIndicator/>}
    </DashboardLayout>
  )
}

export default ProfileNotificationPreferencesPage