import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { goToTop } from 'react-scrollable-anchor'
import { isEmpty } from 'lodash'
import { Box, Grid, Typography } from '@mui/material'

import CoursesSwipable from 'components/courses/CoursesSwipable'
import BasicButton from 'components/shared/basic-button/BasicButton'
import { CourseSearchView } from 'types/CourseSearchView'
import { translations } from 'locales/i18n'
import { EnrolledCourseState } from 'types/EnrolledCourseState'

type ContinueLearningProps = {
  courses: CourseSearchView[]
}

const ContinueLearning: FC<ContinueLearningProps> = ({courses}) => {
  const {t} = useTranslation()

  const inProgressCourses = courses
    .filter(({courseState}) => courseState === EnrolledCourseState.IN_PROGRESS)
    .sort((c1, c2) => (c2.courseLastVisited || 0) - (c1.courseLastVisited || 0))

  return (
    <>
      {!isEmpty(inProgressCourses) && <Box>
        <Typography sx={{
          mt: 3,
          fontSize: {xs: '18px', md: '30px'},
          lineHeight: {xs: '23px', md: '55px'},
          fontWeight: '800',
        }}>{t(translations.courses.continueWhereYouLeft)}</Typography>
        <Grid container direction="row" justifyContent="space-between" alignItems="center"
              className="u-mb-30 u-fs-16 u-lh-24 u-weight-500">
          <Grid item xs={12} md={6} lg={4} className="u-mb-15 u-lh-24">
            <Typography className="u-lh-24" sx={{fontSize: {xs: '12px', md: '16px'}}}>
              {t(translations.courses.continueWhereYouLeftDesc)}
            </Typography>
          </Grid>
          <Grid item xs="auto" md="auto">
            <Link to="/courses/in-progress" onClick={() => goToTop()}>
            <BasicButton
              text={t(translations.common.viewAll)}
              className="u-text-transform-none"
            />
            </Link>
          </Grid>
        </Grid>
        <Box sx={{mb: {xs: '30px'}}}>
          <CoursesSwipable courses={inProgressCourses}/>
        </Box>
      </Box>}
    </>
  )
}

export default ContinueLearning
