import { UseQueryOptions, UseQueryResult } from 'react-query'

import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { AxiosError } from 'axios'
import { getHomeData } from 'services/homePageService'
import { HomeView } from 'types/HomeView'

const useHomeData = (options?: UseQueryOptions<HomeView, AxiosError>): UseQueryResult<HomeView> => {
  return useQueryWithSnackbar<HomeView>(
    [ReactQueryKeys.HOME_DATA],
    () => getHomeData(),
    options,
  )
}

export default useHomeData
