import React, { Dispatch, FC, SetStateAction } from 'react'
import { Link } from 'react-router-dom'
import { Pagination, PaginationItem, useMediaQuery } from '@mui/material'

import theme from 'theme'

type ButtonPaginationProps = {
  page: number
  setPage: Dispatch<SetStateAction<number>>
  totalPages: number
  path: string
}

const RouterPagination: FC<ButtonPaginationProps> = ({
                                                       page,
                                                       setPage,
                                                       totalPages,
                                                       path,
                                                     }) => {
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <Pagination
      page={page}
      count={totalPages}
      siblingCount={isSm ? 2 : 0}
      onChange={(_, value) => setPage(value)}
      shape="rounded"
      renderItem={(item) => (
        <PaginationItem
          component={Link}
          to={`${path}${item.page === 1 ? '' : `?page=${item.page}`}`}
          {...item}
        />
      )}
    />
  )
}

export default RouterPagination
