import React, {FC, useEffect, useState} from 'react'
import {Autocomplete, Box, Popper, styled, TextField} from '@mui/material'
import {Theme} from "@mui/material/styles";
import {SxProps} from "@mui/system";
import {isEmpty} from "lodash";

type OptionsProps = {
    name: string,
    id: string
}

type SimulationFilterProps = {
    isUpdatedLabel?: boolean
    defaultLabel: string
    options: OptionsProps[]
    onChange: (value: string) => void
    sx?: SxProps<Theme>
}

const StyledPopper = styled(Popper)({
    border: '1px solid #303031',
    background: '#000000',

    'ul': {
        background: '#000000',
    },
});

const SimulationFilter: FC<SimulationFilterProps> = ({isUpdatedLabel, defaultLabel, options, onChange, sx}) => {
    const [selected, setSelected] = useState<OptionsProps>({} as OptionsProps)

    useEffect(() => {
        setSelected({} as OptionsProps)
    }, [options]);

    const getOptionLabel = (option: OptionsProps) => isEmpty(selected) && isUpdatedLabel ? '' : option.name;

    const optionItemJSX = ({name, id}, selectedId, props) => (
        <Box
            key={id}
            sx={{
                padding: {xs: '10px 0', md: '10px 20px'},
                background: id === selectedId ? '#3b3c3d !important' : 'transparent',
                '&:hover': {
                    cursor: 'pointer',
                    background: '#3b3c3d',
                },
            }}
            {...props}
        >
            <Box
                sx={{
                    display: 'flex',
                    fontSize: {xs: '14px'},
                    lineHeight: {xs: '16px'},
                    fontWeight: 500,
                    p: '8px',
                }}
            >
                {`${name}`}
            </Box>
        </Box>
    );

    const handleChange = (
        value: OptionsProps,
    ) => {
        if (!isEmpty(value)) {
            setSelected(value);
            onChange(value.id)
        }
    }

    return (
        <Autocomplete
            options={options}
            getOptionLabel={getOptionLabel}
            disableClearable
            PopperComponent={StyledPopper}
            onChange={(e, value) => handleChange(value)}
            renderOption={(props, option) => optionItemJSX(option, selected.id, props)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    variant="standard"
                    inputProps={{style: {minHeight: '45px'}, ...params.inputProps}}
                    InputProps={{
                        ...params.InputProps,
                        sx: {
                            borderBottom: '0.5px solid #303031',
                            '&:after, &:before, &:hover, &:focus, &:hover:not(.Mui-disabled):before': {
                                borderBottom: '0.5px solid #303031'
                            }
                        }
                    }}
                    sx={sx}
                    placeholder={defaultLabel}
                />
            )}
        />
    );
}

export default SimulationFilter
