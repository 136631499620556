import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import useQueryWithSnackbar from 'hooks/useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getStream } from 'services/community/streamService'

const useStream = (
  classId: string,
  streamId: string,
  options?: UseQueryOptions<ClassStreamUserView, AxiosError>,
): UseQueryResult<ClassStreamUserView> =>
  useQueryWithSnackbar<ClassStreamUserView>(
    [ReactQueryKeys.STREAM, classId, streamId],
    () => getStream(classId, streamId),
    {
      enabled: !!classId && !!streamId,
      ...options,
    },
    'getStream',
  )

export default useStream
