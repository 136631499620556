import React, { FC } from 'react'
import { Box, List, ListItem, styled, Typography } from '@mui/material'
import { v1 } from 'uuid'

import CommunityUserAvatar from './CommunityUserAvatar'
import { useHistory } from 'react-router-dom'
import { translations } from 'locales/i18n'
import { useTranslation } from 'react-i18next'
import config from 'utils/config'

type TeacherClassPropsType = {
  userClass: ClassesValueInterface
}
export const TeacherClass: FC<TeacherClassPropsType> = ({
                                                          userClass,
                                                        }) => {
  const {t} = useTranslation()

  const StyledVoiceList = styled(List)(({theme}) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: 0,
    marginRight: '11px',
    marginLeft: '10px',

    '& .MuiListItem-root': {
      alignItems: 'center',
      margin: 0,
      padding: 0,
      marginLeft: '-10px',
      border: `3px solid ${theme.palette.background.default}`,
      borderRadius: '50%',
      overflow: 'hidden',
    },

  }))

  const renderUserAvatar = (firstName: string, lastName: string, profileImageUri: string) => (
    <CommunityUserAvatar
      profileImageUri={profileImageUri}
      firstName={firstName}
      lastName={lastName}
      sx={{width: '100%', height: '100%'}}
    />
  )

  const history = useHistory()

  const handleOpenClass = (classId: string) => history.push(`/classes/${classId}`)

  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.background.paper,
        height: {xs: '280px', sm: '290px', md: '314px'},
        borderRadius: '3px',
      }}
    >
      <Box sx={{width: '100%', height: '135px'}}>
        <img src={userClass.previewImage ? `${config.cdnBaseApiURI}${userClass.previewImage}` : 'images/community/Bitmap2.png'} alt='logo' className='u-h-100 u-w-100'/>
      </Box>
      <Box sx={{m: '20px'}}>
        <Typography
          sx={{
            width: '100%',
            fontWeight: '700',
            fontSize: {xs: '14px', sm: '16px'},
            lineHeight: {xs: '15px', sm: '19px'},
            }}>
            {userClass.name}
        </Typography>
        <Box sx={{display: 'flex', marginTop: '20px'}}>
          <StyledVoiceList>
            {userClass.users.slice(0, 3).map(({
                                                firstName,
                                                lastName,
                                                profileImageUri,
                                              }) =>
              <ListItem key={v1()}
                sx={{
                  width: { xs: '25px', md: '32px' },
                  height: { xs: '25px', md: '32px' },
                  border: '3px solid #1f2021 !important',
                }}>
                {renderUserAvatar(firstName, lastName, profileImageUri)}
              </ListItem>)}
          </StyledVoiceList>
          <Typography
            sx={{
              fontSize: '12px',
              m: '5px'
            }}> {userClass.memberCount} {t(translations.community.members)}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: '20px',
            bgcolor: theme => theme.palette.background.paperLighter ,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: {xs: '29px', sm: '40px'},
            cursor: 'pointer',
          }}
          onClick={() => handleOpenClass(userClass.id)}
        >
          <Typography sx={{fontSize: '14px', lineHeight: '17px'}}>{t(translations.statistics.open)}</Typography>
        </Box>
      </Box>
    </Box>
  )
}
