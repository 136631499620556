import React, { FC } from 'react'
import { Box, List, ListItem, styled, Typography } from '@mui/material'
import { v1 } from 'uuid'
import CommunityUserAvatar from './CommunityUserAvatar'
import { useHistory } from 'react-router-dom'
import { translations } from 'locales/i18n'
import { useTranslation } from 'react-i18next'


type CommunitySelectionPropsType = {
  className: string
  userClass: ClassesValueInterface
  backgroundImage: string
}
export const CommunitySelection: FC<CommunitySelectionPropsType> = ({
                                                                      userClass,
                                                                      backgroundImage,
                                                                      className,
                                                                    }) => {
  const {t} = useTranslation()

  const StyledVoiceList = styled(List)(({theme}) => ({
    display: 'flex',
    flexDirection: 'row-reverse',
    padding: 0,
    marginRight: '11px',

    '& .MuiListItem-root': {
      alignItems: 'center',
      margin: 0,
      padding: 0,
      marginLeft: '-10px',
      border: `3px solid ${theme.palette.background.default}`,
      borderRadius: '50%',
      overflow: 'hidden',
    },

  }))

  const renderUserAvatar = (firstName: string, lastName: string, profileImageUri: string) => (
    <CommunityUserAvatar
      profileImageUri={profileImageUri}
      firstName={firstName}
      lastName={lastName}
      sx={{width: '100%', height: '100%'}}
    />
  )

  const history = useHistory()

  const handleOpenClass = (idClass: string,) => history.push(`/classes/${idClass}`)

  return (
    <Box
      sx={{
        backgroundImage: backgroundImage,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: {xs: '200px', sm: '320px'},
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height:'100%',
          pt: '37px',
          pl: '23px',
        }}>
        <Box>
          <Typography
            sx={{
              height: {xs: '40px', sm: '63px'},
              width: '225px',
              fontWeight: '700',
              fontSize: {xs: '18px', sm: '26px'},
              lineHeight: {xs: '20px', sm: '32px'},

            }}>
            {className}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              marginTop: {xs: '10px', md: '20px'},
              ml: '10px'
            }}>
            <StyledVoiceList>
              {userClass.users.slice(0, 5).map(({
                                                  nickname,
                                                  firstName,
                                                  lastName,
                                                  profileImageUri,
                                                }) => {
                return (
                  <ListItem key={v1()} sx={{width: {xs: '25px', md: '32px'}, height: {xs: '25px', md: '32px'}}}>
                    {renderUserAvatar(firstName, lastName, profileImageUri)}
                  </ListItem>
                )
              })}
            </StyledVoiceList>
            <Typography
              sx={{
                fontSize: '16px',
                color: '#C7C7C7',
                m: '5px'
              }}> {userClass.memberCount} {t(translations.community.members)}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            bgcolor: theme => theme.palette.background.paperLighter,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: {xs: '100px', sm: '135px'},
            height: {xs: '30px', sm: '40px'},
            borderRadius: {sm: '3px'},
            cursor: 'pointer',
            mb: '20px'
          }}
          onClick={() => handleOpenClass(userClass.id)}
        >
          {t(translations.statistics.open)}
        </Box>
      </Box>
    </Box>
  )
}
