import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'

import QuizCard from 'components/quizzes/common/QuizCard'
import { quizCardBackgroundColors } from 'components/quizzes/common/quizCardBackgroundColors'
import { CardType } from 'types/CardType'

type StaticPracticeQuizzesProps = {
  groupItems: StaticPracticeSimulation
  groupId?: string
}

const StaticPracticeQuizzes: FC<StaticPracticeQuizzesProps> = ({ groupItems, groupId }) => {
  const history = useHistory()
  
  return (
    <Box>
      <Box
        sx={{
          fontSize: { xs: '16px', md: '24px' },
          fontWeight: { xs: '800', md: 'normal' },
          lineHeight: { xs: '23px', md: '29px' },
          mt: { xs: '16px', md: '22px' },
          mb: { xs: '15px', md: '25px' },
        }}
      >
        <Box>
          {groupItems.groupName}
        </Box>
      </Box>
      <Grid container spacing={{ xs: 1.5, md: 2 }} sx={{ mb: { xs: '30px', md: '40px' } }}>
        {
          groupItems.quizzes.map(({ id, name }, index) => (
            <Grid item xs={6} md={4} lg={3} key={id}>
              <QuizCard
                questionsCount={50}
                type={CardType.TEST}
                title={<Box>
                  <Typography
                    sx={{
                      fontSize: { xs: '16px', sm: '24px' },
                      lineHeight: { xs: '23px', sm: '29px' },
                      fontWeight: '800',
                    }}>
                    {name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '16px', sm: '24px' },
                      lineHeight: { xs: '23px', sm: '29px' },
                      fontWeight: '800',
                    }}>
                  </Typography>
                </Box>}
                backgroundColor={quizCardBackgroundColors[index % quizCardBackgroundColors.length]}
                onClick={() => history.push(`/quizzes/practice/${groupId}/${id}/new`)}
              />
            </Grid>
          ))
        }
      </Grid>
    </Box>
  )
}

export default StaticPracticeQuizzes
