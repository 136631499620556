import { PostCommentInterface } from 'types/PostCommentInterface'
import { WsMessagePayloadPostCommentAdded } from 'types/ws/WsMessagePayloadPostCommentAdded'

const getUpdatedCommentsOnPostCommentAdded = (postComments: PostCommentInterface[] | undefined, payload: WsMessagePayloadPostCommentAdded) => {
  const {comment, parentCommentId} = payload
  const comments = postComments || []

  return parentCommentId
    ? comments.map((pc) => pc.id === parentCommentId ? {
      ...pc,
      replies: [...pc.replies, comment],
    } : pc)
    : [...comments, comment]
}

export default getUpdatedCommentsOnPostCommentAdded
