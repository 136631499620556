import React, { FC } from "react";
import { Link } from "react-router-dom";
import { Box, Button, Typography } from "@mui/material";
import { PackSimulationsInterface } from "types/PackSimulationsInterface";
import lockIcon from "images/lock.svg";
import { useTranslation } from "react-i18next";
import { translations } from "locales/i18n";

const style = {
  button: {
    backgroundColor: "#4780D8",
    border: "1px #4780D8 solid",
    color: "white",
    padding: "7px",
    marginTop: { xs: "5px", lg: 0 },
    width: { xs: "90%", sm: "150px" },
    fontSize: { xs: "12px", sm: "16px" },
  },
} as const;

type PackSimulationCardProps = {
  packSimulations: PackSimulationsInterface;
  backgroundColors: string;
};

const PackSimulationCard: FC<PackSimulationCardProps> = ({
  packSimulations,
  backgroundColors,
}) => {
  const { t } = useTranslation();
  const quizStep =
    packSimulations?.completedQuizCount === 0
      ? `${t(translations.quizzes.start)}`
      : `${t(translations.courses.continue)}`;

  const renderQuizCount = 
   (
      <>
        <Typography sx={{ fontSize: { xs: "10px", sm: "14px" } }}>
          {packSimulations.locked
            ? packSimulations.quizCount
            : `${packSimulations.completedQuizCount}/${packSimulations.quizCount}`}
        </Typography>
        <Typography
          className="u-opacity-50 u-weight-600"
          sx={{ fontSize: { xs: "10px", sm: "14px" } }}
        >
          {t(translations.dashboard.menu.quizzes)}
        </Typography>
      </>
    );

  return (
    <>
      <Box
        sx={{
          backgroundColor: backgroundColors,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          minHeight: { xs: "140px", sm: "200px", md: "233px" },
          width: "280px",
          padding: { xs: "12px", sm: "21px 25px" },
          borderRadius: "3px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          filter: packSimulations?.locked ? "brightness(60%)" : "",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: { xs: "18px", sm: "24px" },
              lineHeight: { xs: "23px", sm: "29px" },
            }}
            className="u-weight-bold"
          >
            {packSimulations.title}
          </Typography>
        </Box>
        <Box sx={{ display: { xs: "block", sm: "none" } }}>{renderQuizCount}</Box>
      </Box>
      <Box
        sx={{
          width: "100%",
          height: "100px",
          backgroundColor: "rgba(31, 32, 33, 1)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          paddingBottom: "20px",
        }}
      >
        <Box sx={{ height: "100%", display: "flex", alignItems: "center" }}>
          {packSimulations.locked ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "75%",
              }}
              ml={"20px"}
            >
              <Box mr={1}>
                <img src={lockIcon} alt="lock" />
              </Box>
              
            </Box>
          ) : (
            <Box
              sx={{
                width: {xs: "90%", sm: "80%"},
                height: "5px",
                background: "#DCDFE5",
                borderRadius: "4px",
                margin: "0 auto",
              }}
            >
              <Box
                sx={{
                  width: `${
                    (packSimulations.completedQuizCount * 100) /
                    packSimulations.quizCount
                  }%`,
                  height: "5px",
                  background: "#5EA66E",
                }}
              />
            </Box>
          )}
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: { xs: "none", sm: "block" } }}>{renderQuizCount}</Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "auto" },
              textAlign: { xs: "center", sm: "unset" },
            }}
          >
            {packSimulations.locked ? (
              <a href={packSimulations.purchaseLink}>
                <Button sx={{ ...style.button }}>
                  {t(translations.common.purchase)}
                </Button>
              </a>
            ) : (
              <Link to={`/quizzes/pack-simulations/${packSimulations.id}`}>
                <Button sx={{ ...style.button }}>{quizStep}</Button>
              </Link>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PackSimulationCard;
