import { PostCommentInterface } from 'types/PostCommentInterface'

const getUpdatedCommentsOnPostCommentDeleted = (postComments: PostCommentInterface[] | undefined, payload: WsMessagePayloadPostCommentDeleted) => {
  const {commentId, parentCommentId} = payload
  const comments = postComments || []

  return parentCommentId
    ? comments.map((ps) => ps.id === parentCommentId ? {
      ...ps,
      replies: ps.replies.filter(({id}) => id !== commentId),
    } : ps)
    : comments.filter(({id}) => id !== commentId)
}

export default getUpdatedCommentsOnPostCommentDeleted
