import React from 'react'
import { Redirect, Route, RouteProps } from 'react-router-dom'

import useMe from 'hooks/useMe'

export function PrivateRoute(props: RouteProps) {
  const meQuery = useMe()

  if (meQuery.isError) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: {from: props.location},
        }}
      />
    )
  }

  return <Route {...props}/>
}
