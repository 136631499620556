import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { useSnackbar } from 'notistack'

import { resetPassword } from 'services/userService'
import { translations } from 'locales/i18n'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useResetPassword = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  return useMutationWithSnackbar(
    (data: ResetPasswordRequest) => resetPassword(data),
    undefined,
    undefined,
    {
      async onSuccess() {
        enqueueSnackbar(
          t(translations.messages.passwordUpdated),
          { variant: 'success' },
        )
        history.push('/login')
      },
    },
  )
}

export default useResetPassword
