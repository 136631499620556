import React, { ChangeEvent } from "react";
import { translations } from "locales/i18n";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { Box, Typography, IconButton, CircularProgress, List, ListItemAvatar, ListItemText, ListItem } from "@mui/material";

import config from "utils/config";
import deleteIcon from "images/delete-icon.svg";
import { getFileAvatar } from "../posts/getFileAvatar";
import deleteFileIcon from 'images/delete-file.svg'

type StreamVideoUploadProps = {
  name: string;
  uploadingFile: File[];
  currentVideoUri: string;
  files: UploadFilesResponse[];
  currentFiles: UploadFilesResponse[];
  onAddVideo: (e: ChangeEvent<HTMLInputElement>) => void;
  onDeleted: (id: string) => void;
  onCancel: () => void;
};

const StreamVideoUpload = ({
  name,
  currentVideoUri,
  uploadingFile,
  onAddVideo,
  onCancel,
  currentFiles,
  files,
  onDeleted
}: StreamVideoUploadProps) => {
  const { t } = useTranslation();
 
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {isEmpty(uploadingFile) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minWidth: "330px",
            maxWidth: { xs: "100%", sm: "100%" },
            p: "30px 0px",
            mt: "15px",
            border: "3px dashed #353637;",
            borderRadius: "3px",
          }}
        >
          <label
            htmlFor="videoData"
            className="u-cursor-pointer"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/images/icons/community/stream/cloudUpdate.svg"
              alt={`${t(translations.streams.streamPreview)}`}
            />
            <input
              type="file"
              id="videoData"
              name={name}
              hidden={true}
              onChange={onAddVideo}
              multiple
            />
            <Box
              sx={{
                fontSize: { xs: "14px", sm: "16px" },
                lineHeight: { xs: "16px", sm: "19px" },
                textAlign: "center",
                marginTop: "10px",
              }}
            >
              {t(translations.streams.uploadFiles)}
              <Typography
                sx={{
                  fontSize: { xs: "10px", sm: "12px" },
                  lineHeight: { xs: "12px", sm: "14px" },
                  opacity: "40%",
                }}
              >
                {t(translations.community.postForm.dragAndDropFiles)}
              </Typography>
            </Box>
          </label>
        </Box>
      )}
         {(!isEmpty(currentVideoUri) || !isEmpty(uploadingFile)) && (
        <Box
          sx={{
            marginY: "16px",
            minWidth: "330px",
            maxWidth: { xs: "100%", sm: "485px" },
            width: "100%",
            height: "100%",
            borderRadius: "3px",
            position: "relative",
          }}
        >
          {!isEmpty(uploadingFile) && <CircularProgress />}
          {!isEmpty(currentVideoUri) && (
            <>
              <IconButton
                onClick={onCancel}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  zIndex: 1,
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  fontSize: "16px",
                  fontWeight: 400,
                  lineHeight: "19.36px",
                  backgroundColor: "rgba(0, 0, 0, 0.62)",
                  borderRadius: "3px",
                }}
              >
                <img src={deleteIcon} alt="delete video" />
                <Typography sx={{ ml: "8px" }}>Delete</Typography>
              </IconButton>
              <video width="100%" height="100%" style={{ objectFit: "cover" }}>
                <source
                  src={`${config.cdnBaseApiURI}${currentVideoUri}`}
                  type="video/mp4"
                />
              </video>
            </>
          )}
        </Box>
      )}
        <List dense={true}>
    {currentFiles
      .map((item, index) => (
        <div key={item.id}>
        { !item.isDeleted &&
        <ListItem
          sx={{
            borderRadius: '3px',
            mt: index === 0 ? {xs: '6px', sm: '8px'} : {xs: '10px', sm: '12px'},
            py: {xs: '2px', sm: '4px'},
          }}
          secondaryAction={
            <IconButton edge="end" aria-label="Delete file" onClick={() => onDeleted(item.id)}>
              <img
                src={deleteFileIcon}
                alt="Delete file"
                className="u-cursor-pointer"
              />
            </IconButton>
          }
        >
          <ListItemAvatar>
            <IconButton edge="start" aria-label="File type">
              <img src={getFileAvatar(item.extension)} alt="File type"/>
            </IconButton>
          </ListItemAvatar>
          <ListItemText
            primary={item.originalName}
            secondary={`${Math.floor(item.size / 1024)} KB`}
          />
        </ListItem>}
        </div>
      ))
    }
  </List>
    </Box>
  );
};
export default StreamVideoUpload;
