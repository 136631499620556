import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Grid, Typography } from '@mui/material'

import { translations } from 'locales/i18n'
import { QuizType } from 'types/QuizType'

type QuestionsCountWithPlayIconProps = {
  questionsCount?: number
  className?: string
  quizType?: string
  backgroundButton?: string
  textButton: string,
  colorText?: string,
  quizCount?: number
  isHide?: boolean
}
const QuestionsCountWithPlayIcon: FC<QuestionsCountWithPlayIconProps> = ({questionsCount, className, quizType, backgroundButton, textButton, colorText, quizCount, isHide }) => {
  const {t} = useTranslation()
  const isOfficialSimulation = quizType === QuizType.OFFICIAL_NATIONAL_SIMULATION
  
  return (
    <Grid container display="flex" sx={{flexDirection: {xs: "column", lg: 'row'}}} alignItems="flex-start" className={className}>
      {(questionsCount || quizCount) && (
        <Grid item>
          <Typography
            className="u-weight-bold"
            sx={{
              fontSize: { xs: "10px", sm: "15px" },
              lineHeight: { xs: "20px", sm: "28px" },
            }}
          >
            {questionsCount ? questionsCount : quizCount}
          </Typography>
          <Typography
            className="u-weight-600"
            sx={{
              fontSize: { xs: "10px", sm: "14px" },
              lineHeight: { xs: "12px", sm: "17px" },
            }}
          >
            {quizCount
              ? t(translations.quizzes.simulations)
              : t(translations.quizzes.questions)}
          </Typography>
        </Grid>
      )}
      <Grid
        item
        display="flex"
        flexGrow={1}
        justifyContent="flex-end"
        sx={{ width: { xs: "100%", lg: "auto" } }}
      >
        <Button 
          sx={{backgroundColor: backgroundButton,
          border: '1px white solid', 
          color: colorText ? colorText : 'white', 
          padding: '10px',
          marginTop: {xs: '5px', lg: 0 },
          width: isOfficialSimulation ? {xs: '100%', sm: '150px'} : {xs: '100%', lg: '82%', xl: '54%'},
          display: {xs: isHide ? 'none' : 'block', lg: 'block'},
          "&:hover": {
            background: backgroundButton
          },
          }}>
            {textButton}
        </Button>
      </Grid>
    </Grid>
  );
}

export default QuestionsCountWithPlayIcon
