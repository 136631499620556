import { UserProfileRole } from 'types/UserProfileRole'

export const getRoleBackground = (role: UserProfileRole) => {
  switch (role) {
    case UserProfileRole.ADMIN:
      return '#E7AF21'
    case UserProfileRole.STUDENT:
      return '#E25F60'
    case UserProfileRole.TEACHER:
      return '#82A71A'
  }
}
