import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { unpinPost } from 'services/community/postService'
import { translations } from 'locales/i18n'

const useUnpinPost = () => useMutationWithSnackbar(
  (data: PostActionRequest) => unpinPost(data),
  undefined,
  translations.messages.postUnpinned,
)

export default useUnpinPost
