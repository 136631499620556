import { Dispatch, SetStateAction, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { Message } from '@stomp/stompjs'
import { WsMessage } from 'types/ws/WsMessage'
import { WsMessageType } from 'types/ws/WsMessageType'
import { Posts } from 'types/Posts'
import getUpdatedPostsOnCreateVotePoll from 'services/react-query/posts/getUpdatedPostsOnCreateVotePoll'
import getUpdatedPostsOnPostDelete from 'services/react-query/posts/getUpdatedPostsOnPostDelete'
import ReactQueryKeys from 'types/ReactQueryKeys'
import getUpdatedPostsOnPostCommentCountChange from 'services/react-query/posts/getUpdatedPostsOnPostCommentCountChange'
import getUpdatedPostsOnPostAdded from 'services/react-query/posts/getUpdatedPostsOnPostAdded'
import getUpdatedPostsOnPostPinnedChange from 'services/react-query/posts/getUpdatedPostsOnPostPinnedChange'
import getUpdatedPostsOnPostUpdated from 'services/react-query/posts/getUpdatedPostsOnPostUpdated'
import { PostInterface } from 'types/PostInterface'
import getUpdatedPostsOnPostUnpinned from 'services/react-query/posts/getUpdatedPostsOnPostUnpinned'
import { ClassTab } from 'types/ClassTab'

type UseListenToClassResult = {
  handleMessage: (message: Message) => void
}

type UseListenToClassProps = {
  classId: string
  classTab: ClassTab
  currentPage: number
  searchQuery: string
  favouritesOnly: boolean
  pinned: boolean
  typingActive: boolean
  getPostsKey: (classId: string, classTab: ClassTab, page: number, searchQuery: string, favouritesOnly: boolean, pinned: boolean) => any[]
  setPosts?: Dispatch<SetStateAction<PostInterface[]>>
}

const useListenToClassPosts = ({
                                 classId,
                                 classTab,
                                 currentPage,
                                 searchQuery,
                                 favouritesOnly,
                                 pinned,
                                 typingActive,
                                 getPostsKey,
                                 setPosts,
                               }: UseListenToClassProps): UseListenToClassResult => {
  const queryClient = useQueryClient()

  useEffect(() => {
    localStorage.setItem('isTyping', typingActive.toString())
  },[typingActive])

  const isTyping = () => JSON.parse(localStorage.getItem('isTyping') || '')  

  const postsKey = (page: number) => getPostsKey(classId, classTab, page, searchQuery, favouritesOnly, pinned)
  const getPosts = (page: number) => queryClient.getQueryData<Posts>(postsKey(page))

  const invalidateRecentPosts = () => queryClient.invalidateQueries([ReactQueryKeys.RECENT_POSTS, classId])
  const invalidatePost = (postId: string) => queryClient.invalidateQueries([ReactQueryKeys.POST, classId, postId])

  const getUpdatedPosts = (page: number, wsMessage: WsMessage<any>) => {
    const posts = getPosts(page)
    const {type, payload} = wsMessage

    switch (type) {
      case WsMessageType.POST_ADDED:
        invalidateRecentPosts()
        return (!pinned && !isTyping()) ? getUpdatedPostsOnPostAdded(posts, payload, setPosts) : null
      case WsMessageType.POST_DELETED:
        invalidateRecentPosts()
        return getUpdatedPostsOnPostDelete(posts, payload, setPosts)
      case WsMessageType.POST_UPDATED:
        invalidateRecentPosts()
        return getUpdatedPostsOnPostUpdated(posts, payload, setPosts)
      case WsMessageType.POST_PINNED:
        return pinned ? null : getUpdatedPostsOnPostPinnedChange(posts, payload, true, setPosts)
      case WsMessageType.POST_UNPINNED:
        return pinned
          ? getUpdatedPostsOnPostUnpinned(posts, payload, setPosts)
          : getUpdatedPostsOnPostPinnedChange(posts, payload, false, setPosts)
      case WsMessageType.POLL_VOTE_ADDED:
        invalidatePost((payload as WsMessagePayloadPollVoteAdded).postId)
        return getUpdatedPostsOnCreateVotePoll(posts, payload, setPosts)
      case WsMessageType.POST_COMMENT_ADDED:
        return getUpdatedPostsOnPostCommentCountChange(posts, payload, 1, setPosts)
      case WsMessageType.POST_COMMENT_DELETED:
        return getUpdatedPostsOnPostCommentCountChange(posts, payload, -1, setPosts)
      default:
        return null
    }
  }

  const handleMessage = (message: Message) => {
    const messageBody = JSON.parse(message.body)
    const wsMessage = messageBody as WsMessage<any>

    Array.from(Array(currentPage + 1).keys()).forEach((page) => {
      const updatedPosts = getUpdatedPosts(page, wsMessage)
      updatedPosts && queryClient.setQueryData(postsKey(page), updatedPosts)
    })
  }
  return {handleMessage}
}

export default useListenToClassPosts
