import { useHistory } from 'react-router-dom'

import { startStaticPracticeSimulationsQuiz } from 'services/quizService'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useStartStaticPractice = () => {
  const history = useHistory()

  return useMutationWithSnackbar(
    (quizId?: string) => startStaticPracticeSimulationsQuiz(quizId),
    'startStaticPracticeSimulation',
    undefined,
    {
      async onSuccess(quizAttempt) {
        history.push(`/quiz-attempts/${quizAttempt.quizAttemptId}`)
      },
    },
  )
}

export default useStartStaticPractice
