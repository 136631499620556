import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'
import { Box } from '@mui/material'
import { CircularProgress } from '@mui/material'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import { Back } from 'components/shared/back'
import { StreamTitle } from 'components/community/ui/StyledComponent'
import useStream from 'hooks/community/useStream'
import StreamShow from 'components/community/streams/StreamShow'
import useMe from 'hooks/useMe'
import { UserProfileRole } from 'types/UserProfileRole'
import useDeleteStream from 'hooks/community/useDeleteStream'

const StreamShowPage = (props:RouteComponentProps<{ classId?: string, streamId?: string }>) => {

  const {t} = useTranslation()
  const history = useHistory()
  const classId: string = props.match.params.classId || ''
  const streamId: string = props.match.params.streamId || ''

  const streamQuery = useStream(classId, streamId)
  const stream = streamQuery.data
  const meQuery = useMe()
  const me = meQuery.data
  const deleteStreamMutation = useDeleteStream()
  
  const handleEditStream = () => history.push(`/classes/${classId}/streams/${streamId}/edit`)
  const handleDeleteStream = (data: DeleteStreamRequest) => deleteStreamMutation.mutate(data)

  return (
    <DashboardLayout>
      <Back
        text={t(translations.navigation.eventGallery)}
        url={`/classes/${classId}/streams`}
        sx={{mx: {xs: '20px', md: 6.5}}}
      />
      <Box
        sx={{
          p: {xs: 0, md: '0px 55px'},
          mt: {xs: '40px', md: '66px'},
          mb: {xs: '20px', md: '66px'},
        }}
      >
        <Box sx={{display: {xs: 'none', md: 'block  '}}}>
          <StreamTitle>{t(translations.community.feed.pastStreams)}</StreamTitle>
        </Box>
        { stream && <StreamShow
          classId={classId}
          stream={stream}
          showMenu={me?.mainRole !== UserProfileRole.STUDENT}
          onEditStream={handleEditStream}
          onDelete={() => handleDeleteStream({classId, streamId} as DeleteStreamRequest)}
        />}
         {streamQuery.isLoading && <CircularProgress/>}
      </Box>
    </DashboardLayout>
  )
}

export default StreamShowPage
