import React, { FC } from 'react'
import { isEmpty } from 'lodash'
import MathJax from 'react-mathjax-preview'
import { parse } from 'node-html-parser'
import { Box } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'

type ContentWithMathProps = {
  content: string,
  contentSx?: SxProps<Theme>
}

const ContentWithMath: FC<ContentWithMathProps> = ({content, contentSx}) => {
  const contentRoot = parse(content)
  const contentImage = contentRoot.getElementsByTagName('img')

  return (
    <Box>
      {contentRoot.childNodes.map((child, index) => (
        <Box sx={{...contentSx}} key={index}>
          <MathJax math={child.toString()}/>
        </Box>
      ))}
      {!isEmpty(contentImage) && contentImage.map(({attrs}, index) => (
        <Box key={index}>
          <img width="300px" height="auto" src={`${attrs.src}`} alt="content"/>
        </Box>
      ))}
    </Box>
  )
}

export default ContentWithMath
