import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getChartStatistics } from 'services/statisticsService'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { UUID } from 'domain/types'

const useChartStatistics = (sectionId: UUID, periodStart: number, options?: UseQueryOptions<ChartStatistics, AxiosError>): UseQueryResult<ChartStatistics> => {
  return useQueryWithSnackbar<ChartStatistics>(
    [ReactQueryKeys.CHART_STATISTICS, sectionId, periodStart],
    () => getChartStatistics(sectionId, periodStart),
    {
      ...options,
    })
}

export default useChartStatistics
