import React, { Dispatch, FC, SetStateAction } from 'react'
import { Box } from '@mui/material'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'

import Notification from 'components/community/notifications/Notification'
import close from 'images/delete-file.svg'
import { WsMessage } from 'types/ws/WsMessage'
import { WsMessageType } from 'types/ws/WsMessageType'
import { NotificationInterface } from 'types/NotificationInterface'
import { NotificationType } from 'types/NotificationType'
import { StudentProfileInterface } from 'types/StudentProfileInterface'

type NotificationPopupProps = {
  currentUser?: StudentProfileInterface,
  notificationMessage: WsMessage<any> | undefined
  setNotificationMessage: Dispatch<SetStateAction<WsMessage<any> | undefined>>
}

const NotificationPopup: FC<NotificationPopupProps> = ({
                                                         currentUser,
                                                         notificationMessage,
                                                         setNotificationMessage,
                                                       }) => {
  const {type, payload, id} = notificationMessage || {}

  const renderNotification = () => {
    switch (type) {
      case WsMessageType.USER_MENTIONED_IN_COMMENT:
      case WsMessageType.COMMENT_ADDED_TO_YOUR_POST:
        const {
          classId,
          postId,
          commentId,
          commentOwnerNickname,
          commentOwnerProfileImage,
          commentText,
        } = payload as WsMessagePayloadCommentNotification
        const ownComment = currentUser?.profile?.nickname === commentOwnerNickname
        return !ownComment && (
          <Notification
            notification={{
              notificationId: id,
              type: type === WsMessageType.USER_MENTIONED_IN_COMMENT ? NotificationType.MENTIONED_IN_POST_COMMENT : NotificationType.POST_COMMENT_ADDED,
              message: commentText,
              read: false,
              createdAt: dayjs().unix() * 1000,
              commentOwnerNickname,
              commentOwnerProfileImage,
              classId,
              postId,
              postCommentId: commentId,
            } as NotificationInterface}
          />
        )
      case WsMessageType.MONTHLY_SIMULATION_UPDATED:
        return (
          <Notification
          notification={{
            notificationId: id,
            type: NotificationType.MONTHLY_SIMULATION_UPDATED,
            read: false,
            createdAt: dayjs().unix() * 1000,
          } as NotificationInterface}
        />
        )

    }
  }

  const renderedNotification = renderNotification()

  return (
    <Box
      sx={{
        position: "fixed",
        right: '20px',
        top: '70px',
        width: {xs: 'calc(100% - 40px)', sm: '335px'},
        backgroundColor: theme => theme.palette.background.paper,
        px: '20px',
        py: '20px',
        zIndex: 1300,
        borderRadius: '3px',
        display: !isEmpty(renderedNotification) ? 'block' : 'none',
        opacity: !isEmpty(renderedNotification) ? '100%' : 0,
        transition: 'all 0.3s ease',
        border: '1px solid rgba(255, 255, 255, .1)',
      }}
    >
      {renderNotification()}
      <Box
        sx={{
          position: 'absolute',
          top: '20px',
          right: '20px',
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
        onClick={() => setNotificationMessage(undefined)}
      >
        <img
          src={close}
          alt="Cancel"
          role="presentation"
        />
      </Box>
    </Box>
  )
}

export default NotificationPopup
