import { styled, Typography } from "@mui/material";

export const CommunityTitle = styled(Typography)(() => (
    ({theme}) => ({
        opacity: '0.5',
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '1.21',
        letterSpacing: '0.5px',
        margin: 0
  })));
    
export const StreamTitle = styled(Typography)(() => (
    ({theme}) => ({
        fontWeight: 700,
        fontSize: '20px',
        lineHeight: '24px',
        letterSpacing: '0.5px',
        margin: 0
  })));

export const JoinButton = styled('button')(({theme}) => ({
        padding: '15px 11px',
        textTransform: "none",
        lineHeight: '1.21',
        fontWeight: 500,
        background: theme.palette.primary.main,
        color: theme.palette.text.primary,
        border: 'none',
        borderRadius: '3px'
      }));

export const CommunityText = styled('p')(() => (
        ({theme}) => ({
            fontWeight: 500,
            fontSize: '16px',
            lineHeight: '19.36px',
            letterSpacing: '0.5px',
            margin: 0
      })));

export const CommunityDate = styled('p')(() => (
        ({theme}) => ({
            color: '#606060',
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '16.94px',
            letterSpacing: '0.5px',
            margin: 0
      })));
