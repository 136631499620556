import { Dispatch, SetStateAction } from 'react'
import { orderBy } from 'lodash'

import { Posts } from 'types/Posts'
import { PostInterface } from 'types/PostInterface'

const getUpdatedPostsOnPostPinnedChange = (
  posts: Posts | undefined,
  payload: PostInterface,
  newPinnedValue: boolean,
  setPosts?: Dispatch<SetStateAction<PostInterface[]>>,
) => {
  const updatePost = (post: PostInterface) => post.postId === payload.postId
    ? {
      ...post,
      pinned: newPinnedValue,
    }
    : post

  const foundPost = posts?.content?.find(({postId}) => postId === payload.postId)

  const getUpdatedValue = (prevValue?: PostInterface[]) => {
    const oldValue = (prevValue || [])
    if (foundPost) {
      return oldValue.map(updatePost)
    }
    const timestamp = (post: PostInterface) => post.scheduledAt ? post.scheduledAt : post.publishedAt
    if (oldValue.length === 0 || oldValue.length > 0 && timestamp(oldValue[oldValue.length - 1]) < timestamp(payload)) {
      return orderBy([payload, ...(prevValue || [])], 'publishedAt', 'desc')
    }
    return oldValue
  }

  if (setPosts) {
    setPosts(getUpdatedValue)
  }

  return {
    ...posts,
    content: getUpdatedValue(posts?.content),
  }
}

export default getUpdatedPostsOnPostPinnedChange
