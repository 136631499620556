import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'

import { updateQuizAttemptQuestionAnswer } from 'services/quizAttemtService'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useCreateQuizAttemptAnswer = (): UseMutationResult<void, AxiosError, QuizAttemptAnswerCreateRequest> => useMutationWithSnackbar(
    (data: QuizAttemptAnswerCreateRequest) => updateQuizAttemptQuestionAnswer(data),
    'createQuizAttemptQuestionAnswer',
    undefined,
    {},
  )

export default useCreateQuizAttemptAnswer
