import React, { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { translations } from 'locales/i18n'
import NoteEditorForm from './NoteEditorForm'
import pencil from 'images/pencil.svg'
import { Back } from '../shared/back'
import { LessonView } from 'types/LessonView'

type LessonNotesProps = {
  lesson: LessonView
  onNoteTextReset: () => void
  onNoteTextSubmit: (noteText: string) => void
  onInvalidateLesson: () => void
}

const LessonNotes: FC<LessonNotesProps> = ({lesson, onNoteTextReset, onNoteTextSubmit, onInvalidateLesson}) => {
  const {t} = useTranslation()

  const {noteText, name: lessonName, noteTextUpdatedAt} = lesson

  const [editMode, setEditMode] = useState(false)
  const toggleEditMode = async () => {
    setEditMode(!editMode)
    onInvalidateLesson()
  }

  return (
    <Box>
      <Box
        sx={{
          pt: '70px',
          px: {xs: '20px', md: '25px'},
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{maxWidth: '80%'}}>
          <Back text={t(translations.navigation.coursesPage)} url={'/courses'} sx={{mx: {xs: 0, md: '-15px'}, top: '60px'}}/>
          <Box
            sx={{
              color: '#E25F60',
              fontWeight: 600,
              letterSpacing: '2px',
              opacity: 0.9,
              fontSize: {xs: '10px', md: '15px'},
              lineHeight: {xs: '14px', md: '18px'},
            }}
          >
            {t(translations.lessonPage.lesson)}
          </Box>
          <Box
            sx={{
              pt: '6px',
              fontWeight: 'bold',
              fontSize: '30px',
              lineHeight: '38px',
              wordBreak: 'break-all'
            }}
          >
            {lessonName}
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {editMode && (
            <Box
              sx={{
                p: {xs: '8px 10px', md: '8px 20px'},
                border: '1px solid #FFFFFF',
                borderRadius: '3px',
                cursor: 'pointer',
                mr: "20px"
              }}
              onClick={onNoteTextReset}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span className="u-fs-18 u-lh-22"
                      style={{letterSpacing: '0.5px'}}>{t(translations.lessonPage.note.resetButton)}</span>
              </Box>
            </Box>
          )}
          <Box
            sx={{
              p: {xs: '8px 10px', md: '8px 20px'},
              border: '1px solid #FFFFFF',
              borderRadius: '3px',
              cursor: 'pointer',
            }}
            onClick={toggleEditMode}
          >
            {editMode && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <span className="u-fs-18 u-lh-22"
                      style={{letterSpacing: '0.5px'}}>{t(translations.lessonPage.backToReading)}</span>
              </Box>
            )}
            {!editMode && (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <img
                  src={pencil}
                  className="u-pr-10"
                  alt={t(translations.common.edit)}
                  role="presentation"
                />
                <Typography
                  sx={{
                    fontSize: {xs: '12px', md: '18px'},
                    lineHeight: {xs: '14px', md: '22px'},
                    letterSpacing: '0.5px',
                  }}
                >
                  {t(translations.common.edit)}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      {editMode && (
        <Box sx={{px: {xs: '10px', md: '15px'}}}>
          <NoteEditorForm noteText={noteText}
                          noteTextUpdatedAt={noteTextUpdatedAt}
                          onSubmit={onNoteTextSubmit}
                          height={500}/>
        </Box>
      )}
      {!editMode && noteText && (
        <Box
          sx={{
            px: {xs: '20px', md: '25px'},
            maxHeight: '400px',
            overflowY: 'auto',
          }}
          className="LessonTextContent"
          dangerouslySetInnerHTML={{__html: noteText}}
        />
      )}
    </Box>
  )
}

export default LessonNotes
