import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { OfficialNationalSimulationCategory } from 'types/OfficialNationalSimulationCategory'
import { getOfficialNationalSimulationCategories } from 'services/quizService'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useOfficialNationalSimulationCategories = (options?: UseQueryOptions<OfficialNationalSimulationCategory[], AxiosError>): UseQueryResult<OfficialNationalSimulationCategory[]> => {
  return useQueryWithSnackbar<OfficialNationalSimulationCategory[]>(
    [ReactQueryKeys.ARCHIVE_SIMULATION_CATEGORIES],
    () => getOfficialNationalSimulationCategories(),
    options,
  )
}

export default useOfficialNationalSimulationCategories
