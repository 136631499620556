import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/i18n'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { PLATFORM_NAME } from 'theme/constants'

const style = {
  title: {
    fontSize: { xs: '36px', lg: '56px' },
    fontWeight: '800',
    lineHeight: { xs: '36px', lg: '68px' },
    paddingBottom: '10px',
  },
  subtitle: {
    fontSize: { xs: '12px', lg: '16px' },
    fontWeight: '500',
    lineHeight: { xs: '14px', lg: '19px' },
  },

} as const

type WelcomeProps = {
  currentUser: StudentProfileInterface
}

const Welcome: FC<WelcomeProps> = ({ currentUser }) => {
  const { t } = useTranslation()

  const { profile } = currentUser
  const { firstName, lastName } = profile

  return (
    <Box sx={{ height: '100%' }}>
      <Box sx={{ ...style.title }}>
        {t(translations.home.welcome)}, <br/>
        <Typography sx={{ ...style.title }}>{`${firstName} ${lastName}`}</Typography>
      </Box>
      <Box sx={{ ...style.subtitle }}>
        {t(translations.home.welcomeWhere, { platform: PLATFORM_NAME })}
      </Box>
    </Box>
  )
}

export default Welcome
