export const quizCardBackgroundColors = [
  '#E25F60',
  '#F056C5',
  '#E9862C',
  '#4C3F7C',
  '#E7A500',
  '#37679D',
  '#5596F6',
  '#B960FF'
]
