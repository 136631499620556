import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'

import Heading from './Heading'
import QuizCard from 'components/quizzes/common/QuizCard'
import { translations } from 'locales/i18n'
import { quizCardBackgroundColors } from 'components/quizzes/common/quizCardBackgroundColors'
import { CardType } from 'types/CardType'
import { isEmpty } from 'lodash'

type SubjectsProps = {
  onSubjectClick: (subjectId: string) => void
  subjects?: Subject[]
}

const Subjects: FC<SubjectsProps> = ({ onSubjectClick, subjects }) => {
  const { t } = useTranslation()

  return (
  <>
   {subjects && !isEmpty(subjects) && <Box sx={{ pb: { xs: '30px', md: '40px' } }}>
      <Heading content={t(translations.quizzes.subjectTests)}/>
      <Grid container spacing={{ xs: 1.5, md: 2 }}>
        {
          subjects.map(({ id, name }, index) => (
            <Grid item xs={6} md={4} lg={3} key={id} className="u-w-100">
              <Box>
                <QuizCard
                  type={CardType.SUBJECT}
                  isHide
                  questionsCount={10}
                  title={name}
                  backgroundColor={quizCardBackgroundColors[index % quizCardBackgroundColors.length]}
                  onClick={() => onSubjectClick(id)}
                />
              </Box>
            </Grid>
          ))
        }
      </Grid>
    </Box>}
  </>
  )
}

export default Subjects
