import React from 'react'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { Box } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'

import { Back } from 'components/shared/back'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import QuizCompletionConfirmation from 'components/quizzes/QuizCompletionConfirmation'
import useQuizAttemptStatistics from 'hooks/useQuizAttemptStatistics'
import useCompleteQuizAttempt from 'hooks/useCompleteQuizAttempt'
import LoadingIndicator from 'components/shared/loading-indicator'
import { QuizAttemptStatus } from 'types/QuizAttemptStatus'
import useQuizAttempt from 'hooks/useQuizAttempt'


const QuizCompletionConfirmationPage = (
  props: RouteComponentProps<{ quizAttemptId?: string }>,
) => {
  const history = useHistory()
  const {t} = useTranslation()
  const quizAttemptId: string = props.match.params.quizAttemptId || ''
  const quizAttemptStatisticsQuery = useQuizAttemptStatistics(quizAttemptId)
  const quizAttemptStatistics = quizAttemptStatisticsQuery.data
  const quizAttemptDetails = quizAttemptStatisticsQuery.data?.quizAttemptDetails
  const quizAttemptQuery = useQuizAttempt(quizAttemptId)
  const quizAttempt = quizAttemptQuery.data
  const completeQuizAttemptMutation = useCompleteQuizAttempt()
  const groupName = `${quizAttemptDetails?.groupName}: ${quizAttemptDetails?.quizName}`
  const handleCompleteQuizAttempt = () => completeQuizAttemptMutation.mutate(quizAttemptId)

  if (quizAttemptStatistics && quizAttemptStatistics.status === QuizAttemptStatus.COMPLETED) {
    history.push(`/quiz-attempts/${quizAttemptStatistics.id}/results`)
  }
  
  const getLinkBack = () => {
    if (quizAttemptStatisticsQuery.data?.quizType === 'LESSON') {
      return {
        text: t(translations.navigation.lessonPage),
        url: `/courses/${quizAttemptDetails?.courseId}/lessons/${quizAttemptDetails?.lessonId}`
      }
    }
      return {
         text: t(translations.navigation.quizzesPage),
         url: '/quizzes'
      }
 }
  const dataButtonBack = getLinkBack()

  return (
    <DashboardLayout>
      {!quizAttemptStatisticsQuery.isLoading &&
        <Back text={dataButtonBack.text} url={dataButtonBack.url}/>
      }
      {quizAttemptStatistics && quizAttempt && <Box m={6}>
        <QuizCompletionConfirmation
          isDividedBySections={quizAttempt.quizRule?.dividedBySections}
          quizAttempt={quizAttempt}
          groupName={groupName}
          quizAttemptStatistics={quizAttemptStatistics}
          isLoading={completeQuizAttemptMutation.isLoading}
          onCompleteQuizAttempt={handleCompleteQuizAttempt}
        />
      </Box>}
      {quizAttemptStatisticsQuery.isLoading && <LoadingIndicator/>}
    </DashboardLayout>
  )
}
export default QuizCompletionConfirmationPage
