import React from 'react'
import { useTranslation } from 'react-i18next'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import LoadingIndicator from 'components/shared/loading-indicator'
import { Back } from 'components/shared/back'
import Notifications from 'components/community/notifications/Notifications'
import useNotifications from 'hooks/community/useNotifications'
import useMarkNotificationRead from 'hooks/community/useMarkNotificationRead'
import useDeleteNotification from 'hooks/community/useDeleteNotification'
import useMarkAllNotificationsRead from 'hooks/community/useMarkAllNotificationsRead'
import { translations } from 'locales/i18n'
import { isWeTest } from 'theme/constants'


const NotificationsPage = () => {
  const {t} = useTranslation()
  const notificationsQuery = useNotifications()
  const notifications = notificationsQuery.data

  const markAllAsReadMutation = useMarkAllNotificationsRead()
  const markAsReadMutation = useMarkNotificationRead()
  const deleteNotificationMutation = useDeleteNotification()

  const handleMarkAllAsRead = () => markAllAsReadMutation.mutate()
  const handleMarkAsRead = (notificationId: string) => markAsReadMutation.mutate(notificationId)
  const handleDeleteNotification = (notificationId: string) => deleteNotificationMutation.mutate(notificationId)

  return (
    <DashboardLayout>
      {!isWeTest() && <Back text={t(translations.dashboard.menu.classes)} url={'/classes'}/>}
      {notifications && (
        <Notifications
          notifications={notifications}
          onMarkAllAsRead={handleMarkAllAsRead}
          onMarkAsRead={handleMarkAsRead}
          onDeleteNotification={handleDeleteNotification}
        />
      )}
      {notificationsQuery.isLoading && <LoadingIndicator/>}
    </DashboardLayout>
  )
}

export default NotificationsPage
