import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box } from '@mui/material'

import close from 'images/close-menu.svg'
import { translations } from 'locales/i18n'

type FixedBottomModalProps = {
  open: boolean
  onClose: () => void
}

const FixedBottomModal: FC<FixedBottomModalProps> = ({
                                                       open,
                                                       onClose,
                                                       children,
                                                     }) => {
  const {t} = useTranslation()

  return (
    <Box sx={{
      display: open ? 'block' : 'none',
      position: "fixed",
      left: 0,
      bottom: 0,
      width: '100%',
      backgroundColor: theme => theme.palette.background.paper,
      boxShadow: '3px 4px 12px 9px rgba(0, 0, 0, 0.0339543)',
      px: '20px',
      pt: '20px',
      pb: '50px',
      zIndex: 1300,
    }}
    >
      {children}
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: '30px',
          cursor: 'pointer',
        }}
        onClick={onClose}
      >
        <img
          src={close}
          className="u-pr-15"
          alt="Cancel"
          role="presentation"
        />
        <span className="u-fs-14 u-lh-17">{t(translations.common.close)}</span>
      </Box>
    </Box>
  )
}

export default FixedBottomModal
