import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getStaticPracticeSimulationGroupInfo } from 'services/quizService'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useStaticPracticeSimulationGroupInfo = (quizId?: string, options?: UseQueryOptions<StaticPracticeSimulationGroupInfoInterface, AxiosError>): UseQueryResult<StaticPracticeSimulationGroupInfoInterface> => {
  return useQueryWithSnackbar<StaticPracticeSimulationGroupInfoInterface>(
    [ReactQueryKeys.STATIC_PRACTICE_GROUP_INFO],
    () => getStaticPracticeSimulationGroupInfo(quizId),
    {
      enabled: !!quizId,
      ...options,
    },
  )
}

export default useStaticPracticeSimulationGroupInfo
