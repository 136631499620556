import { translations } from 'locales/i18n'
import { reportPost } from 'services/community/postService'
import useMutationWithSnackbar from 'hooks/useMutationWithSnackbar'

const usePostReport = () => useMutationWithSnackbar(
  (data: ReportPostRequest) => reportPost(data),
  undefined,
  translations.lessonPage.reports.sendReport,
)

export default usePostReport
