import React from 'react'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import useCourses from 'hooks/useCourses'
import CoursesInProgress from 'components/courses/CoursesInProgress'
import LoadingIndicator from 'components/shared/loading-indicator'
import { Back } from 'components/shared/back'

const CoursesInProgressPage = () => {
  const coursesQuery = useCourses()
  const courses = coursesQuery.data?.content

  return (
    <DashboardLayout>
      <Back text="HOME" url={'/'}/>
      {courses && <CoursesInProgress courses={courses}/>}
      {coursesQuery.isLoading && <LoadingIndicator/>}
    </DashboardLayout>
  )
}

export default CoursesInProgressPage
