import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { getBlogs } from 'services/blogService'

const useBlogs = (
  page: number,
  size: number,
  options?: UseQueryOptions<BlogsView, AxiosError>,
): UseQueryResult<BlogsView> =>
  useQueryWithSnackbar<BlogsView>(
    [ReactQueryKeys.BLOGS, page, size],
    () => getBlogs(page, size),
    options,
  )

export default useBlogs
