import { createTheme, darkScrollbar, responsiveFontSizes } from '@mui/material'
import { darken, lighten } from '@material-ui/core'

import { inputLabelColor, white } from './constants'

const theme = createTheme({
  typography: {
    allVariants: {
      color: white,
      fontFamily: 'Inter',
    },
  },
  palette: {
    mode: 'dark',
    common: {
      accent: '#E25F60',
      accentLight: '#F17576',
      accentLearning: '#E86393',
      navigationSelected: '#E25F60',
      cardGradient: 'linear-gradient(180deg, #37679D 0%, #E25F60 97.49%)',
      videoGradient: 'linear-gradient(180deg, rgba(0, 0, 0, 0) {start}%, #000000 {finish}%)',
      accentCard: 'linear-gradient(180deg, #37679D 0%, #E86393 97.49%)',
      flag: '#4780D8',
    },
    action: {
      active: white,
      hover: 'rgba(255, 255, 255, 0.08)',
      selected: 'rgba(255, 255, 255, 0.16)',
      disabled: 'rgba(255, 255, 255, 0.3)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
    },
    background: {
      default: '#000000',
      paper: '#1F2021',
      paperLighter: '#2D2E2F',
      paperLightest: '#3D3E3F',
    },
    primary: {
      main: '#4780D8',
    },
    secondary: {
      main: 'rgba(255, 255, 255, 0.1)',
      light: 'rgba(255, 255, 255, 0.3)',
      dark: 'rgba(255, 255, 255, 0.03)',
    },
    text: {
      primary: white,
      secondary: inputLabelColor,
      disabled: 'rgba(0, 0, 0, 0.38)',
    },
  },

  components: {
    MuiAppBar: {
      defaultProps: {
        enableColorOnDark: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: darkScrollbar({
          track: lighten('#1F2021', 0.25),
          thumb: lighten('#1F2021', 0.25),
          active: lighten('#1F2021', 0.25),
        }),
      },
    },
  },
})

export default responsiveFontSizes(theme)
