import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Box, Grid, Typography } from '@mui/material'
import { minBy, maxBy } from 'lodash'

import QuizCard from 'components/quizzes/common/QuizCard'
import { translations } from 'locales/i18n'
import { quizCardBackgroundColors } from 'components/quizzes/common/quizCardBackgroundColors'
import { OfficialNationalSimulationCategoryItem } from 'types/OfficialNationalSimulationCategoryItem'
import { Specialty } from 'types/Specialty'
import PreviousYears from './PreviousYears'
import { OfficialNationalSimulationCategory } from 'types/OfficialNationalSimulationCategory'
import { CardType } from 'types/CardType'

type PreviousYearQuizzesProps = {
  specialty: Specialty
  categoryItems: OfficialNationalSimulationCategoryItem[]
  archiveSimulationCategoriesQuery?: OfficialNationalSimulationCategory[]
}

const PreviousYearQuizzes: FC<PreviousYearQuizzesProps> = ({ categoryItems, specialty, archiveSimulationCategoriesQuery }) => {
  const { t } = useTranslation()
  const history = useHistory()

  const getTitle = () => {
    switch (specialty) {
      case Specialty.HEALTHCARE:
        return t(translations.quizzes.healthcareTests)
      case Specialty.VETERINARY:
        return t(translations.quizzes.veterinaryTests)
      default:
        return t(translations.quizzes.medicalTests)
    }
  }

  const minCategoryYear = minBy(categoryItems, (ci) => ci.year)?.year
  const maxCategoryYear = maxBy(categoryItems, (ci) => ci.year)?.year
  const questionCount = 60

  return (
    <Box>
      <Box
        sx={{
          fontSize: { xs: '16px', md: '24px' },
          fontWeight: { xs: '800', md: 'normal' },
          lineHeight: { xs: '23px', md: '29px' },
          mt: { xs: '16px', md: '22px' },
          mb: { xs: '15px', md: '25px' },
        }}
      >
        <Box>
          {getTitle()}
        </Box>
        <Box>
          {`${minCategoryYear} - ${maxCategoryYear}`}
        </Box>
      </Box>
      <Grid container spacing={{ xs: 1.5, md: 2 }} sx={{ mb: { xs: '30px', md: '40px' } }}>
        {
          categoryItems.map(({ id, name, year }, index) => (
            <Grid item xs={6} md={4} lg={3} key={id}>
              <QuizCard
                questionsCount={questionCount}
                type={CardType.TEST}
                isHide
                title={<Box>
                  <Typography
                    sx={{
                      fontSize: { xs: '16px', sm: '24px' },
                      lineHeight: { xs: '23px', sm: '29px' },
                      fontWeight: '800',
                    }}>
                    {name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: { xs: '16px', sm: '24px' },
                      lineHeight: { xs: '23px', sm: '29px' },
                      fontWeight: '800',
                    }}>
                    {year}
                  </Typography>
                </Box>}
                backgroundColor={quizCardBackgroundColors[index % quizCardBackgroundColors.length]}
                onClick={() => history.push(`/quizzes/official/${id}/new`)}
              />
            </Grid>
          ))
        }
      </Grid>
      <Box>
        <PreviousYears specialtyToFilter={specialty} officialNationalQuizzes={archiveSimulationCategoriesQuery}/>
      </Box>
    </Box>
  )
}

export default PreviousYearQuizzes
