import React, { FC, useState } from 'react'
import { Field, FieldProps } from 'formik'
import { isEmpty } from 'lodash'
import { FormikHelpers } from 'formik/dist/types'
import { TextField, Autocomplete } from '@mui/material'
import { Theme } from '@mui/material/styles'
import { SxProps } from '@mui/system'

type AnyWithId = any & {id: string}

type FormSelectProps = {
  name: string
  options: AnyWithId[]
  selectedOption?: AnyWithId
  renderOption: (option: AnyWithId, selectedId: string, props: any) => React.ReactNode
  label: {text: (option: any) => string, icon: (option: any) => React.ReactNode}
  sx?: SxProps<Theme>
}

const FormSelect: FC<FormSelectProps> = ({ name, options, selectedOption, renderOption, label, sx }) => {
  const [selected, setSelected] = useState<AnyWithId>({} as AnyWithId)

  const defaultProps = {
    defaultValue: selectedOption,
    options,
    getOptionLabel: label.text
  };

  const handleSpeakerChange = (
    value: AnyWithId,
    setFieldValue: FormikHelpers<unknown>['setFieldValue'],
  ) => {
    if (!isEmpty(value)) {
      setFieldValue(name, value.id);
      setSelected(value)
    }
  }
  
  return (
    <Field>
      {({ form: { setFieldValue } }: FieldProps) => (
        <Autocomplete
          {...defaultProps}
          disableClearable
          onChange={(e, value) => handleSpeakerChange(value, setFieldValue)}
          renderOption={(props, option) => renderOption(option, selected.id, props)}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              inputProps={{style: { minHeight: '45px' }, ...params.inputProps}}
              InputProps={{
                ...params.InputProps,
                startAdornment: label.icon(isEmpty(selected) ? selectedOption : selected)
              }}
              sx={sx}
            />
          )}
        />
      )}
    </Field>
  );
}

export default FormSelect
