import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import { Back } from 'components/shared/back'
import QuizAttemptReview from 'components/quiz-attempt-review'
import LoadingIndicator from 'components/shared/loading-indicator'
import { translations } from 'locales/i18n'
import useQuizAttemptReview from 'hooks/useQuizAttemptReview'
import useQuizReport from 'hooks/useQuizReport'
import useQuizAttemptStatistics from 'hooks/useQuizAttemptStatistics'

const QuizAttemptReviewPage = (
  props: RouteComponentProps<{ quizAttemptId?: string }>,
) => {
  const {t} = useTranslation()

  const quizAttemptId: string = props.match.params.quizAttemptId || ''
  const quizAttemptReviewQuery = useQuizAttemptReview(quizAttemptId)
  const quizAttemptReview = quizAttemptReviewQuery.data
  const quizAttemptStatisticsQuery = useQuizAttemptStatistics(quizAttemptId)
  const statistics = quizAttemptStatisticsQuery.data
  const groupName = `${statistics?.quizAttemptDetails?.groupName}: ${statistics?.quizAttemptDetails?.quizName}`

  const getLinkBack = () => {
    if (quizAttemptReview?.quizType === "LESSON") {
      return {
        text: t(translations.quizAttemptReview.backToResult),
        url: `/quiz-attempts/${quizAttemptId}/results`,
      }
    }
    return {
      text: t(translations.navigation.quizzesPage),
      url: '/quizzes',
    }
  }
  const buttonBack = getLinkBack()

  const createQuizReport = useQuizReport()
  const handleCreateReport = ({questionId, reportText}: ReportQuizAttemt) =>
    createQuizReport.mutateAsync({
      questionId,
      reportText,
    } as ReportQuizAttemt)

  const handleQuizReport = (report: ReportQuizAttemt) => handleCreateReport(report)

  return (
    <DashboardLayout>
      {!quizAttemptReviewQuery.isLoading &&
        <Back
          text={buttonBack.text}
          url={buttonBack.url}
          sx={{display: {xs: 'none', lg: 'initial'}, position: 'fixed'}}
        />
      }
      {quizAttemptReview && statistics && (
        <QuizAttemptReview
          quizAttemptReview={quizAttemptReview}
          onSendReport={handleQuizReport}
          statistics={statistics}
          groupName={groupName}
        />
      )}
      {quizAttemptReviewQuery.isLoading && <LoadingIndicator/>}
    </DashboardLayout>
  )
}

export default QuizAttemptReviewPage
