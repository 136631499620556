
import { completeQuizAttemptNextSection } from 'services/quizAttemtService'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useQuizAttemptNextSection = () => {

return useMutationWithSnackbar(
  (data: { quizAttemptId: string, sectionId: string }) => completeQuizAttemptNextSection(data),
    'completeQuizAttempt',
    undefined,
  )
}

export default useQuizAttemptNextSection
