import { UseQueryOptions, UseQueryResult } from "react-query"
import { AxiosError } from 'axios'

import useQueryWithSnackbar from './useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getScoreAverageStatistics } from 'services/statisticsService'

const useStatisticsScoreAverage = (options?: UseQueryOptions<ScoreAverageStatistics, AxiosError>)
  : UseQueryResult<ScoreAverageStatistics> =>
  useQueryWithSnackbar<ScoreAverageStatistics>(
    [ReactQueryKeys.SCORE_AVERAGE_STATISTICS],
    () => getScoreAverageStatistics(),
    {
      ...options,
    },
  )

export default useStatisticsScoreAverage
