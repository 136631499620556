import React, { FC, useState } from 'react'
import { Box, Divider } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { isEmpty } from 'lodash'

import { translations } from 'locales/i18n'
import QuestionNumber from '../../quiz-attempt/quiz-question-card/QuestionNumber'
import Question from '../../quiz-attempt/quiz-question-card/Question'
import AnswerOptionReview from './AnswerOptionReview'
import AnswerExplanation from './AnswerExplanation'
import ModalReport from 'components/shared/modal/ModalReport'

type QuizQuestionReviewCardProps = {
  questionId: string
  questionNumber: number
  questionText: string
  options: QuestionOptionReview[]
  answerExplanation: string
  tagged: boolean
  onSendReport: (report: ReportQuizAttemt) => void
}

const QuizQuestionReviewCard: FC<QuizQuestionReviewCardProps> = ({
                                                                   questionId,
                                                                   questionNumber,
                                                                   questionText,
                                                                   options,
                                                                   answerExplanation,
                                                                   tagged,
                                                                   onSendReport,
                                                                 }) => {

  const correctAnswer = options.find(({correct}) => correct)
  const correctAnswerText = correctAnswer ? correctAnswer.text : ''
  const isAnyAnswerSelected = !!options.find(({studentAnswer}) => studentAnswer)
  const {t} = useTranslation()
  const [reportModalOpen, setReportModalOpen] = useState(false)
  const [quizReport, setQuizeReport] = useState('')

  const handleQuizReport = (e) => {
    const {value} = e.target
    if (!isEmpty(value.trim())) {
      setQuizeReport(value.trim())
    }
  }

  const handleSendReport = () => {
    if (!isEmpty(quizReport)) {
      onSendReport({questionId: questionId, reportText: quizReport})
      setReportModalOpen(false)
    }
  }

  return (
    <>
      <Box
        sx={{
          width: {xs: "335px", sm: "85%", md: "827px"},
          background: theme => theme.palette.background.paper,
          mx: 'auto',
        }}
      >
        <Box sx={{py: {xs: "16px", md: "32px"}, px: {xs: "20px", md: "46px"}}}>
          <QuestionNumber number={questionNumber} questionIndex={questionNumber} tagged={tagged}/>
          <Question questionText={questionText} isAnyAnswerSelected={isAnyAnswerSelected}/>
        </Box>
        <Divider/>
        {
          options.map(({number, studentAnswer, correct, text, selectionPercentage}, i) => (
            <AnswerOptionReview
              index={i}
              key={number}
              selected={studentAnswer}
              correct={correct}
              text={text}
              selectionPercentage={selectionPercentage}
              isAnyAnswerSelected={isAnyAnswerSelected}
            />
          ))
        }
        <AnswerExplanation
          correctAnswerText={correctAnswerText}
          answerExplanation={answerExplanation}
          onStartReportClick={() => setReportModalOpen(true)}/>
      </Box>
      <ModalReport
        open={reportModalOpen}
        onClose={() => setReportModalOpen(false)}
        title={t(translations.quizAttemptResults.reports.reportQuestion)}
        mistakeMessage={t(translations.quizAttemptResults.reports.mistakeMessage)}
        onChangeStudentMessage={handleQuizReport}
        onSendReport={handleSendReport}
        content={
          <Box sx={{width: "100%"}}>
            <Box sx={{
              py: {xs: "20px", md: "15px"},
              px: {xs: "20px", md: "30px"},
            }}>
              <QuestionNumber number={questionNumber}/>
              <Question questionText={questionText} isAnyAnswerSelected={isAnyAnswerSelected}/>
            </Box>
            <Divider/>
            {
              options.map(({number, studentAnswer, correct, text, selectionPercentage}, i) => (
                <AnswerOptionReview
                  index={i}
                  key={number}
                  selected={studentAnswer}
                  correct={correct}
                  text={text}
                  selectionPercentage={selectionPercentage}
                  isAnyAnswerSelected={isAnyAnswerSelected}
                  className={"u-weight-400 u-pt-15 u-pb-15"}
                />
              ))
            }
            <AnswerExplanation
              correctAnswerText={correctAnswerText}
              answerExplanation={answerExplanation}
            />
          </Box>
        }
      />
    </>
  )
}

export default QuizQuestionReviewCard
