import React from 'react'
import { Back } from 'components/shared/back'
import { translations } from '../locales/i18n'
import { useTranslation } from 'react-i18next'
import ComingSoon from 'components/shared/coming-soon/ComingSoon'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'

const ComingSoonPage = () => {
  const {t} = useTranslation()

  return (
    <DashboardLayout>
      <Back text={t(translations.navigation.homePage)} url={'/'}/>
      <ComingSoon pageTitle={t(translations.quizTypes.freeMonthlySimulation)} releaseDate="2021-12-07" />
    </DashboardLayout>
  )
}

export default ComingSoonPage;
