import { useHistory } from 'react-router-dom'

import { startOfficialNationalSimulation } from 'services/quizService'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useStartOfficialNationalSimulation = () => {
  const history = useHistory()

  return useMutationWithSnackbar(
    (quizId?: string) => startOfficialNationalSimulation(quizId),
    'startOfficialNationalSimulation',
    undefined,
    {
      async onSuccess(quizAttempt) {
        history.push(`/quiz-attempts/${quizAttempt.quizAttemptId}`)
      },
    },
  )
}

export default useStartOfficialNationalSimulation
