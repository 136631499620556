import { useQueryClient } from 'react-query'

import { resetLessonNoteText } from 'services/lessonService'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useResetLessonNoteText = (courseId: string, lessonId: string) => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (data: LessonNoteTextResetRequest) => resetLessonNoteText(data),
    undefined,
    undefined,
    {
      async onSuccess() {
        await queryClient.invalidateQueries([ReactQueryKeys.LESSON, courseId, lessonId])
      },
    },
  )
}
export default useResetLessonNoteText
