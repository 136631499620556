import React from 'react'
import { useTranslation } from 'react-i18next'
import { RouteComponentProps } from 'react-router'
import { useHistory } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { Back } from 'components/shared/back'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import QuizStartConfirmation from 'components/quizzes/QuizStartConfirmation'
import { QuizType } from 'types/QuizType'
import useStartRandomSimulation from 'hooks/useStartRandomSimulation'
import useStartMonthlySimulation from 'hooks/useStartMonthlySimulation'
import { translations } from 'locales/i18n'
import useQuizStartInfo from 'hooks/useQuizStartInfo'

const getQuizType = (quizType?: string) => {
  switch (quizType) {
    case 'random':
      return QuizType.RANDOM_SIMULATION
    case 'monthly':
      return QuizType.MONTHLY_SIMULATION
  }
}

export function QuizStartSimulationPage(
  props: RouteComponentProps<{ quizType?: string }>,
) {
  const history = useHistory()
  const { t } = useTranslation()
  const quizTypeParam = props.match.params.quizType || ''
  const type = getQuizType(quizTypeParam) || ''
  const quizStartInfo = useQuizStartInfo(type).data

  if (isEmpty(quizTypeParam)) {
    history.push('/quizzes')
  }

  const startRandomSimulationMutation = useStartRandomSimulation()
  const startMonthlySimulationMutation = useStartMonthlySimulation()

  const simulationMutation = type === QuizType.RANDOM_SIMULATION ? startRandomSimulationMutation : startMonthlySimulationMutation

  const handleStartQuiz = () => simulationMutation.mutate()

  return (
    <DashboardLayout>
      <Back text={t(translations.navigation.quizzesPage)} url={'/quizzes'}/>
      {type && quizStartInfo && (
        <QuizStartConfirmation
          dividedBySections={quizStartInfo.dividedBySections}
          quizType={type}
          handleStartQuiz={handleStartQuiz}
          maxSimulationMinutesAllowed={quizStartInfo.timeLimit / 60}
          questionsCount={quizStartInfo.questionAmount}
        />
      )}
    </DashboardLayout>
  )
}

export default QuizStartSimulationPage
