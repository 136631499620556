import React from 'react'
import { RouteComponentProps } from 'react-router'

import { Back } from 'components/shared/back'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import QuizStartConfirmation from 'components/quizzes/QuizStartConfirmation'
import { QuizType } from 'types/QuizType'
import { MAX_SIMULATION_MINUTES_ALLOWED, STATIC_PRACTICE_SIMULATION_COUNT } from 'theme/constants'
import useStartStaticPractice from 'hooks/useStartStaticPractice'
import useStaticPracticeSimulationGroupInfo from 'hooks/useStaticPracticeSimulationGroupInfo'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'

function QuizStartStaticPracticePage(
  props: RouteComponentProps<{ groupId: string, quizId: string }>,
) {
  const { t } = useTranslation()
  const quizId = props.match.params.quizId || ''

  const startOfficialNationalSimulationMutation = useStartStaticPractice()
  const staticPracticeSimulationGroupInfo = useStaticPracticeSimulationGroupInfo(quizId).data
  const ruleVersion = staticPracticeSimulationGroupInfo?.ruleVersion
  const groupTitle = staticPracticeSimulationGroupInfo?.groupName
  const quizTitle = staticPracticeSimulationGroupInfo?.quizName
  const handleStartQuiz = () => startOfficialNationalSimulationMutation.mutate(quizId)
  
  return (
    <DashboardLayout>
      <Back text={t(translations.navigation.quizzesPage)} url={'/quizzes'}/>
      <QuizStartConfirmation
        groupName = {`${groupTitle}: ${quizTitle}`}
        quizType={QuizType.STATIC_PRACTICE}
        handleStartQuiz={handleStartQuiz}
        questionsCount={STATIC_PRACTICE_SIMULATION_COUNT}
        maxSimulationMinutesAllowed={MAX_SIMULATION_MINUTES_ALLOWED}
      />
    </DashboardLayout>
  )
}

export default QuizStartStaticPracticePage
