import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import {
  Box,
  Button,
  CircularProgress,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DesktopDatePicker, MobileDatePicker, TimePicker } from '@mui/lab'
import { debounce, isEmpty } from 'lodash'
import dayjs, { Dayjs } from 'dayjs'

import FormEditor from 'components/ui/form/form-editor/form-editor'
import FormInput from 'components/ui/form/form-input/form-input'
import PostFormSchema from './PostValidatorSchema'
import { PostInterface } from 'types/PostInterface'
import { translations } from 'locales/i18n'
import { PostType } from 'types/PostType'
import plusIcon from 'images/plus-in-circle.svg'
import schedulePostIcon from 'images/schedule.svg'
import { DEBOUNCE_TYPING_MILLI, MAX_POLL_OPTIONS_COUNT, MEDIUM_DATE_FORMAT } from 'utils/constants'
import { PostFile } from 'types/PostFile'
import PostFormImageList from './PostFormImageList'
import PostFormFileList from './PostFormFileList'
import { PostRequest } from 'types/requests/PostRequest'
import setFieldValueDebounced from 'components/ui/form/utils/setFieldValueDebounced'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { UserProfileRole } from 'types/UserProfileRole'
import StyledModal from 'components/shared/modal/StyledModal'
import theme from 'theme'
import { SortableList } from './SortableList'

type PostFormProps = {
  classId: string
  currentUser: StudentProfileInterface
  post: PostInterface
  postType: PostType
  postText: string
  setPostText: (value: string) => void
  uploadingFiles: File[]
  currentFiles: PostFile[]
  showAddOption: boolean
  pollVariants: PollOption[]
  handleAddFiles: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => Promise<void>,
  handleRemoveFile: (fileId: string) => void
  handleAddOption: () => void
  handleRemoveOption: (fileId: string) => void
  handleUpdateOptionText: (variantId: string, newText: string) => void
  handleUpdateOptionPosition?: (oldIndex: number, newIndex: number) => void
  handleSubmit: (data: PostRequest) => void
  handleCancel: () => void
}

const publishAtFieldName = 'publishAt'

const PostForm: FC<PostFormProps> = ({
                                       classId,
                                       currentUser,
                                       post,
                                       postType,
                                       postText,
                                       setPostText,
                                       uploadingFiles,
                                       currentFiles,
                                       showAddOption,
                                       pollVariants,
                                       handleAddFiles,
                                       handleRemoveFile,
                                       handleAddOption,
                                       handleRemoveOption,
                                       handleUpdateOptionText,
                                       handleUpdateOptionPosition,
                                       handleSubmit,
                                       handleCancel,
                                     }) => {
  const {t} = useTranslation()
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

  const [schedulePostOpen, setSchedulePostOpen] = useState(false)
  const [scheduledDate, setScheduledDate] = React.useState<Dayjs | null | undefined>(dayjs())
  const [scheduledTime, setScheduledTime] = React.useState<Dayjs | null | undefined>()
  const [currentPollVariants, setCurrentPollVariants] = useState<PollOption[]>([])

  useEffect(
    () => {
      setCurrentPollVariants(pollVariants)
    },
    [pollVariants]
  )

  const handleSchedulePostOpen = (setFieldValue: FormikHelpers<PostRequest>['setFieldValue']) => {
    if (!scheduledTime) {
      handleScheduledTimeChange(dayjs().add(1, 'hours').set('minutes', 0), setFieldValue)
    }
    setSchedulePostOpen(true)
  }
  const handleSchedulePostClose = () => setSchedulePostOpen(false)

  const handleScheduledDateChange = (value: Dayjs | null) => {
    setScheduledDate(value)
    setScheduledTime(value)
  }

  const handleScheduledTimeChange = (
    value: Dayjs | null,
    setFieldValue: FormikHelpers<PostRequest>['setFieldValue'],
  ) => {
    const newValue = value?.set('second', 0)
    setScheduledTime(newValue)
    if (newValue) {
      const publishAt = newValue.unix()
      setFieldValue(publishAtFieldName, publishAt * 1000)
    }
  }

  const handleSchedulePostConfirm = async (
    submitForm: FormikHelpers<PostRequest>['submitForm'],
    value?: number,
  ) => {
    if (value && value > dayjs().unix() * 1000) {
      handleSchedulePostClose()
      await submitForm()
    }
  }

  const {mainRole} = currentUser
  const canSchedulePost = post.publishedAt === -1 && (mainRole === UserProfileRole.ADMIN || mainRole === UserProfileRole.TEACHER)

  const setPostTextDebounced =
    debounce(
      nextValue => setPostText(nextValue),
      DEBOUNCE_TYPING_MILLI,
    )

  const handleEditorChange = (value: string, setFieldValue: FormikHelpers<unknown>['setFieldValue']) => {
    setPostTextDebounced(value)
    setFieldValueDebounced({name: 'text', value, setFieldValue})
  }

  const scheduledDateProps = {
    inputFormat: MEDIUM_DATE_FORMAT,
    value: scheduledDate,
    onChange: handleScheduledDateChange,
    disablePast: true,
    renderInput: (params) =>
      <TextField
        sx={{width: {xs: '100%', sm: '300px'}}}
        {...params}
        onKeyDown={(e) => {
          e.preventDefault()
        }}
      />,
  }

  const onSortEnd = ({oldIndex, newIndex}) => handleUpdateOptionPosition && handleUpdateOptionPosition(oldIndex, newIndex)

  return (
    <Formik
      validateOnBlur
      initialValues={{
        postType,
        classId,
        id: post.postId,
        text: postText,
      }}
      validationSchema={PostFormSchema}
      onSubmit={handleSubmit}
    >
      {({handleSubmit, submitForm, setFieldValue, values, errors}) => (
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              mt: {xs: '20px', sm: 0},
            }}
          >
            <FormEditor
              name="text"
              placeholder="Scrivi..."
              className="Post"
              height={194}
              onEditorChange={(newValue) => handleEditorChange(newValue, setFieldValue)}
              plugins="lists paste link"
              toolbar="bold italic underline strikethrough | undo redo | link"
              skinUrl="/styles/skins/ui/promed-community"
              contentCss="/styles/skins/content/promed-community/content.css"
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: '20px 0px',
              mt: '15px',
              backgroundColor: '#2D2E2F',
              border: '1px dashed #353637',
              borderRadius: '3px',
            }}
          >
            <label htmlFor="files" className="u-cursor-pointer"
                   style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <img
                src='/images/icons/community/addPhoto.svg'
                alt="Show previous item"
              />
              <FormInput
                type="file"
                multiple={true}
                id="files"
                name="files"
                hidden={true}
                onChange={handleAddFiles}
              />
              <Box sx={{
                fontSize: {xs: '14px', sm: '16px'},
                lineHeight: {xs: '16px', sm: '19px'},
              }}>{t(translations.community.postForm.addFiles)}</Box>
              {/* TODO uncomment when drag and drop is done */}
              {/*<span className="u-opacity-40 u-fs-12">{t(translations.community.postForm.dragAndDropFiles)}</span>*/}
            </label>
          </Box>
          {(!isEmpty(uploadingFiles) || !isEmpty(currentFiles)) && (
            <Box
              sx={{
                mt: '15px',
              }}
            >
              {!isEmpty(uploadingFiles) && <CircularProgress/>}
              <PostFormImageList files={currentFiles} onRemoveFile={handleRemoveFile}/>
              <PostFormFileList files={currentFiles} onRemoveFile={handleRemoveFile}/>
            </Box>
          )}

          {postType === PostType.POLL_POST && (
            <Box>
              <Box sx={{
                mt: '18px',
                fontSize: {xs: '10px', sm: '12px'},
                lineHeight: {xs: '12px', sm: '15px'},
                letterSpacing: '0.5px',
                fontWeight: '500',
                opacity: '54%',
                textTransform: 'uppercase',
              }}>
                {t(translations.community.postForm.poolOptions)}
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: '11px',
                  backgroundColor: '#2D2E2F',
                  borderRadius: '3px',
                }}
              >
                <Box
                  sx={{
                    display: showAddOption ? 'flex' : 'none',
                    alignItems: 'center',
                    pl: {xs: '15px', sm: '21px'},
                    height: {xs: '48px', sm: '60px'},
                    cursor: 'pointer',
                    borderBottom: pollVariants.length > 0 ? '1px solid rgba(255, 255, 255, 0.12)' : 'unset',
                  }}
                  onClick={handleAddOption}
                >
                  <img src={plusIcon} alt={t(translations.community.postForm.addOption)} className="u-cursor-pointer"/>
                  <Typography
                    sx={{
                      fontSize: {xs: '12px', sm: '16px'},
                      lineHeight: {xs: '22px', sm: '28px'},
                      pl: {xs: '7px', sm: '8px'},
                    }}
                  >
                    {t(translations.community.postForm.addOption)}
                  </Typography>
                </Box>
                <SortableList
                  items={currentPollVariants}
                  onSortEnd={onSortEnd}
                  handleRemoveOption={handleRemoveOption}
                  handleUpdateOptionText={handleUpdateOptionText}
                  useDragHandle
                />
              </Box>
              <Typography
                sx={{
                  fontSize: '12px',
                  lineHeight: '28px',
                }}
              >
                {t(translations.community.postForm.youCanAdd, {count: MAX_POLL_OPTIONS_COUNT})}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: 'flex',
              mt: '20px',
              pb: {xs: '20px', sm: 'unset'},
              justifyContent: canSchedulePost ? 'space-between' : 'unset',
            }}
          >
            <Box sx={{width: {xs: '100%', sm: 'unset'}}}>
              <Button
                variant="contained"
                type="submit"
                sx={{
                  width: {xs: canSchedulePost ? 'calc(50% - 5px)' : '100%', sm: 'auto'},
                  height: {xs: '50px', sm: 'unset'},
                  textTransform: 'none',
                }}
                onClick={() => setFieldValue(publishAtFieldName, null)}
              >
                {t(translations.common.save)}
              </Button>
              {canSchedulePost && (
                <Button
                  variant="contained"
                  type="button"
                  sx={{
                    ml: '10px',
                    width: {xs: 'calc(50% - 5px)', sm: 'auto'},
                    height: {xs: '50px', sm: 'unset'},
                    textTransform: 'none',
                  }}
                  onClick={() => handleSchedulePostOpen(setFieldValue)}
                >
                  <img src={schedulePostIcon} alt={t(translations.community.postForm.schedulePost)}
                       className="u-pr-10"/>
                  {t(translations.community.postForm.schedulePost)}
                </Button>
              )}
            </Box>
            <Button
              sx={{
                display: {xs: 'none', sm: 'flex'},
                ml: '10px',
                backgroundColor: '#2D2E2F',
                textTransform: 'none',
              }}
              variant="contained"
              type="button"
              onClick={handleCancel}
            >
              {t(translations.common.cancel)}
            </Button>
          </Box>

          <StyledModal
            open={schedulePostOpen}
            onClose={handleSchedulePostClose}
            title={t(translations.community.postForm.schedulePost)}
            childrenBgColor={theme.palette.background.default}
          >
            <Box
              sx={{
                mt: {xs: '112px', sm: '37px'},
              }}
            >
              <Box
                sx={{
                  mx: {xs: '20px', sm: 'unset'},
                  px: {xs: '20px', sm: 'unset'},
                  py: {xs: '27px', sm: 'unset'},
                  background: theme.palette.background.paper,
                  borderRadius: '4px',
                }}
              >
                <Box
                  sx={{
                    display: {sm: 'flex'},
                    justifyContent: {sm: 'space-between'},
                  }}
                >
                  {isSm && (
                    <DesktopDatePicker {...scheduledDateProps}/>
                  )}
                  {!isSm && (
                    <MobileDatePicker {...scheduledDateProps}/>
                  )}
                  <TimePicker
                    toolbarTitle={t(translations.picker.selectTime)}
                    value={scheduledTime}
                    onChange={(date) => handleScheduledTimeChange(date, setFieldValue)}
                    renderInput={(params) =>
                      <TextField
                        sx={{
                          width: {xs: '100%', sm: '228px'},
                          mt: {xs: '15px', sm: 'unset'},
                        }}
                        {...params}
                      />
                    }
                  />
                </Box>
                {errors[publishAtFieldName] && (
                  <Box
                    sx={{
                      color: '#F0421C',
                      'mt': '5px',
                      textAlign: 'center',
                      fontSize: '11px',
                      lineHeight: '13px',
                    }}
                  >
                    {errors[publishAtFieldName]}
                  </Box>
                )}

                <Box
                  sx={{
                    mt: '26px',
                    display: 'flex',
                    justifyContent: {xs: 'space-between', sm: 'flex-end'},
                  }}
                >
                  <Button
                    sx={{
                      display: {xs: 'none', sm: 'flex'},
                      backgroundColor: '#2D2E2F',
                      textTransform: 'none',
                    }}
                    variant="contained"
                    type="button"
                    onClick={handleSchedulePostClose}
                  >
                    {t(translations.common.cancel)}
                  </Button>
                  <Button
                    variant="contained"
                    type="button"
                    sx={{
                      ml: {sm: '10px'},
                      width: {xs: '100%', sm: 'unset'},
                      height: {xs: '50px', sm: 'unset'},
                      textTransform: 'none',
                    }}
                    onClick={() => handleSchedulePostConfirm(submitForm, values[publishAtFieldName])}
                  >
                    <img src={schedulePostIcon} alt={t(translations.community.postForm.schedulePost)}
                         className="u-pr-10"/>
                    {t(translations.community.postForm.schedulePost)}
                  </Button>
                </Box>
              </Box>
            </Box>
          </StyledModal>
        </form>
      )}
    </Formik>
  )
}
export default PostForm

