import React from 'react'
import { useHistory } from 'react-router-dom'
import { Box } from '@mui/material'
import { isEmpty } from 'lodash'
import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import { Back } from 'components/shared/back'
import { RouteComponentProps } from 'react-router'
import useOfficialNationalSimulationCategoryItems from 'hooks/useOfficialNationalSimulationCategoryItems'
import useOfficialNationalSimulationCategories from 'hooks/useOfficialNationalSimulationCategories'
import { Specialty } from 'types/Specialty'
import LoadingIndicator from 'components/shared/loading-indicator'
import PreviousYearQuizzes from 'components/quizzes-page/PreviousYearQuizzes'

export function PreviousYearQuizzesPage(
  props: RouteComponentProps<{ speciality?: string }>,
) {
  const history = useHistory()
  const {t} = useTranslation()
  const archiveSimulationCategoriesQuery = useOfficialNationalSimulationCategories()

  const specialityParam = props.match.params.speciality?.toUpperCase()
  const speciality: Specialty = specialityParam ? Specialty[specialityParam] : ''

  if (isEmpty(specialityParam)) {
    history.push('/quizzes')
  }

  const OfficialNationalSimulationCategoryItemsQuery = useOfficialNationalSimulationCategoryItems(speciality)

  return (
    <DashboardLayout>
      <Back text={t(translations.navigation.quizzesPage)} url={'/quizzes'}/>
      <Box sx={{
        mx: {xs: '20px', sm: 6.5},
        mb: {xs: '20px', sm: 6.5},
        mt: {xs: '40px', md: '70px'},
      }}>
        {OfficialNationalSimulationCategoryItemsQuery.isSuccess &&
        <PreviousYearQuizzes
          specialty={speciality}
          categoryItems={OfficialNationalSimulationCategoryItemsQuery.data}
          archiveSimulationCategoriesQuery={archiveSimulationCategoriesQuery.data}
        />}
        {OfficialNationalSimulationCategoryItemsQuery.isLoading && <LoadingIndicator/>}
      </Box>
    </DashboardLayout>
  )
}

export default PreviousYearQuizzesPage
