import React from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider, Helmet } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/react'

import './index.scss'
import { Root } from './components/Root'
import { isWeTest } from 'theme/constants'
import Logger from 'LoggerService'
import reportWebVitals from './reportWebVitals'

Logger()

const platformTitle = isWeTest() ? 'Piattaforma WeTest' : 'Piattaforma Pro-Med'
const platformIcon = isWeTest() ? 'https://staging-web.promedtest.it/faviconWeTest.ico' : 'https://staging-web.promedtest.it/faviconPromedTest.ico'


ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary>
    <BrowserRouter>
      <HelmetProvider>
        <React.StrictMode>
          <Helmet>
            <title>{platformTitle}</title>
            <link rel="icon" href={platformIcon} />
          </Helmet>
          <Root/>
        </React.StrictMode>
      </HelmetProvider>
    </BrowserRouter>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
