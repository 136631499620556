import React, { FC } from 'react'
import { Box, Modal, useMediaQuery } from '@mui/material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import closeLaptop from 'images/close-modal.svg'
import theme from 'theme'
import Overlay from './Overlay'

type StyledModalProps = {
  open: boolean
  onClose: () => void
  onSubmit?: () => Promise<void>
  submitTitle?: string
  title: string
  titleBgColor?: string
  children?: React.ReactNode
  childrenBgColor?: string
  modalBoxSx?: SxProps<Theme>
  stylesTextSx?: SxProps<Theme>
  displayNone?: SxProps<Theme>
  mobileOnly?: boolean
  disableEnforceFocus?: boolean
  desktopOnly?: boolean
}

const StyledModal: FC<StyledModalProps> = ({
                                             open,
                                             title,
                                             titleBgColor = theme.palette.background.default,
                                             onClose,
                                             onSubmit,
                                             submitTitle,
                                             children,
                                             childrenBgColor = theme.palette.background.paper,
                                             modalBoxSx,
                                             displayNone,
                                             stylesTextSx,
                                             mobileOnly = false,
                                             disableEnforceFocus,
                                             desktopOnly = false
                                           }) => {
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

  return (
    <>
      {(!desktopOnly ? isSm && !mobileOnly  : !mobileOnly) && (
        <Modal
          open={open}
          onClose={onClose}
          disableEnforceFocus={disableEnforceFocus}
          aria-labelledby="styled-modal-title"
          aria-describedby="styled-modal-description"
          sx={{
            //display: { xs: 'none', sm: 'block' },
            overflow:'scroll',
          }}
        >
          <Box sx={{
            position: 'absolute',
            top: '5%',
            left: '50%',
            outline: 'none',
            transform: 'translate(-50%, 0%)',
            width: '618px',
            bgcolor: theme.palette.background.paper,
            borderRadius: '4px',
            boxShadow: 24,
            p: '40px',
            ...modalBoxSx,
          }}>
            <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'flex-end'}}>
              <Box
                sx={{
                  fontSize: '34px',
                  lineHeight: '41px',
                  ...stylesTextSx
                }}
              >
                {title}
              </Box>
              <Box onClick={onClose} sx={{...displayNone}}>
                <img
                  src={closeLaptop}
                  className="u-cursor-pointer"
                  alt="Close"
                />
              </Box>
            </Box>
            {children}
          </Box>
        </Modal>
      )}

      {desktopOnly === false && !isSm && open && (
        <Overlay
          open={open}
          onClose={onClose}
          title={title}
          titleBgColor={titleBgColor}
          childrenBgColor={childrenBgColor}
          onSubmit={onSubmit}
          submitTitle={submitTitle}
        >
          {children}
        </Overlay>
      )}
    </>
  )
}

export default StyledModal
