import { clientDataService } from '../clientDataService'
import { PostCommentInterface } from 'types/PostCommentInterface'

export const getPostComments = (classId: string, postId: string): Promise<PostCommentInterface[]> =>
  clientDataService<PostCommentInterface[]>({
    url: `/classes/${classId}/posts/${postId}/comments?size=1000`,
  })

export const getComment = (data: GetCommentRequest): Promise<PostCommentInterface[]> =>
  clientDataService<PostCommentInterface[]>({
    url: `/classes/${data.classId}/posts/${data.postId}/comments?commentId=${data.commentId}`,
  })

export const createPostComment = (data: PostCommentRequest): Promise<PostCommentResponse> =>
  clientDataService<PostCommentResponse>({
    url: `/classes/${data.classId}/posts/${data.postId}/comments`,
    method: 'POST',
    data,
  })

export const updatePostComment = (data: PostCommentRequest): Promise<PostCommentResponse> =>
  clientDataService<PostCommentResponse>({
    url: `/classes/${data.classId}/posts/${data.postId}/comments/${data.commentId}`,
    method: 'PUT',
    data,
  })

export const deletePostComment = ({classId, postId, commentId}: DeletePostCommentRequest): Promise<void> =>
  clientDataService<void>({
    url: `/classes/${classId}/posts/${postId}/comments/${commentId}`,
    method: 'DELETE',
  })


export const reportComment = (data: ReportCommentRequest): Promise<void> =>
  clientDataService<void>({
    method: 'POST',
    url: `/classes/${data.classId}/posts/${data.postId}/comments/${data.commentId}/report`,
    data,
  })
