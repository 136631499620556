const config = {
  baseApiURI: process.env.REACT_APP_API_BASE_URI,
  baseWsApiURI: process.env.REACT_APP_API_BASE_WS_URI,
  cdnBaseApiURI: process.env.REACT_APP_CDN_API_BASE_URI,
  image: {
    maxFileSizeMb: Number(process.env.REACT_APP_PUBLIC_MAX_FILE_SIZE_MB),
  },
  tinyMceApiKey: process.env.REACT_APP_TINY_MCE_KEY,
  authVersion: process.env.REACT_APP_AUTH_VERSION,
  platform: process.env.REACT_APP_PLATFORM,
}

export default config
