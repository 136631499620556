import React, { FC } from 'react'
import { ImageList, ImageListItem, useMediaQuery } from '@mui/material'

import { PostFile } from 'types/PostFile'
import { PostFileType } from 'types/PostFileType'
import config from 'utils/config'
import playIcon from 'images/play-video-for-preview.svg'
import PostVideoPreview from '../PostVideoPreview'
import theme from '../../../../theme'
import PostCardSingleImage from './PostCardSingleImage'
import { ImageSize } from 'types/ImageSize'

type PostCardImageListProps = {
  files: PostFile[]
}

const PostCardImageList: FC<PostCardImageListProps> = ({files}) => {
  const types = [PostFileType.IMAGE, PostFileType.VIDEO]
  const imagesOnly = files.filter(({fileType}) => types.includes(fileType))

  const isLaptop = useMediaQuery(theme.breakpoints.up('md'))

  const getRowHeight = (listLength) => {
    switch (listLength) {
      case 4:
        return isLaptop ? 171 : 89
      case 3:
        return isLaptop ? 262.5 : 136.5
      case 2:
        return isLaptop ? 262.5 : 136.5
      case 1:
        return isLaptop ? 301 : 156
    }
  }

  const renderImages = () => {
    const {length} = imagesOnly
    if (length > 1) {
      return (
        <ImageList
          sx={{
            width: '100%',
            height: length > 1 ? {xs: '279px', md: '537px'} : {xs: '156px', md: '301px'},
            transform: 'translateZ(0)',
            overflow: 'hidden'
          }}
          rowHeight={getRowHeight(length)}
          gap={isLaptop ? 12 : 6}
        >
          {imagesOnly.map(({fileUri, fileType, originalFileName, addedAt}, index) => {
            const cols = imagesOnly.length > 2 ? 1 : 2
            const rows = index === 0 ? imagesOnly.length - 1 : 1

            return (
              <ImageListItem
                key={fileUri}
                cols={cols}
                rows={rows}
                sx={{overflow: 'hidden'}}>
                {fileType === PostFileType.IMAGE ? (
                  <img
                    src={`${config.cdnBaseApiURI}/${fileUri}?t=${addedAt}${ImageSize.PREVIEW}`}
                    alt={originalFileName}
                    loading="lazy"
                  />
                ) : <PostVideoPreview fileUri={fileUri} playIcon={playIcon}/>}
              </ImageListItem>
            )
          })}
        </ImageList>
      )
    } else if (length === 1) {
      return <PostCardSingleImage file={imagesOnly[0]} />
    } else return <></>
  }

  return renderImages()
}

export default PostCardImageList
