import React from 'react'

import PasswordLayout from 'components/layouts/password/PasswordLayout'
import ForgotPassword from 'components/password/forgot-password/ForgotPassword'
import useForgotPassword from 'hooks/useForgotPassword'
import useMe from 'hooks/useMe'

const ForgotPasswordPage = () => {
  const meQuery = useMe()
  const me = meQuery.data

  const forgotPasswordMutation = useForgotPassword()

  const handleSubmit = (data: ForgotPasswordRequest) => forgotPasswordMutation.mutate(data)

  return (
    <PasswordLayout>
      <ForgotPassword currentUser={me} onSubmit={handleSubmit} isLoading={forgotPasswordMutation.isLoading}/>
    </PasswordLayout>
  )
}

export default ForgotPasswordPage
