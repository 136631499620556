import React, { FC, useEffect, useState } from 'react'
import { configureAnchors } from 'react-scrollable-anchor'
import { Box, useMediaQuery } from '@mui/material'

import whiteCircle from 'images/circle-white.svg'
import { QuizAttemptQuestion } from 'types/QuizAttemptQuestion'
import { QUESTION_ANCHOR_PREFIX } from 'utils/constants'
import theme from 'theme'
import { Dictionary } from 'lodash'

type QuestionNavigationProps = {
  questions: QuizAttemptQuestion[]
  actualSection: string
  isDividedBySections: boolean
  questionsGroupedBySectionId: Dictionary<[QuizAttemptQuestion, ...QuizAttemptQuestion[]]>
  isFlagged: (number: number) => boolean
  getQuestionNavigation: (number: number) => string
}

configureAnchors({ offset: -150, scrollDuration: 1000, keepLastAnchorHash: true })

const QuestionNavigation: FC<QuestionNavigationProps> = ({ questions, questionsGroupedBySectionId, actualSection, isDividedBySections, isFlagged, getQuestionNavigation }) => {
  const isLaptop = useMediaQuery(theme.breakpoints.up('md'))
  const isAnswered = (question: QuestionInterface) =>
    question.options.find((o) => o.studentAnswer)

  const [currentQuestions, setCurrentQuestions] = useState<QuizAttemptQuestion[]>(questions)

    useEffect(() => {
      if (actualSection && isDividedBySections) {
        setCurrentQuestions(questionsGroupedBySectionId[actualSection] || [])
      } else {
        setCurrentQuestions(questions)
      }
    }, [questions, actualSection])
  

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: { xs: 'row', lg: 'column' },
    }}>
      {currentQuestions.map(i => 
        
          <a
            href={`#${QUESTION_ANCHOR_PREFIX}${i.number}`}
            style={{ color: 'white', textDecoration: 'none' }}
            id={getQuestionNavigation(i.number)}
            key={`questionNavigation${i.number}`}
          >  
            <Box
              sx={{
                width: { xs: '36px', md: '45px' },
                height: { xs: '36px', md: '45px' },
                borderRadius: { xs: '36px', md: '45px' },
                background: theme => isAnswered(i.question) ? theme.palette.primary.main : theme.palette.background.paper,
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'wrap',
                alignItems: 'center',
                justifyContent: 'center',
                mb: '3px',
              }}
              className="u-cursor-pointer"
            >
              {isFlagged(i.number) && <img
                  src={whiteCircle}
                  alt=""
                  role="presentation"
                  style={{
                    position: 'relative',
                    top: isLaptop ? '-6px' : '-2px',
                  }}
              />}
              <span
                style={isFlagged(currentQuestions.indexOf(i)+1) ? { position: 'relative', top: '-4px' } : {}}
              >
                {currentQuestions.indexOf(i)+1}
              </span>
            </Box>
          </a>
        )
      }
    </Box>
  )
}

export default QuestionNavigation
