import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getMe } from 'services/userService'
import { StudentProfileInterface } from 'types/StudentProfileInterface'

const useMe = (options?: UseQueryOptions<StudentProfileInterface>): UseQueryResult<StudentProfileInterface> => {
  return useQuery<StudentProfileInterface>(
    ReactQueryKeys.ME,
    () => getMe(),
    options,
  )
}

export default useMe
