import { AxiosError } from "axios"
import { UseQueryOptions, UseQueryResult } from "react-query"
import { getSectionsStatistics } from "services/statisticsService"
import ReactQueryKeys from "types/ReactQueryKeys"
import useQueryWithSnackbar from "./useQueryWithSnackbar"

const useSectionsStatistics = (options?: UseQueryOptions<SectionsStatistics[], AxiosError>): UseQueryResult<SectionsStatistics[]> => {
  return useQueryWithSnackbar<SectionsStatistics[]>(
    [ReactQueryKeys.SECTION_STATISTICS],
    () => getSectionsStatistics(),
    {
      ...options,
    },
  )
}

export default useSectionsStatistics
