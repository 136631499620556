import { Dispatch, SetStateAction, useState } from 'react'
import { useQueryClient } from 'react-query'
import { Message } from '@stomp/stompjs'
import { v4 } from 'uuid'

import { WsMessage } from 'types/ws/WsMessage'
import {
  NOTIFICATION_APPEARING_TIMEOUT_MILLI,
  NOTIFICATION_HIDE_TIMEOUT_MILLI,
} from 'utils/constants'
import ReactQueryKeys from 'types/ReactQueryKeys'

type UseListenToNotificationsResult = {
  setNotificationMessage: Dispatch<SetStateAction<WsMessage<any> | undefined>>
  notificationMessage: WsMessage<any> | undefined
  handleMessage: (message: Message) => void
}

const useListenToNotifications = (): UseListenToNotificationsResult => {
  const queryClient = useQueryClient()

  const [notificationMessage, setNotificationMessage] = useState<WsMessage<any>>()
  const [appearingTimerId, setAppearingTimerId] = useState<any>()
  const [hidingTimerId, setHidingTimerId] = useState<any>()

  const hideNotification = () => {
    const hidingId = setTimeout(() => {
      setNotificationMessage(undefined)
    }, NOTIFICATION_HIDE_TIMEOUT_MILLI)

    setHidingTimerId(hidingId)
  }

  const showNotification = (wsMessage: WsMessage<any>) => {
    setNotificationMessage(undefined)

    const newNotification = {
      ...wsMessage,
      id: v4(),
    }

    const appearingId = setTimeout(() => {
      setNotificationMessage(newNotification)
      hideNotification()
    }, NOTIFICATION_APPEARING_TIMEOUT_MILLI)

    setAppearingTimerId(appearingId)
  }

  const handleMessage = (message: Message) => {
    queryClient.invalidateQueries(ReactQueryKeys.NOTIFICATIONS)
    appearingTimerId && clearInterval(appearingTimerId)
    hidingTimerId && clearInterval(hidingTimerId)

    const messageBody = JSON.parse(message.body)
    const wsMessage = messageBody as WsMessage<any>

    showNotification(wsMessage)
  }

  return {
    notificationMessage,
    setNotificationMessage,
    handleMessage
  }
}

export default useListenToNotifications
