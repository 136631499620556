import { UseQueryResult, UseQueryOptions } from 'react-query'
import { AxiosError } from 'axios'

import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getComment } from 'services/community/postCommentService'
import { PostCommentInterface } from 'types/PostCommentInterface'
import { UserProfileRole } from 'types/UserProfileRole'

const usePostComment = (
  {
    classId,
    postId,
    commentId,
  }: GetCommentRequest,
  mainRole?: UserProfileRole,
  options?: UseQueryOptions<PostCommentInterface[], AxiosError>,
): UseQueryResult<PostCommentInterface[]> =>
  useQueryWithSnackbar<PostCommentInterface[]>(
    [ReactQueryKeys.POST_COMMENT, classId, postId, commentId],
    () => getComment({ classId, postId, commentId }),
    {
      enabled: !!classId && !!postId && !!commentId && mainRole === UserProfileRole.ADMIN,
      ...options,
    },
  )

export default usePostComment
