import React, { FC } from 'react'
import { sum, max } from 'lodash'
import { Box, List } from '@mui/material'

import { PostInterface } from 'types/PostInterface'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import PollPostCardOption from './PollPostCardOption'

type PollPostCardProps = {
  post: PostInterface
  currentUser: StudentProfileInterface
  onCreateVotePollOption: (optionId: string) => void
  onUpdateVotePollOption: (optionId: string) => void
  marginPollOptions: boolean
  canVote: boolean
}

const PollPostCard: FC<PollPostCardProps> = ({
                                               post,
                                               currentUser,
                                               onCreateVotePollOption,
                                               onUpdateVotePollOption,
                                               marginPollOptions,
                                               canVote,
                                             }) => {
  const {postText, pollOptions, pinned} = post

  const selectedOptionId = pollOptions
    ?.find(({votedUsers}) => votedUsers?.map(({id}) => id).includes(currentUser.id))
    ?.id
  const totalVotes = sum(pollOptions?.map(({voteCount}) => voteCount))
  const maxVotesCount = max(pollOptions?.map(({voteCount}) => voteCount)) || 0

  return (
    <Box>
      <Box
        sx={{
          maxWidth: '100%',
          fontSize: {xs: '12px', md: '16px'},
          lineHeight: {xs: '18px', md: '24px'},
          textOverflow: 'ellipsis',
          whiteSpace: 'normal',
          wordBreak: 'break-word',
        }}
        dangerouslySetInnerHTML={{__html: postText}}
      />
      {pollOptions && (
        <List
          sx={{
            ml: marginPollOptions ? {xs: '-30px', md: '-50px'} : 0,
            padding: 0,
            mt: '26px',
          }}>
          {pollOptions.map((pollOption) => (
            <PollPostCardOption
              key={pollOption.id}
              pollOption={pollOption}
              totalVotes={totalVotes}
              currentUser={currentUser}
              onCreateVotePollOption={onCreateVotePollOption}
              onUpdateVotePollOption={onUpdateVotePollOption}
              selectedOptionId={selectedOptionId}
              maxVotesCount={maxVotesCount}
              canVote={canVote}
              pinned={pinned}
            />
          ))}
        </List>
      )}
    </Box>
  )
}

export default PollPostCard
