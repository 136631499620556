import { UseQueryOptions, UseQueryResult } from 'react-query'

import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { AxiosError } from 'axios'
import { getInfo } from 'services/quizService'
import { UUID } from 'domain/types'

const useQuizStartInfo = ( type: string, quizId?: string, options?: UseQueryOptions<QuizStartInfoResponse, AxiosError>): UseQueryResult<QuizStartInfoResponse> => {
  return useQueryWithSnackbar<QuizStartInfoResponse>(
    [ReactQueryKeys.QUIZ_INFO, type, quizId],
    () => getInfo(type, quizId),
    {
      ...options,
    })
}

export default useQuizStartInfo
