import { useHistory } from 'react-router-dom'

import { startQuizPractice } from 'services/quizService'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useStartQuizPractice = () => {
  const history = useHistory()

  return useMutationWithSnackbar(
    (data: PracticeQuizCreateRequest) => startQuizPractice(data),
    'startQuizPractice',
    undefined,
    {
      async onSuccess(quizAttempt) {
        history.push(`/quiz-attempts/${quizAttempt.quizAttemptId}`)
      },
    },
  )
}

export default useStartQuizPractice
