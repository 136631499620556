import { UseQueryResult } from 'react-query'

import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getTeachers } from 'services/community/teacherService'

const useTeachers = (classId: string): UseQueryResult<TeacherInterface[]> =>
  useQueryWithSnackbar<TeacherInterface[]>(
    [ReactQueryKeys.TEACHERS, classId],
    () => getTeachers(classId), {
      enabled: !!classId,
    },
  )

export default useTeachers
