import { useQueryClient } from 'react-query'

import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { markNotificationRead } from 'services/community/notificationService'
import ReactQueryKeys from '../../types/ReactQueryKeys'

const useMarkNotificationRead = () => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    (notificationId: string) => markNotificationRead(notificationId),
    undefined,
    undefined,
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.NOTIFICATIONS)
      },
    },
  )
}

export default useMarkNotificationRead
