import React, { FC } from 'react'
import { Box, Typography } from "@mui/material"
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/i18n'
import flag from 'images/flag.svg'
import whiteFlag from 'images/flag-white.svg'
import { isWeTest } from 'theme/constants'

type QuestionNumberProps = {
  number: number
  questionIndex?: number
  tagged?: boolean
  onToggleFlag?: (number: number) => void
}

const QuestionNumber: FC<QuestionNumberProps> = ({number, questionIndex, onToggleFlag, tagged}) => {
  const {t} = useTranslation()

  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", mr: "28px" }}>
      <Typography
        sx={{
          fontSize: { xs: "10px", md: "15px" },
          lineHeight: { xs: "12px", md: "18px" },
          textTransform: "uppercase",
          letterSpacing: "2px",
          opacity: "0.9",
        }}
        className="u-weight-600 u-color-sunglo"
      >
        {t(translations.quizAttemptPage.question)} {questionIndex}
      </Typography>
      {tagged ? (
        <Box sx={{ display: "flex" }} className="u-cursor-pointer">
          <img src={flag} className="u-pr-10" alt="" role="presentation" />
          <Typography
            component={"span"}
            sx={{ fontSize: { xs: "12px", md: "15px" }, lineHeight: "28px" }}
          >
            {t(translations.quizAttemptPage.flag)}
          </Typography>
        </Box>
      ) : (
        onToggleFlag && (
          <Box
            sx={{ display: "flex" }}
            className="u-cursor-pointer"
            onClick={() => onToggleFlag(number)}
          >
            <img
              src={isWeTest() ? whiteFlag : flag}
              className="u-pr-10"
              alt=""
              role="presentation"
            />
            <Typography
              component={"span"}
              sx={{ fontSize: { xs: "12px", md: "15px" }, lineHeight: "28px" }}
            >
              {t(translations.quizAttemptPage.flag)}
            </Typography>
          </Box>
        )
      )}
    </Box>
  );
}

export default QuestionNumber
