import { Client } from '@stomp/stompjs'
import SockJS from 'sockjs-client'
import config from 'utils/config'
import { TOKEN_KEY } from 'utils/constants'

const client: Client = new Client({
  webSocketFactory: () => (new SockJS(config.baseWsApiURI || '')),
  heartbeatIncoming: 0,
  heartbeatOutgoing: 20000,
  reconnectDelay: 500,
  connectHeaders: {
    Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY) || ''}`,
  },
  // debug: (msg: string): void => {
  //   if (!isProduction()) {
  //     console.log(new Date(), `${msg}`)
  //   }
  // },
})

const getWsClient = (): Client => client

export default getWsClient
