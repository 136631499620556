import { useQueryClient } from 'react-query'

import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { markAllNotificationsRead } from 'services/community/notificationService'
import ReactQueryKeys from '../../types/ReactQueryKeys'


const useMarkAllNotificationsRead = () => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    () => markAllNotificationsRead(),
    'markAllNotificationsRead',
    undefined,
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.NOTIFICATIONS)
      },
    },
  )
}

export default useMarkAllNotificationsRead
