import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Countdown from 'react-countdown'
import { Typography, Button, Box } from '@mui/material'

import { translations } from 'locales/i18n'
import constructImage from 'images/construct.svg'

type ComingSoonProps = {
  pageTitle: string
  releaseDate?: string
}

const renderer = ({ days, hours, minutes }) => {
  return <Typography className="u-weight-600" sx={{
    fontSize: { xs: '18px', md: '28px' },
    lineHeight: { xs: '25px', md: '28px' },
  }}>{days}d {hours}h {minutes}m</Typography>
}

const ComingSoon: FC<ComingSoonProps> = ({ pageTitle, releaseDate = '' }) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ height: 'calc(100vh - 60px)', p: '60px' }}>
      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        height: '100%',
        width: { xs: '100%', md: '65%' },
        margin: 'auto',
      }}>
        <Typography className="u-weight-bold" sx={{
          fontSize: { xs: '30px', md: '44px' },
          lineHeight: { xs: '34px', md: '53px' },
          mb: '10px',
          width: { md: '80%' },
          mt: { xs: '25px', md: '50px' },
        }}>{pageTitle}</Typography>
        <Typography sx={{
          fontSize: { xs: '16px', md: '18px' },
          lineHeight: { xs: '20px', md: '30px' },
          mb: 3,
          width: { xs: '100%', md: '65%' },
        }}>{t(translations.comingSoon.subtitle)}
          <br/>
          {releaseDate && (<Typography component="span">{t(translations.comingSoon.text)}</Typography>)}
        </Typography>
        {releaseDate && (<Box sx={{ mb: { xs: '15px', md: '35px' } }}>
          <Countdown date={new Date(`${releaseDate}`).getTime()} renderer={renderer}/>
        </Box>)
        }
        <img src={constructImage} alt="Building scene" style={{ width: 'inherit' }}/>
        <Link to="/" style={{ textDecoration: 'none' }}>
          <Button
            variant="contained"
            className="u-text-transform-none u-color-white"
            sx={{ marginTop: 6, fontSize: '16px', lineHeight: '19px', padding: '10px 13px', minWidth: '146px' }}
          >
            {t(translations.comingSoon.goHome)}
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

export default ComingSoon
