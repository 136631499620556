import i18n from 'i18next'

import deleteIcon from 'images/delete.svg'
import pencilIcon from 'images/pencil.svg'
import { translations } from 'locales/i18n'

type GetStreamMenuItems = {
  classId: string,
  streamId: string,
  onEdit?: () => void,
  onDelete?: () => void,
}

const getStreamMenuItems = ({
                              classId,
                              streamId,
                              onEdit,
                              onDelete,
                            }: GetStreamMenuItems) => (
  [
    {
      icon: pencilIcon,
      title: i18n.t(translations.streams.editStream),
      onClick: onEdit,
    } as MenuItem,
    {
      icon: deleteIcon,
      title: i18n.t(translations.streams.deleteStream),
      onClick: onDelete,
    } as MenuItem,
  ]
)

export default getStreamMenuItems
