import { Dispatch, SetStateAction } from 'react'

import { Posts } from 'types/Posts'
import { PostInterface } from 'types/PostInterface'

const getUpdatedPostsOnPostUnpinned = (
  posts: Posts | undefined,
  payload: PostInterface,
  setPosts?: Dispatch<SetStateAction<PostInterface[]>>,
) => {
  const filterPost = (post: PostInterface) => post.postId !== payload.postId

  if (setPosts) {
    setPosts((prevValue) => prevValue.filter(filterPost))
  }

  return {
    ...posts,
    content: posts?.content?.filter(filterPost),
  }
}

export default getUpdatedPostsOnPostUnpinned
