import React, { Dispatch, FC, SetStateAction, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { DesktopDatePicker, MobileDatePicker, ToggleButton } from '@mui/lab'
import { groupBy } from 'lodash'
import dayjs, { Dayjs } from 'dayjs'

import { translations } from 'locales/i18n'
import { StreamTitle } from 'components/community/ui/StyledComponent'
import { FULL_DATE_FORMAT } from 'utils/constants'
import StreamCard from './StreamCard'
import theme from 'theme'

const buttonStyles = {
  padding: '8px 20px',
  color: '#FFFFFF',
  borderRadius: '4px',
  fontSize: { xs: '14px', md: '15px' },
  lineHeight: { xs: '17px', md: '18px' },
  height: { xs: '33px', md: '35px' },
  border: 'none',
}

type StreamListProps = {
  classId: string
  streams: ClassStreamSearchView[]
  isLoading: boolean
  myStreamsOnly: boolean
  toggleMyStreamsOnly: () => void
  dateFilterOpen: boolean
  setDateFilterOpen: Dispatch<SetStateAction<boolean>>
  toggleDateFilterOpen: () => void
  onClearFilters: () => void
  date: Dayjs | null | undefined
  setDate: Dispatch<SetStateAction<Dayjs | null | undefined>>
  showMenu: boolean
  onDeleteStream: (data: DeleteStreamRequest) => void
  onEditStream: (id) => void
}

const StreamList: FC<StreamListProps> = ({
                                           classId,
                                           streams,
                                           isLoading,
                                           myStreamsOnly,
                                           toggleMyStreamsOnly,
                                           dateFilterOpen,
                                           setDateFilterOpen,
                                           toggleDateFilterOpen,
                                           onClearFilters,
                                           date,
                                           setDate,
                                           showMenu,
                                           onDeleteStream,
                                           onEditStream,
                                         }) => {
  const { t } = useTranslation()
  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

  const dateFilterInputRef = useRef(null)
  const streamsWithValidDate = streams.filter(({ date }) => date !== -1)
  const streamsGroupedByDate = groupBy(streamsWithValidDate, ({ date }) => dayjs(date).format(FULL_DATE_FORMAT))
  const filterDateProps = {
    clearable: true,
    value: date,
    onChange: setDate,
    onClose: () => setDateFilterOpen(false),
    open: dateFilterOpen,
    inputRef: dateFilterInputRef,
    renderInput: (params) => (
      <Button
        onClick={toggleDateFilterOpen}
        sx={{
          ...buttonStyles,
          backgroundColor: date ? '#E25F60 !important' : '#1F2021',
          minWidth: 'unset !important',
          width: { xs: '33px', md: '42px' },
          padding: '10px 0',
        }}
      >
        <input
          ref={params.inputRef}
          {...params.inputProps}
          style={{
            visibility: 'hidden',
            width: 0,
            padding: 0,
          }}
        />
        <img
          src="/images/icons/community/stream/calendar.svg"
          alt="calendar"
          style={{ marginLeft: '-4px' }}
        />
      </Button>
    ),
  }

  return (
    <Box sx={{ width: '100%', ml: { xs: 0, md: '46px' }, mt: { xs: '20px', md: 0 } }}>
      <Box sx={{ display: 'flex', justifyContent: { xs: 'flex-end', md: 'space-between' } }}>
        <StreamTitle sx={{ display: { xs: 'none', md: 'block' } }}>{t(translations.streams.gallery)}</StreamTitle>
        <Box sx={{ display: 'flex', mt: { md: '-10px' } }}>
          {showMenu && <ToggleButton
            value="myOnly"
            selected={myStreamsOnly}
            onClick={toggleMyStreamsOnly}
            sx={{
              ...buttonStyles,
              backgroundColor: myStreamsOnly ? '#E25F60 !important' : '#1F2021',
              mr: '10px',
              textTransform: 'none',
            }}
          >
            {t(translations.streams.myEvents)}
          </ToggleButton>}
          {isSm && (
            <DesktopDatePicker {...filterDateProps}/>
          )}
          {!isSm && (
            <MobileDatePicker {...filterDateProps}/>
          )}
          {isSm && (
            <ToggleButton
              value="myOnly"
              selected={myStreamsOnly}
              disabled={!myStreamsOnly && !date}
              onClick={onClearFilters}
              sx={{
                ...buttonStyles,
                backgroundColor: '#1F2021',
                ml: '10px',
                textTransform: 'none',
              }}
            >
              {t(translations.streams.clearFilters)}
            </ToggleButton>
          )}
        </Box>
      </Box>
      {isLoading && <CircularProgress/>}
      {Object.entries(streamsGroupedByDate).map(([date, streamsPartition], index) => (
        <Box key={index}>
          <Typography
            sx={{
              mt: { xs: '20px', md: '28px' },
              mb: { xs: '10px', md: '15px' },
              fontSize: { xs: '16px', md: '20px' },
              lineHeight: { xs: '19px', md: '24px' },
              opacity: '60%',
            }}
          >
            {date}
          </Typography>
          <Grid container spacing={{ xs: 1.5, md: 2 }} sx={{ mb: { xs: '30px', md: '40px' } }}>
            {
              streamsPartition.map(stream => (
                <Grid item xs={12} sm={6} xl={4} key={stream.id}>
                  <StreamCard
                    classId={classId}
                    stream={stream}
                    showMenu={showMenu}
                    onEdit={() => onEditStream(stream.id)}
                    onDelete={() => onDeleteStream({ classId, streamId: stream.id } as DeleteStreamRequest)}
                  />
                </Grid>
              ))

            }
          </Grid>
        </Box>
      ))}
    </Box>
  )
}

export default StreamList
