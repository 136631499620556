import { useQueryClient } from 'react-query'

import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { updateNotificationSettings } from 'services/community/notificationService'
import ReactQueryKeys from '../../types/ReactQueryKeys'

const useUpdateNotificationSettings = () => {
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(
    updateNotificationSettings,
    undefined,
    undefined,
    {
      async onSuccess() {
        await queryClient.invalidateQueries(ReactQueryKeys.NOTIFICATION_SETTING)
      },
    },
  )
}

export default useUpdateNotificationSettings
