import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography } from '@mui/material'
import { Formik } from 'formik'

import { translations } from 'locales/i18n'
import FormInput from '../../ui/form/form-input/form-input'
import ProfileNavigation from '../ProfileNavigation'
import { UpdateButton } from '../buttons'
import LoginInformationFormSchema from './LoginInformationValidatorSchema'
import { Link } from 'react-router-dom'
import { StudentProfileInterface } from 'types/StudentProfileInterface'

type ProfileLoginInformationProps = {
  currentUser: StudentProfileInterface
  onSubmit: (data: ChangePasswordRequest) => void
}

const ProfileLoginInformation: FC<ProfileLoginInformationProps> = ({currentUser, onSubmit}) => {
  const {t} = useTranslation()

  return (
    <Box
      sx={{
        ml: {xs: '20px', md: '56px'},
        mr: {xs: '20px', md: '0'},
        mt: {xs: '32px', md: '42px'},
        mb: '20px',
      }}
    >
      <Box>
        <Typography variant={"h4"} sx={{
          fontSize: {xs: '18px', md: '34px'},
          lineHeight: {xs: '20px', md: '41px'},
          fontWeight: {xs: '600', md: 'normal'},
          textTransform: 'none',
        }}>
          {t(translations.profilePage.title)}
        </Typography>
      </Box>
      <Grid container sx={{mt: {xs: '21px', md: '44px'}}}>
        <Grid item xs={12} md={3} sx={{mb: {xs: 2}}}>
          <ProfileNavigation/>
        </Grid>
        <Grid item xs={12} md={9} sx={{width: '100%'}}>
          <Formik
            validateOnBlur={true}
            validateOnChange={true}
            initialValues={{
              userId: currentUser.id,
              currentPassword: '',
              newPassword: '',
              passwordConfirm: '',
            }}
            validationSchema={LoginInformationFormSchema}
            onSubmit={onSubmit}
          >
            {({handleSubmit, isValid}) => (
              <form
                style={{display: "flex", flexDirection: "column"}}
                onSubmit={handleSubmit}
              >
                <Box sx={{justifyContent: {xs: 'center'}}}>
                  <Box sx={{
                    width: {md: "501px", lg: "601px"},
                    background: theme => theme.palette.background.paper,
                    borderRadius: "3px",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: {xs: 'center'},
                    m: {xs: 'auto', md: 'unset'},
                  }}>
                    <Box sx={{
                      width: '100%',
                      pl: {xs: '20px', md: '50px'},
                      pr: {xs: '20px', md: '50px'},
                    }}>
                      <Typography
                        sx={{
                          display: "flex",
                          mt: {xs: '20px', md: '30px'},
                          fontSize: {xs: '18px', sm: '24px'},
                          lineHeight: {xs: '20px', sm: '29px'},
                          fontWeight: {xs: '600', sm: 'normal'},
                        }}>
                        {t(translations.profilePage.loginInformation)}
                      </Typography>
                    </Box>
                    <Box sx={{
                      width: "100%",
                      mt: {xs: "10px", md: "20px"},
                      mb: {xs: '22px', md: '42px'},
                      pl: {xs: '20px', md: '50px'},
                      pr: {xs: '20px', md: '50px'},
                    }}>
                      <FormInput
                        label={t(translations.loginInformationPage.accountEmail)}
                        name="accountEmail"
                        variant="standard"
                        value={currentUser.profile.email}
                        disabled={true}
                      />
                      <FormInput
                        type="password"
                        label={t(translations.loginInformationPage.currentPassword)}
                        name="currentPassword"
                        variant="standard"
                        disableAutocomplete={true}
                      />
                      <Box
                        sx={{
                          mt: '10px',
                          fontSize: {xs: '12px', md: '16px'},
                          lineHeight: {xs: '14px', md: '19px'},
                        }}
                      >
                        <Link to="/forgot-password">
                          {t(translations.loginPage.forgotPassword)}
                        </Link>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: {xs: '18px', sm: '24px'},
                          lineHeight: {xs: '20px', sm: '29px'},
                          fontWeight: {xs: '600', sm: 'normal'},
                          pt: 5,
                        }}>
                        Change password
                      </Typography>
                      <Box>
                        <FormInput
                          type="password"
                          label={t(translations.loginInformationPage.addYourNewPassword)}
                          placeholder="Enter"
                          name="newPassword"
                          variant="standard"
                        />
                        <FormInput
                          type="password"
                          label={t(translations.loginInformationPage.repeatYourNewPassword)}
                          name="passwordConfirm"
                          variant="standard"
                        />
                      </Box>
                      <Box sx={{
                        display: "flex",
                        mt: {xs: "27px", md: "47px"},
                        fontWeight: 'bold',
                      }}>
                        <UpdateButton
                          type="submit"
                          variant={"contained"}
                          size="large"
                          disabled={!isValid}
                          sx={{
                            py: "15px",
                            px: {xs: "25px", md: "30px"},
                            fontSize: {xs: '12px', sm: '16px'},
                            lineHeight: {xs: '14px', sm: '19px'},
                            width: {xs: '174px', md: '214px'},
                            minHeight: {xs: '44px', md: '50px'},
                            borderRadius: '3px',
                            boxShadow: 'none',
                          }}
                        >
                          {t(translations.profilePage.saveChanges)}
                        </UpdateButton>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </Box>
  )
}

export default ProfileLoginInformation
