import React, { FC } from 'react'
import { isEmpty } from 'lodash'

import { useTranslation } from 'react-i18next'
import { translations } from 'locales/i18n'

import { PostInterface } from 'types/PostInterface'
import { StudentProfileInterface } from 'types/StudentProfileInterface'

import { List, ListItem, Box } from '@mui/material'
import { CommunityTitle } from '../ui/StyledComponent'
import PopularPollCard from './PopularPollCard'

type PopularPollsListProps = {
  currentUser: StudentProfileInterface
  recentPosts: PostInterface[]
  onFullscreenPost: (post: PostInterface) => void
  onEditPopularPoll: (post: PostInterface) => void
  onDeletePopularPoll: (post: PostInterface) => void
  onReportPopularPoll: (post: PostInterface) => void
  showMenu: boolean
}

const PopularPollsList: FC<PopularPollsListProps> = ({
                                                       currentUser,
                                                       recentPosts,
                                                       onFullscreenPost,
                                                       onEditPopularPoll,
                                                       onDeletePopularPoll,
                                                       onReportPopularPoll,
                                                       showMenu
                                                     }) => {
  const { t } = useTranslation()

  return (
    <Box sx={{mb: '50px', width: {xs: '250px', lg: '310px'}}}>
      <List component="ul">
        <CommunityTitle className="u-mb-15">{t(translations.community.feed.popularPolls)}</CommunityTitle>
        {!isEmpty(recentPosts) && recentPosts.map(post => (
          <ListItem
            key={post.postId}
            className="Navigation u-d-block"
            sx={{
              pl: '22px',
              pt: '15px',
              mb: '16px',
              background: theme => theme.palette.background.paper,
            }}
          >
            <PopularPollCard
              currentUser={currentUser}
              post={post}
              onFullscreenPost={onFullscreenPost}
              onEditPopularPoll={onEditPopularPoll}
              onDeletePopularPoll={onDeletePopularPoll}
              onReportPopularPoll={onReportPopularPoll}
              showMenu={showMenu}
            />
          </ListItem>
        ))}
      </List>
      {/* TODO ADD when we need show more polls */}
      {/* <Grid item xs="auto" md="auto">
        <Link to="#" onClick={() => goToTop()}>
          <Button variant="text" className="u-text-transform-none">View All</Button>
        </Link>
      </Grid> */}
    </Box>
  )
}

export default PopularPollsList
