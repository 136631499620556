import { forgotPassword } from 'services/userService'
import { translations } from 'locales/i18n'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useForgotPassword = () => useMutationWithSnackbar(
  (data: ForgotPasswordRequest) => forgotPassword(data),
  undefined,
  translations.messages.checkYourEmail,
)

export default useForgotPassword
