import { PostCommentInterface } from 'types/PostCommentInterface'
import { WsMessagePayloadPostCommentUpdated } from 'types/ws/WsMessagePayloadPostCommentUpdated'

const getUpdatedCommentsOnPostCommentUpdated = (postComments: PostCommentInterface[] | undefined, payload: WsMessagePayloadPostCommentUpdated) => {
  const {parentCommentId, comment} = payload
  const comments = postComments || []

  return parentCommentId
    ? comments.map((ps) => ps.id === parentCommentId ? {
      ...ps,
      replies: ps.replies.map((c) => c.id === comment.id ? comment : c),
    } : ps)
    : comments.map((c) => c.id === comment.id ? comment : c)
}

export default getUpdatedCommentsOnPostCommentUpdated
