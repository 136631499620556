import React, { FC } from 'react'
import { Grid, styled } from '@mui/material'

import NavBar from './NavBar'
import TopBar from './TopBar'
import useMe from 'hooks/useMe'
import { Subscription } from 'types/Subscription'

const Wrapper = styled('div')({
  overflow: 'hidden',
  display: 'flex',
})

type DashboardLayoutProps = {
  additionalSubscriptions?: Subscription[]
}

const DashboardLayout: FC<DashboardLayoutProps> = ({additionalSubscriptions, children}) => {
  const [navCollapsed, setNavCollapsed] = React.useState(true)

  const meQuery = useMe()
  const me = meQuery.data

  const toggleNav = () => {
    setNavCollapsed(!navCollapsed)
  }

  return (
    <Wrapper style={{border: "1px", height: '100%', marginTop: '50px'}}>
      <NavBar toggleNav={toggleNav} navCollapsed={navCollapsed} currentUser={me}/>
      <TopBar toggleNav={toggleNav} navCollapsed={navCollapsed} currentUser={me} additionalSubscriptions={additionalSubscriptions}/>
      <Grid
        component="main"
        sx={{flexGrow: 1, p: 0, height: '100%', overflowX: 'auto', overflowY: 'hidden'}}
      >
        {children}
      </Grid>
    </Wrapper>
  )
}

export default DashboardLayout
