import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography, Grid } from '@mui/material'

import getQuizTypeTitle from 'components/shared/getQuizTypeTitle'
import examMode from 'images/exam-mode.svg'
import arrowRightMobile from 'images/arrow-right-mobile.svg'
import arrowLeftMobile from 'images/arrow-left-mobile.svg'
import { translations } from 'locales/i18n'
import { QuizType } from 'types/QuizType'
import i18next from 'i18next'

interface QuizStartConfirmationProps {
  groupName?: string
  quizType: QuizType
  handleStartQuiz: () => void
  questionsCount: number
  maxSimulationMinutesAllowed: number
  dividedBySections?: boolean | undefined,

}

const QuizStartConfirmation: FC<QuizStartConfirmationProps> = ({
                                                                 quizType,
                                                                 handleStartQuiz,
                                                                 questionsCount,
                                                                 dividedBySections,
                                                                 maxSimulationMinutesAllowed,
                                                                 groupName
                                                               }) => {
  const { t } = useTranslation()
  const defaultLang = localStorage.getItem('i18nextLng')
  const isEnglish = () => i18next.language === 'en' ||  defaultLang === 'en' || defaultLang === 'en-US' || defaultLang === 'en-GB'
  const simulationMinutes = (min: number) => min % 60 < 10 ? '0' : ''
  // TODO incorrect seconds are shown on staging
  const maxTimeFormatted = maxSimulationMinutesAllowed > 60
    ? `${Math.floor(maxSimulationMinutesAllowed / 60)}:${simulationMinutes(maxSimulationMinutesAllowed)}${maxSimulationMinutesAllowed % 60}:00`
    : `${Math.floor(maxSimulationMinutesAllowed)}:00`
  return (
    <Box sx={{ mt: { xs: 12, sm: 15 } }}>
      <Box display="flex" justifyContent="center">
        <Box>
          <Box
            sx={{
              fontSize: { xs: '18px', md: '34px' },
              lineHeight: { xs: '23px', md: '41px' },
              mb: { xs: '7px', md: '22px' },
              fontWeight: { xs: 'bold', md: 'normal' },
              textAlign: 'center',
            }}
          >
            {getQuizTypeTitle(quizType,'',groupName)}
          </Box>
          <Box display="flex" justifyContent="center" alignItems="center" sx={{ mb: { xs: '24px', md: '44px' } }}>
            <img
              src={examMode}
              className="u-pr-15"
              alt=""
              role="presentation"
            />
            <Typography sx={{ fontSize: { sx: '12px', md: '18px' }, lineHeight: { sx: '14px', md: '22px' } }}
                        className="u-color-pale-sky">{t(translations.quizStartConfirmation.examMode)}</Typography>
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box sx={{
          width: { xs: '100%', sm: '80%', md: '601px' },
          background: theme => theme.palette.background.paper,
          borderRadius: '3px',
          border: '1px solid #363B3F',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          px: { xs: '20px', md: '78px' },
          py: { xs: '28px', md: '46px' },
          mx: { xs: '20px', md: 0 },
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: { xs: '21px', md: '23px' },
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src="/images/icons/correctAnswerMonochromatic.svg" alt="correctAnswerMonochromatic"
                   style={{ width: '12px', height: '12px' }}/>
              <Typography sx={{
                fontSize: { xs: '12px', md: '16px' },
                lineHeight: { xs: '14px', md: '19px' },
                mr: { xs: '28px', md: '55px' },
                ml: '10px',
              }}>{questionsCount}</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <img src="/images/icons/timer.svg" alt="timer" style={{ width: '12px', height: '16px' }}/>
              <Typography
                sx={{
                  fontSize: { xs: '12px', md: '16px' },
                  lineHeight: { xs: '14px', md: '19px' },
                  ml: '10px',
                }}
              >
                {maxTimeFormatted}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center' }}>
            <Box className="u-weight-bold" sx={{
              fontSize: { xs: '14px', md: '24px' },
              lineHeight: { xs: '22px', md: '34px' },
              mb: { xs: '10px', md: '17px' },
            }}>
              {dividedBySections ?
                t(translations.quizStartConfirmation.youHaveToAnswerQuestionsV2, { value: maxSimulationMinutesAllowed })
                :
                t(translations.quizStartConfirmation.youHaveToAnswerQuestions, { value: isEnglish() ? questionsCount : Math.floor(maxSimulationMinutesAllowed) })
              }
              <br/>
              {dividedBySections ?
                t(translations.quizStartConfirmation.youHaveTimeV2, { value: questionsCount })
                :
                t(translations.quizStartConfirmation.youHaveTime, { value: isEnglish() ? Math.floor(maxSimulationMinutesAllowed) : questionsCount })
              }
            </Box>
            <Box sx={{
              fontSize: { xs: '12px', md: '16px' },
              lineHeight: { xs: '21px', md: '22px' },
              mb: { xs: '28px', md: '37px' },
            }}>
              {quizType !== QuizType.RANDOM_SIMULATION && t(translations.quizStartConfirmation.aboutStatistics)}
            </Box>
          </Box>
          <Grid container spacing={'10px'}>
            <Grid item xs={6}>
              <Link to="/quizzes">
                <Button
                  variant="contained"
                  type="button"
                  color="secondary"
                  sx={{
                    textTransform: 'none',
                    minHeight: { xs: '40px', md: '50px' },
                    borderRadius: '3px',
                    boxShadow: 'none',
                  }}
                  className="u-w-100"
                >
                  <Box sx={{ display: { xs: 'none', md: 'inline' } }}>
                    <img
                      src="/images/icons/arrowLeft.svg"
                      alt="arrow left"
                      style={{ width: '12px', height: '12px' }}
                    />
                  </Box>
                  <Box sx={{ display: { xs: 'inline', md: 'none' }, pt: '3px' }}>
                    <img
                      src={arrowLeftMobile}
                      alt="arrow left"
                      style={{ width: '12px', height: '12px' }}
                    />
                  </Box>
                  <Typography component={'span'} sx={{
                    fontSize: { xs: '12px', md: '16px' },
                    lineHeight: { xs: '15px', md: '19px' },
                    fontWeight: 'bold',
                    pl: { xs: '15px', md: '20px' },
                  }}>
                    {t(translations.quizStartConfirmation.anotherTest)}
                  </Typography>
                </Button>
              </Link>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                sx={{
                  textTransform: 'none',
                  minHeight: { xs: '40px', md: '50px' },
                  borderRadius: '3px',
                  boxShadow: 'none',
                }}
                className="u-fs-16 u-lh-19 u-w-100"
                onClick={handleStartQuiz}
              >
                <Typography component={'span'} sx={{
                  fontSize: { xs: '12px', md: '16px' },
                  lineHeight: { xs: '15px', md: '19px' },
                  fontWeight: 'bold',
                  pr: { xs: '15px', md: '20px' },
                }}>
                  {t(translations.quizStartConfirmation.startQuiz)}
                </Typography>
                <Box sx={{ display: { xs: 'none', md: 'inline' } }}>
                  <img
                    src="/images/icons/arrowRight.svg"
                    alt="arrow right"
                    style={{ width: '12px', height: '12px' }}
                  />
                </Box>
                <Box sx={{ display: { xs: 'inline', md: 'none' }, pt: '3px' }}>
                  <img
                    src={arrowRightMobile}
                    alt="arrow right"
                    style={{ width: '12px', height: '12px' }}
                  />
                </Box>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default QuizStartConfirmation
