import React from 'react'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { List } from '@mui/material'

import PollVariant from './PollVariant'

type SortableElementProps = {
  value: PollOption
  listLenght: number
  index: any
  handleRemoveOption: (fileId: string) => void
  handleUpdateOptionText: (variantId: string, newText: string) => void
}

type SortableListProps = {
  items: PollOption[]
  handleRemoveOption: (fileId: string) => void
  handleUpdateOptionText: (variantId: string, newText: string) => void
}

const SortableItem = SortableElement(({
                                        value,
                                        listLenght,
                                        index,
                                        handleRemoveOption,
                                        handleUpdateOptionText
                                      }: SortableElementProps) =>
  <PollVariant
    key={value.id}
    id={value.id}
    text={value.optionText}
    voteCount={value.voteCount}
    divider={index !== listLenght - 1}
    onRemoveOption={() => handleRemoveOption(value.id)}
    onUpdateText={(e) => handleUpdateOptionText(value.id, e.target.value)}
  />)

export const SortableList = SortableContainer(({
                                                 items,
                                                 handleRemoveOption,
                                                 handleUpdateOptionText
                                               }: SortableListProps) => {
  return (
    <List
      component="div"
      sx={{
        width: '100%',
        py: 0,
      }}>
      {items.filter(({deleted}) => !deleted)
        .map((value: PollOption, index) => (
          <SortableItem
            key={value.id}
            value={{...value}}
            listLenght={items.length}
            index={index}
            handleRemoveOption={handleRemoveOption}
            handleUpdateOptionText={handleUpdateOptionText}/>
        ))}
    </List>
  )
})
