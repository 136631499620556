import {UseQueryOptions, UseQueryResult} from 'react-query'
import {AxiosError} from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import {getRankingSummary} from 'services/rankingService'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import {RankingSummaryInterface} from 'types/RankingSummaryInterface'

const useRankingSummary = (options?: UseQueryOptions<RankingSummaryInterface, AxiosError>): UseQueryResult<RankingSummaryInterface> => {
    return useQueryWithSnackbar<RankingSummaryInterface>(
        [ReactQueryKeys.RANKING_SUMMARY],
        () => getRankingSummary(),
        {
            ...options,
        },
    )
}

export default useRankingSummary
