import React, { FC } from 'react'
import { Box, Button, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/i18n'
import timeRemaining from 'images/time-remaining.svg'
import timeRemainingMobile from 'images/time-remaining-mobile.svg'
import { getDurationMilliFormatted } from 'utils/duration'
import { QuizType } from 'types/QuizType'

type FinishQuizProps = {
  questionsAmount: number
  answeredQuestionsAmount: number
  showRemainingTime: boolean
  remainingTime: number
  buttonText: string
  timerStyle: string
  onFinishQuiz: (e: React.FormEvent<HTMLButtonElement>) => void
  quizType: QuizType
}

const FinishQuiz: FC<FinishQuizProps> = ({
                                           questionsAmount,
                                           answeredQuestionsAmount,
                                           onFinishQuiz,
                                           buttonText,
                                           showRemainingTime,
                                           remainingTime,
                                           timerStyle,
                                           quizType
                                         }) => {
  const {t} = useTranslation()
  const isShowTimer = quizType !== QuizType.LESSON &&  quizType !== QuizType.PRACTICE

  return (
    <Box
      sx={{
        display: "flex",
        height: "96px",
        width: '100%',
        alignItems: "center",
        backgroundColor: theme => theme.palette.background.default,
        boxShadow: '0px 39px 34px rgba(0, 0, 0, 0.45)',
      }}
    >
      {isShowTimer && <Box
        display="flex"
        justifyContent="start"
        alignItems="flex-end"
        component={"span"}
      >
        <Box sx={{display: {xs: 'none', md: 'flex'}}}>
        <img
          src={timeRemaining}
          className="u-pr-10"
          alt=""
          role="presentation"
        />
        </Box>
        <Box sx={{display: {xs: 'flex', md: 'none'}}}>
          <img
            src={timeRemainingMobile}
            className="u-pr-10"
            alt=""
            role="presentation"
          />
        </Box>
        <Box sx={{
          fontSize: {xs: '18px', md: '28px'},
          lineHeight: {xs: '18px', md: '28px'},
          fontWeight: '600',
          pr: '10px',
          color: timerStyle
        }}>
          {showRemainingTime ? getDurationMilliFormatted(remainingTime) : getDurationMilliFormatted(0)}
        </Box>
        <Box sx={{
          display: {xs: 'none', sm: 'block'},
          fontSize: {xs: '8px', md: '12px'},
          lineHeight: {xs: '10px', md: '14px'},
          letterSpacing: '2px',
          opacity: '60%',
          maxWidth: '130px',
        }}>
          {t(translations.quizAttemptPage.quizTimeRemaining)}
        </Box>
      </Box>}
      <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems='center'>
        <Box
          sx={{
            mr: "10px",
            fontSize: {xs: '12px', md: "28px"},
          }}
          component={"span"}
        >
          <span className="u-weight-600">{answeredQuestionsAmount}/</span>
          <span className="u-color-pale-sky">{questionsAmount}</span>
        </Box>
        <Box
          sx={{
            display: {xs: 'none', md: 'block'},
            mr: "48px", mb: '8px',
          }} display="flex" alignItems="flex-end">
          <Box>
            <Typography sx={{
              fontSize: "12px",
              letterSpacing: "2px",
              color: theme => theme.palette.grey["500"],
            }}>
              {t(translations.quizAttemptPage.questions)}
            </Typography>
            <Box
              sx={{
                width: "163px", height: "8px",
                background: `linear-gradient(90deg, #5EA66E ${(answeredQuestionsAmount / questionsAmount) * 100}%, white 0)`,
                borderRadius: "6px",
              }}
            >
            </Box>
          </Box>
        </Box>
        <Button
          variant={"contained"}
          sx={{textTransform: "none", width: {md: '146px'}, padding: '6px 8px'}}
          onClick={onFinishQuiz}
          className="Button Button_low u-br-3"
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  )
}

export default FinishQuiz
