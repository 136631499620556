import React, { FC, useState } from "react";
import { Box } from "@mui/material";

import FixedBottomModal from "../modal/FixedBottomModal";
import selectedMenuIcon from "images/selected-menu.svg";
import deleteIcon from "images/delete.svg";

type FixedBottomMenuItemProps = {
  menuItem: MenuItem;
};

const FixedBottomMenuItem: FC<FixedBottomMenuItemProps> = ({ menuItem }) => {
  const [nestedOpen, setNestedOpen] = useState(false);

  const { icon, title, nestedItems, onClick, selected } = menuItem;

  const handleCheckIcon = (additionStyle: string, currentStyle: string) =>
    icon === deleteIcon ? additionStyle : currentStyle;

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    if (nestedItems) {
      setNestedOpen(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mt: "20px",
          cursor: "pointer",
        }}
        onClick={handleClick}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon && (
            <img
              src={icon}
              className={handleCheckIcon(
                "Navigation-deletedIcon u-pr-15",
                "u-pr-15"
              )}
              alt={title}
            />
          )}
          <span
            className={handleCheckIcon(
              "Navigation-text deleted u-fs-14 u-lh-17",
              "u-fs-14 u-lh-17"
            )}
          >
            {title}
          </span>
          {selected && (
            <img src={selectedMenuIcon} className="u-pl-15" alt="Selected" />
          )}
        </Box>
        <Box>{nestedItems?.find(({ selected }) => selected)?.title}</Box>
      </Box>
      {nestedItems && (
        <FixedBottomModal
          open={nestedOpen}
          onClose={() => setNestedOpen(false)}
        >
          <Box>{title}</Box>
          {nestedItems.map((nestedItem, index) => (
            <FixedBottomMenuItem
              menuItem={nestedItem}
              key={`${nestedItem.title}-${index}`}
            />
          ))}
        </FixedBottomModal>
      )}
    </>
  );
};

export default FixedBottomMenuItem;
