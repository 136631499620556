import React from 'react'
import { RouteComponentProps } from 'react-router'

import { Back } from 'components/shared/back'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import QuizStartConfirmation from 'components/quizzes/QuizStartConfirmation'
import { QuizType } from 'types/QuizType'
import useQuizStartInfo from 'hooks/useQuizStartInfo'
import useStartPackSimulationQuiz from 'hooks/useStartPackSimulationQuiz'


function QuizStartPackSimulationPage(
  props: RouteComponentProps<{ packSimulationId: string, quizId: string }>,
) {
  const quizId = props.match.params.quizId || ''
  const packSimulationId = props.match.params.packSimulationId || ''
  const type = QuizType.SIMULATION
  const startPackSimulationQuiz = useStartPackSimulationQuiz()
  const quizStartInfo = useQuizStartInfo(type, quizId).data
  const handleStartQuiz = () => startPackSimulationQuiz.mutate({packSimulationId, quizId})

  return (
    <DashboardLayout>
      <Back text="QUIZZES" url={'/quizzes'}/>
      {quizStartInfo && <QuizStartConfirmation
        dividedBySections={quizStartInfo?.dividedBySections}
        quizType={type}
        handleStartQuiz={handleStartQuiz}
        questionsCount={quizStartInfo?.questionAmount}
        maxSimulationMinutesAllowed={quizStartInfo?.timeLimit / 60}
      />}
    </DashboardLayout>
  )
}

export default QuizStartPackSimulationPage
