import { useQueryClient } from 'react-query'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'

import { logOutUser } from 'services/userService'
import { logOutCdn } from 'services/cdnAuthService'
import ReactQueryKeys from 'types/ReactQueryKeys'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { translations } from 'locales/i18n'
import { TOKEN_KEY } from 'utils/constants'

const useLogOut = () => {
  const history = useHistory()
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar(async () => {
      localStorage.removeItem(TOKEN_KEY)
      await logOutUser()
      await logOutCdn()
    },
    undefined,
    undefined,
    {
      async onSuccess() {
        enqueueSnackbar(
          t(translations.messages.logOutSuccess),
          { variant: 'success' },
        )
        queryClient.setQueryData(ReactQueryKeys.ME, null)
        await history.push('/login')
      },
    },
  )
}

export default useLogOut
