import React, { FC } from 'react'
import { Box, Button, Grid, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/i18n'

type DifficultyLevelCardProps = {
  id: number,
  name: string,
  description: string,
  img: string,
  selectedDifficulty?: number,
  setDifficulty: (id: number) => void
}

const DifficultyLevelCard: FC<DifficultyLevelCardProps> = ({
                                                             id,
                                                             name,
                                                             description,
                                                             img,
                                                             selectedDifficulty,
                                                             setDifficulty,
                                                           }) => {

  const {t} = useTranslation()
  const theme = useTheme()

  return (
    <Box>
      <Box
        className="u-br-3 u-cursor-pointer"
        sx={{
          display: {xs: 'none', lg: 'flex'},
          flexDirection: 'column',
          alignItems: 'center',
          background: selectedDifficulty === id ? 'linear-gradient(180deg, #58B5F8 0%, #8B58F8 100%)' : '#2A2B2C',
          flexWrap: 'nowrap',
          height: '353px',
          padding: '16px',
        }}
        onClick={() => setDifficulty(id)}
      >
        <Box sx={{height: '134px'}}>
          <Box sx={{textAlign: 'center'}} className="u-fs-18 u-lh-34 u-weight-bold">
            {name}
          </Box>
          <Box
            sx={{textAlign: 'center'}}
            className={`u-mt-15 u-fs-14 u-lh-20 ${selectedDifficulty !== id && 'u-color-silver'}`}
          >
            {description}
          </Box>
        </Box>
        <Grid container display="flex" justifyContent="center" flexGrow={1} alignSelf="flex-end" alignItems="flex-end">
          <Grid sx={{mt: 6}}>
            <img src={img} alt="difficultyLevel"/>
          </Grid>
          <Grid sx={{mt: 4}}>
            <Button
              variant={'outlined'}
              className="Button Button_low Button_outlined"
              sx={{textTransform: 'none', color: theme => theme.palette.text['primary']}}
            >
              {t(translations.subjectPractiseInitPage.chooseLevel)}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          display: {xs: 'block', lg: 'none'},
          background: selectedDifficulty === id ? 'linear-gradient(180deg, #58B5F8 0%, #8B58F8 100%)' : '#2A2B2C',
          padding: '16px',
        }}
        onClick={() => setDifficulty(id)}
      >
        <Grid container>
          <Grid item xs={8}>
            <Box className="u-fs-14 u-lh-34 u-weight-bold">
              {name}
            </Box>
            <Box
              className={`u-mt-15 u-fs-12 u-lh-17 ${selectedDifficulty !== id && 'u-color-silver'}`}
            >
              {description}
            </Box>
          </Grid>
          <Grid item xs={4} sx={{display: 'flex', alignItems: 'center', justifyContent: 'flex-end'}}>
            <img src={img} alt="difficultyLevel"/>
          </Grid>
        </Grid>
        <Button
          variant={'outlined'}
          className="Button Button_low Button_outlined u-w-100"
          sx={{mt: '23px', textTransform: 'none', color: theme => theme.palette.text['primary'], fontSize: '12px !important'}}
        >
          {t(translations.subjectPractiseInitPage.chooseLevel)}
        </Button>
      </Box>
    </Box>
  )
}

export default DifficultyLevelCard
