import { Dispatch, SetStateAction } from 'react'

import { Posts } from 'types/Posts'
import { PostInterface } from 'types/PostInterface'

const getUpdatedPostsOnPostAdded = (
  posts: Posts | undefined,
  payload: PostInterface,
  setPosts?: Dispatch<SetStateAction<PostInterface[]>>
) => {
  if (setPosts) {
    setPosts((prevValue) => [payload, ...prevValue])
  }

  return {
    ...posts,
    content: [payload, ...(posts?.content || [])],
  }
}

export default getUpdatedPostsOnPostAdded
