import React, { ChangeEvent, FC, memo } from 'react'
import { Field, FieldProps } from 'formik'
import { FormikHelpers } from 'formik/dist/types'
import { Checkbox, FormControlLabel, FormControlLabelProps, FormGroup, styled } from '@mui/material'
import { CheckboxProps } from '@mui/material/Checkbox/Checkbox'
import cn from 'classnames'

import styles from './form-checkbox-mobile.module.scss'
import ErrorMessage from '../error-message/error-message'

type FormCheckboxProps = CheckboxProps & {
  name: string
  label?: string
  hidden?: boolean
  onChange?: (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void
  inputClassName?: string
  formControlLabelProps?: Partial<FormControlLabelProps>
}

const checkmarkSize = 19

const Icon = styled('span')(() => ({
  borderRadius: 4,
  width: checkmarkSize,
  height: checkmarkSize,
  backgroundColor: '#FFFFFF',
  '.Mui-focusVisible &': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: '#646464',
  },
}))

const CheckedIcon = styled(Icon)({
  backgroundColor: '#4780D8',
  backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: checkmarkSize,
    height: checkmarkSize,
    backgroundImage: "url(/images/icons/checkMarkSimple.svg)",
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#1E88E5',
  },
})

const FormCheckbox: FC<FormCheckboxProps> = ({
                                               name,
                                               label = '',
                                               hidden = false,
                                               onChange,
                                               inputClassName,
                                               formControlLabelProps,
                                               ...restProps
                                             }) => {
  const handlerChange = (
    e: ChangeEvent<HTMLInputElement>,
    setFieldValue: FormikHelpers<unknown>['setFieldValue'],
  ) => {
    if (onChange) {
      onChange(e)
    }

    setFieldValue(name, e.currentTarget.checked)
  }

  return (
    <Field
      name={name}
      id={name}>
      {({form: {setFieldValue, setFieldTouched, values, initialValues}}: FieldProps) => {
        return (
          <div className={cn(styles.formCheckbox, hidden && styles.hidden)}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    id={name}
                    onBlur={() => setFieldTouched(name, true)}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handlerChange(e, setFieldValue)}
                    checked={initialValues[name] || values[name]}
                    className={inputClassName}
                    checkedIcon={<CheckedIcon/>}
                    icon={<Icon/>}
                    {...restProps}
                  />
                }
                label={label}
                sx={{
                  ml: '-2px',
                  '& .MuiTypography-root': {
                    ml: '10px',
                    fontSize: {xs: '12px', md: '16px'},
                    lineHeight: {xs: '14px', md: '19px'},
                    '&.Mui-disabled': {
                      color: '#B2B5BD',
                    }
                  },
                }}
                {...formControlLabelProps}
              />
              <ErrorMessage name={name}/>
            </FormGroup>
          </div>
        )
      }}
    </Field>
  )
}

export default memo(FormCheckbox)
