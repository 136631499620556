import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Typography, useMediaQuery } from '@mui/material'

import { CommunitySelection } from './CommunitySelection'
import { TeacherClass } from './TeacherClass'
import { translations } from 'locales/i18n'
import SwipeableViews from 'components/shared/swipeable-views/SwipeableViews'
import theme from 'theme'

type ClassesStudentPropsType = {
  userClasses: ClassesValueInterface[]
}

export const ClassesTeacher: FC<ClassesStudentPropsType> = ({ userClasses }) => {
  const { t } = useTranslation()

  const isSm = useMediaQuery(theme.breakpoints.up('sm'))

  const notPublicClasses = userClasses.filter((el) => !el.public)
  return (

    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          px: { xs: '20px', sm: '50px', lg: '200px' },
        }}
      >
        <Box
          sx={{
            marginTop: { xs: '20px', md: '40px' },
            marginBottom: { xs: '20px', md: '40px' },
            width: '100%',
          }}
        >
          {userClasses && userClasses.filter((el) => el.public).map((el, index) =>
            <CommunitySelection
              key={index}
              className={t(translations.community.scuolaOnline)}
              userClass={el}
              backgroundImage={`url(/images/community/richard-horvath-big.png)`}
            />)}
          <Typography
            sx={{
              marginTop: '40px',
              marginBottom: '20px',
            }}
          >
            {t(translations.community.masterclassCommunities)}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}>
            <Grid container direction={'column'}>
              <Grid container spacing='20px'>
                {isSm && notPublicClasses.map((el) =>
                  <Grid item key={el.id} sm={6} md={4} lg={3}  >
                    <TeacherClass
                      userClass={el}
                    />
                  </Grid>)}
              </Grid>
            </Grid>
            {userClasses && !isSm && notPublicClasses.length <= 1 && notPublicClasses.map((el) =>
              <TeacherClass
                userClass={el}
              />)}
          </Box>
        </Box>
      </Box>
      {userClasses && !isSm && notPublicClasses.length > 1 && <Box sx={{ ml: '20px', mb: '30px'}}>
          <SwipeableViews
              displayButtons={false}
              spaceBetween={20}
              slidesPerView="auto"
              lastItemSx={{ mr: '20px' }}
              views={notPublicClasses}
              slideRenderer={(index) => {
                const cardClass = notPublicClasses[index]
                return (
                  <Box sx={{ width: { xs: '218px', md: '218px' } }}>
                    <TeacherClass
                      userClass={cardClass}
                    />
                  </Box>
                )
              }}/>
      </Box>}
    </Box>
  )
}
