import { AxiosError } from 'axios'
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack'
import i18n from 'i18next'
import * as Sentry from '@sentry/react'
import { translations } from 'locales/i18n'

const handleAxiosError = (
  error: AxiosError,
  enqueueSnackbar: (message: SnackbarMessage, options?: OptionsObject) => SnackbarKey,
  errorKeyPrefix?: string,
) => {
  const code = error.response?.data?.payload?.code
  const commonMessage = i18n.t(translations.messages.errors[code])
  const specificMessage = errorKeyPrefix && i18n.t(translations.messages.errors[errorKeyPrefix][code])

  enqueueSnackbar(
    specificMessage || commonMessage || i18n.t(translations.messages.errors.internalError),
    {variant: 'error'},
  )

  if (error.response?.status === 404 || error.response?.status === 500) {
    Sentry.captureException(error)
  }
}

export default handleAxiosError
