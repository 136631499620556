import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import useMe from 'hooks/useMe'
import DashboardLayout from '../components/layouts/dashboard/DashboardLayout'
import useClasses from '../hooks/useClasses'
import ClassesHead from '../components/community/ClassesHead'
import { ClassesStudent } from 'components/community/ClassesStudent'
import { ClassesTeacher } from 'components/community/ClassesTeacher'


const CommunityPage = () => {
  const history = useHistory()

  const meQuery = useMe()
  const me = meQuery.data

  const classesQuery = useClasses()
  const allClasses = classesQuery.data

   useEffect(
     () => {
       if (meQuery.isSuccess) {
         const classes = me?.classes || []
         const isAdmin = me?.mainRole === 'ADMIN'
  
         if (classes.length === 1 && !isAdmin) {
           const classId = classes[0]
           history.push(`/classes/${classId}`)
         } else if ( classes.length < 1) {
           history.replace('402')
         }
       }
     },
     [meQuery.isSuccess],
   )

  return (
    <DashboardLayout>
      <ClassesHead/>
     {allClasses && (me &&  me.mainRole==='STUDENT'
       ? <ClassesStudent  userClasses={allClasses}/>
       : <ClassesTeacher  userClasses={allClasses}/>
     )}
      </DashboardLayout>
      )
}

export default CommunityPage
