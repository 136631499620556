import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getQuizAttemptsHistory } from 'services/quizAttemtService'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useQuizAttemptsHistory = (options?: UseQueryOptions<QuizAttemptsHistory, AxiosError>): UseQueryResult<QuizAttemptsHistory> =>
  useQueryWithSnackbar<QuizAttemptsHistory>(
    [ReactQueryKeys.QUIZ_ATTEMPTS_HISTORY],
    () => getQuizAttemptsHistory(),
    options,
  )


export default useQuizAttemptsHistory
