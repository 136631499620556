import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { Box } from '@mui/material'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import { Back } from 'components/shared/back'
import { translations } from 'locales/i18n'
import Simulations from 'components/quizzes-page/Simulations'
import PreviousYears from 'components/quizzes-page/PreviousYears'
import Subjects from 'components/quizzes-page/Subjects'
import { randomSimulation } from 'components/quizzes-page/simulations-config'
import useQuizzesPage from 'hooks/useQuizzesPage'

const QuizzesProMedPage = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const allQuizzes = useQuizzesPage().data
  const Header = () => (
    <Box
      sx={{
        fontSize: { xs: '18px', md: '34px' },
        lineHeight: { xs: '24px', md: '41px' },
        mt: { xs: '40px', md: '70px' },
      }}
      className="u-weight-bold">
      {t(translations.dashboard.menu.quizzes)}
    </Box>
  )

  return (
    <DashboardLayout>
      <Back text={t(translations.navigation.homePage)} url={'/'}/>
      <Box sx={{ mx: { xs: '20px', sm: 6.5 }, mb: { xs: '20px', sm: 6.5 } }}>
        <Header/>
        <Simulations randomSimulationQuestionCount={allQuizzes?.randomSimulation?.questionCount} staticPacks={allQuizzes?.staticSimulationPacks} simulation={randomSimulation} monthlySimulation={allQuizzes?.monthlySimulation} />
        <Subjects subjects = {allQuizzes?.subjects} onSubjectClick={(subjectId) => history.push(`/quizzes/subjects/${subjectId}/new`)}/>
        <PreviousYears officialNationalQuizzes = {allQuizzes?.officialNationalQuizzesSummary} />
      </Box>
    </DashboardLayout>
  )
}

export default QuizzesProMedPage
