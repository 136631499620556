import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { schedulePost } from 'services/community/postService'
import { translations } from 'locales/i18n'

const useSchedulePost = () => useMutationWithSnackbar(
  (data: SchedulePostRequest) => schedulePost(data),
  undefined,
  translations.messages.postScheduled,
)

export default useSchedulePost
