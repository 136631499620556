import React, { FC } from 'react'
import { Box, Typography } from '@mui/material'
import { translations } from 'locales/i18n'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Formik } from 'formik'

import ForgotPasswordFormSchema from './ForgotPasswordValidatorSchema'
import FormInput from '../../ui/form/form-input/form-input'
import LoadingButton from '../../ui/buttons/loading-button/loading-button'
import { StudentProfileInterface } from 'types/StudentProfileInterface'

type ForgotPasswordProps = {
  currentUser?: StudentProfileInterface
  onSubmit: (data: ForgotPasswordRequest) => void
  isLoading: boolean
}

const ForgotPassword: FC<ForgotPasswordProps> = ({currentUser, onSubmit, isLoading}) => {
  const {t} = useTranslation()

  return (
    <>
      <Box display="flex" justifyContent="center">
        <Box>
          <Box sx={{
            fontSize: {xs: '18px', md: '24px'},
            lineHeight: {xs: '23px', md: '29px'},
            mb: {xs: '7px', md: '22px'},
            fontWeight: {xs: 'bold', md: 'normal'},
          }}>
            {t(translations.forgotPasswordPage.title)}
          </Box>
        </Box>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box sx={{
          width: {xs: '100%', sm: '370px'},
          background: theme => theme.palette.background.paper,
          borderRadius: "3px",
          border: '1px solid #363B3F',
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          mx: {xs: '16px'},
          px: {xs: '20px', md: '40px'},
          pt: {xs: '10px', md: '20px'},
          pb: {xs: '18px', md: '28px'},
        }}>
          <Formik
            validateOnBlur
            validateOnChange={false}
            initialValues={{
              email: '',
            }}
            validationSchema={ForgotPasswordFormSchema}
            onSubmit={onSubmit}
          >
            {({handleSubmit, isValid}) => (
              <form
                style={{display: "flex", flexDirection: "column", width: '100%'}}
                onSubmit={handleSubmit}
              >
                <FormInput
                  label={t(translations.loginPage.username)}
                  name="email"
                  variant="standard"/>
                <Box sx={{mt: '28px'}}>
                  <LoadingButton
                    loading={isLoading}
                    disabled={isLoading || !isValid}
                    className="Button Button--primary Button_low u-w-100 u-text-transform-capitalize"
                    type="submit"
                    variant="contained"
                    color="primary">
                    {t(translations.forgotPasswordPage.resetPassword)}
                  </LoadingButton>
                </Box>
              </form>
            )}
          </Formik>
          {!currentUser && (
            <Typography className="u-fs-14 u-lh-17 u-mt-30">
              <Link to="/login">
                {t(translations.forgotPasswordPage.backToSignIn)}
              </Link>
            </Typography>
          )}
        </Box>
      </Box>
    </>
  )
}

export default ForgotPassword
