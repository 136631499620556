import { FC, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { translations } from "locales/i18n";
import { useTranslation } from "react-i18next";
import dayjs, { Dayjs } from "dayjs";
import { isNull, isEmpty } from "lodash";
import {
  Grid,
  InputLabel,
  TextField,
  Box,
  useMediaQuery,
  Typography,
} from "@mui/material";
import { DesktopDatePicker, StaticDatePicker } from "@mui/lab";
import { SxProps } from "@mui/system";
import { Theme } from "@mui/material/styles";

import { CancelButton, UpdateButton } from "components/profile/buttons";
import { MEDIUM_DATE_FORMAT } from "utils/constants";

import StreamFormSchema from "./StreamValidationSchema";
import FormInput from "components/ui/form/form-input/form-input";
import FormSwitch from "../../ui/form/form-switch/FormSwitch";
import useFileUri from "hooks/useFileUri";
import useUploadVideo from "hooks/community/useUploadVideo";
import StreamPhotoMask from "./StreamPhotoMask";
import StreamVideoUpload from "./StreamVideoUpload";
import StreamSpeakers from "./StreamSpeakers";
import theme from "theme";
import ErrorMessage from "components/ui/form/error-message/error-message";
import close from "images/close-menu.svg";

const InputStyle: SxProps<Theme> = {
  maxWidth: { xs: "100%", sm: "376px" },
  minWidth: "330px",
  color: "#FFFFFF",
  backgroundColor: "rgba(0, 0, 0, 0)",
  border: "none",
  outline: "none",
  fontSize: { xs: "14px", md: "19px" },
  lineHeight: { xs: "25px", md: "29px" },
  padding: 0,

  "&::placeholder": {
    color: "rgba(255, 255, 255, 0.6)",
  },
};

const labelStyle: SxProps<Theme> = {
  zIndex: "100",
  minHeight: "0px !important",
  textTransform: "uppercase",
  fontSize: { xs: "10px", md: "11px" },
  lineHeight: { xs: "12px", md: "13px" },
  letterSpacing: "0.4",
  backgroundColor: "rgba(0, 0, 0, 0)",
  color: "#B2B5BD",
};

type StreamFormProps = {
  classId: string;
  streamId: string;
  stream?: ClassStreamUserView;
  teachers?: TeacherInterface[];
  submitTitle?: string;
  onUpdateStream: (data: UpdateStreamRequest) => Promise<CreateStreamResponse>;
  onUpdateFiles:(data: AddFileToStreamRequest) => Promise<UploadFilesResponse>;
  sx?: SxProps<Theme>;
};

const StreamForm: FC<StreamFormProps> = ({
  classId,
  streamId,
  stream,
  teachers,
  submitTitle,
  onUpdateStream,
  onUpdateFiles,
  sx,
}) => {
  const [scheduledDate, setScheduledDate] = useState<Dayjs>(
    stream?.date !== -1 ? dayjs(stream?.date) : dayjs().hour(12)
  );
  const { change: handleFileChange, fileUri, file } = useFileUri();
  const inputFileLabelRef = useRef<HTMLLabelElement>(null);
  const { t } = useTranslation();
  const isSm = useMediaQuery(theme.breakpoints.up("sm"));

  const handleScheduledDateChange = (value: Dayjs | null) =>
    !isNull(value) && setScheduledDate(value.hour(12));

  const scheduledDateProps = {
    inputFormat: MEDIUM_DATE_FORMAT,
    value: scheduledDate,
    onChange: handleScheduledDateChange,
    disablePast: false,
    renderInput: (params) => (
      <TextField
        variant="standard"
        sx={{
          minWidth: "330px",
          maxWidth: { xs: "330px", sm: "376px" },
          input: {
            minHeight: "45px !important",
            padding: 0,
          },
          button: {
            margin: 0,
          },
        }}
        {...params}
        onKeyDown={(e) => {
          e.preventDefault();
        }}
      />
    ),
  };

  const {
    classStreamSpeaker,
    name,
    date,
    description,
    videoUri,
    previewImageUri,
    files
  } = stream || ({} as ClassStreamUserView);

  const {
    uploadingFile,
    currentVideoUri,
    currentFiles,
    handleAddFiles,
    handleSubmit,
    handleCancel,
    handleDelete,
  } = useUploadVideo({
    classId,
    streamId,
    date: scheduledDate.unix() * 1000,
    videoField: "videoUri",
    initialFiles: files ? files : [],
    initialVideoUri: videoUri ? videoUri : "",
    initialImageUri: previewImageUri ? previewImageUri : "",
    previewImage: file,
    onAddFiles: onUpdateFiles,
    onSubmit: onUpdateStream,
  });

  return (
    <>
      <Formik
        validateOnBlur
        initialValues={{
          classId,
          streamId,
          previewImage: previewImageUri ? previewImageUri : null,
          videoUri: videoUri ? videoUri : currentVideoUri,
          name: name ? name : "",
          date: scheduledDate.unix() * 1000,
          description: description ? description : "",
          speakerId: classStreamSpeaker ? classStreamSpeaker.id : "",
          sendNotifications: false,
          sendEmails: false,
          files: files ? files.map(item => ({fileUri: item.fileUri, fileId: item.id, deleted: false })) : currentFiles.map(item => ({fileUri: item.fileUri, fileId: item.id, deleted: false }))
        }}
        validationSchema={StreamFormSchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, handleChange, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              className="u-m-0"
              sx={{ justifyContent: { xs: "center", lg: "start" }, ...sx }}
            >
              <Grid
                item
                sx={{
                  mr: { xs: 0, sm: "43px" },
                  mb: { xs: "27px", lg: 0 },
                  p: 0,
                }}
              >
                <StreamPhotoMask
                  href={inputFileLabelRef}
                  photo={{ title: "photo", uri: fileUri || "" }}
                  initialImage={{ title: "initialImage", uri: previewImageUri }}
                >
                  <input
                    id="photo"
                    name="previewImage"
                    type="file"
                    onChange={(event) => {
                      handleFileChange(
                        (event.target as HTMLInputElement).files![0]
                      );
                      setFieldValue("previewImage", event.target.files![0]);
                    }}
                    hidden
                  />
                </StreamPhotoMask>
                <ErrorMessage name="previewImage" className="u-w-100 u-mt-23" />
                
              </Grid>
              <Grid
                container
                sx={{
                  justifyContent: "space-between",
                  maxWidth: {
                    xs: "100%",
                    sm: "448px",
                    lg: "calc(100% - 365px)",
                  },
                  paddingY: { xs: 0, sm: "27px" },
                  paddingX: { xs: 0, sm: "36px" },
                  backgroundColor: { xs: "inherit", sm: "#1F2021" },
                  borderRadius: "3px",
                }}
              >
                <Grid
                  item
                  xs={12}
                  lg={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mr: { xs: 0, lg: "55px" },
                    mb: "35px",
                  }}
                >
                  <InputLabel sx={{ ...labelStyle }}>
                    {t(translations.streams.nameEvent)}
                  </InputLabel>
                  <FormInput
                    name="name"
                    variant="standard"
                    onChange={handleChange}
                    placeholder={`${t(translations.streams.addName)}`}
                    InputProps={{
                      sx: { minHeight: "45px !important", ...InputStyle },
                    }}
                    autoComplete="off"
                  />
                  <InputLabel sx={{ mt: "35px", ...labelStyle }}>
                    {t(translations.streams.startDate)}
                  </InputLabel>
                  {isSm && <DesktopDatePicker {...scheduledDateProps} />}
                  {!isSm && (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "15px",
                      }}
                    >
                      <StaticDatePicker
                        displayStaticWrapperAs="desktop"
                        {...scheduledDateProps}
                      />
                    </Box>
                  )}
                  <ErrorMessage name="date" className="u-w-100 u-mt-8" />

                  <InputLabel sx={{ mt: "35px", ...labelStyle }}>
                    {t(translations.streams.speaker)}
                  </InputLabel>
                  {teachers && (
                    <StreamSpeakers
                      teachers={teachers}
                      currentSpeaker={classStreamSpeaker}
                    />
                  )}
                  <ErrorMessage name="speakerId" className="u-w-100 u-mt-8" />

                  <InputLabel
                    sx={{
                      textTransform: "uppercase",
                      mt: "35px",
                      mb: "17px",
                      ...labelStyle,
                    }}
                  >
                    {t(translations.streams.usersNotification)}
                  </InputLabel>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      ml: "10px",
                    }}
                  >
                    <FormSwitch
                      name="sendNotifications"
                      label={{
                        text: t(translations.streams.sendNotification),
                        position: "end",
                      }}
                      sxSwitch={{ mr: "10px" }}
                    />
                    <FormSwitch
                      name="sendEmails"
                      label={{
                        text: t(translations.streams.sendEmail),
                        position: "end",
                      }}
                      sxSwitch={{ mr: "10px", ml: "35px" }}
                    />
                  </Box>

                  <InputLabel sx={{ mt: "48px", ...labelStyle }}>
                    {t(translations.streams.writeShortDescription)}
                  </InputLabel>
                  <FormInput
                    name="description"
                    variant="standard"
                    onChange={handleChange}
                    multiline={true}
                    placeholder={`${t(
                      translations.lessonPage.reports.message
                    )}`}
                    InputProps={{
                      sx: { alignItems: "flex-start", ...InputStyle },
                    }}
                    autoComplete="off"
                  />
                </Grid>
                <Grid item xs={12} lg={"auto"}>
                  <Grid
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      mb: "35px",
                    }}
                  >
                    <InputLabel sx={{ ...labelStyle, pb: "15px" }}>
                      FILES
                    </InputLabel>
                    <Typography
                      sx={{
                        textTransform: "capitalize",
                        fontSize: "12px",
                        lineHeight: 1.21,
                        color: "#606060",
                      }}
                    >
                      {t(translations.streams.uploadVideoDescription)}
                    </Typography>
                    <StreamVideoUpload
                      name="videoUri"
                      uploadingFile={uploadingFile}
                      currentVideoUri={currentVideoUri}
                      files={files}
                      currentFiles={currentFiles}
                      onAddVideo={(e) => handleAddFiles(e, setFieldValue)}
                      onCancel={() => handleCancel(setFieldValue)}
                      onDeleted={handleDelete}
                    />
                    <ErrorMessage name="videoUri" className="u-w-100 u-mt-8" />
                  </Grid>
                </Grid>
                {isSm && (
                  <Grid item xs={12} sx={{ mt: "15px" }}>
                    <Link
                      to={`${
                        !isEmpty(name)
                          ? `/classes/${classId}/streams/${streamId}`
                          : `/classes/${classId}/streams`
                      }`}
                    >
                      <CancelButton
                        sx={{
                          color: "white",
                          marginRight: "10px",
                          fontSize: { xs: "12px", sm: "14px" },
                        }}
                      >
                        {t(translations.common.cancel)}
                      </CancelButton>
                    </Link>
                    <UpdateButton
                      type="submit"
                      disabled={!isEmpty(uploadingFile) ? true : false}
                      sx={{
                        color: "white",
                        fontSize: { xs: "12px", sm: "14px" },
                        paddingX: "42px",
                      }}
                    >
                      {submitTitle || t(translations.common.save)}
                    </UpdateButton>
                  </Grid>
                )}
                {!isSm && (
                  <Box>
                    <Link
                      to={`${
                        !isEmpty(name)
                          ? `/classes/${classId}/streams/${streamId}`
                          : `/classes/${classId}/streams`
                      }`}
                    >
                      <Box
                        sx={{
                          position: "fixed",
                          top: "20px",
                          left: "20px",
                          zIndex: 110,
                          cursor: "pointer",
                        }}
                      >
                        <img src={close} alt="Cancel" role="presentation" />
                      </Box>
                    </Link>
                    <Grid
                      sx={{
                        position: "fixed",
                        top: "10px",
                        right: "10px",
                        zIndex: 110,
                      }}
                    >
                      <UpdateButton
                        type="submit"
                        sx={{
                          color: "white",
                          fontSize: { xs: "12px", sm: "14px" },
                          padding: "10px 6px",
                        }}
                      >
                        {submitTitle || t(translations.common.save)}
                      </UpdateButton>
                    </Grid>
                  </Box>
                )}
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </>
  );
};
export default StreamForm;
