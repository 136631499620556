import React, { FC } from 'react'
import { translations } from 'locales/i18n'
import { useTranslation } from 'react-i18next'
import { Box, Grid } from '@mui/material'
import PreviousYearsOfficialTestingCard from './PreviousYearsOfficialTestingCard'
import { OfficialNationalSimulationCategory } from 'types/OfficialNationalSimulationCategory'
import { Specialty } from 'types/Specialty'

type PreviousYearsOfficialTestingsProps = {
  categories: OfficialNationalSimulationCategory[]
  specialtyToFilter?: Specialty
}

const backgroundColors = [
  '/images/quizes/official-national-1.svg',
  '/images/quizes/official-national-2.svg',
  '/images/quizes/official-national-3.svg',
]

const PreviousYearsOfficialTestings: FC<PreviousYearsOfficialTestingsProps> = ({categories, specialtyToFilter}) => {
  
const {t} = useTranslation()

const getTitle = (specialty) => {
  switch (specialty) {
    case Specialty.HEALTHCARE:
      return t(translations.quizzes.healthcareTests)
    case Specialty.VETERINARY:
      return t(translations.quizzes.veterinaryTests)
    default:
      return t(translations.quizzes.medicalTests)
  }
}

  return (
    <Grid container spacing={{xs: 1.5, md: 2}}>
      {
        categories
          .filter(({specialty}) => specialty !== specialtyToFilter)
          .map(({fromYear, toYear, specialty}, index) => (
            <Grid item xs={12} sm={6} md={4} key={`${specialty}-${fromYear}-${toYear}`}>
              <Box>
                <PreviousYearsOfficialTestingCard
                  speciality={specialty}
                  title={getTitle(specialty)}
                  fromYear={fromYear}
                  toYear={toYear}
                  backgroundImg={backgroundColors[index % backgroundColors.length]}
                />
              </Box>
            </Grid>
          ))
      }
    </Grid>
  )
}

export default PreviousYearsOfficialTestings
