import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Grid, Button, Typography } from '@mui/material'
import cn from 'classnames'
import dayjs from 'dayjs'
import { isEmpty } from 'lodash'

import redo from 'images/redo.svg'
import redCircle from 'images/circle-red.svg'
import yellowCircle from 'images/circle-yellow.svg'
import greenCircle from 'images/circle-green.svg'
import redCross from 'images/cross-red.svg'
import ready from 'images/ready.svg'
import { QuizAttemptResultsInterface } from 'types/QuizAttemptResultsInterface'
import CircularChart from 'components/shared/circular-chart/CircularChart'
import { translations } from 'locales/i18n'
import { QuizType } from 'types/QuizType'
import Jaw from 'components/shared/jaw/Jaw'
import timer from 'images/timer.svg'
import difficultyLowLevel from 'images/difficulty-low-level-selected.svg'
import difficultyMediumLevel from 'images/difficulty-medium-level-selected.svg'
import difficultyHighLevel from 'images/difficulty-high-level-selected.svg'
import { QuizAttemptStatisticsInterface } from 'types/QuizAttemptStatisticsInterface'
import { FULL_DATE_FORMAT } from 'utils/constants'
import getQuizTypeTitle from 'components/shared/getQuizTypeTitle'
import { getDurationFormatted } from 'utils/duration'
import TitilePracticeQuiz from 'components/quiz-attempt/components/PracticeQuizTitle'

const green = 'jungle-green'
const yellow = 'sunglow'
const red = 'sunglo'

const excludedQuizTypesForSectionScores = [QuizType.AREA_PRACTICE, QuizType.AREA_MONTHLY_PRACTICE]
const quizTypesForAveragePerSection = [QuizType.MONTHLY_SIMULATION, QuizType.OFFICIAL_NATIONAL_SIMULATION, QuizType.PACK_SIMULATION]

type QuizScoresProps = {
  results: QuizAttemptResultsInterface
  statistics: QuizAttemptStatisticsInterface
  handleRestartTest: () => void
  groupName?: string
}

const QuizAttemptResults: FC<QuizScoresProps> = ({ results, statistics, groupName, handleRestartTest }) => {
  const { t } = useTranslation()

  const {
    quizAttemptId,
    quizType,
    completedAt,
    createdAt,
    correctAnswers,
    incorrectAnswers,
    notAnsweredQuestions,
    score,
    scoreAverage,
    scoreMax,
    sectionScores
  } = results


  const showSectionScores = !isEmpty(sectionScores) && !excludedQuizTypesForSectionScores.includes(quizType)
  const showAveragePerSection = quizTypesForAveragePerSection.includes(quizType)
  
  const getChartBackground = (score: number, scoreAverage: number, scoreMax: number) => {
    if (scoreAverage) {
      if (score > scoreAverage) {
        return green
      }
      if (score === scoreAverage) {
        return yellow
      }
      return red
    } else if (scoreMax) {
      const percents = score / scoreMax * 100
      if (percents >= 66) {
        return green
      }
      if (percents < 33) {
        return red
      }
      return yellow
    } else {
      return green
    }
  }

  const getCircle = (score: number, scoreAverage: number, scoreMax: number) => {
    const background = getChartBackground(score, scoreAverage, scoreMax)
    if (background === green) {
      return greenCircle
    } else if (background === yellow) {
      return yellowCircle
    }
    return redCircle
  }

  const getBarChartConfig = (score: number, scoreAverage: number, scoreMax: number) => {
    const pr6 = 'u-pr-6'
    const pr10 = 'u-pr-10'
    const background = getChartBackground(score, scoreAverage, scoreMax)
    const backgroundClass = (bg) => `u-bg-${bg}`

    if (background === green) {
      return { image: ready, barBackgroundClass: backgroundClass(green), imagePaddingClass: pr6 }
    } else if (background === yellowCircle) {
      return { image: yellowCircle, barBackgroundClass: backgroundClass(yellow), imagePaddingClass: pr10 }
    }
    return { image: redCross, barBackgroundClass: backgroundClass(red), imagePaddingClass: pr10 }
  }

  const getDifficultyLevelConfig = () => {
    switch (statistics.quizAttemptDetails.difficulty) {
      case 1:
        return { image: difficultyLowLevel, title: t(translations.quizAttemptResults.lowLevel) }
      case 2:
        return { image: difficultyMediumLevel, title: t(translations.quizAttemptResults.mediumLevel) }
      default:
        return { image: difficultyHighLevel, title: t(translations.quizAttemptResults.highLevel) }
    }
  }
  const difficultyLevelConfig = getDifficultyLevelConfig()

  const getQuizAttemptSubjectName = () => statistics.quizAttemptDetails.subject?.name || ''

  const chartScore = (score) => score > 0 ? score : 0

  return (
    <Jaw
      header={
        <Grid container>
          <Grid
            container
            justifyContent="center"
            item
            className="u-mb-10 u-fs-14 u-lh-22 u-color-pale-sky"
          >
            {dayjs(completedAt).format(FULL_DATE_FORMAT)}
          </Grid>

          <Grid
            container
            justifyContent="center"
            item
            className="u-mb-15 u-fs-24 u-lh-29"
          >
            {getQuizTypeTitle(quizType, getQuizAttemptSubjectName(), groupName)}
          </Grid>
          {quizType === QuizType.LESSON && (
            <Box
              justifyContent="center"
              textAlign="center"
              alignItems="center"
              width="600px"
              className="u-mb-15 u-fs-34 u-lh-41"
            >
              {statistics.quizAttemptDetails.lessonName}
            </Box>
          )}
          <TitilePracticeQuiz statistics={statistics} />
          {quizType !== QuizType.PRACTICE && (
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className="u-fs-16 u-lh-19"
            >
              <img src={timer} className="u-pr-15" alt="" role="presentation" />
              <span>{`${t(
                translations.quizAttemptResults.totalTime
              )} ${getDurationFormatted(createdAt, completedAt)}`}</span>
            </Grid>
          )}
          {quizType === QuizType.PRACTICE && (
            <Grid
              container
              item
              justifyContent="center"
              alignItems="center"
              className="u-fs-16 u-lh-19"
            >
              <img
                src={difficultyLevelConfig.image}
                className="u-pr-15"
                alt=""
                role="presentation"
                style={{ height: "15px", width: "auto" }}
              />
              <span>{difficultyLevelConfig.title}</span>
            </Grid>
          )}
        </Grid>
      }
    >
      <Grid container>
        <Grid
          container
          sx={{
            padding: { xs: "15px 20px", sm: "35px 40px" },
          }}
        >
          <Grid
            sx={{
              fontSize: { xs: "16px", sm: "24px" },
              lineHeight: "34px",
              fontWeight: "bold",
            }}
          >
            {t(translations.quizAttemptResults.yourScoreAndAverageScore)}
          </Grid>
          <Grid container className="u-mt-20">
            <Grid item xs={7} sm={6} display="grid">
              <CircularChart
                percentage={{
                  value: chartScore(results.score),
                  maxValue: results.scoreMax,
                }}
                className={cn(
                  `CircularChart_${getChartBackground(
                    score,
                    scoreAverage,
                    scoreMax
                  )}`,
                  results.scoreAverage && "u-mt-8 u-ml-8 u-grid-overlay"
                )}
                text={
                  <text x="18" y="20.35" className="Percentage" fontSize={45}>
                    <tspan>{results.score / 100}</tspan>
                    <tspan fill="#6F7981" style={{ fontSize: "5px" }}>{`/${
                      results.scoreMax / 100
                    }`}</tspan>
                  </text>
                }
                height="140px"
              />
              {scoreAverage && (
                <CircularChart
                  percentage={{
                    value: chartScore(scoreAverage),
                    maxValue: scoreMax,
                  }}
                  className="CircularChart_sunglow u-grid-overlay"
                  height="156px"
                />
              )}
            </Grid>
            <Grid item xs={5} sm={6} className="u-mt-20">
              {[
                {
                  name: t(translations.quizAttemptResults.correctAnswers),
                  value: correctAnswers,
                },
                {
                  name: t(translations.quizAttemptResults.incorrectAnswers),
                  value: incorrectAnswers,
                },
                {
                  name: t(translations.quizAttemptResults.notGivenAnswers),
                  value: notAnsweredQuestions,
                },
              ].map(({ name, value }) => (
                <Box display="flex" className="u-pb-5 u-lh-26" key={name}>
                  <Box
                    sx={{
                      width: "135px",
                      fontSize: { xs: "12px", sm: "14px" },
                      lineHeight: { xs: "20px", sm: "26px" },
                    }}
                  >
                    {name}
                  </Box>
                  <Box className="u-fs-18 u-ml-15">{value}</Box>
                </Box>
              ))}
              <Grid
                className="u-mt-50 u-fs-16 u-lh-19 u-color-havelock-blue u-weight-bold u-cursor-pointer"
                onClick={handleRestartTest}
              >
                {t(translations.quizAttemptResults.resetAndReTakeExam)}
                <img
                  src={redo}
                  className="u-pl-10"
                  alt=""
                  role="presentation"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container alignItems="center" className="u-mt-25">
            <Grid container item xs={7} sm={6} className="u-lh-26">
              <Grid item>
                <Grid sx={{ display: "flex" }}>
                  <img
                    src={getCircle(score, scoreAverage, scoreMax)}
                    className="u-pr-15"
                    alt=""
                    role="presentation"
                  />
                  <Typography
                    sx={{
                      margin: 0,
                      paddingRight: { xs: "5px", sm: "15px" },
                      fontSize: { xs: "12px", sm: "14px" },
                      lineHeight: { xs: "18.61px", sm: "26px" },
                    }}
                  >
                    {t(translations.quizAttemptResults.yourScore)}
                  </Typography>
                </Grid>
                {scoreAverage && (
                  <Grid sx={{ display: "flex" }}>
                    <img
                      src={yellowCircle}
                      className="u-pr-15"
                      alt=""
                      role="presentation"
                    />
                    <Typography
                      sx={{
                        margin: 0,
                        paddingRight: { xs: "5px", sm: "15px" },
                        fontSize: { xs: "12px", sm: "14px" },
                        lineHeight: { xs: "18.61px", sm: "26px" },
                      }}
                    >
                      {t(translations.quizAttemptResults.averageScore)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid item>
                <Grid>
                  <Typography
                    sx={{
                      margin: 0,
                      paddingRight: { xs: "5px", sm: "15px" },
                      fontSize: { xs: "14px", sm: "18px" },
                      lineHeight: { xs: "18.61px", sm: "26px" },
                    }}
                  >
                    {score / 100}
                  </Typography>
                </Grid>
                {scoreAverage && (
                  <Grid>
                    <Typography
                      sx={{
                        margin: 0,
                        paddingRight: { xs: "5px", sm: "15px" },
                        fontSize: { xs: "14px", sm: "18px" },
                        lineHeight: { xs: "18.61px", sm: "26px" },
                      }}
                    >
                      {scoreAverage / 100}
                    </Typography>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item xs={5} sm={6}>
              <Grid>
                <Link to={`/quiz-attempts/${quizAttemptId}/review`}>
                  <Button
                    variant="contained"
                    sx={{
                      textTransform: "capitalize",
                      fontSize: { xs: "12px", sm: "16px" },
                      lineHeight: { xs: "14px", sm: "19.36px" },
                      padding: { xs: "8px 15px", md: "15px 50px" },
                    }}
                  >
                    {t(translations.quizAttemptResults.reviewQuestions)}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {showSectionScores && (
          <Grid
            container
            className="u-pt-35 u-pl-40 u-pb-35 u-pr-40"
            sx={{
              borderTop: "1px solid #535353",
            }}
          >
            <Grid container item justifyContent="center">
              <Grid
                item
                sx={{
                  fontSize: { xs: "14px", sm: "22px" },
                  lineHeight: { xs: "16.5px", sm: "22px" },
                }}
              >
                { t(translations.quizAttemptResults.yourScoreDividedBySection) }
              </Grid>
              <Grid
                container
                item
                className="u-pt-20 u-fs-14 u-lh-26"
                justifyContent="center"
              >
                <Grid item className="u-pr-10">
                  <img
                    src={redCross}
                    className="u-pr-10"
                    alt=""
                    role="presentation"
                  />
                  <span>
                    {t(translations.quizAttemptResults.worseThanAverage)}
                  </span>
                </Grid>
                <Grid item className="u-pr-10">
                  <img
                    src={yellowCircle}
                    className="u-pr-10"
                    alt=""
                    role="presentation"
                  />
                  <span>
                    {t(translations.quizAttemptResults.equalToAverage)}
                  </span>
                </Grid>
                <Grid item>
                  <img
                    src={ready}
                    className="u-pr-10"
                    alt=""
                    role="presentation"
                  />
                  <span>
                    {t(translations.quizAttemptResults.betterThanAverage)}
                  </span>
                </Grid>
              </Grid>
            </Grid>
            <Grid item className="u-pt-40 u-pb-27 u-fs-18 u-lh-22">
              {t(translations.quizAttemptResults.scoreBreakdownSection)}
            </Grid>
            <Grid container item>
              {sectionScores.map(
                    ({ section, score, scoreAverage, scoreMax }) => {
                      const chartConfig = getBarChartConfig(
                        score,
                        scoreAverage,
                        scoreMax
                      );
                      return (
                        <Grid
                          container
                          item
                          className="u-mb-30"
                          key={section.id}
                        >
                          <Grid>
                            <img
                              src={chartConfig.image}
                              className={chartConfig.imagePaddingClass}
                              alt=""
                              role="presentation"
                            />
                          </Grid>
                          <Grid className="u-flex-grow-1">
                            <Grid className="BarChart">
                              <Grid
                                container
                                item
                                alignItems="center"
                                className={cn(
                                  "Bar u-fs-14 u-lh-17",
                                  chartConfig.barBackgroundClass
                                )}
                                style={{
                                  width: `${
                                    (chartScore(score) / scoreMax) * 100
                                  }%`,
                                }}
                              >
                                <Grid className="u-h-auto">
                                  <span className="u-weight-bold u-pl-10 u-pr-5">
                                    {section.name}
                                  </span>
                                  <span>{score / 100}</span>
                                </Grid>
                              </Grid>
                            </Grid>
                            {showAveragePerSection && (
                              <Grid>
                                <Grid className="u-mt-10 u-mb-7 u-fs-14 u-lh-17">
                                  <span className="u-weight-bold">
                                    {t(translations.quizAttemptResults.average)}
                                  </span>
                                  <span className="u-opacity-70">{` ${
                                    scoreAverage / 100
                                  }`}</span>
                                </Grid>
                                <Grid className="BarChart BarChart_slim">
                                  <Grid
                                    className="Bar u-fs-14 u-lh-17 u-bg-sunglow"
                                    style={{
                                      width: `${
                                        (chartScore(scoreAverage) / scoreMax) *
                                        100
                                      }%`,
                                    }}
                                  ></Grid>
                                </Grid>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                      );
                    }
                  )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Jaw>
  );
}

export default QuizAttemptResults
