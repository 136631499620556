import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getSubjects } from 'services/subjectService'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useSubjects = (options?: UseQueryOptions<Subject[], AxiosError>): UseQueryResult<Subject[]> => {
  return useQueryWithSnackbar<Subject[]>(
    [ReactQueryKeys.SUBJECTS],
    () => getSubjects(),
    options,
  )
}

export default useSubjects
