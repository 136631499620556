import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'
import ReactQueryKeys from 'types/ReactQueryKeys'

import useQueryWithSnackbar from './useQueryWithSnackbar'
import { QuizInterface } from 'types/QuizInterface'
import { getQuizzes } from 'services/quizService'

const useQuizzesPage = (options?: UseQueryOptions<QuizInterface, AxiosError>): UseQueryResult<QuizInterface> => {
  return useQueryWithSnackbar<QuizInterface>(
    [ReactQueryKeys.QUIZZES],
    () => getQuizzes(),
    options,
  )
}

export default useQuizzesPage
