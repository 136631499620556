import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { createPostComment } from 'services/community/postCommentService'
import { translations } from 'locales/i18n'

const useCreatePostComment = () => useMutationWithSnackbar(
  (data: PostCommentRequest) => createPostComment(data),
  'createPostComment',
  translations.messages.postCommentCreated,
)

export default useCreatePostComment
