import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getPackSimulationQuizzes } from 'services/quizService'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { UUID } from 'domain/types'
import { PackSimulationQuizzesInterface } from 'types/PackSimulationQuizzesInterface'

const usePackSimulationQuizzes = (
  packSimulationId: UUID,
  options?: UseQueryOptions<PackSimulationQuizzesInterface, AxiosError>,
): UseQueryResult<PackSimulationQuizzesInterface> => {
  return useQueryWithSnackbar<PackSimulationQuizzesInterface>(
    [ReactQueryKeys.PACK_SIMULATION_QUIZZES, packSimulationId],
    () => getPackSimulationQuizzes(packSimulationId),
    {
      enabled: !!packSimulationId,
      ...options,
    })
}

export default usePackSimulationQuizzes
