import { Button as MuiButton, styled } from '@mui/material'

export const UpdateButton = styled(MuiButton)(({ theme }) => ({
  padding: '15px 49px',
  fontSize: '16px',
  fontWeight: 'bold',
  textTransform: 'none',
  background: theme.palette.primary.main,
}))

export const CancelButton = styled(MuiButton)(({ theme }) => ({
  padding: '15px 33px',
  fontSize: '16px',
  textTransform: 'none',
  background: theme.palette.secondary.main,
  '&:hover': {
    background: theme.palette.secondary.light,
  },
}))
