import React, { ChangeEvent, FC } from 'react'
import { Field, FieldProps } from 'formik'
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from '@mui/material'

interface FormRadioGroupProps {
  name: string
  label?: string
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void
  inputs: { label: string, value: string, checked?: boolean }[]
  className?: string
}

const FormRadioGroup: FC<FormRadioGroupProps> = ({
                                                   name,
                                                   label = '',
                                                   onChange,
                                                   inputs,
                                                   className,
                                                 }) => {
  return (
    <Field
      name={name}
      id={name}>
      {({form: {setFieldValue, values}}: FieldProps) =>
        <FormControl component="fieldset" className={className}>
          {label && <FormLabel component="legend">{label}</FormLabel>}
          <RadioGroup
            aria-label={name}
            name={name}
            onChange={(e) => {
              if (onChange) {
                onChange(e)
              }
              setFieldValue(name, e.target.value)
            }}>
            <Grid container>
              {inputs.map(({label, value, checked}) => (
                <Grid item xs={6} lg={12} key={value}>
                  <FormControlLabel
                    sx={{
                      '& .MuiFormControlLabel-label': {
                        fontSize: {xs: '12px', lg: '16px'},
                        lineHeight: {xs: '17px', lg: '22px'},
                      },
                    }}
                    checked={checked || values[name] === value}
                    value={value}
                    control={<Radio color="primary"/>}
                    label={label}/>
                </Grid>
                ),
              )}
            </Grid>
          </RadioGroup>
        </FormControl>
      }
    </Field>
  )
}

export default FormRadioGroup
