import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'

import { translations } from 'locales/i18n'

type LessonMaterialProps = {
  text: string
}

const LessonMaterial: FC<LessonMaterialProps> = ({text}) => {
  const {t} = useTranslation()

  return (
    <>
      <Box>
        <Typography
          className="u-weight-bold"
          sx={{fontSize: {xs: '14px', md: '30px'}, lineHeight: {xs: '38px', md: '38px'}}}>
          {t(translations.lessonPage.relatedMaterial)}
        </Typography>
      </Box>
      <Box className="LessonTextContent" dangerouslySetInnerHTML={{__html: text}}></Box>
    </>
  )
}

export default LessonMaterial
