import React, { FC, ReactElement } from 'react'
import { Box, IconButton, useMediaQuery } from '@mui/material'

import nextItemIcon from 'images/carousel-next-item.svg'
import prevItemIcon from 'images/carousel-prev-item.svg'
import nextItemMobileIcon from 'images/carousel-next-item-mobile.svg'
import prevItemMobileIcon from 'images/carousel-prev-item-mobile.svg'
import theme from 'theme'


type CarouselProps = {
  views: any[]
  slideRenderer: (index: number) => ReactElement
  initialSlideIndex?: number
}

const Carousel: FC<CarouselProps> = ({
                                       views,
                                       slideRenderer,
                                       initialSlideIndex = 0,
                                     }) => {
  const isLaptop = useMediaQuery(theme.breakpoints.up('lg'))

  const [currentSlideIndex, setCurrentCourseIndex] = React.useState(initialSlideIndex)

  const handlePreviousClick = () => {
    if (currentSlideIndex !== 0) {
      setCurrentCourseIndex(currentSlideIndex - 1)
    }
  }

  const handleNextClick = () => {
    if (currentSlideIndex < views.length - 1) {
      setCurrentCourseIndex(currentSlideIndex + 1)
    }
  }

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      {views.length > 1 && currentSlideIndex !== 0 && (
        <IconButton
          onClick={handlePreviousClick}
          aria-label="Carousel"
          size="medium"
          sx={{
            position: 'absolute',
            left: {xs: '10px', md: '30px'},
            zIndex: 1,
          }}
        >
          <img src={isLaptop ? prevItemIcon : prevItemMobileIcon} alt="Previous"/>
        </IconButton>
      )}

      <Box className="carousel">
        <Box className="carousel-inner">
          {views.map((item, index) => (
            <Box key={`carousel-view-${index}`}>
              <input
                checked={currentSlideIndex === index}
                className="carousel-open"
                type="radio"
                id={`carousel-${index}`}
                name="carousel"
                aria-hidden="true"
                hidden
              />
              <Box className="carousel-item">
                {slideRenderer(index)}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      {views.length > 1 && currentSlideIndex !== views.length - 1 && (
        <IconButton
          onClick={handleNextClick}
          aria-label="Carousel"
          size="medium"
          sx={{
            position: 'absolute',
            right: {xs: '10px', md: '30px'},
          }}
        >
          <img src={isLaptop ? nextItemIcon : nextItemMobileIcon} alt="Next"/>
        </IconButton>
      )}
    </Box>
  )

}

export default Carousel
