import { useHistory } from 'react-router-dom'

import { startRandomSimulation } from 'services/quizService'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useStartRandomSimulation = () => {
  const history = useHistory()

  return useMutationWithSnackbar(
    () => startRandomSimulation(),
    'startRandomSimulation',
    undefined,
    {
      async onSuccess(quizAttempt) {
        history.push(`/quiz-attempts/${quizAttempt.quizAttemptId}`)
      },
    },
  )
}

export default useStartRandomSimulation
