import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { Box, Grid, Typography } from '@mui/material'

import LessonCard from 'components/shared/cards/LessonCard'
import SwitchElement from 'components/shared/switch-element/SwitchElement'
import VideoPlayer from 'components/video-player/VideoPlayer'
import { Back } from 'components/shared/back'
import { translations } from 'locales/i18n'
import { LessonView } from 'types/LessonView'
import { EnrolledLessonState } from 'types/EnrolledLessonState'
import { CourseView } from 'types/CourseView'
import config from 'utils/config'
import SwipeableViews from 'components/shared/swipeable-views/SwipeableViews'
import ReactQueryKeys from 'types/ReactQueryKeys'
import theme from '../../theme'

type CourseProps = {
  course: CourseView
  frequentTopicLessons: LessonView[]
  onUpdateCourseVideoWatchedSeconds: (currentVideoTime: number) => void
}

const Course: FC<CourseProps> = ({course, frequentTopicLessons, onUpdateCourseVideoWatchedSeconds}) => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()

  const {name, welcomeVideoFileUri, welcomeVideoVariants, previewImageUri, videoSecondsViewed, lessons} = course

  const [unopenedOnly, setUnopenedOnly] = React.useState(false)

  const toggleUnopenedOnly = () => setUnopenedOnly(!unopenedOnly)

  const filterByUnopenedOnly = (lessons) => unopenedOnly
    ? lessons.filter(({state}) => state === EnrolledLessonState.UNOPENED)
    : lessons

  const lessonsToShow: LessonView[] = filterByUnopenedOnly(lessons)

  let welcomeBlock
  if (welcomeVideoFileUri) {
    welcomeBlock = <VideoPlayer
      title={name}
      titleDescription={t(translations.coursePage.welcomeVideo)}
      videoFileUri={`${config.cdnBaseApiURI}${welcomeVideoFileUri}`}
      videoVariants={welcomeVideoVariants}
      videoStartTimeSeconds={videoSecondsViewed}
      onWatchUpdate={onUpdateCourseVideoWatchedSeconds}
      invalidate={() => queryClient.invalidateQueries([ReactQueryKeys.COURSE, course.id])}
    />
  } else {
    welcomeBlock =
      <>
        <img src={`${config.cdnBaseApiURI}${previewImageUri}`} alt="Welcome" style={{
          width: '100%',
          height: 'auto',
          objectFit: 'cover',
        }}/><Box
        sx={{
          position: 'relative'
        }}>
        <Box
          sx={{
            background: {
              xs: theme.palette.common.videoGradient.replace('{start}', '24.56').replace('{finish}', '71.5'),
              md: theme.palette.common.videoGradient.replace('{start}', '0').replace('{finish}', '56.25'),
            },
            top: '-151px',
            width: '100%',
            height: '160px',
            position: 'absolute',
          }}
        />
      </Box>
      </>
  }

  return (
    <Box>
      <Back text={t(translations.navigation.coursesPage)} url={'/courses'}/>
      {welcomeBlock}
      <Box sx={{px: {xs: '20px', sm: 6.5}, mb: 3, mt: {xs: 0, md: 4}}}>
        <Grid container justifyContent="space-between" alignItems="center" sx={{mt: 3, mb: 2}}
              className="u-mt-10 u-fs-16 u-lh-24 u-weight-500">
          <Grid item xs={6} sx={{mb: {sm: '15px'}}}>
            <Typography
              sx={{fontSize: {xs: '18px', md: '30px'}, lineHeight: {xs: '23px', md: '55px'}, fontWeight: '800'}}>
              {t(translations.coursePage.allLessons)}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <SwitchElement onChange={toggleUnopenedOnly} text={t(translations.coursePage.onlyUnopened)}/>
          </Grid>
        </Grid>
        <Box>
          <Grid container spacing="20px">
            {lessonsToShow.map(({
                                  courseId,
                                  id: lessonId,
                                  lecturer,
                                  name: lessonName,
                                  previewImageUri,
                                  videoUri,
                                  videoLength,
                                  videoSecondsViewed,
                                  state,
                                }) => (
              <Grid item md={4} key={lessonId} className="u-w-100">
                <LessonCard
                  courseId={courseId}
                  lessonId={lessonId}
                  lecturer={lecturer}
                  name={lessonName}
                  lessonState={state}
                  previewImageUri={previewImageUri}
                  videoUri={videoUri}
                  videoLength={videoLength}
                  videoSecondsViewed={videoSecondsViewed}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
      {frequentTopicLessons.length > 0 && (
        <Box>
          <Box sx={{pt: {xs: 4, md: 6}, ml: {xs: '85px', sm: 6.5}, mr: {xs: '0', md: 6.5}, mb: 5}}>
            <Typography sx={{
              mt: 3,
              mb: 2,
              fontSize: {xs: '18px', md: '30px'},
              lineHeight: {xs: '23px', md: '55px'},
              fontWeight: '800',
            }}>
              {t(translations.coursePage.frequentTopicLessons)}
            </Typography>
            <Grid container direction="row" justifyContent="space-between" alignItems="center"
                  className="u-mt-10 u-mb-10 u-fs-16 u-lh-24 u-weight-500">
              <Grid item xs={12} md={6} lg={5} className="u-mb-15 u-lh-24">
                <Typography className="u-lh-24" sx={{fontSize: {xs: '12px', md: '16px'}}}>
                  {t(translations.coursePage.interestingLessons)}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ml: {xs: '85px', sm: 6.5}, mb: {xs: '30px'}}}>
            <SwipeableViews
              spaceBetween={20}
              slidesPerView="auto"
              views={frequentTopicLessons}
              slideRenderer={(index) => {
                const lesson = frequentTopicLessons[index]
                const {
                  courseId,
                  id: lessonId,
                  lecturer,
                  name: lessonName,
                  previewImageUri,
                  videoUri,
                  videoLength,
                  videoSecondsViewed,
                  state
                } = lesson
                return (
                  <Box sx={{maxWidth: {xs: '161px', md: '407px'}}}>
                    <LessonCard
                      courseId={courseId}
                      lessonId={lessonId}
                      lecturer={lecturer}
                      name={lessonName}
                      lessonState={state}
                      previewImageUri={previewImageUri}
                      videoUri={videoUri}
                      videoLength={videoLength}
                      videoSecondsViewed={videoSecondsViewed}
                    />
                  </Box>
                )
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Course
