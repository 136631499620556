import React, { Dispatch, FC, SetStateAction } from 'react'
import { Box, Grid } from '@mui/material'

import BlogCard from 'components/shared/cards/BlogCard'
import RouterPagination from 'components/shared/pagination/RouterPagination'

type BLogListProps = {
  blogs: BlogSearchView[]
  isLoading: boolean
  page: number
  setPage: Dispatch<SetStateAction<number>>
  totalPages: number
}

const BlogList: FC<BLogListProps> = ({
                                       blogs,
                                       isLoading,
                                       page,
                                       setPage,
                                       totalPages,
                                     }) =>
  (
    <Box>
      <Grid container spacing={{ xs: '15px', md: '20px' }}>
        {blogs.map(({ id, title, previewText, previewImageUri, publishedAt }, index) => (
          <Grid item xs={12} sm={4} lg={3} xl={2} key={id || index}>
            <BlogCard
              isLoading={isLoading}
              id={id}
              title={title}
              introText={previewText}
              date={publishedAt}
              imageUri={previewImageUri}
            />
          </Grid>
        ))}
      </Grid>
      <Box sx={{
        mt: { xs: '30px', md: '60px' },
        display: 'flex',
        justifyContent: 'center',
      }}>
        <RouterPagination page={page} setPage={setPage} totalPages={totalPages} path="/blog"/>
      </Box>
    </Box>
  )


export default BlogList
