import { useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { completeLesson } from 'services/lessonService'
import useMutationWithSnackbar from './useMutationWithSnackbar'
import { translations } from 'locales/i18n'

const useCompleteLesson = (): UseMutationResult<void, AxiosError, CourseLessonRequest> => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()
  const queryClient = useQueryClient()

  return useMutationWithSnackbar((data: CourseLessonRequest) =>
      completeLesson(data),
    'completeLesson',
    undefined,
    {
      async onSuccess() {
        enqueueSnackbar(
          t(translations.messages.lessonCompleted),
          { variant: 'success' },
        )
        await queryClient.invalidateQueries(ReactQueryKeys.ME)
      },
    },
  )
}

export default useCompleteLesson
