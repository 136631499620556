import { UseQueryOptions, UseQueryResult } from 'react-query'

import ReactQueryKeys from 'types/ReactQueryKeys'
import useQueryWithSnackbar from './useQueryWithSnackbar'
import { AxiosError } from 'axios'
import {getClasses} from '../services/community/classService';



const useClasses = (options?: UseQueryOptions<ClassesValueInterface[], AxiosError>): UseQueryResult<ClassesValueInterface[]> =>
   useQueryWithSnackbar<ClassesValueInterface[]>(
    [ReactQueryKeys.CLASSES],
    () => getClasses(),
    options,
  )

export default useClasses
