import React, { FC } from 'react'
import { Box } from '@mui/material'

import { PostInterface } from 'types/PostInterface'
import PostCommentForm from './PostCommentForm'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { PostCommentInterface } from 'types/PostCommentInterface'
import { PostCommentAuthor } from 'types/PostCommentAuthor'

type PostCommentFormStarterProps = {
  classId: string
  currentUser: StudentProfileInterface
  post: PostInterface
  isCommentFormSubmitting: boolean
  onTypingActive: (status: boolean) => void
  onSubmit: (data: PostCommentRequest) => Promise<PostCommentResponse>
  inputBackground: string
}

const PostCommentFormStarter: FC<PostCommentFormStarterProps> = ({
                                                                   classId,
                                                                   currentUser,
                                                                   post,
                                                                   isCommentFormSubmitting,
                                                                   onTypingActive,
                                                                   onSubmit,
                                                                   inputBackground,
                                                                 }) => {
  const {profile, mainRole} = currentUser
  const {firstName, lastName, profileImageUri} = profile

  const comment = {
    text: '',
    author: {
      firstName,
      lastName,
      mainRole,
      profileImageUri,
    } as PostCommentAuthor,
  } as PostCommentInterface

  return (
    <Box
      sx={{
        mt: {xs: '27px', sm: '42px'},
      }}
    >
      <PostCommentForm
        classId={classId}
        currentUser={currentUser}
        postId={post.postId}
        comment={comment}
        isCommentFormSubmitting={isCommentFormSubmitting}
        onTypingActive={onTypingActive}
        onSubmit={onSubmit}
        inputBackground={inputBackground}
      />
    </Box>
  )
}

export default PostCommentFormStarter

