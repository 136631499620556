import React, { FC } from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { translations } from 'locales/i18n'
import { FULL_DATE_FORMAT } from 'utils/constants'

import { PostInterface } from 'types/PostInterface'
import { StudentProfileInterface } from 'types/StudentProfileInterface'
import { UserProfileRole } from 'types/UserProfileRole'

import CommunityUserAvatar from '../CommunityUserAvatar'
import { CommunityDate } from '../ui/StyledComponent'
import pencilIcon from 'images/pencil.svg'
import deleteIcon from 'images/delete.svg'
import CommunityMenu from '../CommunityMenu'

type PostCommentCardHeaderProps = {
  post: PostInterface
  currentUser: StudentProfileInterface
  onStartEdit?: () => void
  onDelete?: () => void
  onOpenPostReport?: () => void
  showMenu: boolean
}

const PopularPollCardHeader: FC<PostCommentCardHeaderProps> = ({
                                                                 currentUser,
                                                                 post,
                                                                 onStartEdit,
                                                                 onDelete,
                                                                 onOpenPostReport,
                                                                 showMenu
                                                               }) => {
  const {t} = useTranslation()
  const {postAuthor, postId, publishedAt} = post
  const { name, profileImageUri, deleted } = postAuthor
  const fullName = deleted ? t(translations.community.postCard.deletedUser) : name
  const [firstName, lastName] = fullName.split(' ')

  const {profile, mainRole} = currentUser
  const isAdmin = mainRole === UserProfileRole.ADMIN
  const isTeacher = mainRole === UserProfileRole.TEACHER
  const canEdit = isAdmin || isTeacher || profile.nickname === postAuthor.nickname

  const menuItems = [
    onStartEdit && canEdit && ({
      icon: pencilIcon,
      title: t(translations.community.postCard.editPost),
      onClick: onStartEdit,
    } as MenuItem),
    onDelete && canEdit && {
      icon: deleteIcon,
      title: t(translations.community.postCard.deletePost),
      onClick: onDelete,
    } as MenuItem,
    onOpenPostReport && {
      icon: '/images/icons/community/post-report-icon.svg',
      title: t(translations.community.postCard.reportPost),
      onClick: onOpenPostReport,
    } as MenuItem,
  ]

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        width: {xs: '200px', lg: 'unset'},
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'start',
          width: '100%',
        }}
      >
        <Box>
          <CommunityUserAvatar
            profileImageUri={deleted ? '' : profileImageUri}
            firstName={firstName}
            lastName={lastName}
            sx={{width: {xs: '34px', lg: '53px'}, height: {xs: '34px', lg: '53px'}}}
          />
        </Box>
        <Box sx={{textAlign: 'left', ml: '10px'}}>
          <Typography
            sx={{
              fontSize: {xs: '14px', lg: '16px'},
              lineHeight: {xs: '16px', lg: '19px'},
              letterSpacing: '0.5px', mb: {xs: '5px', lg: '9px'},
            }}
          >
            {fullName}
          </Typography>
          <CommunityDate>{dayjs(publishedAt).format(FULL_DATE_FORMAT)}</CommunityDate>
        </Box>
      </Box>
      {showMenu && menuItems.length > 0 && (
        <CommunityMenu menuItems={menuItems} keyPrefix={`popular-${postId}`} />
      )}
    </Box>
  )
}
export default PopularPollCardHeader
