import { useHistory } from 'react-router-dom'

import useMutationWithSnackbar from '../useMutationWithSnackbar'
import { createStream } from 'services/community/streamService'

const useCreateStream = () => {
  const history = useHistory()

  return useMutationWithSnackbar((data: CreateStreamRequest) => createStream(data),
    undefined,
    undefined,
    {
      async onSuccess(stream) {
        await history.push(`/classes/${stream.classId}/streams/${stream.id}/edit`)
      },
    },
  )
}

export default useCreateStream
