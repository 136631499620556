import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { QuizAttemptResultsInterface } from 'types/QuizAttemptResultsInterface'
import { getQuizAttemptResults } from 'services/quizAttemtService'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useQuizAttemptResults = (
  quizAttemptId: string,
  options?: UseQueryOptions<QuizAttemptResultsInterface, AxiosError>,
): UseQueryResult<QuizAttemptResultsInterface> =>
  useQueryWithSnackbar<QuizAttemptResultsInterface>(
    [ReactQueryKeys.QUIZ_ATTEMPT_RESULTS, quizAttemptId],
    () => getQuizAttemptResults(quizAttemptId),
    {
      enabled: !!quizAttemptId,
      ...options,
    },
  )


export default useQuizAttemptResults
