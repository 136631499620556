import Login from './Login.page'
import Lesson from './Lesson.page'
import Home from './Home.page'
import NotFound from './NotFound.page'
import PracticeQuizCreate from './PracticeQuizCreate.page'
import PracticeTopicQuizCreate from './PracticeTopicQuizCreate.page'
import Profile from './Profile.page'
import ComingSoonPage from './ComingSoon.page'
import Course from './Course.page'
import Courses from './Courses.page'
import CoursesInProgress from './CoursesInProgress.page'
import QuizAttemptResultsPage from './QuizAttemptResults.page'
import StatisticsPage from './Statistics.page'
import QuizAttemptPage from './QuizAttempt.page'
import QuizCompletionConfirmationPage from './QuizCompletionConfirmation.page'
import QuizStartSimulationPage from './QuizStartSimulation.page'
import PreviousYearQuizzesPage from './PreviousYearQuizzes.page'
import QuizStartOfficialNationalSimulationPage from './QuizStartOfficialNationalSimulation.page'
import QuizStartStaticPracticePage from './QuizStartStaticPractice.page'
import QuizAttemptReviewPage from './QuizAttemptReview.page'
import RankingPage from './Ranking.page'
import ForgotPasswordPage from './ForgotPassword.page'
import ResetPasswordPage from './ResetPassword.page'
import ProfileLoginInformationPage from './ProfileLoginInformation.page'
import ProfileNotificationPreferencesPage from './ProfileNotificationPreferences.page'
import CommunityPage from './Community.page'
import CommunityClassPage from './CommunityClass.page'
import AccessDeniedPage from './AccessDenied.page'
import LessonEditor from './LessonEditor.page'
import StreamListPage from './StreamList.page'
import StreamCreatePage from 'pages/StreamCreate.page'
import NotificationsPage from './Notifications.page'
import StreamShowPage from './StreamShow.page'
import BlogListPage from './BlogList.page'
import QuizzesProMedPage from './pro-med/QuizzesProMed.page'
import QuizzesWeTestPage from './we-test/QuizzesWeTest.page'
import BlogShowPage from './BlogShow.page'
import StaticPracticeQuizzesPage from './StaticPracticeQuizzes.page'
import PackSimulationsPage from './PackSimulationsPage.page'
import QuizStartPackSimulationPage from './QuizStartPackSimulationPage.page'

const pages = {
  Home,
  Profile,
  ProfileLoginInformationPage,
  ProfileNotificationPreferencesPage,
  RankingPage,
  Lesson,
  Login,
  CommunityPage,
  CommunityClassPage,
  ComingSoonPage,
  Courses,
  CoursesInProgress,
  Course,
  QuizzesProMedPage,
  QuizzesWeTestPage,
  QuizStartSimulationPage,
  QuizStartOfficialNationalSimulationPage,
  QuizStartPackSimulationPage,
  PackSimulationsPage,
  PreviousYearQuizzesPage,
  PracticeQuizCreate,
  PracticeTopicQuizCreate,
  QuizAttemptPage,
  QuizCompletionConfirmationPage,
  QuizAttemptResultsPage,
  QuizAttemptReviewPage,
  StatisticsPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  NotFound,
  AccessDeniedPage,
  LessonEditor,
  StreamListPage,
  StreamCreatePage,
  StreamShowPage,
  NotificationsPage,
  BlogListPage,
  BlogShowPage,
  QuizStartStaticPracticePage,
  StaticPracticeQuizzesPage
}

export default pages
