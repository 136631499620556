import React, { FC } from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { List, ListItem } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { translations } from 'locales/i18n'

const ProfileNavigation: FC = () => {
  const {t} = useTranslation()
  const location = useLocation()

  const navs = [
    {link: '/profile', title: t(translations.profilePage.profile)},
    {link: '/profile/login-information', title: t(translations.profilePage.loginInformation)},
    {link: '/profile/notification-preferences', title: t(translations.profilePage.notifPreferences)},
  ]

  return (
    <List>
      {navs.map(({link, title}, index) => (
        <ListItem key={index} component={"li"} sx={{
          p: '0',
          pb: {xs: '10px', sm: '15px'},
          fontSize: {xs: '14px', sm: '16px'},
          lineHeight: {xs: '14px', sm: '19px'},
          textTransform: 'none',
          opacity: `${location.pathname === link ? '100%' : '50%'}`,
        }}>

          <Link to={link} style={{textDecoration: "none", color: "white"}}>
            {title}
          </Link>
        </ListItem>
      ))}
    </List>
  )
}

export default ProfileNavigation

