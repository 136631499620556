import { CourseSearchView } from "../types/CourseSearchView"

export const getCourseContinueLink = (course: CourseSearchView) =>
  course.lastVisitedLessonId ? getCourseLessonLink(course) : getCourseLink(course)

export const getCourseLink = (course: CourseSearchView) =>
  `/courses/${course.courseId}`

export const getCourseLessonLink = (course: CourseSearchView) =>
  `/courses/${course.courseId}/lessons/${course.lastVisitedLessonId}`
