import React, { useState } from 'react'
import { useQueryClient } from 'react-query'

import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import Profile from 'components/profile/update-profile/Profile'
import useMe from 'hooks/useMe'
import LoadingIndicator from 'components/shared/loading-indicator'
import ReactQueryKeys from 'types/ReactQueryKeys'

const ProfilePage = () => {
  const queryClient = useQueryClient()

  const [resetingForm, setResetingForm] = useState(false)

  const meQuery = useMe()
  const me = meQuery.data

  const handleResetForm = async () => {
    setResetingForm(true)
    await queryClient.invalidateQueries(ReactQueryKeys.ME)
    setResetingForm(false)
  }

  return (
    <DashboardLayout>
      {me && <Profile currentUser={me} onResetForm={handleResetForm} resetingForm={resetingForm}/>}
      {meQuery.isLoading && <LoadingIndicator/>}
    </DashboardLayout>
  )
}

export default ProfilePage
