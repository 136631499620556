import React, { FC } from 'react'
import { Grid } from '@mui/material'

type CurrentSlideIndicatorProps = {
  slides: any[]
  currentIndex: number
}

const CurrentSlideIndicator: FC<CurrentSlideIndicatorProps> = ({slides = [], currentIndex}) => (
  <Grid container sx={{width: '125px', height: '5px', bgcolor: 'white', borderRadius: '10px'}}>
    {slides.map((slide, index) => (
      <Grid
        item
        key={`slide-bar-${new Date()}-${index}`}
        className={`${(index === currentIndex) ? 'u-bg-froly' : ''}`}
        sx={{width: `${100 / slides.length}%`, borderRadius: '10px'}}
      />
    ))}
  </Grid>
)

export default CurrentSlideIndicator
