import React from 'react'
import { RouteComponentProps } from 'react-router'

import { Back } from 'components/shared/back'
import DashboardLayout from 'components/layouts/dashboard/DashboardLayout'
import QuizStartConfirmation from 'components/quizzes/QuizStartConfirmation'
import { QuizType } from 'types/QuizType'
import useStartOfficialNationalSimulation from 'hooks/useStartOfficialNationalSimulation'
import useQuizStartInfo from 'hooks/useQuizStartInfo'


function QuizStartOfficialNationalSimulationPage(
  props: RouteComponentProps<{ quizId: string, }>,
) {
  const quizId = props.match.params.quizId || ''
  const type = QuizType.OFFICIAL_NATIONAL_SIMULATION
  const startOfficialNationalSimulationMutation = useStartOfficialNationalSimulation()
  const quizStartInfo = useQuizStartInfo(type, quizId).data
  const handleStartQuiz = () => startOfficialNationalSimulationMutation.mutate(quizId)

  return (
    <DashboardLayout>
      <Back text="QUIZZES" url={'/quizzes'}/>
      {quizStartInfo && <QuizStartConfirmation
        dividedBySections={quizStartInfo?.dividedBySections}
        quizType={QuizType.OFFICIAL_NATIONAL_SIMULATION}
        handleStartQuiz={handleStartQuiz}
        questionsCount={quizStartInfo?.questionAmount}
        maxSimulationMinutesAllowed={quizStartInfo?.timeLimit / 60}
      />}
    </DashboardLayout>
  )
}

export default QuizStartOfficialNationalSimulationPage
