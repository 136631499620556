import { UseQueryResult } from 'react-query'
import { UseQueryOptions } from 'react-query/types/react/types'
import { AxiosError } from 'axios'

import useQueryWithSnackbar from '../useQueryWithSnackbar'
import ReactQueryKeys from 'types/ReactQueryKeys'
import { getPosts } from 'services/community/postService'
import { Posts } from 'types/Posts'
import { ClassTab } from 'types/ClassTab'

const usePosts = (
  classId: string,
  classTab: ClassTab,
  page: number,
  searchQuery: string,
  favouritesOnly: boolean,
  pinnedOnly: boolean,
  options?: UseQueryOptions<Posts, AxiosError>,
): UseQueryResult<Posts> =>
  useQueryWithSnackbar<Posts>(
    [ReactQueryKeys.POSTS, classId, classTab, page, searchQuery, favouritesOnly, pinnedOnly],
    () => getPosts(classId, classTab, page, searchQuery, favouritesOnly, pinnedOnly), {
      enabled: !!classId,
      ...options,
    },
  )

export default usePosts
