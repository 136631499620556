import { UseQueryOptions, UseQueryResult } from 'react-query'
import { AxiosError } from 'axios'

import ReactQueryKeys from 'types/ReactQueryKeys'
import { getOfficialNationalSimulationCategoryItems } from 'services/quizService'
import { OfficialNationalSimulationCategoryItem } from 'types/OfficialNationalSimulationCategoryItem'
import { Specialty } from 'types/Specialty'
import useQueryWithSnackbar from './useQueryWithSnackbar'

const useOfficialNationalSimulationCategoryItems = (
  speciality?: Specialty,
  options?: UseQueryOptions<OfficialNationalSimulationCategoryItem[], AxiosError>,
): UseQueryResult<OfficialNationalSimulationCategoryItem[]> => {
  return useQueryWithSnackbar<OfficialNationalSimulationCategoryItem[]>(
    [ReactQueryKeys.ARCHIVE_SIMULATION_CATEGORY, speciality],
    () => getOfficialNationalSimulationCategoryItems(speciality),
    {
      enabled: !!speciality,
      ...options,
    })
}

export default useOfficialNationalSimulationCategoryItems
