import React, { FC } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Button, Typography } from '@mui/material'

import { QuizAttemptStatisticsInterface } from 'types/QuizAttemptStatisticsInterface'
import getQuizTypeTitle from 'components/shared/getQuizTypeTitle'
import examMode from 'images/exam-mode.svg'
import { translations } from 'locales/i18n'
import { getDurationFormatted } from 'utils/duration'
import { QuizType } from 'types/QuizType'
import TitilePracticeQuiz from 'components/quiz-attempt/components/PracticeQuizTitle'
import { QuizAttemptInterface } from 'types/QuizAttemptInterface'

interface QuizCompletionConfirmationProps {
  quizAttemptStatistics: QuizAttemptStatisticsInterface
  isLoading: boolean
  quizAttempt: QuizAttemptInterface
  isDividedBySections?: boolean
  onCompleteQuizAttempt: () => void
  groupName?: string
}

const QuizCompletionConfirmation: FC<QuizCompletionConfirmationProps> = ({ quizAttemptStatistics, isDividedBySections, quizAttempt, groupName, onCompleteQuizAttempt, isLoading}) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id, answeredQuestions, totalQuestions, quizType, createdAt, completedAt } = quizAttemptStatistics
  const lastElemSectionId = quizType !== QuizType.PRACTICE && quizType !== QuizType.LESSON && quizAttempt.quizRule.sections[quizAttempt.quizRule.sections.length - 1].id
  const quizWithSections = isDividedBySections && quizType !== QuizType.PRACTICE && quizType !== QuizType.LESSON

  return (
    <Box sx={{ mt: 15 }}>
      <Box display="flex" justifyContent="center">
        <Box>
          <Box className="u-mb-15 u-fs-34 u-lh-41">
          {getQuizTypeTitle(quizType,'',groupName)}
          </Box>
          { quizType !== QuizType.PRACTICE &&
            <Box display="flex" justifyContent="center" alignItems="center" className="u-fs-16 u-lh-19 u-mb-44">
              <img
                src={examMode}
                className="u-pr-15"
                alt=""
                role="presentation"
              />
              <span>{t(translations.quizCompletionConfirmation.examMode)}</span>
            </Box>
          }
        </Box>
      </Box>
      <Box display="flex" justifyContent="center" className='u-mb-44'>
        {quizType === QuizType.LESSON && 
          <Box justifyContent="center" textAlign="center" alignItems="center" width='600px' className="u-mb-15 u-fs-34 u-lh-41">
            {quizAttemptStatistics.quizAttemptDetails.lessonName}
          </Box>
        }
        <TitilePracticeQuiz
         statistics={quizAttemptStatistics}
         width='600px'
        />
      </Box>
      <Box display="flex" justifyContent="center">
        <Box sx={{
          width: { xs: '100%', md: '600px' },
          height: { md: '340px' },
          background: theme => theme.palette.background.paper,
          borderRadius: '3px',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <Box sx={{
            display: 'flex',
            width: '32%',
            justifyContent: 'space-between',
            mt: { xs: '22px', sm: '46px' },
            alignItems: 'center',
          }}>
            <Box sx={{ display: 'flex', ml: { sm: 1 }, mr: { xs: '10px', sm: '0px' } }}>
              <img src="/images/icons/correctAnswerMonochromatic.svg" alt="correctAnswerMonochromatic"/>
              <Typography sx={{ fontSize: { xs: '12px', sm: '16px' }, ml: 1 }}>
                {answeredQuestions}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex' }}>
              <img src="/images/icons/timer.svg" alt="timer"/>
              <Typography sx={{ fontSize: { xs: '12px', sm: '16px' }, ml: 1 }}>
                {getDurationFormatted(createdAt, completedAt)}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ textAlign: 'center', mt: '17px' }}>
            <Box sx={{
              fontSize: { xs: '14px', sm: '24px' },
              lineHeight: '1.41',
              fontWeight: 'bold',
              width: { xs: '100%', md: '424px' },
              padding: { xs: '0px 20px', md: 0 },
            }}
            >
              {t(translations.quizCompletionConfirmation.answeredQuestionsOfTotal, {
                answeredQuestions,
                totalQuestions,
              })}
            </Box>
            <Box sx={{
              fontSize: { xs: '12px', sm: '16px' },
              lineHeight: { xs: '1.75', sm: '1.38' },
              mt: '17px',
            }}
            >
              {t(translations.quizCompletionConfirmation.areYouReady)}
            </Box>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '75%',
            mt: { xs: '30px', sm: '50px' },
            pb: { xs: '30px', md: '0' },
          }}
          >
              <Button
                variant="contained"
                type="button"
                color="secondary"
                sx={{
                  color: 'white',
                  width: '48%',
                  minHeight: { xs: '40px', md: '50px' },
                  textTransform: 'none',
                  padding: { xs: '12px 20px', sm: 0 },
                }}
                onClick={async() => {
                  await history.push(`/quiz-attempts/${id}`);
                  quizAttempt.activeSectionId = lastElemSectionId
                }}
              >
                <img
                  src="/images/icons/arrowLeft.svg"
                  alt="arrow left"
                  className="u-pr-10"
                />
                <Typography sx={{
                  fontSize: { xs: '12px', sm: '16px' },
                  lineHeight: '1.2',
                }}>
                { quizWithSections === true
                  ? t(translations.quizCompletionConfirmation.backToLastSection)
                  : t(translations.quizCompletionConfirmation.skippedQuestions)}
                </Typography>
              </Button>
            <Button
              variant="contained"
              sx={{
                width: '48%',
                padding: { xs: '12px 20px', sm: 0 },
              }}
              disabled={isLoading}
              onClick={onCompleteQuizAttempt}
            >
              <Typography sx={{
                fontSize: { xs: '12px', sm: '16px' },
                lineHeight: '1.2',
                textTransform: 'none',
              }}
              >
                {t(translations.quizCompletionConfirmation.submitAndScore)}
              </Typography>
              <img
                src="/images/icons/arrowRight.svg"
                alt="arrow right"
                className="u-pl-10"
              />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default QuizCompletionConfirmation
