import { UseMutationResult } from 'react-query/types/react/types'
import { AxiosError } from 'axios'

import { createQuizAttemptQuestionFlag } from 'services/quizAttemtService'
import useMutationWithSnackbar from './useMutationWithSnackbar'

const useCreateQuizAttemptQuestionFlag = (): UseMutationResult<void, AxiosError, QuizAttemptQuestionTagRequest> => useMutationWithSnackbar(
    (data: QuizAttemptQuestionTagRequest) => createQuizAttemptQuestionFlag(data),
    undefined,
    undefined,
    {},
  )

export default useCreateQuizAttemptQuestionFlag
