import { useCallback } from 'react'
import { useQueryClient } from 'react-query'

import ReactQueryKeys from 'types/ReactQueryKeys'

const useInvalidateQueriesAfterLogin = (): () => Promise<void> => {
  const queryClient = useQueryClient()

  return useCallback(
    async () => {
      await Promise.all([
        queryClient.invalidateQueries(ReactQueryKeys.COURSES),
        queryClient.invalidateQueries(ReactQueryKeys.COURSE),
        queryClient.invalidateQueries(ReactQueryKeys.SEARCH_LESSONS),
        queryClient.invalidateQueries(ReactQueryKeys.LESSON),
        queryClient.invalidateQueries(ReactQueryKeys.SUBJECTS),
        queryClient.invalidateQueries(ReactQueryKeys.SUBJECT),
        queryClient.invalidateQueries(ReactQueryKeys.SUBJECT_TOPICS),
        queryClient.invalidateQueries(ReactQueryKeys.POSTS),
      ])
    },
    [queryClient],
  )
}

export default useInvalidateQueriesAfterLogin
