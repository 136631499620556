import { useMutation } from 'react-query'
import { useTranslation } from 'react-i18next'
import { MutationFunction } from 'react-query/types/core/types'
import { UseMutationOptions, UseMutationResult } from 'react-query/types/react/types'
import { useSnackbar } from 'notistack'
import { AxiosError } from 'axios'
import { isEmpty } from 'lodash'

import handleAxiosError from './handleAxiosError'

function useMutationWithSnackbar<TData = unknown, TVariables = void, TContext = unknown>(
  mutationFn: MutationFunction<TData, TVariables>,
  errorKeyPrefix?: string,
  successKey?: string,
  options?: Omit<UseMutationOptions<TData, AxiosError, TVariables, TContext>, 'mutationFn'>,
): UseMutationResult<TData, AxiosError, TVariables, TContext> {
  const {t} = useTranslation()
  const {enqueueSnackbar} = useSnackbar()

  return useMutation<TData, AxiosError, TVariables, TContext>(mutationFn, {
    async onSuccess() {
      if (successKey && !isEmpty(successKey)) {
        enqueueSnackbar(t(successKey), {variant: 'success'})
      }
    },
    async onError(error) {
      handleAxiosError(error, enqueueSnackbar, errorKeyPrefix)
    },
    ...options,
  })
}

export default useMutationWithSnackbar
