import React, { FC, ReactElement, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import { SxProps } from '@mui/system'
import { Theme } from '@mui/material/styles'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.min.css'

import CarouselButtons from './CarouselButtons'
import CurrentSlideIndicator from './CurrentSlideIndicator'


type SwipeableViewsProps = {
  displayButtons?: boolean
  views?: any[]
  slideRenderer: (index: number) => ReactElement
  slidesPerView?: number | 'auto'
  lastItemSx?: SxProps<Theme>
  spaceBetween?: number
  hideIndicator?: boolean
}

const SwipeableViews: FC<SwipeableViewsProps> = ({
                                                   displayButtons = true,
                                                   views = [],
                                                   slideRenderer,
                                                   slidesPerView = 1,
                                                   spaceBetween = 0,
                                                   lastItemSx,
                                                   hideIndicator
                                                 }) => {
  const [swiper, setSwiper] = useState<any>(null)
  const [currentSlideIndex, setCurrentCourseIndex] = React.useState(0)

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(currentSlideIndex)
    }
  }, [currentSlideIndex])

  const handlePreviousClick = () => {
    if (currentSlideIndex !== 0) {
      setCurrentCourseIndex(currentSlideIndex - 1)
    }
  }

  const handleNextClick = () => {
    if (currentSlideIndex < views.length - 1) {
      setCurrentCourseIndex(currentSlideIndex + 1)
    }
  }

  return (
    <Box>
      {displayButtons && <CarouselButtons
          onPreviousClick={handlePreviousClick}
          onNextClick={handleNextClick}
      />}
      <Swiper
        spaceBetween={spaceBetween}
        slidesPerView={slidesPerView}
        onSwiper={(swiper) => setSwiper(swiper)}
        onSlideChange={({activeIndex}) => setCurrentCourseIndex(activeIndex)}
      >
        {views.map((item, index) => (
          <SwiperSlide key={index}>
            {index === views.length - 1 && slidesPerView === 'auto' ?
              <Box sx={lastItemSx}>{slideRenderer(index)}</Box> : slideRenderer(index)}
          </SwiperSlide>
        ))}
      </Swiper>
      {!hideIndicator && displayButtons && <Box sx={{mt: {xs: '20px', md: '40px'}}}>
          <CurrentSlideIndicator slides={views} currentIndex={currentSlideIndex}/>
      </Box>}
    </Box>
  )

}

export default SwipeableViews
